/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.listener = (function() {
    
        /**
         * Namespace listener.
         * @exports listener
         * @namespace
         */
        var listener = {};
    
        listener.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof listener
             * @interface IRequest
             * @property {listener.IAuthorizationRequest|null} [authorizationRequest] Request authorizationRequest
             * @property {listener.IDeviceListRequest|null} [deviceListRequest] Request deviceListRequest
             * @property {listener.IServiceVersionRequest|null} [serviceVersionRequest] Request serviceVersionRequest
             * @property {listener.ISystemInformationRequest|null} [systemInformationRequest] Request systemInformationRequest
             * @property {listener.IStartMixedRecordingRequest|null} [startMixedRecordingRequest] Request startMixedRecordingRequest
             * @property {listener.IStopMixedRecordingRequest|null} [stopMixedRecordingRequest] Request stopMixedRecordingRequest
             * @property {listener.IEventBroadcasterRequest|null} [eventBroadcasterRequest] Request eventBroadcasterRequest
             * @property {listener.IJwtUpdate|null} [jwt] Request jwt
             */
    
            /**
             * Constructs a new Request.
             * @memberof listener
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {listener.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request authorizationRequest.
             * @member {listener.IAuthorizationRequest|null|undefined} authorizationRequest
             * @memberof listener.Request
             * @instance
             */
            Request.prototype.authorizationRequest = null;
    
            /**
             * Request deviceListRequest.
             * @member {listener.IDeviceListRequest|null|undefined} deviceListRequest
             * @memberof listener.Request
             * @instance
             */
            Request.prototype.deviceListRequest = null;
    
            /**
             * Request serviceVersionRequest.
             * @member {listener.IServiceVersionRequest|null|undefined} serviceVersionRequest
             * @memberof listener.Request
             * @instance
             */
            Request.prototype.serviceVersionRequest = null;
    
            /**
             * Request systemInformationRequest.
             * @member {listener.ISystemInformationRequest|null|undefined} systemInformationRequest
             * @memberof listener.Request
             * @instance
             */
            Request.prototype.systemInformationRequest = null;
    
            /**
             * Request startMixedRecordingRequest.
             * @member {listener.IStartMixedRecordingRequest|null|undefined} startMixedRecordingRequest
             * @memberof listener.Request
             * @instance
             */
            Request.prototype.startMixedRecordingRequest = null;
    
            /**
             * Request stopMixedRecordingRequest.
             * @member {listener.IStopMixedRecordingRequest|null|undefined} stopMixedRecordingRequest
             * @memberof listener.Request
             * @instance
             */
            Request.prototype.stopMixedRecordingRequest = null;
    
            /**
             * Request eventBroadcasterRequest.
             * @member {listener.IEventBroadcasterRequest|null|undefined} eventBroadcasterRequest
             * @memberof listener.Request
             * @instance
             */
            Request.prototype.eventBroadcasterRequest = null;
    
            /**
             * Request jwt.
             * @member {listener.IJwtUpdate|null|undefined} jwt
             * @memberof listener.Request
             * @instance
             */
            Request.prototype.jwt = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Request requestType.
             * @member {"authorizationRequest"|"deviceListRequest"|"serviceVersionRequest"|"systemInformationRequest"|"startMixedRecordingRequest"|"stopMixedRecordingRequest"|"eventBroadcasterRequest"|"jwt"|undefined} requestType
             * @memberof listener.Request
             * @instance
             */
            Object.defineProperty(Request.prototype, "requestType", {
                get: $util.oneOfGetter($oneOfFields = ["authorizationRequest", "deviceListRequest", "serviceVersionRequest", "systemInformationRequest", "startMixedRecordingRequest", "stopMixedRecordingRequest", "eventBroadcasterRequest", "jwt"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof listener.Request
             * @static
             * @param {listener.IRequest=} [properties] Properties to set
             * @returns {listener.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link listener.Request.verify|verify} messages.
             * @function encode
             * @memberof listener.Request
             * @static
             * @param {listener.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.authorizationRequest != null && Object.hasOwnProperty.call(message, "authorizationRequest"))
                    $root.listener.AuthorizationRequest.encode(message.authorizationRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.deviceListRequest != null && Object.hasOwnProperty.call(message, "deviceListRequest"))
                    $root.listener.DeviceListRequest.encode(message.deviceListRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.serviceVersionRequest != null && Object.hasOwnProperty.call(message, "serviceVersionRequest"))
                    $root.listener.ServiceVersionRequest.encode(message.serviceVersionRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.systemInformationRequest != null && Object.hasOwnProperty.call(message, "systemInformationRequest"))
                    $root.listener.SystemInformationRequest.encode(message.systemInformationRequest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.startMixedRecordingRequest != null && Object.hasOwnProperty.call(message, "startMixedRecordingRequest"))
                    $root.listener.StartMixedRecordingRequest.encode(message.startMixedRecordingRequest, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.stopMixedRecordingRequest != null && Object.hasOwnProperty.call(message, "stopMixedRecordingRequest"))
                    $root.listener.StopMixedRecordingRequest.encode(message.stopMixedRecordingRequest, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.eventBroadcasterRequest != null && Object.hasOwnProperty.call(message, "eventBroadcasterRequest"))
                    $root.listener.EventBroadcasterRequest.encode(message.eventBroadcasterRequest, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.jwt != null && Object.hasOwnProperty.call(message, "jwt"))
                    $root.listener.JwtUpdate.encode(message.jwt, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link listener.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.Request
             * @static
             * @param {listener.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof listener.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.authorizationRequest = $root.listener.AuthorizationRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.deviceListRequest = $root.listener.DeviceListRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.serviceVersionRequest = $root.listener.ServiceVersionRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.systemInformationRequest = $root.listener.SystemInformationRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.startMixedRecordingRequest = $root.listener.StartMixedRecordingRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.stopMixedRecordingRequest = $root.listener.StopMixedRecordingRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.eventBroadcasterRequest = $root.listener.EventBroadcasterRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.jwt = $root.listener.JwtUpdate.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof listener.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.authorizationRequest != null && message.hasOwnProperty("authorizationRequest")) {
                    properties.requestType = 1;
                    {
                        var error = $root.listener.AuthorizationRequest.verify(message.authorizationRequest);
                        if (error)
                            return "authorizationRequest." + error;
                    }
                }
                if (message.deviceListRequest != null && message.hasOwnProperty("deviceListRequest")) {
                    if (properties.requestType === 1)
                        return "requestType: multiple values";
                    properties.requestType = 1;
                    {
                        var error = $root.listener.DeviceListRequest.verify(message.deviceListRequest);
                        if (error)
                            return "deviceListRequest." + error;
                    }
                }
                if (message.serviceVersionRequest != null && message.hasOwnProperty("serviceVersionRequest")) {
                    if (properties.requestType === 1)
                        return "requestType: multiple values";
                    properties.requestType = 1;
                    {
                        var error = $root.listener.ServiceVersionRequest.verify(message.serviceVersionRequest);
                        if (error)
                            return "serviceVersionRequest." + error;
                    }
                }
                if (message.systemInformationRequest != null && message.hasOwnProperty("systemInformationRequest")) {
                    if (properties.requestType === 1)
                        return "requestType: multiple values";
                    properties.requestType = 1;
                    {
                        var error = $root.listener.SystemInformationRequest.verify(message.systemInformationRequest);
                        if (error)
                            return "systemInformationRequest." + error;
                    }
                }
                if (message.startMixedRecordingRequest != null && message.hasOwnProperty("startMixedRecordingRequest")) {
                    if (properties.requestType === 1)
                        return "requestType: multiple values";
                    properties.requestType = 1;
                    {
                        var error = $root.listener.StartMixedRecordingRequest.verify(message.startMixedRecordingRequest);
                        if (error)
                            return "startMixedRecordingRequest." + error;
                    }
                }
                if (message.stopMixedRecordingRequest != null && message.hasOwnProperty("stopMixedRecordingRequest")) {
                    if (properties.requestType === 1)
                        return "requestType: multiple values";
                    properties.requestType = 1;
                    {
                        var error = $root.listener.StopMixedRecordingRequest.verify(message.stopMixedRecordingRequest);
                        if (error)
                            return "stopMixedRecordingRequest." + error;
                    }
                }
                if (message.eventBroadcasterRequest != null && message.hasOwnProperty("eventBroadcasterRequest")) {
                    if (properties.requestType === 1)
                        return "requestType: multiple values";
                    properties.requestType = 1;
                    {
                        var error = $root.listener.EventBroadcasterRequest.verify(message.eventBroadcasterRequest);
                        if (error)
                            return "eventBroadcasterRequest." + error;
                    }
                }
                if (message.jwt != null && message.hasOwnProperty("jwt")) {
                    if (properties.requestType === 1)
                        return "requestType: multiple values";
                    properties.requestType = 1;
                    {
                        var error = $root.listener.JwtUpdate.verify(message.jwt);
                        if (error)
                            return "jwt." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.Request)
                    return object;
                var message = new $root.listener.Request();
                if (object.authorizationRequest != null) {
                    if (typeof object.authorizationRequest !== "object")
                        throw TypeError(".listener.Request.authorizationRequest: object expected");
                    message.authorizationRequest = $root.listener.AuthorizationRequest.fromObject(object.authorizationRequest);
                }
                if (object.deviceListRequest != null) {
                    if (typeof object.deviceListRequest !== "object")
                        throw TypeError(".listener.Request.deviceListRequest: object expected");
                    message.deviceListRequest = $root.listener.DeviceListRequest.fromObject(object.deviceListRequest);
                }
                if (object.serviceVersionRequest != null) {
                    if (typeof object.serviceVersionRequest !== "object")
                        throw TypeError(".listener.Request.serviceVersionRequest: object expected");
                    message.serviceVersionRequest = $root.listener.ServiceVersionRequest.fromObject(object.serviceVersionRequest);
                }
                if (object.systemInformationRequest != null) {
                    if (typeof object.systemInformationRequest !== "object")
                        throw TypeError(".listener.Request.systemInformationRequest: object expected");
                    message.systemInformationRequest = $root.listener.SystemInformationRequest.fromObject(object.systemInformationRequest);
                }
                if (object.startMixedRecordingRequest != null) {
                    if (typeof object.startMixedRecordingRequest !== "object")
                        throw TypeError(".listener.Request.startMixedRecordingRequest: object expected");
                    message.startMixedRecordingRequest = $root.listener.StartMixedRecordingRequest.fromObject(object.startMixedRecordingRequest);
                }
                if (object.stopMixedRecordingRequest != null) {
                    if (typeof object.stopMixedRecordingRequest !== "object")
                        throw TypeError(".listener.Request.stopMixedRecordingRequest: object expected");
                    message.stopMixedRecordingRequest = $root.listener.StopMixedRecordingRequest.fromObject(object.stopMixedRecordingRequest);
                }
                if (object.eventBroadcasterRequest != null) {
                    if (typeof object.eventBroadcasterRequest !== "object")
                        throw TypeError(".listener.Request.eventBroadcasterRequest: object expected");
                    message.eventBroadcasterRequest = $root.listener.EventBroadcasterRequest.fromObject(object.eventBroadcasterRequest);
                }
                if (object.jwt != null) {
                    if (typeof object.jwt !== "object")
                        throw TypeError(".listener.Request.jwt: object expected");
                    message.jwt = $root.listener.JwtUpdate.fromObject(object.jwt);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.Request
             * @static
             * @param {listener.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.authorizationRequest != null && message.hasOwnProperty("authorizationRequest")) {
                    object.authorizationRequest = $root.listener.AuthorizationRequest.toObject(message.authorizationRequest, options);
                    if (options.oneofs)
                        object.requestType = "authorizationRequest";
                }
                if (message.deviceListRequest != null && message.hasOwnProperty("deviceListRequest")) {
                    object.deviceListRequest = $root.listener.DeviceListRequest.toObject(message.deviceListRequest, options);
                    if (options.oneofs)
                        object.requestType = "deviceListRequest";
                }
                if (message.serviceVersionRequest != null && message.hasOwnProperty("serviceVersionRequest")) {
                    object.serviceVersionRequest = $root.listener.ServiceVersionRequest.toObject(message.serviceVersionRequest, options);
                    if (options.oneofs)
                        object.requestType = "serviceVersionRequest";
                }
                if (message.systemInformationRequest != null && message.hasOwnProperty("systemInformationRequest")) {
                    object.systemInformationRequest = $root.listener.SystemInformationRequest.toObject(message.systemInformationRequest, options);
                    if (options.oneofs)
                        object.requestType = "systemInformationRequest";
                }
                if (message.startMixedRecordingRequest != null && message.hasOwnProperty("startMixedRecordingRequest")) {
                    object.startMixedRecordingRequest = $root.listener.StartMixedRecordingRequest.toObject(message.startMixedRecordingRequest, options);
                    if (options.oneofs)
                        object.requestType = "startMixedRecordingRequest";
                }
                if (message.stopMixedRecordingRequest != null && message.hasOwnProperty("stopMixedRecordingRequest")) {
                    object.stopMixedRecordingRequest = $root.listener.StopMixedRecordingRequest.toObject(message.stopMixedRecordingRequest, options);
                    if (options.oneofs)
                        object.requestType = "stopMixedRecordingRequest";
                }
                if (message.eventBroadcasterRequest != null && message.hasOwnProperty("eventBroadcasterRequest")) {
                    object.eventBroadcasterRequest = $root.listener.EventBroadcasterRequest.toObject(message.eventBroadcasterRequest, options);
                    if (options.oneofs)
                        object.requestType = "eventBroadcasterRequest";
                }
                if (message.jwt != null && message.hasOwnProperty("jwt")) {
                    object.jwt = $root.listener.JwtUpdate.toObject(message.jwt, options);
                    if (options.oneofs)
                        object.requestType = "jwt";
                }
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof listener.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof listener.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.Request";
            };
    
            return Request;
        })();
    
        listener.JwtUpdate = (function() {
    
            /**
             * Properties of a JwtUpdate.
             * @memberof listener
             * @interface IJwtUpdate
             * @property {string|null} [token] JwtUpdate token
             */
    
            /**
             * Constructs a new JwtUpdate.
             * @memberof listener
             * @classdesc Represents a JwtUpdate.
             * @implements IJwtUpdate
             * @constructor
             * @param {listener.IJwtUpdate=} [properties] Properties to set
             */
            function JwtUpdate(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * JwtUpdate token.
             * @member {string} token
             * @memberof listener.JwtUpdate
             * @instance
             */
            JwtUpdate.prototype.token = "";
    
            /**
             * Creates a new JwtUpdate instance using the specified properties.
             * @function create
             * @memberof listener.JwtUpdate
             * @static
             * @param {listener.IJwtUpdate=} [properties] Properties to set
             * @returns {listener.JwtUpdate} JwtUpdate instance
             */
            JwtUpdate.create = function create(properties) {
                return new JwtUpdate(properties);
            };
    
            /**
             * Encodes the specified JwtUpdate message. Does not implicitly {@link listener.JwtUpdate.verify|verify} messages.
             * @function encode
             * @memberof listener.JwtUpdate
             * @static
             * @param {listener.IJwtUpdate} message JwtUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JwtUpdate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                return writer;
            };
    
            /**
             * Encodes the specified JwtUpdate message, length delimited. Does not implicitly {@link listener.JwtUpdate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.JwtUpdate
             * @static
             * @param {listener.IJwtUpdate} message JwtUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JwtUpdate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a JwtUpdate message from the specified reader or buffer.
             * @function decode
             * @memberof listener.JwtUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.JwtUpdate} JwtUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JwtUpdate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.JwtUpdate();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.token = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a JwtUpdate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.JwtUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.JwtUpdate} JwtUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JwtUpdate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a JwtUpdate message.
             * @function verify
             * @memberof listener.JwtUpdate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            JwtUpdate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                return null;
            };
    
            /**
             * Creates a JwtUpdate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.JwtUpdate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.JwtUpdate} JwtUpdate
             */
            JwtUpdate.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.JwtUpdate)
                    return object;
                var message = new $root.listener.JwtUpdate();
                if (object.token != null)
                    message.token = String(object.token);
                return message;
            };
    
            /**
             * Creates a plain object from a JwtUpdate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.JwtUpdate
             * @static
             * @param {listener.JwtUpdate} message JwtUpdate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            JwtUpdate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.token = "";
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                return object;
            };
    
            /**
             * Converts this JwtUpdate to JSON.
             * @function toJSON
             * @memberof listener.JwtUpdate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            JwtUpdate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for JwtUpdate
             * @function getTypeUrl
             * @memberof listener.JwtUpdate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            JwtUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.JwtUpdate";
            };
    
            return JwtUpdate;
        })();
    
        listener.AuthorizationRequest = (function() {
    
            /**
             * Properties of an AuthorizationRequest.
             * @memberof listener
             * @interface IAuthorizationRequest
             * @property {string|null} [payload] AuthorizationRequest payload
             */
    
            /**
             * Constructs a new AuthorizationRequest.
             * @memberof listener
             * @classdesc Represents an AuthorizationRequest.
             * @implements IAuthorizationRequest
             * @constructor
             * @param {listener.IAuthorizationRequest=} [properties] Properties to set
             */
            function AuthorizationRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AuthorizationRequest payload.
             * @member {string} payload
             * @memberof listener.AuthorizationRequest
             * @instance
             */
            AuthorizationRequest.prototype.payload = "";
    
            /**
             * Creates a new AuthorizationRequest instance using the specified properties.
             * @function create
             * @memberof listener.AuthorizationRequest
             * @static
             * @param {listener.IAuthorizationRequest=} [properties] Properties to set
             * @returns {listener.AuthorizationRequest} AuthorizationRequest instance
             */
            AuthorizationRequest.create = function create(properties) {
                return new AuthorizationRequest(properties);
            };
    
            /**
             * Encodes the specified AuthorizationRequest message. Does not implicitly {@link listener.AuthorizationRequest.verify|verify} messages.
             * @function encode
             * @memberof listener.AuthorizationRequest
             * @static
             * @param {listener.IAuthorizationRequest} message AuthorizationRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthorizationRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.payload);
                return writer;
            };
    
            /**
             * Encodes the specified AuthorizationRequest message, length delimited. Does not implicitly {@link listener.AuthorizationRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.AuthorizationRequest
             * @static
             * @param {listener.IAuthorizationRequest} message AuthorizationRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthorizationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AuthorizationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof listener.AuthorizationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.AuthorizationRequest} AuthorizationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthorizationRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.AuthorizationRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.payload = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AuthorizationRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.AuthorizationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.AuthorizationRequest} AuthorizationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthorizationRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AuthorizationRequest message.
             * @function verify
             * @memberof listener.AuthorizationRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuthorizationRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.payload != null && message.hasOwnProperty("payload"))
                    if (!$util.isString(message.payload))
                        return "payload: string expected";
                return null;
            };
    
            /**
             * Creates an AuthorizationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.AuthorizationRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.AuthorizationRequest} AuthorizationRequest
             */
            AuthorizationRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.AuthorizationRequest)
                    return object;
                var message = new $root.listener.AuthorizationRequest();
                if (object.payload != null)
                    message.payload = String(object.payload);
                return message;
            };
    
            /**
             * Creates a plain object from an AuthorizationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.AuthorizationRequest
             * @static
             * @param {listener.AuthorizationRequest} message AuthorizationRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthorizationRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.payload = "";
                if (message.payload != null && message.hasOwnProperty("payload"))
                    object.payload = message.payload;
                return object;
            };
    
            /**
             * Converts this AuthorizationRequest to JSON.
             * @function toJSON
             * @memberof listener.AuthorizationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthorizationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AuthorizationRequest
             * @function getTypeUrl
             * @memberof listener.AuthorizationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AuthorizationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.AuthorizationRequest";
            };
    
            return AuthorizationRequest;
        })();
    
        listener.DeviceListRequest = (function() {
    
            /**
             * Properties of a DeviceListRequest.
             * @memberof listener
             * @interface IDeviceListRequest
             */
    
            /**
             * Constructs a new DeviceListRequest.
             * @memberof listener
             * @classdesc Represents a DeviceListRequest.
             * @implements IDeviceListRequest
             * @constructor
             * @param {listener.IDeviceListRequest=} [properties] Properties to set
             */
            function DeviceListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new DeviceListRequest instance using the specified properties.
             * @function create
             * @memberof listener.DeviceListRequest
             * @static
             * @param {listener.IDeviceListRequest=} [properties] Properties to set
             * @returns {listener.DeviceListRequest} DeviceListRequest instance
             */
            DeviceListRequest.create = function create(properties) {
                return new DeviceListRequest(properties);
            };
    
            /**
             * Encodes the specified DeviceListRequest message. Does not implicitly {@link listener.DeviceListRequest.verify|verify} messages.
             * @function encode
             * @memberof listener.DeviceListRequest
             * @static
             * @param {listener.IDeviceListRequest} message DeviceListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified DeviceListRequest message, length delimited. Does not implicitly {@link listener.DeviceListRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.DeviceListRequest
             * @static
             * @param {listener.IDeviceListRequest} message DeviceListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceListRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DeviceListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof listener.DeviceListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.DeviceListRequest} DeviceListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.DeviceListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DeviceListRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.DeviceListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.DeviceListRequest} DeviceListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceListRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DeviceListRequest message.
             * @function verify
             * @memberof listener.DeviceListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeviceListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a DeviceListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.DeviceListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.DeviceListRequest} DeviceListRequest
             */
            DeviceListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.DeviceListRequest)
                    return object;
                return new $root.listener.DeviceListRequest();
            };
    
            /**
             * Creates a plain object from a DeviceListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.DeviceListRequest
             * @static
             * @param {listener.DeviceListRequest} message DeviceListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeviceListRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this DeviceListRequest to JSON.
             * @function toJSON
             * @memberof listener.DeviceListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeviceListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DeviceListRequest
             * @function getTypeUrl
             * @memberof listener.DeviceListRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeviceListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.DeviceListRequest";
            };
    
            return DeviceListRequest;
        })();
    
        listener.EventBroadcasterRequest = (function() {
    
            /**
             * Properties of an EventBroadcasterRequest.
             * @memberof listener
             * @interface IEventBroadcasterRequest
             */
    
            /**
             * Constructs a new EventBroadcasterRequest.
             * @memberof listener
             * @classdesc Represents an EventBroadcasterRequest.
             * @implements IEventBroadcasterRequest
             * @constructor
             * @param {listener.IEventBroadcasterRequest=} [properties] Properties to set
             */
            function EventBroadcasterRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new EventBroadcasterRequest instance using the specified properties.
             * @function create
             * @memberof listener.EventBroadcasterRequest
             * @static
             * @param {listener.IEventBroadcasterRequest=} [properties] Properties to set
             * @returns {listener.EventBroadcasterRequest} EventBroadcasterRequest instance
             */
            EventBroadcasterRequest.create = function create(properties) {
                return new EventBroadcasterRequest(properties);
            };
    
            /**
             * Encodes the specified EventBroadcasterRequest message. Does not implicitly {@link listener.EventBroadcasterRequest.verify|verify} messages.
             * @function encode
             * @memberof listener.EventBroadcasterRequest
             * @static
             * @param {listener.IEventBroadcasterRequest} message EventBroadcasterRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EventBroadcasterRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified EventBroadcasterRequest message, length delimited. Does not implicitly {@link listener.EventBroadcasterRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.EventBroadcasterRequest
             * @static
             * @param {listener.IEventBroadcasterRequest} message EventBroadcasterRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EventBroadcasterRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EventBroadcasterRequest message from the specified reader or buffer.
             * @function decode
             * @memberof listener.EventBroadcasterRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.EventBroadcasterRequest} EventBroadcasterRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EventBroadcasterRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.EventBroadcasterRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EventBroadcasterRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.EventBroadcasterRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.EventBroadcasterRequest} EventBroadcasterRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EventBroadcasterRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EventBroadcasterRequest message.
             * @function verify
             * @memberof listener.EventBroadcasterRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EventBroadcasterRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an EventBroadcasterRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.EventBroadcasterRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.EventBroadcasterRequest} EventBroadcasterRequest
             */
            EventBroadcasterRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.EventBroadcasterRequest)
                    return object;
                return new $root.listener.EventBroadcasterRequest();
            };
    
            /**
             * Creates a plain object from an EventBroadcasterRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.EventBroadcasterRequest
             * @static
             * @param {listener.EventBroadcasterRequest} message EventBroadcasterRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EventBroadcasterRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this EventBroadcasterRequest to JSON.
             * @function toJSON
             * @memberof listener.EventBroadcasterRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EventBroadcasterRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EventBroadcasterRequest
             * @function getTypeUrl
             * @memberof listener.EventBroadcasterRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EventBroadcasterRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.EventBroadcasterRequest";
            };
    
            return EventBroadcasterRequest;
        })();
    
        /**
         * AudioEncoding enum.
         * @name listener.AudioEncoding
         * @enum {number}
         * @property {number} LINEAR16=0 LINEAR16 value
         * @property {number} FLAC=1 FLAC value
         * @property {number} OPUS=2 OPUS value
         */
        listener.AudioEncoding = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "LINEAR16"] = 0;
            values[valuesById[1] = "FLAC"] = 1;
            values[valuesById[2] = "OPUS"] = 2;
            return values;
        })();
    
        listener.StartMixedRecordingRequest = (function() {
    
            /**
             * Properties of a StartMixedRecordingRequest.
             * @memberof listener
             * @interface IStartMixedRecordingRequest
             * @property {Array.<listener.IAudioDevice>|null} [audioDevices] StartMixedRecordingRequest audioDevices
             * @property {listener.AudioEncoding|null} [encoding] StartMixedRecordingRequest encoding
             */
    
            /**
             * Constructs a new StartMixedRecordingRequest.
             * @memberof listener
             * @classdesc Represents a StartMixedRecordingRequest.
             * @implements IStartMixedRecordingRequest
             * @constructor
             * @param {listener.IStartMixedRecordingRequest=} [properties] Properties to set
             */
            function StartMixedRecordingRequest(properties) {
                this.audioDevices = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StartMixedRecordingRequest audioDevices.
             * @member {Array.<listener.IAudioDevice>} audioDevices
             * @memberof listener.StartMixedRecordingRequest
             * @instance
             */
            StartMixedRecordingRequest.prototype.audioDevices = $util.emptyArray;
    
            /**
             * StartMixedRecordingRequest encoding.
             * @member {listener.AudioEncoding} encoding
             * @memberof listener.StartMixedRecordingRequest
             * @instance
             */
            StartMixedRecordingRequest.prototype.encoding = 0;
    
            /**
             * Creates a new StartMixedRecordingRequest instance using the specified properties.
             * @function create
             * @memberof listener.StartMixedRecordingRequest
             * @static
             * @param {listener.IStartMixedRecordingRequest=} [properties] Properties to set
             * @returns {listener.StartMixedRecordingRequest} StartMixedRecordingRequest instance
             */
            StartMixedRecordingRequest.create = function create(properties) {
                return new StartMixedRecordingRequest(properties);
            };
    
            /**
             * Encodes the specified StartMixedRecordingRequest message. Does not implicitly {@link listener.StartMixedRecordingRequest.verify|verify} messages.
             * @function encode
             * @memberof listener.StartMixedRecordingRequest
             * @static
             * @param {listener.IStartMixedRecordingRequest} message StartMixedRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartMixedRecordingRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.audioDevices != null && message.audioDevices.length)
                    for (var i = 0; i < message.audioDevices.length; ++i)
                        $root.listener.AudioDevice.encode(message.audioDevices[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.encoding != null && Object.hasOwnProperty.call(message, "encoding"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.encoding);
                return writer;
            };
    
            /**
             * Encodes the specified StartMixedRecordingRequest message, length delimited. Does not implicitly {@link listener.StartMixedRecordingRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.StartMixedRecordingRequest
             * @static
             * @param {listener.IStartMixedRecordingRequest} message StartMixedRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartMixedRecordingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StartMixedRecordingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof listener.StartMixedRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.StartMixedRecordingRequest} StartMixedRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartMixedRecordingRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.StartMixedRecordingRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.audioDevices && message.audioDevices.length))
                                message.audioDevices = [];
                            message.audioDevices.push($root.listener.AudioDevice.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.encoding = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StartMixedRecordingRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.StartMixedRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.StartMixedRecordingRequest} StartMixedRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartMixedRecordingRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StartMixedRecordingRequest message.
             * @function verify
             * @memberof listener.StartMixedRecordingRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StartMixedRecordingRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.audioDevices != null && message.hasOwnProperty("audioDevices")) {
                    if (!Array.isArray(message.audioDevices))
                        return "audioDevices: array expected";
                    for (var i = 0; i < message.audioDevices.length; ++i) {
                        var error = $root.listener.AudioDevice.verify(message.audioDevices[i]);
                        if (error)
                            return "audioDevices." + error;
                    }
                }
                if (message.encoding != null && message.hasOwnProperty("encoding"))
                    switch (message.encoding) {
                    default:
                        return "encoding: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a StartMixedRecordingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.StartMixedRecordingRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.StartMixedRecordingRequest} StartMixedRecordingRequest
             */
            StartMixedRecordingRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.StartMixedRecordingRequest)
                    return object;
                var message = new $root.listener.StartMixedRecordingRequest();
                if (object.audioDevices) {
                    if (!Array.isArray(object.audioDevices))
                        throw TypeError(".listener.StartMixedRecordingRequest.audioDevices: array expected");
                    message.audioDevices = [];
                    for (var i = 0; i < object.audioDevices.length; ++i) {
                        if (typeof object.audioDevices[i] !== "object")
                            throw TypeError(".listener.StartMixedRecordingRequest.audioDevices: object expected");
                        message.audioDevices[i] = $root.listener.AudioDevice.fromObject(object.audioDevices[i]);
                    }
                }
                switch (object.encoding) {
                default:
                    if (typeof object.encoding === "number") {
                        message.encoding = object.encoding;
                        break;
                    }
                    break;
                case "LINEAR16":
                case 0:
                    message.encoding = 0;
                    break;
                case "FLAC":
                case 1:
                    message.encoding = 1;
                    break;
                case "OPUS":
                case 2:
                    message.encoding = 2;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a StartMixedRecordingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.StartMixedRecordingRequest
             * @static
             * @param {listener.StartMixedRecordingRequest} message StartMixedRecordingRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartMixedRecordingRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.audioDevices = [];
                if (options.defaults)
                    object.encoding = options.enums === String ? "LINEAR16" : 0;
                if (message.audioDevices && message.audioDevices.length) {
                    object.audioDevices = [];
                    for (var j = 0; j < message.audioDevices.length; ++j)
                        object.audioDevices[j] = $root.listener.AudioDevice.toObject(message.audioDevices[j], options);
                }
                if (message.encoding != null && message.hasOwnProperty("encoding"))
                    object.encoding = options.enums === String ? $root.listener.AudioEncoding[message.encoding] === undefined ? message.encoding : $root.listener.AudioEncoding[message.encoding] : message.encoding;
                return object;
            };
    
            /**
             * Converts this StartMixedRecordingRequest to JSON.
             * @function toJSON
             * @memberof listener.StartMixedRecordingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartMixedRecordingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StartMixedRecordingRequest
             * @function getTypeUrl
             * @memberof listener.StartMixedRecordingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartMixedRecordingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.StartMixedRecordingRequest";
            };
    
            return StartMixedRecordingRequest;
        })();
    
        listener.StopMixedRecordingRequest = (function() {
    
            /**
             * Properties of a StopMixedRecordingRequest.
             * @memberof listener
             * @interface IStopMixedRecordingRequest
             */
    
            /**
             * Constructs a new StopMixedRecordingRequest.
             * @memberof listener
             * @classdesc Represents a StopMixedRecordingRequest.
             * @implements IStopMixedRecordingRequest
             * @constructor
             * @param {listener.IStopMixedRecordingRequest=} [properties] Properties to set
             */
            function StopMixedRecordingRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new StopMixedRecordingRequest instance using the specified properties.
             * @function create
             * @memberof listener.StopMixedRecordingRequest
             * @static
             * @param {listener.IStopMixedRecordingRequest=} [properties] Properties to set
             * @returns {listener.StopMixedRecordingRequest} StopMixedRecordingRequest instance
             */
            StopMixedRecordingRequest.create = function create(properties) {
                return new StopMixedRecordingRequest(properties);
            };
    
            /**
             * Encodes the specified StopMixedRecordingRequest message. Does not implicitly {@link listener.StopMixedRecordingRequest.verify|verify} messages.
             * @function encode
             * @memberof listener.StopMixedRecordingRequest
             * @static
             * @param {listener.IStopMixedRecordingRequest} message StopMixedRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StopMixedRecordingRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified StopMixedRecordingRequest message, length delimited. Does not implicitly {@link listener.StopMixedRecordingRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.StopMixedRecordingRequest
             * @static
             * @param {listener.IStopMixedRecordingRequest} message StopMixedRecordingRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StopMixedRecordingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StopMixedRecordingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof listener.StopMixedRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.StopMixedRecordingRequest} StopMixedRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StopMixedRecordingRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.StopMixedRecordingRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StopMixedRecordingRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.StopMixedRecordingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.StopMixedRecordingRequest} StopMixedRecordingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StopMixedRecordingRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StopMixedRecordingRequest message.
             * @function verify
             * @memberof listener.StopMixedRecordingRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StopMixedRecordingRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a StopMixedRecordingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.StopMixedRecordingRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.StopMixedRecordingRequest} StopMixedRecordingRequest
             */
            StopMixedRecordingRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.StopMixedRecordingRequest)
                    return object;
                return new $root.listener.StopMixedRecordingRequest();
            };
    
            /**
             * Creates a plain object from a StopMixedRecordingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.StopMixedRecordingRequest
             * @static
             * @param {listener.StopMixedRecordingRequest} message StopMixedRecordingRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StopMixedRecordingRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this StopMixedRecordingRequest to JSON.
             * @function toJSON
             * @memberof listener.StopMixedRecordingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StopMixedRecordingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StopMixedRecordingRequest
             * @function getTypeUrl
             * @memberof listener.StopMixedRecordingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StopMixedRecordingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.StopMixedRecordingRequest";
            };
    
            return StopMixedRecordingRequest;
        })();
    
        listener.ServiceVersionRequest = (function() {
    
            /**
             * Properties of a ServiceVersionRequest.
             * @memberof listener
             * @interface IServiceVersionRequest
             */
    
            /**
             * Constructs a new ServiceVersionRequest.
             * @memberof listener
             * @classdesc Represents a ServiceVersionRequest.
             * @implements IServiceVersionRequest
             * @constructor
             * @param {listener.IServiceVersionRequest=} [properties] Properties to set
             */
            function ServiceVersionRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ServiceVersionRequest instance using the specified properties.
             * @function create
             * @memberof listener.ServiceVersionRequest
             * @static
             * @param {listener.IServiceVersionRequest=} [properties] Properties to set
             * @returns {listener.ServiceVersionRequest} ServiceVersionRequest instance
             */
            ServiceVersionRequest.create = function create(properties) {
                return new ServiceVersionRequest(properties);
            };
    
            /**
             * Encodes the specified ServiceVersionRequest message. Does not implicitly {@link listener.ServiceVersionRequest.verify|verify} messages.
             * @function encode
             * @memberof listener.ServiceVersionRequest
             * @static
             * @param {listener.IServiceVersionRequest} message ServiceVersionRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceVersionRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ServiceVersionRequest message, length delimited. Does not implicitly {@link listener.ServiceVersionRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.ServiceVersionRequest
             * @static
             * @param {listener.IServiceVersionRequest} message ServiceVersionRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceVersionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ServiceVersionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof listener.ServiceVersionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.ServiceVersionRequest} ServiceVersionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceVersionRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.ServiceVersionRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ServiceVersionRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.ServiceVersionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.ServiceVersionRequest} ServiceVersionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceVersionRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ServiceVersionRequest message.
             * @function verify
             * @memberof listener.ServiceVersionRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceVersionRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ServiceVersionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.ServiceVersionRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.ServiceVersionRequest} ServiceVersionRequest
             */
            ServiceVersionRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.ServiceVersionRequest)
                    return object;
                return new $root.listener.ServiceVersionRequest();
            };
    
            /**
             * Creates a plain object from a ServiceVersionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.ServiceVersionRequest
             * @static
             * @param {listener.ServiceVersionRequest} message ServiceVersionRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceVersionRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ServiceVersionRequest to JSON.
             * @function toJSON
             * @memberof listener.ServiceVersionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceVersionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ServiceVersionRequest
             * @function getTypeUrl
             * @memberof listener.ServiceVersionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceVersionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.ServiceVersionRequest";
            };
    
            return ServiceVersionRequest;
        })();
    
        listener.SystemInformationRequest = (function() {
    
            /**
             * Properties of a SystemInformationRequest.
             * @memberof listener
             * @interface ISystemInformationRequest
             */
    
            /**
             * Constructs a new SystemInformationRequest.
             * @memberof listener
             * @classdesc Represents a SystemInformationRequest.
             * @implements ISystemInformationRequest
             * @constructor
             * @param {listener.ISystemInformationRequest=} [properties] Properties to set
             */
            function SystemInformationRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new SystemInformationRequest instance using the specified properties.
             * @function create
             * @memberof listener.SystemInformationRequest
             * @static
             * @param {listener.ISystemInformationRequest=} [properties] Properties to set
             * @returns {listener.SystemInformationRequest} SystemInformationRequest instance
             */
            SystemInformationRequest.create = function create(properties) {
                return new SystemInformationRequest(properties);
            };
    
            /**
             * Encodes the specified SystemInformationRequest message. Does not implicitly {@link listener.SystemInformationRequest.verify|verify} messages.
             * @function encode
             * @memberof listener.SystemInformationRequest
             * @static
             * @param {listener.ISystemInformationRequest} message SystemInformationRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SystemInformationRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified SystemInformationRequest message, length delimited. Does not implicitly {@link listener.SystemInformationRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.SystemInformationRequest
             * @static
             * @param {listener.ISystemInformationRequest} message SystemInformationRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SystemInformationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SystemInformationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof listener.SystemInformationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.SystemInformationRequest} SystemInformationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SystemInformationRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.SystemInformationRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SystemInformationRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.SystemInformationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.SystemInformationRequest} SystemInformationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SystemInformationRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SystemInformationRequest message.
             * @function verify
             * @memberof listener.SystemInformationRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SystemInformationRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a SystemInformationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.SystemInformationRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.SystemInformationRequest} SystemInformationRequest
             */
            SystemInformationRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.SystemInformationRequest)
                    return object;
                return new $root.listener.SystemInformationRequest();
            };
    
            /**
             * Creates a plain object from a SystemInformationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.SystemInformationRequest
             * @static
             * @param {listener.SystemInformationRequest} message SystemInformationRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SystemInformationRequest.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this SystemInformationRequest to JSON.
             * @function toJSON
             * @memberof listener.SystemInformationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SystemInformationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SystemInformationRequest
             * @function getTypeUrl
             * @memberof listener.SystemInformationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SystemInformationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.SystemInformationRequest";
            };
    
            return SystemInformationRequest;
        })();
    
        listener.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof listener
             * @interface IResponse
             * @property {listener.IAuthorizationPrompt|null} [authorizationPrompt] Response authorizationPrompt
             * @property {listener.IServiceMessage|null} [serviceMessage] Response serviceMessage
             * @property {listener.IServiceVersion|null} [serviceVersion] Response serviceVersion
             * @property {listener.IActiveAudioDevices|null} [activeAudioDevices] Response activeAudioDevices
             * @property {listener.IDeviceEndpoint|null} [deviceEndpoint] Response deviceEndpoint
             * @property {listener.ISystemInformation|null} [systemInformation] Response systemInformation
             * @property {listener.IAudiosystemNotificatorEndpoint|null} [audiosystemNotificatorEndpoint] Response audiosystemNotificatorEndpoint
             * @property {listener.IEventBroadcasterEndpoint|null} [eventBroadcasterEndpoint] Response eventBroadcasterEndpoint
             */
    
            /**
             * Constructs a new Response.
             * @memberof listener
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {listener.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response authorizationPrompt.
             * @member {listener.IAuthorizationPrompt|null|undefined} authorizationPrompt
             * @memberof listener.Response
             * @instance
             */
            Response.prototype.authorizationPrompt = null;
    
            /**
             * Response serviceMessage.
             * @member {listener.IServiceMessage|null|undefined} serviceMessage
             * @memberof listener.Response
             * @instance
             */
            Response.prototype.serviceMessage = null;
    
            /**
             * Response serviceVersion.
             * @member {listener.IServiceVersion|null|undefined} serviceVersion
             * @memberof listener.Response
             * @instance
             */
            Response.prototype.serviceVersion = null;
    
            /**
             * Response activeAudioDevices.
             * @member {listener.IActiveAudioDevices|null|undefined} activeAudioDevices
             * @memberof listener.Response
             * @instance
             */
            Response.prototype.activeAudioDevices = null;
    
            /**
             * Response deviceEndpoint.
             * @member {listener.IDeviceEndpoint|null|undefined} deviceEndpoint
             * @memberof listener.Response
             * @instance
             */
            Response.prototype.deviceEndpoint = null;
    
            /**
             * Response systemInformation.
             * @member {listener.ISystemInformation|null|undefined} systemInformation
             * @memberof listener.Response
             * @instance
             */
            Response.prototype.systemInformation = null;
    
            /**
             * Response audiosystemNotificatorEndpoint.
             * @member {listener.IAudiosystemNotificatorEndpoint|null|undefined} audiosystemNotificatorEndpoint
             * @memberof listener.Response
             * @instance
             */
            Response.prototype.audiosystemNotificatorEndpoint = null;
    
            /**
             * Response eventBroadcasterEndpoint.
             * @member {listener.IEventBroadcasterEndpoint|null|undefined} eventBroadcasterEndpoint
             * @memberof listener.Response
             * @instance
             */
            Response.prototype.eventBroadcasterEndpoint = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Response responseType.
             * @member {"authorizationPrompt"|"serviceMessage"|"serviceVersion"|"activeAudioDevices"|"deviceEndpoint"|"systemInformation"|"audiosystemNotificatorEndpoint"|"eventBroadcasterEndpoint"|undefined} responseType
             * @memberof listener.Response
             * @instance
             */
            Object.defineProperty(Response.prototype, "responseType", {
                get: $util.oneOfGetter($oneOfFields = ["authorizationPrompt", "serviceMessage", "serviceVersion", "activeAudioDevices", "deviceEndpoint", "systemInformation", "audiosystemNotificatorEndpoint", "eventBroadcasterEndpoint"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof listener.Response
             * @static
             * @param {listener.IResponse=} [properties] Properties to set
             * @returns {listener.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link listener.Response.verify|verify} messages.
             * @function encode
             * @memberof listener.Response
             * @static
             * @param {listener.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.authorizationPrompt != null && Object.hasOwnProperty.call(message, "authorizationPrompt"))
                    $root.listener.AuthorizationPrompt.encode(message.authorizationPrompt, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.serviceMessage != null && Object.hasOwnProperty.call(message, "serviceMessage"))
                    $root.listener.ServiceMessage.encode(message.serviceMessage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.serviceVersion != null && Object.hasOwnProperty.call(message, "serviceVersion"))
                    $root.listener.ServiceVersion.encode(message.serviceVersion, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.activeAudioDevices != null && Object.hasOwnProperty.call(message, "activeAudioDevices"))
                    $root.listener.ActiveAudioDevices.encode(message.activeAudioDevices, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.deviceEndpoint != null && Object.hasOwnProperty.call(message, "deviceEndpoint"))
                    $root.listener.DeviceEndpoint.encode(message.deviceEndpoint, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.systemInformation != null && Object.hasOwnProperty.call(message, "systemInformation"))
                    $root.listener.SystemInformation.encode(message.systemInformation, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.audiosystemNotificatorEndpoint != null && Object.hasOwnProperty.call(message, "audiosystemNotificatorEndpoint"))
                    $root.listener.AudiosystemNotificatorEndpoint.encode(message.audiosystemNotificatorEndpoint, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.eventBroadcasterEndpoint != null && Object.hasOwnProperty.call(message, "eventBroadcasterEndpoint"))
                    $root.listener.EventBroadcasterEndpoint.encode(message.eventBroadcasterEndpoint, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link listener.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.Response
             * @static
             * @param {listener.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof listener.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.authorizationPrompt = $root.listener.AuthorizationPrompt.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.serviceMessage = $root.listener.ServiceMessage.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.serviceVersion = $root.listener.ServiceVersion.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.activeAudioDevices = $root.listener.ActiveAudioDevices.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.deviceEndpoint = $root.listener.DeviceEndpoint.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.systemInformation = $root.listener.SystemInformation.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.audiosystemNotificatorEndpoint = $root.listener.AudiosystemNotificatorEndpoint.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.eventBroadcasterEndpoint = $root.listener.EventBroadcasterEndpoint.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof listener.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.authorizationPrompt != null && message.hasOwnProperty("authorizationPrompt")) {
                    properties.responseType = 1;
                    {
                        var error = $root.listener.AuthorizationPrompt.verify(message.authorizationPrompt);
                        if (error)
                            return "authorizationPrompt." + error;
                    }
                }
                if (message.serviceMessage != null && message.hasOwnProperty("serviceMessage")) {
                    if (properties.responseType === 1)
                        return "responseType: multiple values";
                    properties.responseType = 1;
                    {
                        var error = $root.listener.ServiceMessage.verify(message.serviceMessage);
                        if (error)
                            return "serviceMessage." + error;
                    }
                }
                if (message.serviceVersion != null && message.hasOwnProperty("serviceVersion")) {
                    if (properties.responseType === 1)
                        return "responseType: multiple values";
                    properties.responseType = 1;
                    {
                        var error = $root.listener.ServiceVersion.verify(message.serviceVersion);
                        if (error)
                            return "serviceVersion." + error;
                    }
                }
                if (message.activeAudioDevices != null && message.hasOwnProperty("activeAudioDevices")) {
                    if (properties.responseType === 1)
                        return "responseType: multiple values";
                    properties.responseType = 1;
                    {
                        var error = $root.listener.ActiveAudioDevices.verify(message.activeAudioDevices);
                        if (error)
                            return "activeAudioDevices." + error;
                    }
                }
                if (message.deviceEndpoint != null && message.hasOwnProperty("deviceEndpoint")) {
                    if (properties.responseType === 1)
                        return "responseType: multiple values";
                    properties.responseType = 1;
                    {
                        var error = $root.listener.DeviceEndpoint.verify(message.deviceEndpoint);
                        if (error)
                            return "deviceEndpoint." + error;
                    }
                }
                if (message.systemInformation != null && message.hasOwnProperty("systemInformation")) {
                    if (properties.responseType === 1)
                        return "responseType: multiple values";
                    properties.responseType = 1;
                    {
                        var error = $root.listener.SystemInformation.verify(message.systemInformation);
                        if (error)
                            return "systemInformation." + error;
                    }
                }
                if (message.audiosystemNotificatorEndpoint != null && message.hasOwnProperty("audiosystemNotificatorEndpoint")) {
                    if (properties.responseType === 1)
                        return "responseType: multiple values";
                    properties.responseType = 1;
                    {
                        var error = $root.listener.AudiosystemNotificatorEndpoint.verify(message.audiosystemNotificatorEndpoint);
                        if (error)
                            return "audiosystemNotificatorEndpoint." + error;
                    }
                }
                if (message.eventBroadcasterEndpoint != null && message.hasOwnProperty("eventBroadcasterEndpoint")) {
                    if (properties.responseType === 1)
                        return "responseType: multiple values";
                    properties.responseType = 1;
                    {
                        var error = $root.listener.EventBroadcasterEndpoint.verify(message.eventBroadcasterEndpoint);
                        if (error)
                            return "eventBroadcasterEndpoint." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.Response)
                    return object;
                var message = new $root.listener.Response();
                if (object.authorizationPrompt != null) {
                    if (typeof object.authorizationPrompt !== "object")
                        throw TypeError(".listener.Response.authorizationPrompt: object expected");
                    message.authorizationPrompt = $root.listener.AuthorizationPrompt.fromObject(object.authorizationPrompt);
                }
                if (object.serviceMessage != null) {
                    if (typeof object.serviceMessage !== "object")
                        throw TypeError(".listener.Response.serviceMessage: object expected");
                    message.serviceMessage = $root.listener.ServiceMessage.fromObject(object.serviceMessage);
                }
                if (object.serviceVersion != null) {
                    if (typeof object.serviceVersion !== "object")
                        throw TypeError(".listener.Response.serviceVersion: object expected");
                    message.serviceVersion = $root.listener.ServiceVersion.fromObject(object.serviceVersion);
                }
                if (object.activeAudioDevices != null) {
                    if (typeof object.activeAudioDevices !== "object")
                        throw TypeError(".listener.Response.activeAudioDevices: object expected");
                    message.activeAudioDevices = $root.listener.ActiveAudioDevices.fromObject(object.activeAudioDevices);
                }
                if (object.deviceEndpoint != null) {
                    if (typeof object.deviceEndpoint !== "object")
                        throw TypeError(".listener.Response.deviceEndpoint: object expected");
                    message.deviceEndpoint = $root.listener.DeviceEndpoint.fromObject(object.deviceEndpoint);
                }
                if (object.systemInformation != null) {
                    if (typeof object.systemInformation !== "object")
                        throw TypeError(".listener.Response.systemInformation: object expected");
                    message.systemInformation = $root.listener.SystemInformation.fromObject(object.systemInformation);
                }
                if (object.audiosystemNotificatorEndpoint != null) {
                    if (typeof object.audiosystemNotificatorEndpoint !== "object")
                        throw TypeError(".listener.Response.audiosystemNotificatorEndpoint: object expected");
                    message.audiosystemNotificatorEndpoint = $root.listener.AudiosystemNotificatorEndpoint.fromObject(object.audiosystemNotificatorEndpoint);
                }
                if (object.eventBroadcasterEndpoint != null) {
                    if (typeof object.eventBroadcasterEndpoint !== "object")
                        throw TypeError(".listener.Response.eventBroadcasterEndpoint: object expected");
                    message.eventBroadcasterEndpoint = $root.listener.EventBroadcasterEndpoint.fromObject(object.eventBroadcasterEndpoint);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.Response
             * @static
             * @param {listener.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.authorizationPrompt != null && message.hasOwnProperty("authorizationPrompt")) {
                    object.authorizationPrompt = $root.listener.AuthorizationPrompt.toObject(message.authorizationPrompt, options);
                    if (options.oneofs)
                        object.responseType = "authorizationPrompt";
                }
                if (message.serviceMessage != null && message.hasOwnProperty("serviceMessage")) {
                    object.serviceMessage = $root.listener.ServiceMessage.toObject(message.serviceMessage, options);
                    if (options.oneofs)
                        object.responseType = "serviceMessage";
                }
                if (message.serviceVersion != null && message.hasOwnProperty("serviceVersion")) {
                    object.serviceVersion = $root.listener.ServiceVersion.toObject(message.serviceVersion, options);
                    if (options.oneofs)
                        object.responseType = "serviceVersion";
                }
                if (message.activeAudioDevices != null && message.hasOwnProperty("activeAudioDevices")) {
                    object.activeAudioDevices = $root.listener.ActiveAudioDevices.toObject(message.activeAudioDevices, options);
                    if (options.oneofs)
                        object.responseType = "activeAudioDevices";
                }
                if (message.deviceEndpoint != null && message.hasOwnProperty("deviceEndpoint")) {
                    object.deviceEndpoint = $root.listener.DeviceEndpoint.toObject(message.deviceEndpoint, options);
                    if (options.oneofs)
                        object.responseType = "deviceEndpoint";
                }
                if (message.systemInformation != null && message.hasOwnProperty("systemInformation")) {
                    object.systemInformation = $root.listener.SystemInformation.toObject(message.systemInformation, options);
                    if (options.oneofs)
                        object.responseType = "systemInformation";
                }
                if (message.audiosystemNotificatorEndpoint != null && message.hasOwnProperty("audiosystemNotificatorEndpoint")) {
                    object.audiosystemNotificatorEndpoint = $root.listener.AudiosystemNotificatorEndpoint.toObject(message.audiosystemNotificatorEndpoint, options);
                    if (options.oneofs)
                        object.responseType = "audiosystemNotificatorEndpoint";
                }
                if (message.eventBroadcasterEndpoint != null && message.hasOwnProperty("eventBroadcasterEndpoint")) {
                    object.eventBroadcasterEndpoint = $root.listener.EventBroadcasterEndpoint.toObject(message.eventBroadcasterEndpoint, options);
                    if (options.oneofs)
                        object.responseType = "eventBroadcasterEndpoint";
                }
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof listener.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof listener.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.Response";
            };
    
            return Response;
        })();
    
        listener.EventBroadcasterEndpoint = (function() {
    
            /**
             * Properties of an EventBroadcasterEndpoint.
             * @memberof listener
             * @interface IEventBroadcasterEndpoint
             * @property {number|null} [port] EventBroadcasterEndpoint port
             */
    
            /**
             * Constructs a new EventBroadcasterEndpoint.
             * @memberof listener
             * @classdesc Represents an EventBroadcasterEndpoint.
             * @implements IEventBroadcasterEndpoint
             * @constructor
             * @param {listener.IEventBroadcasterEndpoint=} [properties] Properties to set
             */
            function EventBroadcasterEndpoint(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EventBroadcasterEndpoint port.
             * @member {number} port
             * @memberof listener.EventBroadcasterEndpoint
             * @instance
             */
            EventBroadcasterEndpoint.prototype.port = 0;
    
            /**
             * Creates a new EventBroadcasterEndpoint instance using the specified properties.
             * @function create
             * @memberof listener.EventBroadcasterEndpoint
             * @static
             * @param {listener.IEventBroadcasterEndpoint=} [properties] Properties to set
             * @returns {listener.EventBroadcasterEndpoint} EventBroadcasterEndpoint instance
             */
            EventBroadcasterEndpoint.create = function create(properties) {
                return new EventBroadcasterEndpoint(properties);
            };
    
            /**
             * Encodes the specified EventBroadcasterEndpoint message. Does not implicitly {@link listener.EventBroadcasterEndpoint.verify|verify} messages.
             * @function encode
             * @memberof listener.EventBroadcasterEndpoint
             * @static
             * @param {listener.IEventBroadcasterEndpoint} message EventBroadcasterEndpoint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EventBroadcasterEndpoint.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.port);
                return writer;
            };
    
            /**
             * Encodes the specified EventBroadcasterEndpoint message, length delimited. Does not implicitly {@link listener.EventBroadcasterEndpoint.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.EventBroadcasterEndpoint
             * @static
             * @param {listener.IEventBroadcasterEndpoint} message EventBroadcasterEndpoint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EventBroadcasterEndpoint.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EventBroadcasterEndpoint message from the specified reader or buffer.
             * @function decode
             * @memberof listener.EventBroadcasterEndpoint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.EventBroadcasterEndpoint} EventBroadcasterEndpoint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EventBroadcasterEndpoint.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.EventBroadcasterEndpoint();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.port = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EventBroadcasterEndpoint message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.EventBroadcasterEndpoint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.EventBroadcasterEndpoint} EventBroadcasterEndpoint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EventBroadcasterEndpoint.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EventBroadcasterEndpoint message.
             * @function verify
             * @memberof listener.EventBroadcasterEndpoint
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EventBroadcasterEndpoint.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.port != null && message.hasOwnProperty("port"))
                    if (!$util.isInteger(message.port))
                        return "port: integer expected";
                return null;
            };
    
            /**
             * Creates an EventBroadcasterEndpoint message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.EventBroadcasterEndpoint
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.EventBroadcasterEndpoint} EventBroadcasterEndpoint
             */
            EventBroadcasterEndpoint.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.EventBroadcasterEndpoint)
                    return object;
                var message = new $root.listener.EventBroadcasterEndpoint();
                if (object.port != null)
                    message.port = object.port >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from an EventBroadcasterEndpoint message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.EventBroadcasterEndpoint
             * @static
             * @param {listener.EventBroadcasterEndpoint} message EventBroadcasterEndpoint
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EventBroadcasterEndpoint.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.port = 0;
                if (message.port != null && message.hasOwnProperty("port"))
                    object.port = message.port;
                return object;
            };
    
            /**
             * Converts this EventBroadcasterEndpoint to JSON.
             * @function toJSON
             * @memberof listener.EventBroadcasterEndpoint
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EventBroadcasterEndpoint.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EventBroadcasterEndpoint
             * @function getTypeUrl
             * @memberof listener.EventBroadcasterEndpoint
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EventBroadcasterEndpoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.EventBroadcasterEndpoint";
            };
    
            return EventBroadcasterEndpoint;
        })();
    
        listener.BroadcastedEvent = (function() {
    
            /**
             * Properties of a BroadcastedEvent.
             * @memberof listener
             * @interface IBroadcastedEvent
             * @property {string|null} [rawStringContent] BroadcastedEvent rawStringContent
             */
    
            /**
             * Constructs a new BroadcastedEvent.
             * @memberof listener
             * @classdesc Represents a BroadcastedEvent.
             * @implements IBroadcastedEvent
             * @constructor
             * @param {listener.IBroadcastedEvent=} [properties] Properties to set
             */
            function BroadcastedEvent(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BroadcastedEvent rawStringContent.
             * @member {string|null|undefined} rawStringContent
             * @memberof listener.BroadcastedEvent
             * @instance
             */
            BroadcastedEvent.prototype.rawStringContent = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * BroadcastedEvent broadcastedEventType.
             * @member {"rawStringContent"|undefined} broadcastedEventType
             * @memberof listener.BroadcastedEvent
             * @instance
             */
            Object.defineProperty(BroadcastedEvent.prototype, "broadcastedEventType", {
                get: $util.oneOfGetter($oneOfFields = ["rawStringContent"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new BroadcastedEvent instance using the specified properties.
             * @function create
             * @memberof listener.BroadcastedEvent
             * @static
             * @param {listener.IBroadcastedEvent=} [properties] Properties to set
             * @returns {listener.BroadcastedEvent} BroadcastedEvent instance
             */
            BroadcastedEvent.create = function create(properties) {
                return new BroadcastedEvent(properties);
            };
    
            /**
             * Encodes the specified BroadcastedEvent message. Does not implicitly {@link listener.BroadcastedEvent.verify|verify} messages.
             * @function encode
             * @memberof listener.BroadcastedEvent
             * @static
             * @param {listener.IBroadcastedEvent} message BroadcastedEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BroadcastedEvent.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.rawStringContent != null && Object.hasOwnProperty.call(message, "rawStringContent"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.rawStringContent);
                return writer;
            };
    
            /**
             * Encodes the specified BroadcastedEvent message, length delimited. Does not implicitly {@link listener.BroadcastedEvent.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.BroadcastedEvent
             * @static
             * @param {listener.IBroadcastedEvent} message BroadcastedEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BroadcastedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BroadcastedEvent message from the specified reader or buffer.
             * @function decode
             * @memberof listener.BroadcastedEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.BroadcastedEvent} BroadcastedEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BroadcastedEvent.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.BroadcastedEvent();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.rawStringContent = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BroadcastedEvent message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.BroadcastedEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.BroadcastedEvent} BroadcastedEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BroadcastedEvent.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BroadcastedEvent message.
             * @function verify
             * @memberof listener.BroadcastedEvent
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BroadcastedEvent.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.rawStringContent != null && message.hasOwnProperty("rawStringContent")) {
                    properties.broadcastedEventType = 1;
                    if (!$util.isString(message.rawStringContent))
                        return "rawStringContent: string expected";
                }
                return null;
            };
    
            /**
             * Creates a BroadcastedEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.BroadcastedEvent
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.BroadcastedEvent} BroadcastedEvent
             */
            BroadcastedEvent.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.BroadcastedEvent)
                    return object;
                var message = new $root.listener.BroadcastedEvent();
                if (object.rawStringContent != null)
                    message.rawStringContent = String(object.rawStringContent);
                return message;
            };
    
            /**
             * Creates a plain object from a BroadcastedEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.BroadcastedEvent
             * @static
             * @param {listener.BroadcastedEvent} message BroadcastedEvent
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BroadcastedEvent.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.rawStringContent != null && message.hasOwnProperty("rawStringContent")) {
                    object.rawStringContent = message.rawStringContent;
                    if (options.oneofs)
                        object.broadcastedEventType = "rawStringContent";
                }
                return object;
            };
    
            /**
             * Converts this BroadcastedEvent to JSON.
             * @function toJSON
             * @memberof listener.BroadcastedEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BroadcastedEvent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BroadcastedEvent
             * @function getTypeUrl
             * @memberof listener.BroadcastedEvent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BroadcastedEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.BroadcastedEvent";
            };
    
            return BroadcastedEvent;
        })();
    
        listener.AudioResponse = (function() {
    
            /**
             * Properties of an AudioResponse.
             * @memberof listener
             * @interface IAudioResponse
             * @property {listener.IRecordingInfo|null} [recordingInfo] AudioResponse recordingInfo
             * @property {listener.IAudioChunk|null} [audioChunk] AudioResponse audioChunk
             */
    
            /**
             * Constructs a new AudioResponse.
             * @memberof listener
             * @classdesc Represents an AudioResponse.
             * @implements IAudioResponse
             * @constructor
             * @param {listener.IAudioResponse=} [properties] Properties to set
             */
            function AudioResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AudioResponse recordingInfo.
             * @member {listener.IRecordingInfo|null|undefined} recordingInfo
             * @memberof listener.AudioResponse
             * @instance
             */
            AudioResponse.prototype.recordingInfo = null;
    
            /**
             * AudioResponse audioChunk.
             * @member {listener.IAudioChunk|null|undefined} audioChunk
             * @memberof listener.AudioResponse
             * @instance
             */
            AudioResponse.prototype.audioChunk = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * AudioResponse audioResponseType.
             * @member {"recordingInfo"|"audioChunk"|undefined} audioResponseType
             * @memberof listener.AudioResponse
             * @instance
             */
            Object.defineProperty(AudioResponse.prototype, "audioResponseType", {
                get: $util.oneOfGetter($oneOfFields = ["recordingInfo", "audioChunk"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new AudioResponse instance using the specified properties.
             * @function create
             * @memberof listener.AudioResponse
             * @static
             * @param {listener.IAudioResponse=} [properties] Properties to set
             * @returns {listener.AudioResponse} AudioResponse instance
             */
            AudioResponse.create = function create(properties) {
                return new AudioResponse(properties);
            };
    
            /**
             * Encodes the specified AudioResponse message. Does not implicitly {@link listener.AudioResponse.verify|verify} messages.
             * @function encode
             * @memberof listener.AudioResponse
             * @static
             * @param {listener.IAudioResponse} message AudioResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.recordingInfo != null && Object.hasOwnProperty.call(message, "recordingInfo"))
                    $root.listener.RecordingInfo.encode(message.recordingInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.audioChunk != null && Object.hasOwnProperty.call(message, "audioChunk"))
                    $root.listener.AudioChunk.encode(message.audioChunk, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AudioResponse message, length delimited. Does not implicitly {@link listener.AudioResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.AudioResponse
             * @static
             * @param {listener.IAudioResponse} message AudioResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AudioResponse message from the specified reader or buffer.
             * @function decode
             * @memberof listener.AudioResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.AudioResponse} AudioResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.AudioResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.recordingInfo = $root.listener.RecordingInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.audioChunk = $root.listener.AudioChunk.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AudioResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.AudioResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.AudioResponse} AudioResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AudioResponse message.
             * @function verify
             * @memberof listener.AudioResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AudioResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.recordingInfo != null && message.hasOwnProperty("recordingInfo")) {
                    properties.audioResponseType = 1;
                    {
                        var error = $root.listener.RecordingInfo.verify(message.recordingInfo);
                        if (error)
                            return "recordingInfo." + error;
                    }
                }
                if (message.audioChunk != null && message.hasOwnProperty("audioChunk")) {
                    if (properties.audioResponseType === 1)
                        return "audioResponseType: multiple values";
                    properties.audioResponseType = 1;
                    {
                        var error = $root.listener.AudioChunk.verify(message.audioChunk);
                        if (error)
                            return "audioChunk." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an AudioResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.AudioResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.AudioResponse} AudioResponse
             */
            AudioResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.AudioResponse)
                    return object;
                var message = new $root.listener.AudioResponse();
                if (object.recordingInfo != null) {
                    if (typeof object.recordingInfo !== "object")
                        throw TypeError(".listener.AudioResponse.recordingInfo: object expected");
                    message.recordingInfo = $root.listener.RecordingInfo.fromObject(object.recordingInfo);
                }
                if (object.audioChunk != null) {
                    if (typeof object.audioChunk !== "object")
                        throw TypeError(".listener.AudioResponse.audioChunk: object expected");
                    message.audioChunk = $root.listener.AudioChunk.fromObject(object.audioChunk);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AudioResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.AudioResponse
             * @static
             * @param {listener.AudioResponse} message AudioResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AudioResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.recordingInfo != null && message.hasOwnProperty("recordingInfo")) {
                    object.recordingInfo = $root.listener.RecordingInfo.toObject(message.recordingInfo, options);
                    if (options.oneofs)
                        object.audioResponseType = "recordingInfo";
                }
                if (message.audioChunk != null && message.hasOwnProperty("audioChunk")) {
                    object.audioChunk = $root.listener.AudioChunk.toObject(message.audioChunk, options);
                    if (options.oneofs)
                        object.audioResponseType = "audioChunk";
                }
                return object;
            };
    
            /**
             * Converts this AudioResponse to JSON.
             * @function toJSON
             * @memberof listener.AudioResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AudioResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AudioResponse
             * @function getTypeUrl
             * @memberof listener.AudioResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AudioResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.AudioResponse";
            };
    
            return AudioResponse;
        })();
    
        listener.AudiosystemChangeResponse = (function() {
    
            /**
             * Properties of an AudiosystemChangeResponse.
             * @memberof listener
             * @interface IAudiosystemChangeResponse
             */
    
            /**
             * Constructs a new AudiosystemChangeResponse.
             * @memberof listener
             * @classdesc Represents an AudiosystemChangeResponse.
             * @implements IAudiosystemChangeResponse
             * @constructor
             * @param {listener.IAudiosystemChangeResponse=} [properties] Properties to set
             */
            function AudiosystemChangeResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AudiosystemChangeResponse instance using the specified properties.
             * @function create
             * @memberof listener.AudiosystemChangeResponse
             * @static
             * @param {listener.IAudiosystemChangeResponse=} [properties] Properties to set
             * @returns {listener.AudiosystemChangeResponse} AudiosystemChangeResponse instance
             */
            AudiosystemChangeResponse.create = function create(properties) {
                return new AudiosystemChangeResponse(properties);
            };
    
            /**
             * Encodes the specified AudiosystemChangeResponse message. Does not implicitly {@link listener.AudiosystemChangeResponse.verify|verify} messages.
             * @function encode
             * @memberof listener.AudiosystemChangeResponse
             * @static
             * @param {listener.IAudiosystemChangeResponse} message AudiosystemChangeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudiosystemChangeResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AudiosystemChangeResponse message, length delimited. Does not implicitly {@link listener.AudiosystemChangeResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.AudiosystemChangeResponse
             * @static
             * @param {listener.IAudiosystemChangeResponse} message AudiosystemChangeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudiosystemChangeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AudiosystemChangeResponse message from the specified reader or buffer.
             * @function decode
             * @memberof listener.AudiosystemChangeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.AudiosystemChangeResponse} AudiosystemChangeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudiosystemChangeResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.AudiosystemChangeResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AudiosystemChangeResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.AudiosystemChangeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.AudiosystemChangeResponse} AudiosystemChangeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudiosystemChangeResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AudiosystemChangeResponse message.
             * @function verify
             * @memberof listener.AudiosystemChangeResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AudiosystemChangeResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an AudiosystemChangeResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.AudiosystemChangeResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.AudiosystemChangeResponse} AudiosystemChangeResponse
             */
            AudiosystemChangeResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.AudiosystemChangeResponse)
                    return object;
                return new $root.listener.AudiosystemChangeResponse();
            };
    
            /**
             * Creates a plain object from an AudiosystemChangeResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.AudiosystemChangeResponse
             * @static
             * @param {listener.AudiosystemChangeResponse} message AudiosystemChangeResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AudiosystemChangeResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AudiosystemChangeResponse to JSON.
             * @function toJSON
             * @memberof listener.AudiosystemChangeResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AudiosystemChangeResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AudiosystemChangeResponse
             * @function getTypeUrl
             * @memberof listener.AudiosystemChangeResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AudiosystemChangeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.AudiosystemChangeResponse";
            };
    
            return AudiosystemChangeResponse;
        })();
    
        listener.DeviceEndpoint = (function() {
    
            /**
             * Properties of a DeviceEndpoint.
             * @memberof listener
             * @interface IDeviceEndpoint
             * @property {number|null} [port] DeviceEndpoint port
             */
    
            /**
             * Constructs a new DeviceEndpoint.
             * @memberof listener
             * @classdesc Represents a DeviceEndpoint.
             * @implements IDeviceEndpoint
             * @constructor
             * @param {listener.IDeviceEndpoint=} [properties] Properties to set
             */
            function DeviceEndpoint(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DeviceEndpoint port.
             * @member {number} port
             * @memberof listener.DeviceEndpoint
             * @instance
             */
            DeviceEndpoint.prototype.port = 0;
    
            /**
             * Creates a new DeviceEndpoint instance using the specified properties.
             * @function create
             * @memberof listener.DeviceEndpoint
             * @static
             * @param {listener.IDeviceEndpoint=} [properties] Properties to set
             * @returns {listener.DeviceEndpoint} DeviceEndpoint instance
             */
            DeviceEndpoint.create = function create(properties) {
                return new DeviceEndpoint(properties);
            };
    
            /**
             * Encodes the specified DeviceEndpoint message. Does not implicitly {@link listener.DeviceEndpoint.verify|verify} messages.
             * @function encode
             * @memberof listener.DeviceEndpoint
             * @static
             * @param {listener.IDeviceEndpoint} message DeviceEndpoint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceEndpoint.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.port);
                return writer;
            };
    
            /**
             * Encodes the specified DeviceEndpoint message, length delimited. Does not implicitly {@link listener.DeviceEndpoint.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.DeviceEndpoint
             * @static
             * @param {listener.IDeviceEndpoint} message DeviceEndpoint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceEndpoint.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DeviceEndpoint message from the specified reader or buffer.
             * @function decode
             * @memberof listener.DeviceEndpoint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.DeviceEndpoint} DeviceEndpoint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceEndpoint.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.DeviceEndpoint();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.port = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DeviceEndpoint message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.DeviceEndpoint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.DeviceEndpoint} DeviceEndpoint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceEndpoint.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DeviceEndpoint message.
             * @function verify
             * @memberof listener.DeviceEndpoint
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeviceEndpoint.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.port != null && message.hasOwnProperty("port"))
                    if (!$util.isInteger(message.port))
                        return "port: integer expected";
                return null;
            };
    
            /**
             * Creates a DeviceEndpoint message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.DeviceEndpoint
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.DeviceEndpoint} DeviceEndpoint
             */
            DeviceEndpoint.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.DeviceEndpoint)
                    return object;
                var message = new $root.listener.DeviceEndpoint();
                if (object.port != null)
                    message.port = object.port >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a DeviceEndpoint message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.DeviceEndpoint
             * @static
             * @param {listener.DeviceEndpoint} message DeviceEndpoint
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeviceEndpoint.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.port = 0;
                if (message.port != null && message.hasOwnProperty("port"))
                    object.port = message.port;
                return object;
            };
    
            /**
             * Converts this DeviceEndpoint to JSON.
             * @function toJSON
             * @memberof listener.DeviceEndpoint
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeviceEndpoint.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for DeviceEndpoint
             * @function getTypeUrl
             * @memberof listener.DeviceEndpoint
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeviceEndpoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.DeviceEndpoint";
            };
    
            return DeviceEndpoint;
        })();
    
        listener.RecordingInfo = (function() {
    
            /**
             * Properties of a RecordingInfo.
             * @memberof listener
             * @interface IRecordingInfo
             * @property {listener.AudioEncoding|null} [encoding] RecordingInfo encoding
             * @property {number|null} [sampleRateHz] RecordingInfo sampleRateHz
             */
    
            /**
             * Constructs a new RecordingInfo.
             * @memberof listener
             * @classdesc Represents a RecordingInfo.
             * @implements IRecordingInfo
             * @constructor
             * @param {listener.IRecordingInfo=} [properties] Properties to set
             */
            function RecordingInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RecordingInfo encoding.
             * @member {listener.AudioEncoding} encoding
             * @memberof listener.RecordingInfo
             * @instance
             */
            RecordingInfo.prototype.encoding = 0;
    
            /**
             * RecordingInfo sampleRateHz.
             * @member {number} sampleRateHz
             * @memberof listener.RecordingInfo
             * @instance
             */
            RecordingInfo.prototype.sampleRateHz = 0;
    
            /**
             * Creates a new RecordingInfo instance using the specified properties.
             * @function create
             * @memberof listener.RecordingInfo
             * @static
             * @param {listener.IRecordingInfo=} [properties] Properties to set
             * @returns {listener.RecordingInfo} RecordingInfo instance
             */
            RecordingInfo.create = function create(properties) {
                return new RecordingInfo(properties);
            };
    
            /**
             * Encodes the specified RecordingInfo message. Does not implicitly {@link listener.RecordingInfo.verify|verify} messages.
             * @function encode
             * @memberof listener.RecordingInfo
             * @static
             * @param {listener.IRecordingInfo} message RecordingInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecordingInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.encoding != null && Object.hasOwnProperty.call(message, "encoding"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.encoding);
                if (message.sampleRateHz != null && Object.hasOwnProperty.call(message, "sampleRateHz"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.sampleRateHz);
                return writer;
            };
    
            /**
             * Encodes the specified RecordingInfo message, length delimited. Does not implicitly {@link listener.RecordingInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.RecordingInfo
             * @static
             * @param {listener.IRecordingInfo} message RecordingInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecordingInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RecordingInfo message from the specified reader or buffer.
             * @function decode
             * @memberof listener.RecordingInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.RecordingInfo} RecordingInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecordingInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.RecordingInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.encoding = reader.int32();
                            break;
                        }
                    case 3: {
                            message.sampleRateHz = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RecordingInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.RecordingInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.RecordingInfo} RecordingInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecordingInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RecordingInfo message.
             * @function verify
             * @memberof listener.RecordingInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RecordingInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.encoding != null && message.hasOwnProperty("encoding"))
                    switch (message.encoding) {
                    default:
                        return "encoding: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.sampleRateHz != null && message.hasOwnProperty("sampleRateHz"))
                    if (!$util.isInteger(message.sampleRateHz))
                        return "sampleRateHz: integer expected";
                return null;
            };
    
            /**
             * Creates a RecordingInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.RecordingInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.RecordingInfo} RecordingInfo
             */
            RecordingInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.RecordingInfo)
                    return object;
                var message = new $root.listener.RecordingInfo();
                switch (object.encoding) {
                default:
                    if (typeof object.encoding === "number") {
                        message.encoding = object.encoding;
                        break;
                    }
                    break;
                case "LINEAR16":
                case 0:
                    message.encoding = 0;
                    break;
                case "FLAC":
                case 1:
                    message.encoding = 1;
                    break;
                case "OPUS":
                case 2:
                    message.encoding = 2;
                    break;
                }
                if (object.sampleRateHz != null)
                    message.sampleRateHz = object.sampleRateHz >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a RecordingInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.RecordingInfo
             * @static
             * @param {listener.RecordingInfo} message RecordingInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RecordingInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.encoding = options.enums === String ? "LINEAR16" : 0;
                    object.sampleRateHz = 0;
                }
                if (message.encoding != null && message.hasOwnProperty("encoding"))
                    object.encoding = options.enums === String ? $root.listener.AudioEncoding[message.encoding] === undefined ? message.encoding : $root.listener.AudioEncoding[message.encoding] : message.encoding;
                if (message.sampleRateHz != null && message.hasOwnProperty("sampleRateHz"))
                    object.sampleRateHz = message.sampleRateHz;
                return object;
            };
    
            /**
             * Converts this RecordingInfo to JSON.
             * @function toJSON
             * @memberof listener.RecordingInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RecordingInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RecordingInfo
             * @function getTypeUrl
             * @memberof listener.RecordingInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RecordingInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.RecordingInfo";
            };
    
            return RecordingInfo;
        })();
    
        listener.AudioChunk = (function() {
    
            /**
             * Properties of an AudioChunk.
             * @memberof listener
             * @interface IAudioChunk
             * @property {Uint8Array|null} [audio] AudioChunk audio
             * @property {number|Long|null} [sequenceNumber] AudioChunk sequenceNumber
             * @property {number|null} [offsetInAudioStreamMsec] AudioChunk offsetInAudioStreamMsec
             * @property {number|null} [audioLengthMsec] AudioChunk audioLengthMsec
             * @property {listener.AudioChunk.DataFlow|null} [dataFlow] AudioChunk dataFlow
             * @property {number|Long|null} [totalGlitchesInStream] AudioChunk totalGlitchesInStream
             */
    
            /**
             * Constructs a new AudioChunk.
             * @memberof listener
             * @classdesc Represents an AudioChunk.
             * @implements IAudioChunk
             * @constructor
             * @param {listener.IAudioChunk=} [properties] Properties to set
             */
            function AudioChunk(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AudioChunk audio.
             * @member {Uint8Array} audio
             * @memberof listener.AudioChunk
             * @instance
             */
            AudioChunk.prototype.audio = $util.newBuffer([]);
    
            /**
             * AudioChunk sequenceNumber.
             * @member {number|Long} sequenceNumber
             * @memberof listener.AudioChunk
             * @instance
             */
            AudioChunk.prototype.sequenceNumber = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * AudioChunk offsetInAudioStreamMsec.
             * @member {number} offsetInAudioStreamMsec
             * @memberof listener.AudioChunk
             * @instance
             */
            AudioChunk.prototype.offsetInAudioStreamMsec = 0;
    
            /**
             * AudioChunk audioLengthMsec.
             * @member {number} audioLengthMsec
             * @memberof listener.AudioChunk
             * @instance
             */
            AudioChunk.prototype.audioLengthMsec = 0;
    
            /**
             * AudioChunk dataFlow.
             * @member {listener.AudioChunk.DataFlow} dataFlow
             * @memberof listener.AudioChunk
             * @instance
             */
            AudioChunk.prototype.dataFlow = 0;
    
            /**
             * AudioChunk totalGlitchesInStream.
             * @member {number|Long} totalGlitchesInStream
             * @memberof listener.AudioChunk
             * @instance
             */
            AudioChunk.prototype.totalGlitchesInStream = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Creates a new AudioChunk instance using the specified properties.
             * @function create
             * @memberof listener.AudioChunk
             * @static
             * @param {listener.IAudioChunk=} [properties] Properties to set
             * @returns {listener.AudioChunk} AudioChunk instance
             */
            AudioChunk.create = function create(properties) {
                return new AudioChunk(properties);
            };
    
            /**
             * Encodes the specified AudioChunk message. Does not implicitly {@link listener.AudioChunk.verify|verify} messages.
             * @function encode
             * @memberof listener.AudioChunk
             * @static
             * @param {listener.IAudioChunk} message AudioChunk message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioChunk.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.audio);
                if (message.sequenceNumber != null && Object.hasOwnProperty.call(message, "sequenceNumber"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.sequenceNumber);
                if (message.offsetInAudioStreamMsec != null && Object.hasOwnProperty.call(message, "offsetInAudioStreamMsec"))
                    writer.uint32(/* id 5, wireType 1 =*/41).double(message.offsetInAudioStreamMsec);
                if (message.audioLengthMsec != null && Object.hasOwnProperty.call(message, "audioLengthMsec"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.audioLengthMsec);
                if (message.dataFlow != null && Object.hasOwnProperty.call(message, "dataFlow"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.dataFlow);
                if (message.totalGlitchesInStream != null && Object.hasOwnProperty.call(message, "totalGlitchesInStream"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.totalGlitchesInStream);
                return writer;
            };
    
            /**
             * Encodes the specified AudioChunk message, length delimited. Does not implicitly {@link listener.AudioChunk.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.AudioChunk
             * @static
             * @param {listener.IAudioChunk} message AudioChunk message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioChunk.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AudioChunk message from the specified reader or buffer.
             * @function decode
             * @memberof listener.AudioChunk
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.AudioChunk} AudioChunk
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioChunk.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.AudioChunk();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.audio = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.sequenceNumber = reader.uint64();
                            break;
                        }
                    case 5: {
                            message.offsetInAudioStreamMsec = reader.double();
                            break;
                        }
                    case 6: {
                            message.audioLengthMsec = reader.double();
                            break;
                        }
                    case 7: {
                            message.dataFlow = reader.int32();
                            break;
                        }
                    case 8: {
                            message.totalGlitchesInStream = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AudioChunk message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.AudioChunk
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.AudioChunk} AudioChunk
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioChunk.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AudioChunk message.
             * @function verify
             * @memberof listener.AudioChunk
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AudioChunk.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.audio != null && message.hasOwnProperty("audio"))
                    if (!(message.audio && typeof message.audio.length === "number" || $util.isString(message.audio)))
                        return "audio: buffer expected";
                if (message.sequenceNumber != null && message.hasOwnProperty("sequenceNumber"))
                    if (!$util.isInteger(message.sequenceNumber) && !(message.sequenceNumber && $util.isInteger(message.sequenceNumber.low) && $util.isInteger(message.sequenceNumber.high)))
                        return "sequenceNumber: integer|Long expected";
                if (message.offsetInAudioStreamMsec != null && message.hasOwnProperty("offsetInAudioStreamMsec"))
                    if (typeof message.offsetInAudioStreamMsec !== "number")
                        return "offsetInAudioStreamMsec: number expected";
                if (message.audioLengthMsec != null && message.hasOwnProperty("audioLengthMsec"))
                    if (typeof message.audioLengthMsec !== "number")
                        return "audioLengthMsec: number expected";
                if (message.dataFlow != null && message.hasOwnProperty("dataFlow"))
                    switch (message.dataFlow) {
                    default:
                        return "dataFlow: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.totalGlitchesInStream != null && message.hasOwnProperty("totalGlitchesInStream"))
                    if (!$util.isInteger(message.totalGlitchesInStream) && !(message.totalGlitchesInStream && $util.isInteger(message.totalGlitchesInStream.low) && $util.isInteger(message.totalGlitchesInStream.high)))
                        return "totalGlitchesInStream: integer|Long expected";
                return null;
            };
    
            /**
             * Creates an AudioChunk message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.AudioChunk
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.AudioChunk} AudioChunk
             */
            AudioChunk.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.AudioChunk)
                    return object;
                var message = new $root.listener.AudioChunk();
                if (object.audio != null)
                    if (typeof object.audio === "string")
                        $util.base64.decode(object.audio, message.audio = $util.newBuffer($util.base64.length(object.audio)), 0);
                    else if (object.audio.length >= 0)
                        message.audio = object.audio;
                if (object.sequenceNumber != null)
                    if ($util.Long)
                        (message.sequenceNumber = $util.Long.fromValue(object.sequenceNumber)).unsigned = true;
                    else if (typeof object.sequenceNumber === "string")
                        message.sequenceNumber = parseInt(object.sequenceNumber, 10);
                    else if (typeof object.sequenceNumber === "number")
                        message.sequenceNumber = object.sequenceNumber;
                    else if (typeof object.sequenceNumber === "object")
                        message.sequenceNumber = new $util.LongBits(object.sequenceNumber.low >>> 0, object.sequenceNumber.high >>> 0).toNumber(true);
                if (object.offsetInAudioStreamMsec != null)
                    message.offsetInAudioStreamMsec = Number(object.offsetInAudioStreamMsec);
                if (object.audioLengthMsec != null)
                    message.audioLengthMsec = Number(object.audioLengthMsec);
                switch (object.dataFlow) {
                default:
                    if (typeof object.dataFlow === "number") {
                        message.dataFlow = object.dataFlow;
                        break;
                    }
                    break;
                case "UNDEFINED":
                case 0:
                    message.dataFlow = 0;
                    break;
                case "CAPTURE":
                case 1:
                    message.dataFlow = 1;
                    break;
                case "RENDER":
                case 2:
                    message.dataFlow = 2;
                    break;
                }
                if (object.totalGlitchesInStream != null)
                    if ($util.Long)
                        (message.totalGlitchesInStream = $util.Long.fromValue(object.totalGlitchesInStream)).unsigned = true;
                    else if (typeof object.totalGlitchesInStream === "string")
                        message.totalGlitchesInStream = parseInt(object.totalGlitchesInStream, 10);
                    else if (typeof object.totalGlitchesInStream === "number")
                        message.totalGlitchesInStream = object.totalGlitchesInStream;
                    else if (typeof object.totalGlitchesInStream === "object")
                        message.totalGlitchesInStream = new $util.LongBits(object.totalGlitchesInStream.low >>> 0, object.totalGlitchesInStream.high >>> 0).toNumber(true);
                return message;
            };
    
            /**
             * Creates a plain object from an AudioChunk message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.AudioChunk
             * @static
             * @param {listener.AudioChunk} message AudioChunk
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AudioChunk.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.audio = "";
                    else {
                        object.audio = [];
                        if (options.bytes !== Array)
                            object.audio = $util.newBuffer(object.audio);
                    }
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.sequenceNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.sequenceNumber = options.longs === String ? "0" : 0;
                    object.offsetInAudioStreamMsec = 0;
                    object.audioLengthMsec = 0;
                    object.dataFlow = options.enums === String ? "UNDEFINED" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.totalGlitchesInStream = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalGlitchesInStream = options.longs === String ? "0" : 0;
                }
                if (message.audio != null && message.hasOwnProperty("audio"))
                    object.audio = options.bytes === String ? $util.base64.encode(message.audio, 0, message.audio.length) : options.bytes === Array ? Array.prototype.slice.call(message.audio) : message.audio;
                if (message.sequenceNumber != null && message.hasOwnProperty("sequenceNumber"))
                    if (typeof message.sequenceNumber === "number")
                        object.sequenceNumber = options.longs === String ? String(message.sequenceNumber) : message.sequenceNumber;
                    else
                        object.sequenceNumber = options.longs === String ? $util.Long.prototype.toString.call(message.sequenceNumber) : options.longs === Number ? new $util.LongBits(message.sequenceNumber.low >>> 0, message.sequenceNumber.high >>> 0).toNumber(true) : message.sequenceNumber;
                if (message.offsetInAudioStreamMsec != null && message.hasOwnProperty("offsetInAudioStreamMsec"))
                    object.offsetInAudioStreamMsec = options.json && !isFinite(message.offsetInAudioStreamMsec) ? String(message.offsetInAudioStreamMsec) : message.offsetInAudioStreamMsec;
                if (message.audioLengthMsec != null && message.hasOwnProperty("audioLengthMsec"))
                    object.audioLengthMsec = options.json && !isFinite(message.audioLengthMsec) ? String(message.audioLengthMsec) : message.audioLengthMsec;
                if (message.dataFlow != null && message.hasOwnProperty("dataFlow"))
                    object.dataFlow = options.enums === String ? $root.listener.AudioChunk.DataFlow[message.dataFlow] === undefined ? message.dataFlow : $root.listener.AudioChunk.DataFlow[message.dataFlow] : message.dataFlow;
                if (message.totalGlitchesInStream != null && message.hasOwnProperty("totalGlitchesInStream"))
                    if (typeof message.totalGlitchesInStream === "number")
                        object.totalGlitchesInStream = options.longs === String ? String(message.totalGlitchesInStream) : message.totalGlitchesInStream;
                    else
                        object.totalGlitchesInStream = options.longs === String ? $util.Long.prototype.toString.call(message.totalGlitchesInStream) : options.longs === Number ? new $util.LongBits(message.totalGlitchesInStream.low >>> 0, message.totalGlitchesInStream.high >>> 0).toNumber(true) : message.totalGlitchesInStream;
                return object;
            };
    
            /**
             * Converts this AudioChunk to JSON.
             * @function toJSON
             * @memberof listener.AudioChunk
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AudioChunk.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AudioChunk
             * @function getTypeUrl
             * @memberof listener.AudioChunk
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AudioChunk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.AudioChunk";
            };
    
            /**
             * DataFlow enum.
             * @name listener.AudioChunk.DataFlow
             * @enum {number}
             * @property {number} UNDEFINED=0 UNDEFINED value
             * @property {number} CAPTURE=1 CAPTURE value
             * @property {number} RENDER=2 RENDER value
             */
            AudioChunk.DataFlow = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNDEFINED"] = 0;
                values[valuesById[1] = "CAPTURE"] = 1;
                values[valuesById[2] = "RENDER"] = 2;
                return values;
            })();
    
            return AudioChunk;
        })();
    
        listener.ActiveAudioDevices = (function() {
    
            /**
             * Properties of an ActiveAudioDevices.
             * @memberof listener
             * @interface IActiveAudioDevices
             * @property {Array.<listener.IAudioDevice>|null} [audioDevices] ActiveAudioDevices audioDevices
             */
    
            /**
             * Constructs a new ActiveAudioDevices.
             * @memberof listener
             * @classdesc Represents an ActiveAudioDevices.
             * @implements IActiveAudioDevices
             * @constructor
             * @param {listener.IActiveAudioDevices=} [properties] Properties to set
             */
            function ActiveAudioDevices(properties) {
                this.audioDevices = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ActiveAudioDevices audioDevices.
             * @member {Array.<listener.IAudioDevice>} audioDevices
             * @memberof listener.ActiveAudioDevices
             * @instance
             */
            ActiveAudioDevices.prototype.audioDevices = $util.emptyArray;
    
            /**
             * Creates a new ActiveAudioDevices instance using the specified properties.
             * @function create
             * @memberof listener.ActiveAudioDevices
             * @static
             * @param {listener.IActiveAudioDevices=} [properties] Properties to set
             * @returns {listener.ActiveAudioDevices} ActiveAudioDevices instance
             */
            ActiveAudioDevices.create = function create(properties) {
                return new ActiveAudioDevices(properties);
            };
    
            /**
             * Encodes the specified ActiveAudioDevices message. Does not implicitly {@link listener.ActiveAudioDevices.verify|verify} messages.
             * @function encode
             * @memberof listener.ActiveAudioDevices
             * @static
             * @param {listener.IActiveAudioDevices} message ActiveAudioDevices message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ActiveAudioDevices.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.audioDevices != null && message.audioDevices.length)
                    for (var i = 0; i < message.audioDevices.length; ++i)
                        $root.listener.AudioDevice.encode(message.audioDevices[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ActiveAudioDevices message, length delimited. Does not implicitly {@link listener.ActiveAudioDevices.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.ActiveAudioDevices
             * @static
             * @param {listener.IActiveAudioDevices} message ActiveAudioDevices message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ActiveAudioDevices.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an ActiveAudioDevices message from the specified reader or buffer.
             * @function decode
             * @memberof listener.ActiveAudioDevices
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.ActiveAudioDevices} ActiveAudioDevices
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ActiveAudioDevices.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.ActiveAudioDevices();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.audioDevices && message.audioDevices.length))
                                message.audioDevices = [];
                            message.audioDevices.push($root.listener.AudioDevice.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an ActiveAudioDevices message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.ActiveAudioDevices
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.ActiveAudioDevices} ActiveAudioDevices
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ActiveAudioDevices.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an ActiveAudioDevices message.
             * @function verify
             * @memberof listener.ActiveAudioDevices
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ActiveAudioDevices.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.audioDevices != null && message.hasOwnProperty("audioDevices")) {
                    if (!Array.isArray(message.audioDevices))
                        return "audioDevices: array expected";
                    for (var i = 0; i < message.audioDevices.length; ++i) {
                        var error = $root.listener.AudioDevice.verify(message.audioDevices[i]);
                        if (error)
                            return "audioDevices." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an ActiveAudioDevices message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.ActiveAudioDevices
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.ActiveAudioDevices} ActiveAudioDevices
             */
            ActiveAudioDevices.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.ActiveAudioDevices)
                    return object;
                var message = new $root.listener.ActiveAudioDevices();
                if (object.audioDevices) {
                    if (!Array.isArray(object.audioDevices))
                        throw TypeError(".listener.ActiveAudioDevices.audioDevices: array expected");
                    message.audioDevices = [];
                    for (var i = 0; i < object.audioDevices.length; ++i) {
                        if (typeof object.audioDevices[i] !== "object")
                            throw TypeError(".listener.ActiveAudioDevices.audioDevices: object expected");
                        message.audioDevices[i] = $root.listener.AudioDevice.fromObject(object.audioDevices[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an ActiveAudioDevices message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.ActiveAudioDevices
             * @static
             * @param {listener.ActiveAudioDevices} message ActiveAudioDevices
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ActiveAudioDevices.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.audioDevices = [];
                if (message.audioDevices && message.audioDevices.length) {
                    object.audioDevices = [];
                    for (var j = 0; j < message.audioDevices.length; ++j)
                        object.audioDevices[j] = $root.listener.AudioDevice.toObject(message.audioDevices[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ActiveAudioDevices to JSON.
             * @function toJSON
             * @memberof listener.ActiveAudioDevices
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ActiveAudioDevices.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ActiveAudioDevices
             * @function getTypeUrl
             * @memberof listener.ActiveAudioDevices
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ActiveAudioDevices.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.ActiveAudioDevices";
            };
    
            return ActiveAudioDevices;
        })();
    
        listener.AudioDevice = (function() {
    
            /**
             * Properties of an AudioDevice.
             * @memberof listener
             * @interface IAudioDevice
             * @property {Uint8Array|null} [id] AudioDevice id
             * @property {listener.AudioDevice.DataFlow|null} [dataFlow] AudioDevice dataFlow
             * @property {boolean|null} [isDefaultConsole] AudioDevice isDefaultConsole
             * @property {boolean|null} [isDefaultCommunications] AudioDevice isDefaultCommunications
             * @property {boolean|null} [isDefaultMultimedia] AudioDevice isDefaultMultimedia
             * @property {string|null} [description] AudioDevice description
             */
    
            /**
             * Constructs a new AudioDevice.
             * @memberof listener
             * @classdesc Represents an AudioDevice.
             * @implements IAudioDevice
             * @constructor
             * @param {listener.IAudioDevice=} [properties] Properties to set
             */
            function AudioDevice(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AudioDevice id.
             * @member {Uint8Array} id
             * @memberof listener.AudioDevice
             * @instance
             */
            AudioDevice.prototype.id = $util.newBuffer([]);
    
            /**
             * AudioDevice dataFlow.
             * @member {listener.AudioDevice.DataFlow} dataFlow
             * @memberof listener.AudioDevice
             * @instance
             */
            AudioDevice.prototype.dataFlow = 0;
    
            /**
             * AudioDevice isDefaultConsole.
             * @member {boolean} isDefaultConsole
             * @memberof listener.AudioDevice
             * @instance
             */
            AudioDevice.prototype.isDefaultConsole = false;
    
            /**
             * AudioDevice isDefaultCommunications.
             * @member {boolean} isDefaultCommunications
             * @memberof listener.AudioDevice
             * @instance
             */
            AudioDevice.prototype.isDefaultCommunications = false;
    
            /**
             * AudioDevice isDefaultMultimedia.
             * @member {boolean} isDefaultMultimedia
             * @memberof listener.AudioDevice
             * @instance
             */
            AudioDevice.prototype.isDefaultMultimedia = false;
    
            /**
             * AudioDevice description.
             * @member {string} description
             * @memberof listener.AudioDevice
             * @instance
             */
            AudioDevice.prototype.description = "";
    
            /**
             * Creates a new AudioDevice instance using the specified properties.
             * @function create
             * @memberof listener.AudioDevice
             * @static
             * @param {listener.IAudioDevice=} [properties] Properties to set
             * @returns {listener.AudioDevice} AudioDevice instance
             */
            AudioDevice.create = function create(properties) {
                return new AudioDevice(properties);
            };
    
            /**
             * Encodes the specified AudioDevice message. Does not implicitly {@link listener.AudioDevice.verify|verify} messages.
             * @function encode
             * @memberof listener.AudioDevice
             * @static
             * @param {listener.IAudioDevice} message AudioDevice message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioDevice.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
                if (message.dataFlow != null && Object.hasOwnProperty.call(message, "dataFlow"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dataFlow);
                if (message.isDefaultConsole != null && Object.hasOwnProperty.call(message, "isDefaultConsole"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isDefaultConsole);
                if (message.isDefaultCommunications != null && Object.hasOwnProperty.call(message, "isDefaultCommunications"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDefaultCommunications);
                if (message.isDefaultMultimedia != null && Object.hasOwnProperty.call(message, "isDefaultMultimedia"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDefaultMultimedia);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.description);
                return writer;
            };
    
            /**
             * Encodes the specified AudioDevice message, length delimited. Does not implicitly {@link listener.AudioDevice.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.AudioDevice
             * @static
             * @param {listener.IAudioDevice} message AudioDevice message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioDevice.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AudioDevice message from the specified reader or buffer.
             * @function decode
             * @memberof listener.AudioDevice
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.AudioDevice} AudioDevice
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioDevice.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.AudioDevice();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.dataFlow = reader.int32();
                            break;
                        }
                    case 3: {
                            message.isDefaultConsole = reader.bool();
                            break;
                        }
                    case 4: {
                            message.isDefaultCommunications = reader.bool();
                            break;
                        }
                    case 5: {
                            message.isDefaultMultimedia = reader.bool();
                            break;
                        }
                    case 6: {
                            message.description = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AudioDevice message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.AudioDevice
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.AudioDevice} AudioDevice
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioDevice.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AudioDevice message.
             * @function verify
             * @memberof listener.AudioDevice
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AudioDevice.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                        return "id: buffer expected";
                if (message.dataFlow != null && message.hasOwnProperty("dataFlow"))
                    switch (message.dataFlow) {
                    default:
                        return "dataFlow: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.isDefaultConsole != null && message.hasOwnProperty("isDefaultConsole"))
                    if (typeof message.isDefaultConsole !== "boolean")
                        return "isDefaultConsole: boolean expected";
                if (message.isDefaultCommunications != null && message.hasOwnProperty("isDefaultCommunications"))
                    if (typeof message.isDefaultCommunications !== "boolean")
                        return "isDefaultCommunications: boolean expected";
                if (message.isDefaultMultimedia != null && message.hasOwnProperty("isDefaultMultimedia"))
                    if (typeof message.isDefaultMultimedia !== "boolean")
                        return "isDefaultMultimedia: boolean expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };
    
            /**
             * Creates an AudioDevice message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.AudioDevice
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.AudioDevice} AudioDevice
             */
            AudioDevice.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.AudioDevice)
                    return object;
                var message = new $root.listener.AudioDevice();
                if (object.id != null)
                    if (typeof object.id === "string")
                        $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                    else if (object.id.length >= 0)
                        message.id = object.id;
                switch (object.dataFlow) {
                default:
                    if (typeof object.dataFlow === "number") {
                        message.dataFlow = object.dataFlow;
                        break;
                    }
                    break;
                case "UNDEFINED":
                case 0:
                    message.dataFlow = 0;
                    break;
                case "CAPTURE":
                case 1:
                    message.dataFlow = 1;
                    break;
                case "RENDER":
                case 2:
                    message.dataFlow = 2;
                    break;
                }
                if (object.isDefaultConsole != null)
                    message.isDefaultConsole = Boolean(object.isDefaultConsole);
                if (object.isDefaultCommunications != null)
                    message.isDefaultCommunications = Boolean(object.isDefaultCommunications);
                if (object.isDefaultMultimedia != null)
                    message.isDefaultMultimedia = Boolean(object.isDefaultMultimedia);
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };
    
            /**
             * Creates a plain object from an AudioDevice message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.AudioDevice
             * @static
             * @param {listener.AudioDevice} message AudioDevice
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AudioDevice.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.id = "";
                    else {
                        object.id = [];
                        if (options.bytes !== Array)
                            object.id = $util.newBuffer(object.id);
                    }
                    object.dataFlow = options.enums === String ? "UNDEFINED" : 0;
                    object.isDefaultConsole = false;
                    object.isDefaultCommunications = false;
                    object.isDefaultMultimedia = false;
                    object.description = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
                if (message.dataFlow != null && message.hasOwnProperty("dataFlow"))
                    object.dataFlow = options.enums === String ? $root.listener.AudioDevice.DataFlow[message.dataFlow] === undefined ? message.dataFlow : $root.listener.AudioDevice.DataFlow[message.dataFlow] : message.dataFlow;
                if (message.isDefaultConsole != null && message.hasOwnProperty("isDefaultConsole"))
                    object.isDefaultConsole = message.isDefaultConsole;
                if (message.isDefaultCommunications != null && message.hasOwnProperty("isDefaultCommunications"))
                    object.isDefaultCommunications = message.isDefaultCommunications;
                if (message.isDefaultMultimedia != null && message.hasOwnProperty("isDefaultMultimedia"))
                    object.isDefaultMultimedia = message.isDefaultMultimedia;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };
    
            /**
             * Converts this AudioDevice to JSON.
             * @function toJSON
             * @memberof listener.AudioDevice
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AudioDevice.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AudioDevice
             * @function getTypeUrl
             * @memberof listener.AudioDevice
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AudioDevice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.AudioDevice";
            };
    
            /**
             * DataFlow enum.
             * @name listener.AudioDevice.DataFlow
             * @enum {number}
             * @property {number} UNDEFINED=0 UNDEFINED value
             * @property {number} CAPTURE=1 CAPTURE value
             * @property {number} RENDER=2 RENDER value
             */
            AudioDevice.DataFlow = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNDEFINED"] = 0;
                values[valuesById[1] = "CAPTURE"] = 1;
                values[valuesById[2] = "RENDER"] = 2;
                return values;
            })();
    
            return AudioDevice;
        })();
    
        listener.ServiceVersion = (function() {
    
            /**
             * Properties of a ServiceVersion.
             * @memberof listener
             * @interface IServiceVersion
             * @property {number|null} [major] ServiceVersion major
             * @property {number|null} [minor] ServiceVersion minor
             * @property {number|null} [patch] ServiceVersion patch
             * @property {boolean|null} [hasOpus] ServiceVersion hasOpus
             */
    
            /**
             * Constructs a new ServiceVersion.
             * @memberof listener
             * @classdesc Represents a ServiceVersion.
             * @implements IServiceVersion
             * @constructor
             * @param {listener.IServiceVersion=} [properties] Properties to set
             */
            function ServiceVersion(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ServiceVersion major.
             * @member {number} major
             * @memberof listener.ServiceVersion
             * @instance
             */
            ServiceVersion.prototype.major = 0;
    
            /**
             * ServiceVersion minor.
             * @member {number} minor
             * @memberof listener.ServiceVersion
             * @instance
             */
            ServiceVersion.prototype.minor = 0;
    
            /**
             * ServiceVersion patch.
             * @member {number} patch
             * @memberof listener.ServiceVersion
             * @instance
             */
            ServiceVersion.prototype.patch = 0;
    
            /**
             * ServiceVersion hasOpus.
             * @member {boolean} hasOpus
             * @memberof listener.ServiceVersion
             * @instance
             */
            ServiceVersion.prototype.hasOpus = false;
    
            /**
             * Creates a new ServiceVersion instance using the specified properties.
             * @function create
             * @memberof listener.ServiceVersion
             * @static
             * @param {listener.IServiceVersion=} [properties] Properties to set
             * @returns {listener.ServiceVersion} ServiceVersion instance
             */
            ServiceVersion.create = function create(properties) {
                return new ServiceVersion(properties);
            };
    
            /**
             * Encodes the specified ServiceVersion message. Does not implicitly {@link listener.ServiceVersion.verify|verify} messages.
             * @function encode
             * @memberof listener.ServiceVersion
             * @static
             * @param {listener.IServiceVersion} message ServiceVersion message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceVersion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.major != null && Object.hasOwnProperty.call(message, "major"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.major);
                if (message.minor != null && Object.hasOwnProperty.call(message, "minor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.minor);
                if (message.patch != null && Object.hasOwnProperty.call(message, "patch"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.patch);
                if (message.hasOpus != null && Object.hasOwnProperty.call(message, "hasOpus"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.hasOpus);
                return writer;
            };
    
            /**
             * Encodes the specified ServiceVersion message, length delimited. Does not implicitly {@link listener.ServiceVersion.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.ServiceVersion
             * @static
             * @param {listener.IServiceVersion} message ServiceVersion message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceVersion.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ServiceVersion message from the specified reader or buffer.
             * @function decode
             * @memberof listener.ServiceVersion
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.ServiceVersion} ServiceVersion
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceVersion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.ServiceVersion();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.major = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.minor = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.patch = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.hasOpus = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ServiceVersion message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.ServiceVersion
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.ServiceVersion} ServiceVersion
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceVersion.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ServiceVersion message.
             * @function verify
             * @memberof listener.ServiceVersion
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceVersion.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.major != null && message.hasOwnProperty("major"))
                    if (!$util.isInteger(message.major))
                        return "major: integer expected";
                if (message.minor != null && message.hasOwnProperty("minor"))
                    if (!$util.isInteger(message.minor))
                        return "minor: integer expected";
                if (message.patch != null && message.hasOwnProperty("patch"))
                    if (!$util.isInteger(message.patch))
                        return "patch: integer expected";
                if (message.hasOpus != null && message.hasOwnProperty("hasOpus"))
                    if (typeof message.hasOpus !== "boolean")
                        return "hasOpus: boolean expected";
                return null;
            };
    
            /**
             * Creates a ServiceVersion message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.ServiceVersion
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.ServiceVersion} ServiceVersion
             */
            ServiceVersion.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.ServiceVersion)
                    return object;
                var message = new $root.listener.ServiceVersion();
                if (object.major != null)
                    message.major = object.major >>> 0;
                if (object.minor != null)
                    message.minor = object.minor >>> 0;
                if (object.patch != null)
                    message.patch = object.patch >>> 0;
                if (object.hasOpus != null)
                    message.hasOpus = Boolean(object.hasOpus);
                return message;
            };
    
            /**
             * Creates a plain object from a ServiceVersion message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.ServiceVersion
             * @static
             * @param {listener.ServiceVersion} message ServiceVersion
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceVersion.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.major = 0;
                    object.minor = 0;
                    object.patch = 0;
                    object.hasOpus = false;
                }
                if (message.major != null && message.hasOwnProperty("major"))
                    object.major = message.major;
                if (message.minor != null && message.hasOwnProperty("minor"))
                    object.minor = message.minor;
                if (message.patch != null && message.hasOwnProperty("patch"))
                    object.patch = message.patch;
                if (message.hasOpus != null && message.hasOwnProperty("hasOpus"))
                    object.hasOpus = message.hasOpus;
                return object;
            };
    
            /**
             * Converts this ServiceVersion to JSON.
             * @function toJSON
             * @memberof listener.ServiceVersion
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceVersion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ServiceVersion
             * @function getTypeUrl
             * @memberof listener.ServiceVersion
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceVersion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.ServiceVersion";
            };
    
            return ServiceVersion;
        })();
    
        listener.ServiceMessage = (function() {
    
            /**
             * Properties of a ServiceMessage.
             * @memberof listener
             * @interface IServiceMessage
             * @property {listener.ServiceMessage.Status|null} [status] ServiceMessage status
             * @property {Array.<string>|null} [metainfo] ServiceMessage metainfo
             * @property {listener.IRequest|null} [initialRequest] ServiceMessage initialRequest
             */
    
            /**
             * Constructs a new ServiceMessage.
             * @memberof listener
             * @classdesc Represents a ServiceMessage.
             * @implements IServiceMessage
             * @constructor
             * @param {listener.IServiceMessage=} [properties] Properties to set
             */
            function ServiceMessage(properties) {
                this.metainfo = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ServiceMessage status.
             * @member {listener.ServiceMessage.Status} status
             * @memberof listener.ServiceMessage
             * @instance
             */
            ServiceMessage.prototype.status = 0;
    
            /**
             * ServiceMessage metainfo.
             * @member {Array.<string>} metainfo
             * @memberof listener.ServiceMessage
             * @instance
             */
            ServiceMessage.prototype.metainfo = $util.emptyArray;
    
            /**
             * ServiceMessage initialRequest.
             * @member {listener.IRequest|null|undefined} initialRequest
             * @memberof listener.ServiceMessage
             * @instance
             */
            ServiceMessage.prototype.initialRequest = null;
    
            /**
             * Creates a new ServiceMessage instance using the specified properties.
             * @function create
             * @memberof listener.ServiceMessage
             * @static
             * @param {listener.IServiceMessage=} [properties] Properties to set
             * @returns {listener.ServiceMessage} ServiceMessage instance
             */
            ServiceMessage.create = function create(properties) {
                return new ServiceMessage(properties);
            };
    
            /**
             * Encodes the specified ServiceMessage message. Does not implicitly {@link listener.ServiceMessage.verify|verify} messages.
             * @function encode
             * @memberof listener.ServiceMessage
             * @static
             * @param {listener.IServiceMessage} message ServiceMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                if (message.metainfo != null && message.metainfo.length)
                    for (var i = 0; i < message.metainfo.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.metainfo[i]);
                if (message.initialRequest != null && Object.hasOwnProperty.call(message, "initialRequest"))
                    $root.listener.Request.encode(message.initialRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ServiceMessage message, length delimited. Does not implicitly {@link listener.ServiceMessage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.ServiceMessage
             * @static
             * @param {listener.IServiceMessage} message ServiceMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceMessage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ServiceMessage message from the specified reader or buffer.
             * @function decode
             * @memberof listener.ServiceMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.ServiceMessage} ServiceMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.ServiceMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.status = reader.int32();
                            break;
                        }
                    case 2: {
                            if (!(message.metainfo && message.metainfo.length))
                                message.metainfo = [];
                            message.metainfo.push(reader.string());
                            break;
                        }
                    case 3: {
                            message.initialRequest = $root.listener.Request.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ServiceMessage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.ServiceMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.ServiceMessage} ServiceMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceMessage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ServiceMessage message.
             * @function verify
             * @memberof listener.ServiceMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.metainfo != null && message.hasOwnProperty("metainfo")) {
                    if (!Array.isArray(message.metainfo))
                        return "metainfo: array expected";
                    for (var i = 0; i < message.metainfo.length; ++i)
                        if (!$util.isString(message.metainfo[i]))
                            return "metainfo: string[] expected";
                }
                if (message.initialRequest != null && message.hasOwnProperty("initialRequest")) {
                    var error = $root.listener.Request.verify(message.initialRequest);
                    if (error)
                        return "initialRequest." + error;
                }
                return null;
            };
    
            /**
             * Creates a ServiceMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.ServiceMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.ServiceMessage} ServiceMessage
             */
            ServiceMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.ServiceMessage)
                    return object;
                var message = new $root.listener.ServiceMessage();
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "OK":
                case 0:
                    message.status = 0;
                    break;
                case "INVALID_REQUEST":
                case 1:
                    message.status = 1;
                    break;
                case "INTERNAL_ERROR":
                case 2:
                    message.status = 2;
                    break;
                }
                if (object.metainfo) {
                    if (!Array.isArray(object.metainfo))
                        throw TypeError(".listener.ServiceMessage.metainfo: array expected");
                    message.metainfo = [];
                    for (var i = 0; i < object.metainfo.length; ++i)
                        message.metainfo[i] = String(object.metainfo[i]);
                }
                if (object.initialRequest != null) {
                    if (typeof object.initialRequest !== "object")
                        throw TypeError(".listener.ServiceMessage.initialRequest: object expected");
                    message.initialRequest = $root.listener.Request.fromObject(object.initialRequest);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ServiceMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.ServiceMessage
             * @static
             * @param {listener.ServiceMessage} message ServiceMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.metainfo = [];
                if (options.defaults) {
                    object.status = options.enums === String ? "OK" : 0;
                    object.initialRequest = null;
                }
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.listener.ServiceMessage.Status[message.status] === undefined ? message.status : $root.listener.ServiceMessage.Status[message.status] : message.status;
                if (message.metainfo && message.metainfo.length) {
                    object.metainfo = [];
                    for (var j = 0; j < message.metainfo.length; ++j)
                        object.metainfo[j] = message.metainfo[j];
                }
                if (message.initialRequest != null && message.hasOwnProperty("initialRequest"))
                    object.initialRequest = $root.listener.Request.toObject(message.initialRequest, options);
                return object;
            };
    
            /**
             * Converts this ServiceMessage to JSON.
             * @function toJSON
             * @memberof listener.ServiceMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ServiceMessage
             * @function getTypeUrl
             * @memberof listener.ServiceMessage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.ServiceMessage";
            };
    
            /**
             * Status enum.
             * @name listener.ServiceMessage.Status
             * @enum {number}
             * @property {number} OK=0 OK value
             * @property {number} INVALID_REQUEST=1 INVALID_REQUEST value
             * @property {number} INTERNAL_ERROR=2 INTERNAL_ERROR value
             */
            ServiceMessage.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "OK"] = 0;
                values[valuesById[1] = "INVALID_REQUEST"] = 1;
                values[valuesById[2] = "INTERNAL_ERROR"] = 2;
                return values;
            })();
    
            return ServiceMessage;
        })();
    
        listener.SystemInformation = (function() {
    
            /**
             * Properties of a SystemInformation.
             * @memberof listener
             * @interface ISystemInformation
             * @property {string|null} [username] SystemInformation username
             * @property {string|null} [companyName] SystemInformation companyName
             * @property {string|null} [companySecretToken] SystemInformation companySecretToken
             * @property {string|null} [country] SystemInformation country
             * @property {string|null} [comments] SystemInformation comments
             * @property {string|null} [uniqueId] SystemInformation uniqueId
             * @property {listener.SystemInformation.StealthMode|null} [coachStealthMode] SystemInformation coachStealthMode
             * @property {listener.SystemInformation.WindowMode|null} [coachWindowMode] SystemInformation coachWindowMode
             * @property {listener.SystemInformation.UserIdentificationMode|null} [userIdMode] SystemInformation userIdMode
             * @property {string|null} [osUsername] SystemInformation osUsername
             */
    
            /**
             * Constructs a new SystemInformation.
             * @memberof listener
             * @classdesc Represents a SystemInformation.
             * @implements ISystemInformation
             * @constructor
             * @param {listener.ISystemInformation=} [properties] Properties to set
             */
            function SystemInformation(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SystemInformation username.
             * @member {string} username
             * @memberof listener.SystemInformation
             * @instance
             */
            SystemInformation.prototype.username = "";
    
            /**
             * SystemInformation companyName.
             * @member {string} companyName
             * @memberof listener.SystemInformation
             * @instance
             */
            SystemInformation.prototype.companyName = "";
    
            /**
             * SystemInformation companySecretToken.
             * @member {string} companySecretToken
             * @memberof listener.SystemInformation
             * @instance
             */
            SystemInformation.prototype.companySecretToken = "";
    
            /**
             * SystemInformation country.
             * @member {string} country
             * @memberof listener.SystemInformation
             * @instance
             */
            SystemInformation.prototype.country = "";
    
            /**
             * SystemInformation comments.
             * @member {string} comments
             * @memberof listener.SystemInformation
             * @instance
             */
            SystemInformation.prototype.comments = "";
    
            /**
             * SystemInformation uniqueId.
             * @member {string} uniqueId
             * @memberof listener.SystemInformation
             * @instance
             */
            SystemInformation.prototype.uniqueId = "";
    
            /**
             * SystemInformation coachStealthMode.
             * @member {listener.SystemInformation.StealthMode} coachStealthMode
             * @memberof listener.SystemInformation
             * @instance
             */
            SystemInformation.prototype.coachStealthMode = 0;
    
            /**
             * SystemInformation coachWindowMode.
             * @member {listener.SystemInformation.WindowMode} coachWindowMode
             * @memberof listener.SystemInformation
             * @instance
             */
            SystemInformation.prototype.coachWindowMode = 0;
    
            /**
             * SystemInformation userIdMode.
             * @member {listener.SystemInformation.UserIdentificationMode} userIdMode
             * @memberof listener.SystemInformation
             * @instance
             */
            SystemInformation.prototype.userIdMode = 0;
    
            /**
             * SystemInformation osUsername.
             * @member {string} osUsername
             * @memberof listener.SystemInformation
             * @instance
             */
            SystemInformation.prototype.osUsername = "";
    
            /**
             * Creates a new SystemInformation instance using the specified properties.
             * @function create
             * @memberof listener.SystemInformation
             * @static
             * @param {listener.ISystemInformation=} [properties] Properties to set
             * @returns {listener.SystemInformation} SystemInformation instance
             */
            SystemInformation.create = function create(properties) {
                return new SystemInformation(properties);
            };
    
            /**
             * Encodes the specified SystemInformation message. Does not implicitly {@link listener.SystemInformation.verify|verify} messages.
             * @function encode
             * @memberof listener.SystemInformation
             * @static
             * @param {listener.ISystemInformation} message SystemInformation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SystemInformation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
                if (message.companyName != null && Object.hasOwnProperty.call(message, "companyName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.companyName);
                if (message.companySecretToken != null && Object.hasOwnProperty.call(message, "companySecretToken"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.companySecretToken);
                if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.country);
                if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.comments);
                if (message.uniqueId != null && Object.hasOwnProperty.call(message, "uniqueId"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.uniqueId);
                if (message.coachStealthMode != null && Object.hasOwnProperty.call(message, "coachStealthMode"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.coachStealthMode);
                if (message.coachWindowMode != null && Object.hasOwnProperty.call(message, "coachWindowMode"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.coachWindowMode);
                if (message.userIdMode != null && Object.hasOwnProperty.call(message, "userIdMode"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.userIdMode);
                if (message.osUsername != null && Object.hasOwnProperty.call(message, "osUsername"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.osUsername);
                return writer;
            };
    
            /**
             * Encodes the specified SystemInformation message, length delimited. Does not implicitly {@link listener.SystemInformation.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.SystemInformation
             * @static
             * @param {listener.ISystemInformation} message SystemInformation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SystemInformation.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SystemInformation message from the specified reader or buffer.
             * @function decode
             * @memberof listener.SystemInformation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.SystemInformation} SystemInformation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SystemInformation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.SystemInformation();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.username = reader.string();
                            break;
                        }
                    case 2: {
                            message.companyName = reader.string();
                            break;
                        }
                    case 3: {
                            message.companySecretToken = reader.string();
                            break;
                        }
                    case 4: {
                            message.country = reader.string();
                            break;
                        }
                    case 5: {
                            message.comments = reader.string();
                            break;
                        }
                    case 6: {
                            message.uniqueId = reader.string();
                            break;
                        }
                    case 7: {
                            message.coachStealthMode = reader.int32();
                            break;
                        }
                    case 8: {
                            message.coachWindowMode = reader.int32();
                            break;
                        }
                    case 9: {
                            message.userIdMode = reader.int32();
                            break;
                        }
                    case 10: {
                            message.osUsername = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SystemInformation message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.SystemInformation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.SystemInformation} SystemInformation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SystemInformation.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SystemInformation message.
             * @function verify
             * @memberof listener.SystemInformation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SystemInformation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.username != null && message.hasOwnProperty("username"))
                    if (!$util.isString(message.username))
                        return "username: string expected";
                if (message.companyName != null && message.hasOwnProperty("companyName"))
                    if (!$util.isString(message.companyName))
                        return "companyName: string expected";
                if (message.companySecretToken != null && message.hasOwnProperty("companySecretToken"))
                    if (!$util.isString(message.companySecretToken))
                        return "companySecretToken: string expected";
                if (message.country != null && message.hasOwnProperty("country"))
                    if (!$util.isString(message.country))
                        return "country: string expected";
                if (message.comments != null && message.hasOwnProperty("comments"))
                    if (!$util.isString(message.comments))
                        return "comments: string expected";
                if (message.uniqueId != null && message.hasOwnProperty("uniqueId"))
                    if (!$util.isString(message.uniqueId))
                        return "uniqueId: string expected";
                if (message.coachStealthMode != null && message.hasOwnProperty("coachStealthMode"))
                    switch (message.coachStealthMode) {
                    default:
                        return "coachStealthMode: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.coachWindowMode != null && message.hasOwnProperty("coachWindowMode"))
                    switch (message.coachWindowMode) {
                    default:
                        return "coachWindowMode: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.userIdMode != null && message.hasOwnProperty("userIdMode"))
                    switch (message.userIdMode) {
                    default:
                        return "userIdMode: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.osUsername != null && message.hasOwnProperty("osUsername"))
                    if (!$util.isString(message.osUsername))
                        return "osUsername: string expected";
                return null;
            };
    
            /**
             * Creates a SystemInformation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.SystemInformation
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.SystemInformation} SystemInformation
             */
            SystemInformation.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.SystemInformation)
                    return object;
                var message = new $root.listener.SystemInformation();
                if (object.username != null)
                    message.username = String(object.username);
                if (object.companyName != null)
                    message.companyName = String(object.companyName);
                if (object.companySecretToken != null)
                    message.companySecretToken = String(object.companySecretToken);
                if (object.country != null)
                    message.country = String(object.country);
                if (object.comments != null)
                    message.comments = String(object.comments);
                if (object.uniqueId != null)
                    message.uniqueId = String(object.uniqueId);
                switch (object.coachStealthMode) {
                default:
                    if (typeof object.coachStealthMode === "number") {
                        message.coachStealthMode = object.coachStealthMode;
                        break;
                    }
                    break;
                case "STEALTH_MODE_UNKNOWN":
                case 0:
                    message.coachStealthMode = 0;
                    break;
                case "ON":
                case 1:
                    message.coachStealthMode = 1;
                    break;
                case "OFF":
                case 2:
                    message.coachStealthMode = 2;
                    break;
                }
                switch (object.coachWindowMode) {
                default:
                    if (typeof object.coachWindowMode === "number") {
                        message.coachWindowMode = object.coachWindowMode;
                        break;
                    }
                    break;
                case "WINDOW_MODE_UNKNOWN":
                case 0:
                    message.coachWindowMode = 0;
                    break;
                case "COLLAPSED":
                case 1:
                    message.coachWindowMode = 1;
                    break;
                case "VISIBLE":
                case 2:
                    message.coachWindowMode = 2;
                    break;
                }
                switch (object.userIdMode) {
                default:
                    if (typeof object.userIdMode === "number") {
                        message.userIdMode = object.userIdMode;
                        break;
                    }
                    break;
                case "WINDOWS_USERNAME":
                case 0:
                    message.userIdMode = 0;
                    break;
                case "GENESYS_AGENT_ID":
                case 1:
                    message.userIdMode = 1;
                    break;
                }
                if (object.osUsername != null)
                    message.osUsername = String(object.osUsername);
                return message;
            };
    
            /**
             * Creates a plain object from a SystemInformation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.SystemInformation
             * @static
             * @param {listener.SystemInformation} message SystemInformation
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SystemInformation.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.username = "";
                    object.companyName = "";
                    object.companySecretToken = "";
                    object.country = "";
                    object.comments = "";
                    object.uniqueId = "";
                    object.coachStealthMode = options.enums === String ? "STEALTH_MODE_UNKNOWN" : 0;
                    object.coachWindowMode = options.enums === String ? "WINDOW_MODE_UNKNOWN" : 0;
                    object.userIdMode = options.enums === String ? "WINDOWS_USERNAME" : 0;
                    object.osUsername = "";
                }
                if (message.username != null && message.hasOwnProperty("username"))
                    object.username = message.username;
                if (message.companyName != null && message.hasOwnProperty("companyName"))
                    object.companyName = message.companyName;
                if (message.companySecretToken != null && message.hasOwnProperty("companySecretToken"))
                    object.companySecretToken = message.companySecretToken;
                if (message.country != null && message.hasOwnProperty("country"))
                    object.country = message.country;
                if (message.comments != null && message.hasOwnProperty("comments"))
                    object.comments = message.comments;
                if (message.uniqueId != null && message.hasOwnProperty("uniqueId"))
                    object.uniqueId = message.uniqueId;
                if (message.coachStealthMode != null && message.hasOwnProperty("coachStealthMode"))
                    object.coachStealthMode = options.enums === String ? $root.listener.SystemInformation.StealthMode[message.coachStealthMode] === undefined ? message.coachStealthMode : $root.listener.SystemInformation.StealthMode[message.coachStealthMode] : message.coachStealthMode;
                if (message.coachWindowMode != null && message.hasOwnProperty("coachWindowMode"))
                    object.coachWindowMode = options.enums === String ? $root.listener.SystemInformation.WindowMode[message.coachWindowMode] === undefined ? message.coachWindowMode : $root.listener.SystemInformation.WindowMode[message.coachWindowMode] : message.coachWindowMode;
                if (message.userIdMode != null && message.hasOwnProperty("userIdMode"))
                    object.userIdMode = options.enums === String ? $root.listener.SystemInformation.UserIdentificationMode[message.userIdMode] === undefined ? message.userIdMode : $root.listener.SystemInformation.UserIdentificationMode[message.userIdMode] : message.userIdMode;
                if (message.osUsername != null && message.hasOwnProperty("osUsername"))
                    object.osUsername = message.osUsername;
                return object;
            };
    
            /**
             * Converts this SystemInformation to JSON.
             * @function toJSON
             * @memberof listener.SystemInformation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SystemInformation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SystemInformation
             * @function getTypeUrl
             * @memberof listener.SystemInformation
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SystemInformation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.SystemInformation";
            };
    
            /**
             * StealthMode enum.
             * @name listener.SystemInformation.StealthMode
             * @enum {number}
             * @property {number} STEALTH_MODE_UNKNOWN=0 STEALTH_MODE_UNKNOWN value
             * @property {number} ON=1 ON value
             * @property {number} OFF=2 OFF value
             */
            SystemInformation.StealthMode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STEALTH_MODE_UNKNOWN"] = 0;
                values[valuesById[1] = "ON"] = 1;
                values[valuesById[2] = "OFF"] = 2;
                return values;
            })();
    
            /**
             * WindowMode enum.
             * @name listener.SystemInformation.WindowMode
             * @enum {number}
             * @property {number} WINDOW_MODE_UNKNOWN=0 WINDOW_MODE_UNKNOWN value
             * @property {number} COLLAPSED=1 COLLAPSED value
             * @property {number} VISIBLE=2 VISIBLE value
             */
            SystemInformation.WindowMode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "WINDOW_MODE_UNKNOWN"] = 0;
                values[valuesById[1] = "COLLAPSED"] = 1;
                values[valuesById[2] = "VISIBLE"] = 2;
                return values;
            })();
    
            /**
             * UserIdentificationMode enum.
             * @name listener.SystemInformation.UserIdentificationMode
             * @enum {number}
             * @property {number} WINDOWS_USERNAME=0 WINDOWS_USERNAME value
             * @property {number} GENESYS_AGENT_ID=1 GENESYS_AGENT_ID value
             */
            SystemInformation.UserIdentificationMode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "WINDOWS_USERNAME"] = 0;
                values[valuesById[1] = "GENESYS_AGENT_ID"] = 1;
                return values;
            })();
    
            return SystemInformation;
        })();
    
        listener.AuthorizationPrompt = (function() {
    
            /**
             * Properties of an AuthorizationPrompt.
             * @memberof listener
             * @interface IAuthorizationPrompt
             * @property {listener.ISystemInformation|null} [systemInformation] AuthorizationPrompt systemInformation
             */
    
            /**
             * Constructs a new AuthorizationPrompt.
             * @memberof listener
             * @classdesc Represents an AuthorizationPrompt.
             * @implements IAuthorizationPrompt
             * @constructor
             * @param {listener.IAuthorizationPrompt=} [properties] Properties to set
             */
            function AuthorizationPrompt(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AuthorizationPrompt systemInformation.
             * @member {listener.ISystemInformation|null|undefined} systemInformation
             * @memberof listener.AuthorizationPrompt
             * @instance
             */
            AuthorizationPrompt.prototype.systemInformation = null;
    
            /**
             * Creates a new AuthorizationPrompt instance using the specified properties.
             * @function create
             * @memberof listener.AuthorizationPrompt
             * @static
             * @param {listener.IAuthorizationPrompt=} [properties] Properties to set
             * @returns {listener.AuthorizationPrompt} AuthorizationPrompt instance
             */
            AuthorizationPrompt.create = function create(properties) {
                return new AuthorizationPrompt(properties);
            };
    
            /**
             * Encodes the specified AuthorizationPrompt message. Does not implicitly {@link listener.AuthorizationPrompt.verify|verify} messages.
             * @function encode
             * @memberof listener.AuthorizationPrompt
             * @static
             * @param {listener.IAuthorizationPrompt} message AuthorizationPrompt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthorizationPrompt.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.systemInformation != null && Object.hasOwnProperty.call(message, "systemInformation"))
                    $root.listener.SystemInformation.encode(message.systemInformation, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AuthorizationPrompt message, length delimited. Does not implicitly {@link listener.AuthorizationPrompt.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.AuthorizationPrompt
             * @static
             * @param {listener.IAuthorizationPrompt} message AuthorizationPrompt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthorizationPrompt.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AuthorizationPrompt message from the specified reader or buffer.
             * @function decode
             * @memberof listener.AuthorizationPrompt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.AuthorizationPrompt} AuthorizationPrompt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthorizationPrompt.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.AuthorizationPrompt();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.systemInformation = $root.listener.SystemInformation.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AuthorizationPrompt message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.AuthorizationPrompt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.AuthorizationPrompt} AuthorizationPrompt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthorizationPrompt.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AuthorizationPrompt message.
             * @function verify
             * @memberof listener.AuthorizationPrompt
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuthorizationPrompt.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.systemInformation != null && message.hasOwnProperty("systemInformation")) {
                    var error = $root.listener.SystemInformation.verify(message.systemInformation);
                    if (error)
                        return "systemInformation." + error;
                }
                return null;
            };
    
            /**
             * Creates an AuthorizationPrompt message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.AuthorizationPrompt
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.AuthorizationPrompt} AuthorizationPrompt
             */
            AuthorizationPrompt.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.AuthorizationPrompt)
                    return object;
                var message = new $root.listener.AuthorizationPrompt();
                if (object.systemInformation != null) {
                    if (typeof object.systemInformation !== "object")
                        throw TypeError(".listener.AuthorizationPrompt.systemInformation: object expected");
                    message.systemInformation = $root.listener.SystemInformation.fromObject(object.systemInformation);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AuthorizationPrompt message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.AuthorizationPrompt
             * @static
             * @param {listener.AuthorizationPrompt} message AuthorizationPrompt
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthorizationPrompt.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.systemInformation = null;
                if (message.systemInformation != null && message.hasOwnProperty("systemInformation"))
                    object.systemInformation = $root.listener.SystemInformation.toObject(message.systemInformation, options);
                return object;
            };
    
            /**
             * Converts this AuthorizationPrompt to JSON.
             * @function toJSON
             * @memberof listener.AuthorizationPrompt
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthorizationPrompt.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AuthorizationPrompt
             * @function getTypeUrl
             * @memberof listener.AuthorizationPrompt
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AuthorizationPrompt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.AuthorizationPrompt";
            };
    
            return AuthorizationPrompt;
        })();
    
        listener.AudiosystemNotificatorEndpoint = (function() {
    
            /**
             * Properties of an AudiosystemNotificatorEndpoint.
             * @memberof listener
             * @interface IAudiosystemNotificatorEndpoint
             * @property {number|null} [port] AudiosystemNotificatorEndpoint port
             */
    
            /**
             * Constructs a new AudiosystemNotificatorEndpoint.
             * @memberof listener
             * @classdesc Represents an AudiosystemNotificatorEndpoint.
             * @implements IAudiosystemNotificatorEndpoint
             * @constructor
             * @param {listener.IAudiosystemNotificatorEndpoint=} [properties] Properties to set
             */
            function AudiosystemNotificatorEndpoint(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AudiosystemNotificatorEndpoint port.
             * @member {number} port
             * @memberof listener.AudiosystemNotificatorEndpoint
             * @instance
             */
            AudiosystemNotificatorEndpoint.prototype.port = 0;
    
            /**
             * Creates a new AudiosystemNotificatorEndpoint instance using the specified properties.
             * @function create
             * @memberof listener.AudiosystemNotificatorEndpoint
             * @static
             * @param {listener.IAudiosystemNotificatorEndpoint=} [properties] Properties to set
             * @returns {listener.AudiosystemNotificatorEndpoint} AudiosystemNotificatorEndpoint instance
             */
            AudiosystemNotificatorEndpoint.create = function create(properties) {
                return new AudiosystemNotificatorEndpoint(properties);
            };
    
            /**
             * Encodes the specified AudiosystemNotificatorEndpoint message. Does not implicitly {@link listener.AudiosystemNotificatorEndpoint.verify|verify} messages.
             * @function encode
             * @memberof listener.AudiosystemNotificatorEndpoint
             * @static
             * @param {listener.IAudiosystemNotificatorEndpoint} message AudiosystemNotificatorEndpoint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudiosystemNotificatorEndpoint.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.port);
                return writer;
            };
    
            /**
             * Encodes the specified AudiosystemNotificatorEndpoint message, length delimited. Does not implicitly {@link listener.AudiosystemNotificatorEndpoint.verify|verify} messages.
             * @function encodeDelimited
             * @memberof listener.AudiosystemNotificatorEndpoint
             * @static
             * @param {listener.IAudiosystemNotificatorEndpoint} message AudiosystemNotificatorEndpoint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudiosystemNotificatorEndpoint.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AudiosystemNotificatorEndpoint message from the specified reader or buffer.
             * @function decode
             * @memberof listener.AudiosystemNotificatorEndpoint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {listener.AudiosystemNotificatorEndpoint} AudiosystemNotificatorEndpoint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudiosystemNotificatorEndpoint.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.listener.AudiosystemNotificatorEndpoint();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.port = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AudiosystemNotificatorEndpoint message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof listener.AudiosystemNotificatorEndpoint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {listener.AudiosystemNotificatorEndpoint} AudiosystemNotificatorEndpoint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudiosystemNotificatorEndpoint.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AudiosystemNotificatorEndpoint message.
             * @function verify
             * @memberof listener.AudiosystemNotificatorEndpoint
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AudiosystemNotificatorEndpoint.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.port != null && message.hasOwnProperty("port"))
                    if (!$util.isInteger(message.port))
                        return "port: integer expected";
                return null;
            };
    
            /**
             * Creates an AudiosystemNotificatorEndpoint message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof listener.AudiosystemNotificatorEndpoint
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {listener.AudiosystemNotificatorEndpoint} AudiosystemNotificatorEndpoint
             */
            AudiosystemNotificatorEndpoint.fromObject = function fromObject(object) {
                if (object instanceof $root.listener.AudiosystemNotificatorEndpoint)
                    return object;
                var message = new $root.listener.AudiosystemNotificatorEndpoint();
                if (object.port != null)
                    message.port = object.port >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from an AudiosystemNotificatorEndpoint message. Also converts values to other types if specified.
             * @function toObject
             * @memberof listener.AudiosystemNotificatorEndpoint
             * @static
             * @param {listener.AudiosystemNotificatorEndpoint} message AudiosystemNotificatorEndpoint
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AudiosystemNotificatorEndpoint.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.port = 0;
                if (message.port != null && message.hasOwnProperty("port"))
                    object.port = message.port;
                return object;
            };
    
            /**
             * Converts this AudiosystemNotificatorEndpoint to JSON.
             * @function toJSON
             * @memberof listener.AudiosystemNotificatorEndpoint
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AudiosystemNotificatorEndpoint.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AudiosystemNotificatorEndpoint
             * @function getTypeUrl
             * @memberof listener.AudiosystemNotificatorEndpoint
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AudiosystemNotificatorEndpoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/listener.AudiosystemNotificatorEndpoint";
            };
    
            return AudiosystemNotificatorEndpoint;
        })();
    
        return listener;
    })();

    return $root;
});
