/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.vibe = (function() {
    
        /**
         * Namespace vibe.
         * @exports vibe
         * @namespace
         */
        var vibe = {};
    
        vibe.RecognizeRequest = (function() {
    
            /**
             * Properties of a RecognizeRequest.
             * @memberof vibe
             * @interface IRecognizeRequest
             * @property {vibe.IRecognitionConfig|null} [initialConfig] RecognizeRequest initialConfig
             * @property {vibe.IStreamingRequest|null} [streamingRequest] RecognizeRequest streamingRequest
             * @property {vibe.IAuthenticationRequest|null} [authRequest] RecognizeRequest authRequest
             * @property {vibe.ICallDetectionConfig|null} [detectionConfig] RecognizeRequest detectionConfig
             * @property {vibe.IClientCapabilitiesRegistration|null} [capInfo] RecognizeRequest capInfo
             * @property {vibe.IChannelControl|null} [channelControl] RecognizeRequest channelControl
             */
    
            /**
             * Constructs a new RecognizeRequest.
             * @memberof vibe
             * @classdesc Represents a RecognizeRequest.
             * @implements IRecognizeRequest
             * @constructor
             * @param {vibe.IRecognizeRequest=} [properties] Properties to set
             */
            function RecognizeRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RecognizeRequest initialConfig.
             * @member {vibe.IRecognitionConfig|null|undefined} initialConfig
             * @memberof vibe.RecognizeRequest
             * @instance
             */
            RecognizeRequest.prototype.initialConfig = null;
    
            /**
             * RecognizeRequest streamingRequest.
             * @member {vibe.IStreamingRequest|null|undefined} streamingRequest
             * @memberof vibe.RecognizeRequest
             * @instance
             */
            RecognizeRequest.prototype.streamingRequest = null;
    
            /**
             * RecognizeRequest authRequest.
             * @member {vibe.IAuthenticationRequest|null|undefined} authRequest
             * @memberof vibe.RecognizeRequest
             * @instance
             */
            RecognizeRequest.prototype.authRequest = null;
    
            /**
             * RecognizeRequest detectionConfig.
             * @member {vibe.ICallDetectionConfig|null|undefined} detectionConfig
             * @memberof vibe.RecognizeRequest
             * @instance
             */
            RecognizeRequest.prototype.detectionConfig = null;
    
            /**
             * RecognizeRequest capInfo.
             * @member {vibe.IClientCapabilitiesRegistration|null|undefined} capInfo
             * @memberof vibe.RecognizeRequest
             * @instance
             */
            RecognizeRequest.prototype.capInfo = null;
    
            /**
             * RecognizeRequest channelControl.
             * @member {vibe.IChannelControl|null|undefined} channelControl
             * @memberof vibe.RecognizeRequest
             * @instance
             */
            RecognizeRequest.prototype.channelControl = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * RecognizeRequest inputMsgType.
             * @member {"initialConfig"|"streamingRequest"|"authRequest"|"detectionConfig"|"capInfo"|"channelControl"|undefined} inputMsgType
             * @memberof vibe.RecognizeRequest
             * @instance
             */
            Object.defineProperty(RecognizeRequest.prototype, "inputMsgType", {
                get: $util.oneOfGetter($oneOfFields = ["initialConfig", "streamingRequest", "authRequest", "detectionConfig", "capInfo", "channelControl"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new RecognizeRequest instance using the specified properties.
             * @function create
             * @memberof vibe.RecognizeRequest
             * @static
             * @param {vibe.IRecognizeRequest=} [properties] Properties to set
             * @returns {vibe.RecognizeRequest} RecognizeRequest instance
             */
            RecognizeRequest.create = function create(properties) {
                return new RecognizeRequest(properties);
            };
    
            /**
             * Encodes the specified RecognizeRequest message. Does not implicitly {@link vibe.RecognizeRequest.verify|verify} messages.
             * @function encode
             * @memberof vibe.RecognizeRequest
             * @static
             * @param {vibe.IRecognizeRequest} message RecognizeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecognizeRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.initialConfig != null && Object.hasOwnProperty.call(message, "initialConfig"))
                    $root.vibe.RecognitionConfig.encode(message.initialConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.streamingRequest != null && Object.hasOwnProperty.call(message, "streamingRequest"))
                    $root.vibe.StreamingRequest.encode(message.streamingRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.authRequest != null && Object.hasOwnProperty.call(message, "authRequest"))
                    $root.vibe.AuthenticationRequest.encode(message.authRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.detectionConfig != null && Object.hasOwnProperty.call(message, "detectionConfig"))
                    $root.vibe.CallDetectionConfig.encode(message.detectionConfig, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.capInfo != null && Object.hasOwnProperty.call(message, "capInfo"))
                    $root.vibe.ClientCapabilitiesRegistration.encode(message.capInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.channelControl != null && Object.hasOwnProperty.call(message, "channelControl"))
                    $root.vibe.ChannelControl.encode(message.channelControl, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RecognizeRequest message, length delimited. Does not implicitly {@link vibe.RecognizeRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.RecognizeRequest
             * @static
             * @param {vibe.IRecognizeRequest} message RecognizeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecognizeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RecognizeRequest message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.RecognizeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.RecognizeRequest} RecognizeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecognizeRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.RecognizeRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.initialConfig = $root.vibe.RecognitionConfig.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.streamingRequest = $root.vibe.StreamingRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.authRequest = $root.vibe.AuthenticationRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.detectionConfig = $root.vibe.CallDetectionConfig.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.capInfo = $root.vibe.ClientCapabilitiesRegistration.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.channelControl = $root.vibe.ChannelControl.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RecognizeRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.RecognizeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.RecognizeRequest} RecognizeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecognizeRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RecognizeRequest message.
             * @function verify
             * @memberof vibe.RecognizeRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RecognizeRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.initialConfig != null && message.hasOwnProperty("initialConfig")) {
                    properties.inputMsgType = 1;
                    {
                        var error = $root.vibe.RecognitionConfig.verify(message.initialConfig);
                        if (error)
                            return "initialConfig." + error;
                    }
                }
                if (message.streamingRequest != null && message.hasOwnProperty("streamingRequest")) {
                    if (properties.inputMsgType === 1)
                        return "inputMsgType: multiple values";
                    properties.inputMsgType = 1;
                    {
                        var error = $root.vibe.StreamingRequest.verify(message.streamingRequest);
                        if (error)
                            return "streamingRequest." + error;
                    }
                }
                if (message.authRequest != null && message.hasOwnProperty("authRequest")) {
                    if (properties.inputMsgType === 1)
                        return "inputMsgType: multiple values";
                    properties.inputMsgType = 1;
                    {
                        var error = $root.vibe.AuthenticationRequest.verify(message.authRequest);
                        if (error)
                            return "authRequest." + error;
                    }
                }
                if (message.detectionConfig != null && message.hasOwnProperty("detectionConfig")) {
                    if (properties.inputMsgType === 1)
                        return "inputMsgType: multiple values";
                    properties.inputMsgType = 1;
                    {
                        var error = $root.vibe.CallDetectionConfig.verify(message.detectionConfig);
                        if (error)
                            return "detectionConfig." + error;
                    }
                }
                if (message.capInfo != null && message.hasOwnProperty("capInfo")) {
                    if (properties.inputMsgType === 1)
                        return "inputMsgType: multiple values";
                    properties.inputMsgType = 1;
                    {
                        var error = $root.vibe.ClientCapabilitiesRegistration.verify(message.capInfo);
                        if (error)
                            return "capInfo." + error;
                    }
                }
                if (message.channelControl != null && message.hasOwnProperty("channelControl")) {
                    if (properties.inputMsgType === 1)
                        return "inputMsgType: multiple values";
                    properties.inputMsgType = 1;
                    {
                        var error = $root.vibe.ChannelControl.verify(message.channelControl);
                        if (error)
                            return "channelControl." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a RecognizeRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.RecognizeRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.RecognizeRequest} RecognizeRequest
             */
            RecognizeRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.RecognizeRequest)
                    return object;
                var message = new $root.vibe.RecognizeRequest();
                if (object.initialConfig != null) {
                    if (typeof object.initialConfig !== "object")
                        throw TypeError(".vibe.RecognizeRequest.initialConfig: object expected");
                    message.initialConfig = $root.vibe.RecognitionConfig.fromObject(object.initialConfig);
                }
                if (object.streamingRequest != null) {
                    if (typeof object.streamingRequest !== "object")
                        throw TypeError(".vibe.RecognizeRequest.streamingRequest: object expected");
                    message.streamingRequest = $root.vibe.StreamingRequest.fromObject(object.streamingRequest);
                }
                if (object.authRequest != null) {
                    if (typeof object.authRequest !== "object")
                        throw TypeError(".vibe.RecognizeRequest.authRequest: object expected");
                    message.authRequest = $root.vibe.AuthenticationRequest.fromObject(object.authRequest);
                }
                if (object.detectionConfig != null) {
                    if (typeof object.detectionConfig !== "object")
                        throw TypeError(".vibe.RecognizeRequest.detectionConfig: object expected");
                    message.detectionConfig = $root.vibe.CallDetectionConfig.fromObject(object.detectionConfig);
                }
                if (object.capInfo != null) {
                    if (typeof object.capInfo !== "object")
                        throw TypeError(".vibe.RecognizeRequest.capInfo: object expected");
                    message.capInfo = $root.vibe.ClientCapabilitiesRegistration.fromObject(object.capInfo);
                }
                if (object.channelControl != null) {
                    if (typeof object.channelControl !== "object")
                        throw TypeError(".vibe.RecognizeRequest.channelControl: object expected");
                    message.channelControl = $root.vibe.ChannelControl.fromObject(object.channelControl);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RecognizeRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.RecognizeRequest
             * @static
             * @param {vibe.RecognizeRequest} message RecognizeRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RecognizeRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.initialConfig != null && message.hasOwnProperty("initialConfig")) {
                    object.initialConfig = $root.vibe.RecognitionConfig.toObject(message.initialConfig, options);
                    if (options.oneofs)
                        object.inputMsgType = "initialConfig";
                }
                if (message.streamingRequest != null && message.hasOwnProperty("streamingRequest")) {
                    object.streamingRequest = $root.vibe.StreamingRequest.toObject(message.streamingRequest, options);
                    if (options.oneofs)
                        object.inputMsgType = "streamingRequest";
                }
                if (message.authRequest != null && message.hasOwnProperty("authRequest")) {
                    object.authRequest = $root.vibe.AuthenticationRequest.toObject(message.authRequest, options);
                    if (options.oneofs)
                        object.inputMsgType = "authRequest";
                }
                if (message.detectionConfig != null && message.hasOwnProperty("detectionConfig")) {
                    object.detectionConfig = $root.vibe.CallDetectionConfig.toObject(message.detectionConfig, options);
                    if (options.oneofs)
                        object.inputMsgType = "detectionConfig";
                }
                if (message.capInfo != null && message.hasOwnProperty("capInfo")) {
                    object.capInfo = $root.vibe.ClientCapabilitiesRegistration.toObject(message.capInfo, options);
                    if (options.oneofs)
                        object.inputMsgType = "capInfo";
                }
                if (message.channelControl != null && message.hasOwnProperty("channelControl")) {
                    object.channelControl = $root.vibe.ChannelControl.toObject(message.channelControl, options);
                    if (options.oneofs)
                        object.inputMsgType = "channelControl";
                }
                return object;
            };
    
            /**
             * Converts this RecognizeRequest to JSON.
             * @function toJSON
             * @memberof vibe.RecognizeRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RecognizeRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RecognizeRequest
             * @function getTypeUrl
             * @memberof vibe.RecognizeRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RecognizeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.RecognizeRequest";
            };
    
            return RecognizeRequest;
        })();
    
        vibe.UCTRequest = (function() {
    
            /**
             * Properties of a UCTRequest.
             * @memberof vibe
             * @interface IUCTRequest
             * @property {string|null} [jsonRequest] UCTRequest jsonRequest
             * @property {number|null} [offsetMsec] UCTRequest offsetMsec
             */
    
            /**
             * Constructs a new UCTRequest.
             * @memberof vibe
             * @classdesc Represents a UCTRequest.
             * @implements IUCTRequest
             * @constructor
             * @param {vibe.IUCTRequest=} [properties] Properties to set
             */
            function UCTRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UCTRequest jsonRequest.
             * @member {string} jsonRequest
             * @memberof vibe.UCTRequest
             * @instance
             */
            UCTRequest.prototype.jsonRequest = "";
    
            /**
             * UCTRequest offsetMsec.
             * @member {number} offsetMsec
             * @memberof vibe.UCTRequest
             * @instance
             */
            UCTRequest.prototype.offsetMsec = 0;
    
            /**
             * Creates a new UCTRequest instance using the specified properties.
             * @function create
             * @memberof vibe.UCTRequest
             * @static
             * @param {vibe.IUCTRequest=} [properties] Properties to set
             * @returns {vibe.UCTRequest} UCTRequest instance
             */
            UCTRequest.create = function create(properties) {
                return new UCTRequest(properties);
            };
    
            /**
             * Encodes the specified UCTRequest message. Does not implicitly {@link vibe.UCTRequest.verify|verify} messages.
             * @function encode
             * @memberof vibe.UCTRequest
             * @static
             * @param {vibe.IUCTRequest} message UCTRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UCTRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.jsonRequest != null && Object.hasOwnProperty.call(message, "jsonRequest"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.jsonRequest);
                if (message.offsetMsec != null && Object.hasOwnProperty.call(message, "offsetMsec"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.offsetMsec);
                return writer;
            };
    
            /**
             * Encodes the specified UCTRequest message, length delimited. Does not implicitly {@link vibe.UCTRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.UCTRequest
             * @static
             * @param {vibe.IUCTRequest} message UCTRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UCTRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a UCTRequest message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.UCTRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.UCTRequest} UCTRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UCTRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.UCTRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.jsonRequest = reader.string();
                            break;
                        }
                    case 2: {
                            message.offsetMsec = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a UCTRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.UCTRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.UCTRequest} UCTRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UCTRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a UCTRequest message.
             * @function verify
             * @memberof vibe.UCTRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UCTRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.jsonRequest != null && message.hasOwnProperty("jsonRequest"))
                    if (!$util.isString(message.jsonRequest))
                        return "jsonRequest: string expected";
                if (message.offsetMsec != null && message.hasOwnProperty("offsetMsec"))
                    if (!$util.isInteger(message.offsetMsec))
                        return "offsetMsec: integer expected";
                return null;
            };
    
            /**
             * Creates a UCTRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.UCTRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.UCTRequest} UCTRequest
             */
            UCTRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.UCTRequest)
                    return object;
                var message = new $root.vibe.UCTRequest();
                if (object.jsonRequest != null)
                    message.jsonRequest = String(object.jsonRequest);
                if (object.offsetMsec != null)
                    message.offsetMsec = object.offsetMsec >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a UCTRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.UCTRequest
             * @static
             * @param {vibe.UCTRequest} message UCTRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UCTRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.jsonRequest = "";
                    object.offsetMsec = 0;
                }
                if (message.jsonRequest != null && message.hasOwnProperty("jsonRequest"))
                    object.jsonRequest = message.jsonRequest;
                if (message.offsetMsec != null && message.hasOwnProperty("offsetMsec"))
                    object.offsetMsec = message.offsetMsec;
                return object;
            };
    
            /**
             * Converts this UCTRequest to JSON.
             * @function toJSON
             * @memberof vibe.UCTRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UCTRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UCTRequest
             * @function getTypeUrl
             * @memberof vibe.UCTRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UCTRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.UCTRequest";
            };
    
            return UCTRequest;
        })();
    
        vibe.AuthenticationRequest = (function() {
    
            /**
             * Properties of an AuthenticationRequest.
             * @memberof vibe
             * @interface IAuthenticationRequest
             * @property {vibe.AuthenticationRequest.MechanismName|null} [mechanismName] AuthenticationRequest mechanismName
             * @property {string|null} [authData] AuthenticationRequest authData
             */
    
            /**
             * Constructs a new AuthenticationRequest.
             * @memberof vibe
             * @classdesc Represents an AuthenticationRequest.
             * @implements IAuthenticationRequest
             * @constructor
             * @param {vibe.IAuthenticationRequest=} [properties] Properties to set
             */
            function AuthenticationRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AuthenticationRequest mechanismName.
             * @member {vibe.AuthenticationRequest.MechanismName} mechanismName
             * @memberof vibe.AuthenticationRequest
             * @instance
             */
            AuthenticationRequest.prototype.mechanismName = 0;
    
            /**
             * AuthenticationRequest authData.
             * @member {string} authData
             * @memberof vibe.AuthenticationRequest
             * @instance
             */
            AuthenticationRequest.prototype.authData = "";
    
            /**
             * Creates a new AuthenticationRequest instance using the specified properties.
             * @function create
             * @memberof vibe.AuthenticationRequest
             * @static
             * @param {vibe.IAuthenticationRequest=} [properties] Properties to set
             * @returns {vibe.AuthenticationRequest} AuthenticationRequest instance
             */
            AuthenticationRequest.create = function create(properties) {
                return new AuthenticationRequest(properties);
            };
    
            /**
             * Encodes the specified AuthenticationRequest message. Does not implicitly {@link vibe.AuthenticationRequest.verify|verify} messages.
             * @function encode
             * @memberof vibe.AuthenticationRequest
             * @static
             * @param {vibe.IAuthenticationRequest} message AuthenticationRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthenticationRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.mechanismName != null && Object.hasOwnProperty.call(message, "mechanismName"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.mechanismName);
                if (message.authData != null && Object.hasOwnProperty.call(message, "authData"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.authData);
                return writer;
            };
    
            /**
             * Encodes the specified AuthenticationRequest message, length delimited. Does not implicitly {@link vibe.AuthenticationRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.AuthenticationRequest
             * @static
             * @param {vibe.IAuthenticationRequest} message AuthenticationRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthenticationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AuthenticationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.AuthenticationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.AuthenticationRequest} AuthenticationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthenticationRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.AuthenticationRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.mechanismName = reader.int32();
                            break;
                        }
                    case 2: {
                            message.authData = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AuthenticationRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.AuthenticationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.AuthenticationRequest} AuthenticationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthenticationRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AuthenticationRequest message.
             * @function verify
             * @memberof vibe.AuthenticationRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuthenticationRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.mechanismName != null && message.hasOwnProperty("mechanismName"))
                    switch (message.mechanismName) {
                    default:
                        return "mechanismName: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.authData != null && message.hasOwnProperty("authData"))
                    if (!$util.isString(message.authData))
                        return "authData: string expected";
                return null;
            };
    
            /**
             * Creates an AuthenticationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.AuthenticationRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.AuthenticationRequest} AuthenticationRequest
             */
            AuthenticationRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.AuthenticationRequest)
                    return object;
                var message = new $root.vibe.AuthenticationRequest();
                switch (object.mechanismName) {
                default:
                    if (typeof object.mechanismName === "number") {
                        message.mechanismName = object.mechanismName;
                        break;
                    }
                    break;
                case "UNDEFINED":
                case 0:
                    message.mechanismName = 0;
                    break;
                case "JWT":
                case 1:
                    message.mechanismName = 1;
                    break;
                }
                if (object.authData != null)
                    message.authData = String(object.authData);
                return message;
            };
    
            /**
             * Creates a plain object from an AuthenticationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.AuthenticationRequest
             * @static
             * @param {vibe.AuthenticationRequest} message AuthenticationRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthenticationRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.mechanismName = options.enums === String ? "UNDEFINED" : 0;
                    object.authData = "";
                }
                if (message.mechanismName != null && message.hasOwnProperty("mechanismName"))
                    object.mechanismName = options.enums === String ? $root.vibe.AuthenticationRequest.MechanismName[message.mechanismName] === undefined ? message.mechanismName : $root.vibe.AuthenticationRequest.MechanismName[message.mechanismName] : message.mechanismName;
                if (message.authData != null && message.hasOwnProperty("authData"))
                    object.authData = message.authData;
                return object;
            };
    
            /**
             * Converts this AuthenticationRequest to JSON.
             * @function toJSON
             * @memberof vibe.AuthenticationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthenticationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AuthenticationRequest
             * @function getTypeUrl
             * @memberof vibe.AuthenticationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AuthenticationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.AuthenticationRequest";
            };
    
            /**
             * MechanismName enum.
             * @name vibe.AuthenticationRequest.MechanismName
             * @enum {number}
             * @property {number} UNDEFINED=0 UNDEFINED value
             * @property {number} JWT=1 JWT value
             */
            AuthenticationRequest.MechanismName = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNDEFINED"] = 0;
                values[valuesById[1] = "JWT"] = 1;
                return values;
            })();
    
            return AuthenticationRequest;
        })();
    
        vibe.ClientCapabilitiesRegistration = (function() {
    
            /**
             * Properties of a ClientCapabilitiesRegistration.
             * @memberof vibe
             * @interface IClientCapabilitiesRegistration
             * @property {Array.<vibe.ClientCapabilitiesRegistration.Capability>|null} [capabilities] ClientCapabilitiesRegistration capabilities
             */
    
            /**
             * Constructs a new ClientCapabilitiesRegistration.
             * @memberof vibe
             * @classdesc Represents a ClientCapabilitiesRegistration.
             * @implements IClientCapabilitiesRegistration
             * @constructor
             * @param {vibe.IClientCapabilitiesRegistration=} [properties] Properties to set
             */
            function ClientCapabilitiesRegistration(properties) {
                this.capabilities = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientCapabilitiesRegistration capabilities.
             * @member {Array.<vibe.ClientCapabilitiesRegistration.Capability>} capabilities
             * @memberof vibe.ClientCapabilitiesRegistration
             * @instance
             */
            ClientCapabilitiesRegistration.prototype.capabilities = $util.emptyArray;
    
            /**
             * Creates a new ClientCapabilitiesRegistration instance using the specified properties.
             * @function create
             * @memberof vibe.ClientCapabilitiesRegistration
             * @static
             * @param {vibe.IClientCapabilitiesRegistration=} [properties] Properties to set
             * @returns {vibe.ClientCapabilitiesRegistration} ClientCapabilitiesRegistration instance
             */
            ClientCapabilitiesRegistration.create = function create(properties) {
                return new ClientCapabilitiesRegistration(properties);
            };
    
            /**
             * Encodes the specified ClientCapabilitiesRegistration message. Does not implicitly {@link vibe.ClientCapabilitiesRegistration.verify|verify} messages.
             * @function encode
             * @memberof vibe.ClientCapabilitiesRegistration
             * @static
             * @param {vibe.IClientCapabilitiesRegistration} message ClientCapabilitiesRegistration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientCapabilitiesRegistration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.capabilities != null && message.capabilities.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.capabilities.length; ++i)
                        writer.int32(message.capabilities[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified ClientCapabilitiesRegistration message, length delimited. Does not implicitly {@link vibe.ClientCapabilitiesRegistration.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.ClientCapabilitiesRegistration
             * @static
             * @param {vibe.IClientCapabilitiesRegistration} message ClientCapabilitiesRegistration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientCapabilitiesRegistration.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientCapabilitiesRegistration message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.ClientCapabilitiesRegistration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.ClientCapabilitiesRegistration} ClientCapabilitiesRegistration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientCapabilitiesRegistration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ClientCapabilitiesRegistration();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.capabilities && message.capabilities.length))
                                message.capabilities = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.capabilities.push(reader.int32());
                            } else
                                message.capabilities.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientCapabilitiesRegistration message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.ClientCapabilitiesRegistration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.ClientCapabilitiesRegistration} ClientCapabilitiesRegistration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientCapabilitiesRegistration.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientCapabilitiesRegistration message.
             * @function verify
             * @memberof vibe.ClientCapabilitiesRegistration
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientCapabilitiesRegistration.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.capabilities != null && message.hasOwnProperty("capabilities")) {
                    if (!Array.isArray(message.capabilities))
                        return "capabilities: array expected";
                    for (var i = 0; i < message.capabilities.length; ++i)
                        switch (message.capabilities[i]) {
                        default:
                            return "capabilities: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                }
                return null;
            };
    
            /**
             * Creates a ClientCapabilitiesRegistration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.ClientCapabilitiesRegistration
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.ClientCapabilitiesRegistration} ClientCapabilitiesRegistration
             */
            ClientCapabilitiesRegistration.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.ClientCapabilitiesRegistration)
                    return object;
                var message = new $root.vibe.ClientCapabilitiesRegistration();
                if (object.capabilities) {
                    if (!Array.isArray(object.capabilities))
                        throw TypeError(".vibe.ClientCapabilitiesRegistration.capabilities: array expected");
                    message.capabilities = [];
                    for (var i = 0; i < object.capabilities.length; ++i)
                        switch (object.capabilities[i]) {
                        default:
                            if (typeof object.capabilities[i] === "number") {
                                message.capabilities[i] = object.capabilities[i];
                                break;
                            }
                        case "ESSENTIAL":
                        case 0:
                            message.capabilities[i] = 0;
                            break;
                        case "FEED_AUDIO":
                        case 1:
                            message.capabilities[i] = 1;
                            break;
                        case "RECEIVE_ASR_RESULTS":
                        case 2:
                            message.capabilities[i] = 2;
                            break;
                        case "RECEIVE_PHRASE_SPOTTING_RESULTS":
                        case 3:
                            message.capabilities[i] = 3;
                            break;
                        case "RECEIVE_METRICS":
                        case 4:
                            message.capabilities[i] = 4;
                            break;
                        }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientCapabilitiesRegistration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.ClientCapabilitiesRegistration
             * @static
             * @param {vibe.ClientCapabilitiesRegistration} message ClientCapabilitiesRegistration
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientCapabilitiesRegistration.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.capabilities = [];
                if (message.capabilities && message.capabilities.length) {
                    object.capabilities = [];
                    for (var j = 0; j < message.capabilities.length; ++j)
                        object.capabilities[j] = options.enums === String ? $root.vibe.ClientCapabilitiesRegistration.Capability[message.capabilities[j]] === undefined ? message.capabilities[j] : $root.vibe.ClientCapabilitiesRegistration.Capability[message.capabilities[j]] : message.capabilities[j];
                }
                return object;
            };
    
            /**
             * Converts this ClientCapabilitiesRegistration to JSON.
             * @function toJSON
             * @memberof vibe.ClientCapabilitiesRegistration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientCapabilitiesRegistration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ClientCapabilitiesRegistration
             * @function getTypeUrl
             * @memberof vibe.ClientCapabilitiesRegistration
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ClientCapabilitiesRegistration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.ClientCapabilitiesRegistration";
            };
    
            /**
             * Capability enum.
             * @name vibe.ClientCapabilitiesRegistration.Capability
             * @enum {number}
             * @property {number} ESSENTIAL=0 ESSENTIAL value
             * @property {number} FEED_AUDIO=1 FEED_AUDIO value
             * @property {number} RECEIVE_ASR_RESULTS=2 RECEIVE_ASR_RESULTS value
             * @property {number} RECEIVE_PHRASE_SPOTTING_RESULTS=3 RECEIVE_PHRASE_SPOTTING_RESULTS value
             * @property {number} RECEIVE_METRICS=4 RECEIVE_METRICS value
             */
            ClientCapabilitiesRegistration.Capability = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ESSENTIAL"] = 0;
                values[valuesById[1] = "FEED_AUDIO"] = 1;
                values[valuesById[2] = "RECEIVE_ASR_RESULTS"] = 2;
                values[valuesById[3] = "RECEIVE_PHRASE_SPOTTING_RESULTS"] = 3;
                values[valuesById[4] = "RECEIVE_METRICS"] = 4;
                return values;
            })();
    
            return ClientCapabilitiesRegistration;
        })();
    
        vibe.SessionInfo = (function() {
    
            /**
             * Properties of a SessionInfo.
             * @memberof vibe
             * @interface ISessionInfo
             * @property {string|null} [callId] SessionInfo callId
             * @property {vibe.SessionInfo.Source|null} [source] SessionInfo source
             * @property {string|null} [sourceDescription] SessionInfo sourceDescription
             * @property {number|null} [incallRecognitionSessionNumber] SessionInfo incallRecognitionSessionNumber
             * @property {string|null} [callDetectionSessionId] SessionInfo callDetectionSessionId
             */
    
            /**
             * Constructs a new SessionInfo.
             * @memberof vibe
             * @classdesc Represents a SessionInfo.
             * @implements ISessionInfo
             * @constructor
             * @param {vibe.ISessionInfo=} [properties] Properties to set
             */
            function SessionInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SessionInfo callId.
             * @member {string} callId
             * @memberof vibe.SessionInfo
             * @instance
             */
            SessionInfo.prototype.callId = "";
    
            /**
             * SessionInfo source.
             * @member {vibe.SessionInfo.Source} source
             * @memberof vibe.SessionInfo
             * @instance
             */
            SessionInfo.prototype.source = 0;
    
            /**
             * SessionInfo sourceDescription.
             * @member {string} sourceDescription
             * @memberof vibe.SessionInfo
             * @instance
             */
            SessionInfo.prototype.sourceDescription = "";
    
            /**
             * SessionInfo incallRecognitionSessionNumber.
             * @member {number} incallRecognitionSessionNumber
             * @memberof vibe.SessionInfo
             * @instance
             */
            SessionInfo.prototype.incallRecognitionSessionNumber = 0;
    
            /**
             * SessionInfo callDetectionSessionId.
             * @member {string} callDetectionSessionId
             * @memberof vibe.SessionInfo
             * @instance
             */
            SessionInfo.prototype.callDetectionSessionId = "";
    
            /**
             * Creates a new SessionInfo instance using the specified properties.
             * @function create
             * @memberof vibe.SessionInfo
             * @static
             * @param {vibe.ISessionInfo=} [properties] Properties to set
             * @returns {vibe.SessionInfo} SessionInfo instance
             */
            SessionInfo.create = function create(properties) {
                return new SessionInfo(properties);
            };
    
            /**
             * Encodes the specified SessionInfo message. Does not implicitly {@link vibe.SessionInfo.verify|verify} messages.
             * @function encode
             * @memberof vibe.SessionInfo
             * @static
             * @param {vibe.ISessionInfo} message SessionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SessionInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.callId != null && Object.hasOwnProperty.call(message, "callId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.callId);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.source);
                if (message.sourceDescription != null && Object.hasOwnProperty.call(message, "sourceDescription"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.sourceDescription);
                if (message.incallRecognitionSessionNumber != null && Object.hasOwnProperty.call(message, "incallRecognitionSessionNumber"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.incallRecognitionSessionNumber);
                if (message.callDetectionSessionId != null && Object.hasOwnProperty.call(message, "callDetectionSessionId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.callDetectionSessionId);
                return writer;
            };
    
            /**
             * Encodes the specified SessionInfo message, length delimited. Does not implicitly {@link vibe.SessionInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.SessionInfo
             * @static
             * @param {vibe.ISessionInfo} message SessionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SessionInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SessionInfo message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.SessionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.SessionInfo} SessionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SessionInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.SessionInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.callId = reader.string();
                            break;
                        }
                    case 2: {
                            message.source = reader.int32();
                            break;
                        }
                    case 3: {
                            message.sourceDescription = reader.string();
                            break;
                        }
                    case 4: {
                            message.incallRecognitionSessionNumber = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.callDetectionSessionId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SessionInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.SessionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.SessionInfo} SessionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SessionInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SessionInfo message.
             * @function verify
             * @memberof vibe.SessionInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SessionInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.callId != null && message.hasOwnProperty("callId"))
                    if (!$util.isString(message.callId))
                        return "callId: string expected";
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.sourceDescription != null && message.hasOwnProperty("sourceDescription"))
                    if (!$util.isString(message.sourceDescription))
                        return "sourceDescription: string expected";
                if (message.incallRecognitionSessionNumber != null && message.hasOwnProperty("incallRecognitionSessionNumber"))
                    if (!$util.isInteger(message.incallRecognitionSessionNumber))
                        return "incallRecognitionSessionNumber: integer expected";
                if (message.callDetectionSessionId != null && message.hasOwnProperty("callDetectionSessionId"))
                    if (!$util.isString(message.callDetectionSessionId))
                        return "callDetectionSessionId: string expected";
                return null;
            };
    
            /**
             * Creates a SessionInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.SessionInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.SessionInfo} SessionInfo
             */
            SessionInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.SessionInfo)
                    return object;
                var message = new $root.vibe.SessionInfo();
                if (object.callId != null)
                    message.callId = String(object.callId);
                switch (object.source) {
                default:
                    if (typeof object.source === "number") {
                        message.source = object.source;
                        break;
                    }
                    break;
                case "MICROPHONE":
                case 0:
                    message.source = 0;
                    break;
                case "SYSTEM":
                case 1:
                    message.source = 1;
                    break;
                case "MULTIPLE_CHANNELS":
                case 2:
                    message.source = 2;
                    break;
                }
                if (object.sourceDescription != null)
                    message.sourceDescription = String(object.sourceDescription);
                if (object.incallRecognitionSessionNumber != null)
                    message.incallRecognitionSessionNumber = object.incallRecognitionSessionNumber >>> 0;
                if (object.callDetectionSessionId != null)
                    message.callDetectionSessionId = String(object.callDetectionSessionId);
                return message;
            };
    
            /**
             * Creates a plain object from a SessionInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.SessionInfo
             * @static
             * @param {vibe.SessionInfo} message SessionInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SessionInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.callId = "";
                    object.source = options.enums === String ? "MICROPHONE" : 0;
                    object.sourceDescription = "";
                    object.incallRecognitionSessionNumber = 0;
                    object.callDetectionSessionId = "";
                }
                if (message.callId != null && message.hasOwnProperty("callId"))
                    object.callId = message.callId;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.vibe.SessionInfo.Source[message.source] === undefined ? message.source : $root.vibe.SessionInfo.Source[message.source] : message.source;
                if (message.sourceDescription != null && message.hasOwnProperty("sourceDescription"))
                    object.sourceDescription = message.sourceDescription;
                if (message.incallRecognitionSessionNumber != null && message.hasOwnProperty("incallRecognitionSessionNumber"))
                    object.incallRecognitionSessionNumber = message.incallRecognitionSessionNumber;
                if (message.callDetectionSessionId != null && message.hasOwnProperty("callDetectionSessionId"))
                    object.callDetectionSessionId = message.callDetectionSessionId;
                return object;
            };
    
            /**
             * Converts this SessionInfo to JSON.
             * @function toJSON
             * @memberof vibe.SessionInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SessionInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SessionInfo
             * @function getTypeUrl
             * @memberof vibe.SessionInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SessionInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.SessionInfo";
            };
    
            /**
             * Source enum.
             * @name vibe.SessionInfo.Source
             * @enum {number}
             * @property {number} MICROPHONE=0 MICROPHONE value
             * @property {number} SYSTEM=1 SYSTEM value
             * @property {number} MULTIPLE_CHANNELS=2 MULTIPLE_CHANNELS value
             */
            SessionInfo.Source = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "MICROPHONE"] = 0;
                values[valuesById[1] = "SYSTEM"] = 1;
                values[valuesById[2] = "MULTIPLE_CHANNELS"] = 2;
                return values;
            })();
    
            return SessionInfo;
        })();
    
        vibe.SystemInfo = (function() {
    
            /**
             * Properties of a SystemInfo.
             * @memberof vibe
             * @interface ISystemInfo
             * @property {string|null} [username] SystemInfo username
             * @property {string|null} [companyName] SystemInfo companyName
             * @property {string|null} [country] SystemInfo country
             * @property {string|null} [comments] SystemInfo comments
             * @property {string|null} [uniqueId] SystemInfo uniqueId
             * @property {vibe.SystemInfo.StealthMode|null} [coachStealthMode] SystemInfo coachStealthMode
             * @property {vibe.SystemInfo.WindowMode|null} [coachWindowMode] SystemInfo coachWindowMode
             */
    
            /**
             * Constructs a new SystemInfo.
             * @memberof vibe
             * @classdesc Represents a SystemInfo.
             * @implements ISystemInfo
             * @constructor
             * @param {vibe.ISystemInfo=} [properties] Properties to set
             */
            function SystemInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SystemInfo username.
             * @member {string} username
             * @memberof vibe.SystemInfo
             * @instance
             */
            SystemInfo.prototype.username = "";
    
            /**
             * SystemInfo companyName.
             * @member {string} companyName
             * @memberof vibe.SystemInfo
             * @instance
             */
            SystemInfo.prototype.companyName = "";
    
            /**
             * SystemInfo country.
             * @member {string} country
             * @memberof vibe.SystemInfo
             * @instance
             */
            SystemInfo.prototype.country = "";
    
            /**
             * SystemInfo comments.
             * @member {string} comments
             * @memberof vibe.SystemInfo
             * @instance
             */
            SystemInfo.prototype.comments = "";
    
            /**
             * SystemInfo uniqueId.
             * @member {string} uniqueId
             * @memberof vibe.SystemInfo
             * @instance
             */
            SystemInfo.prototype.uniqueId = "";
    
            /**
             * SystemInfo coachStealthMode.
             * @member {vibe.SystemInfo.StealthMode} coachStealthMode
             * @memberof vibe.SystemInfo
             * @instance
             */
            SystemInfo.prototype.coachStealthMode = 0;
    
            /**
             * SystemInfo coachWindowMode.
             * @member {vibe.SystemInfo.WindowMode} coachWindowMode
             * @memberof vibe.SystemInfo
             * @instance
             */
            SystemInfo.prototype.coachWindowMode = 0;
    
            /**
             * Creates a new SystemInfo instance using the specified properties.
             * @function create
             * @memberof vibe.SystemInfo
             * @static
             * @param {vibe.ISystemInfo=} [properties] Properties to set
             * @returns {vibe.SystemInfo} SystemInfo instance
             */
            SystemInfo.create = function create(properties) {
                return new SystemInfo(properties);
            };
    
            /**
             * Encodes the specified SystemInfo message. Does not implicitly {@link vibe.SystemInfo.verify|verify} messages.
             * @function encode
             * @memberof vibe.SystemInfo
             * @static
             * @param {vibe.ISystemInfo} message SystemInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SystemInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
                if (message.companyName != null && Object.hasOwnProperty.call(message, "companyName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.companyName);
                if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.country);
                if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.comments);
                if (message.uniqueId != null && Object.hasOwnProperty.call(message, "uniqueId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.uniqueId);
                if (message.coachStealthMode != null && Object.hasOwnProperty.call(message, "coachStealthMode"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.coachStealthMode);
                if (message.coachWindowMode != null && Object.hasOwnProperty.call(message, "coachWindowMode"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.coachWindowMode);
                return writer;
            };
    
            /**
             * Encodes the specified SystemInfo message, length delimited. Does not implicitly {@link vibe.SystemInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.SystemInfo
             * @static
             * @param {vibe.ISystemInfo} message SystemInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SystemInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SystemInfo message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.SystemInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.SystemInfo} SystemInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SystemInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.SystemInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.username = reader.string();
                            break;
                        }
                    case 2: {
                            message.companyName = reader.string();
                            break;
                        }
                    case 3: {
                            message.country = reader.string();
                            break;
                        }
                    case 4: {
                            message.comments = reader.string();
                            break;
                        }
                    case 5: {
                            message.uniqueId = reader.string();
                            break;
                        }
                    case 6: {
                            message.coachStealthMode = reader.int32();
                            break;
                        }
                    case 7: {
                            message.coachWindowMode = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SystemInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.SystemInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.SystemInfo} SystemInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SystemInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SystemInfo message.
             * @function verify
             * @memberof vibe.SystemInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SystemInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.username != null && message.hasOwnProperty("username"))
                    if (!$util.isString(message.username))
                        return "username: string expected";
                if (message.companyName != null && message.hasOwnProperty("companyName"))
                    if (!$util.isString(message.companyName))
                        return "companyName: string expected";
                if (message.country != null && message.hasOwnProperty("country"))
                    if (!$util.isString(message.country))
                        return "country: string expected";
                if (message.comments != null && message.hasOwnProperty("comments"))
                    if (!$util.isString(message.comments))
                        return "comments: string expected";
                if (message.uniqueId != null && message.hasOwnProperty("uniqueId"))
                    if (!$util.isString(message.uniqueId))
                        return "uniqueId: string expected";
                if (message.coachStealthMode != null && message.hasOwnProperty("coachStealthMode"))
                    switch (message.coachStealthMode) {
                    default:
                        return "coachStealthMode: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.coachWindowMode != null && message.hasOwnProperty("coachWindowMode"))
                    switch (message.coachWindowMode) {
                    default:
                        return "coachWindowMode: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a SystemInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.SystemInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.SystemInfo} SystemInfo
             */
            SystemInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.SystemInfo)
                    return object;
                var message = new $root.vibe.SystemInfo();
                if (object.username != null)
                    message.username = String(object.username);
                if (object.companyName != null)
                    message.companyName = String(object.companyName);
                if (object.country != null)
                    message.country = String(object.country);
                if (object.comments != null)
                    message.comments = String(object.comments);
                if (object.uniqueId != null)
                    message.uniqueId = String(object.uniqueId);
                switch (object.coachStealthMode) {
                default:
                    if (typeof object.coachStealthMode === "number") {
                        message.coachStealthMode = object.coachStealthMode;
                        break;
                    }
                    break;
                case "STEALTH_MODE_UNKNOWN":
                case 0:
                    message.coachStealthMode = 0;
                    break;
                case "ON":
                case 1:
                    message.coachStealthMode = 1;
                    break;
                case "OFF":
                case 2:
                    message.coachStealthMode = 2;
                    break;
                }
                switch (object.coachWindowMode) {
                default:
                    if (typeof object.coachWindowMode === "number") {
                        message.coachWindowMode = object.coachWindowMode;
                        break;
                    }
                    break;
                case "WINDOW_MODE_UNKNOWN":
                case 0:
                    message.coachWindowMode = 0;
                    break;
                case "COLLAPSED":
                case 1:
                    message.coachWindowMode = 1;
                    break;
                case "VISIBLE":
                case 2:
                    message.coachWindowMode = 2;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a SystemInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.SystemInfo
             * @static
             * @param {vibe.SystemInfo} message SystemInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SystemInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.username = "";
                    object.companyName = "";
                    object.country = "";
                    object.comments = "";
                    object.uniqueId = "";
                    object.coachStealthMode = options.enums === String ? "STEALTH_MODE_UNKNOWN" : 0;
                    object.coachWindowMode = options.enums === String ? "WINDOW_MODE_UNKNOWN" : 0;
                }
                if (message.username != null && message.hasOwnProperty("username"))
                    object.username = message.username;
                if (message.companyName != null && message.hasOwnProperty("companyName"))
                    object.companyName = message.companyName;
                if (message.country != null && message.hasOwnProperty("country"))
                    object.country = message.country;
                if (message.comments != null && message.hasOwnProperty("comments"))
                    object.comments = message.comments;
                if (message.uniqueId != null && message.hasOwnProperty("uniqueId"))
                    object.uniqueId = message.uniqueId;
                if (message.coachStealthMode != null && message.hasOwnProperty("coachStealthMode"))
                    object.coachStealthMode = options.enums === String ? $root.vibe.SystemInfo.StealthMode[message.coachStealthMode] === undefined ? message.coachStealthMode : $root.vibe.SystemInfo.StealthMode[message.coachStealthMode] : message.coachStealthMode;
                if (message.coachWindowMode != null && message.hasOwnProperty("coachWindowMode"))
                    object.coachWindowMode = options.enums === String ? $root.vibe.SystemInfo.WindowMode[message.coachWindowMode] === undefined ? message.coachWindowMode : $root.vibe.SystemInfo.WindowMode[message.coachWindowMode] : message.coachWindowMode;
                return object;
            };
    
            /**
             * Converts this SystemInfo to JSON.
             * @function toJSON
             * @memberof vibe.SystemInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SystemInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SystemInfo
             * @function getTypeUrl
             * @memberof vibe.SystemInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SystemInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.SystemInfo";
            };
    
            /**
             * StealthMode enum.
             * @name vibe.SystemInfo.StealthMode
             * @enum {number}
             * @property {number} STEALTH_MODE_UNKNOWN=0 STEALTH_MODE_UNKNOWN value
             * @property {number} ON=1 ON value
             * @property {number} OFF=2 OFF value
             */
            SystemInfo.StealthMode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STEALTH_MODE_UNKNOWN"] = 0;
                values[valuesById[1] = "ON"] = 1;
                values[valuesById[2] = "OFF"] = 2;
                return values;
            })();
    
            /**
             * WindowMode enum.
             * @name vibe.SystemInfo.WindowMode
             * @enum {number}
             * @property {number} WINDOW_MODE_UNKNOWN=0 WINDOW_MODE_UNKNOWN value
             * @property {number} COLLAPSED=1 COLLAPSED value
             * @property {number} VISIBLE=2 VISIBLE value
             */
            SystemInfo.WindowMode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "WINDOW_MODE_UNKNOWN"] = 0;
                values[valuesById[1] = "COLLAPSED"] = 1;
                values[valuesById[2] = "VISIBLE"] = 2;
                return values;
            })();
    
            return SystemInfo;
        })();
    
        vibe.ServiceVersion = (function() {
    
            /**
             * Properties of a ServiceVersion.
             * @memberof vibe
             * @interface IServiceVersion
             * @property {number|null} [major] ServiceVersion major
             * @property {number|null} [minor] ServiceVersion minor
             * @property {number|null} [patch] ServiceVersion patch
             */
    
            /**
             * Constructs a new ServiceVersion.
             * @memberof vibe
             * @classdesc Represents a ServiceVersion.
             * @implements IServiceVersion
             * @constructor
             * @param {vibe.IServiceVersion=} [properties] Properties to set
             */
            function ServiceVersion(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ServiceVersion major.
             * @member {number} major
             * @memberof vibe.ServiceVersion
             * @instance
             */
            ServiceVersion.prototype.major = 0;
    
            /**
             * ServiceVersion minor.
             * @member {number} minor
             * @memberof vibe.ServiceVersion
             * @instance
             */
            ServiceVersion.prototype.minor = 0;
    
            /**
             * ServiceVersion patch.
             * @member {number} patch
             * @memberof vibe.ServiceVersion
             * @instance
             */
            ServiceVersion.prototype.patch = 0;
    
            /**
             * Creates a new ServiceVersion instance using the specified properties.
             * @function create
             * @memberof vibe.ServiceVersion
             * @static
             * @param {vibe.IServiceVersion=} [properties] Properties to set
             * @returns {vibe.ServiceVersion} ServiceVersion instance
             */
            ServiceVersion.create = function create(properties) {
                return new ServiceVersion(properties);
            };
    
            /**
             * Encodes the specified ServiceVersion message. Does not implicitly {@link vibe.ServiceVersion.verify|verify} messages.
             * @function encode
             * @memberof vibe.ServiceVersion
             * @static
             * @param {vibe.IServiceVersion} message ServiceVersion message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceVersion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.major != null && Object.hasOwnProperty.call(message, "major"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.major);
                if (message.minor != null && Object.hasOwnProperty.call(message, "minor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.minor);
                if (message.patch != null && Object.hasOwnProperty.call(message, "patch"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.patch);
                return writer;
            };
    
            /**
             * Encodes the specified ServiceVersion message, length delimited. Does not implicitly {@link vibe.ServiceVersion.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.ServiceVersion
             * @static
             * @param {vibe.IServiceVersion} message ServiceVersion message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceVersion.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ServiceVersion message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.ServiceVersion
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.ServiceVersion} ServiceVersion
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceVersion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ServiceVersion();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.major = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.minor = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.patch = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ServiceVersion message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.ServiceVersion
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.ServiceVersion} ServiceVersion
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceVersion.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ServiceVersion message.
             * @function verify
             * @memberof vibe.ServiceVersion
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceVersion.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.major != null && message.hasOwnProperty("major"))
                    if (!$util.isInteger(message.major))
                        return "major: integer expected";
                if (message.minor != null && message.hasOwnProperty("minor"))
                    if (!$util.isInteger(message.minor))
                        return "minor: integer expected";
                if (message.patch != null && message.hasOwnProperty("patch"))
                    if (!$util.isInteger(message.patch))
                        return "patch: integer expected";
                return null;
            };
    
            /**
             * Creates a ServiceVersion message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.ServiceVersion
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.ServiceVersion} ServiceVersion
             */
            ServiceVersion.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.ServiceVersion)
                    return object;
                var message = new $root.vibe.ServiceVersion();
                if (object.major != null)
                    message.major = object.major >>> 0;
                if (object.minor != null)
                    message.minor = object.minor >>> 0;
                if (object.patch != null)
                    message.patch = object.patch >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a ServiceVersion message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.ServiceVersion
             * @static
             * @param {vibe.ServiceVersion} message ServiceVersion
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceVersion.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.major = 0;
                    object.minor = 0;
                    object.patch = 0;
                }
                if (message.major != null && message.hasOwnProperty("major"))
                    object.major = message.major;
                if (message.minor != null && message.hasOwnProperty("minor"))
                    object.minor = message.minor;
                if (message.patch != null && message.hasOwnProperty("patch"))
                    object.patch = message.patch;
                return object;
            };
    
            /**
             * Converts this ServiceVersion to JSON.
             * @function toJSON
             * @memberof vibe.ServiceVersion
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceVersion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ServiceVersion
             * @function getTypeUrl
             * @memberof vibe.ServiceVersion
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceVersion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.ServiceVersion";
            };
    
            return ServiceVersion;
        })();
    
        /**
         * AudioEncoding enum.
         * @name vibe.AudioEncoding
         * @enum {number}
         * @property {number} LINEAR16=0 LINEAR16 value
         * @property {number} FLAC=1 FLAC value
         * @property {number} OPUS=2 OPUS value
         */
        vibe.AudioEncoding = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "LINEAR16"] = 0;
            values[valuesById[1] = "FLAC"] = 1;
            values[valuesById[2] = "OPUS"] = 2;
            return values;
        })();
    
        vibe.CallDetectionConfig = (function() {
    
            /**
             * Properties of a CallDetectionConfig.
             * @memberof vibe
             * @interface ICallDetectionConfig
             * @property {boolean|null} [saveStream] CallDetectionConfig saveStream
             * @property {number|null} [sampleRate] CallDetectionConfig sampleRate
             * @property {vibe.CallDetectionConfig.IAbsoluteSilenceDefinition|null} [absoluteSilenceDefinition] CallDetectionConfig absoluteSilenceDefinition
             * @property {string|null} [sessionId] CallDetectionConfig sessionId
             * @property {vibe.ISystemInfo|null} [systemInfo] CallDetectionConfig systemInfo
             * @property {vibe.AudioEncoding|null} [encoding] CallDetectionConfig encoding
             * @property {vibe.CallDetectionConfig.IntegrationMethod|null} [integrationMethod] CallDetectionConfig integrationMethod
             * @property {vibe.CallDetectionConfig.ExplicitIntegrationSource|null} [explicitIntegrationSource] CallDetectionConfig explicitIntegrationSource
             * @property {vibe.IServiceVersion|null} [serviceVersion] CallDetectionConfig serviceVersion
             * @property {number|null} [optInGracePeriodMsec] CallDetectionConfig optInGracePeriodMsec
             * @property {number|null} [explicitPingPeriodMsec] CallDetectionConfig explicitPingPeriodMsec
             * @property {Array.<string>|null} [appWhitelist] CallDetectionConfig appWhitelist
             * @property {boolean|null} [enableCallMetadataOnly] CallDetectionConfig enableCallMetadataOnly
             */
    
            /**
             * Constructs a new CallDetectionConfig.
             * @memberof vibe
             * @classdesc Represents a CallDetectionConfig.
             * @implements ICallDetectionConfig
             * @constructor
             * @param {vibe.ICallDetectionConfig=} [properties] Properties to set
             */
            function CallDetectionConfig(properties) {
                this.appWhitelist = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CallDetectionConfig saveStream.
             * @member {boolean} saveStream
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.saveStream = false;
    
            /**
             * CallDetectionConfig sampleRate.
             * @member {number} sampleRate
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.sampleRate = 0;
    
            /**
             * CallDetectionConfig absoluteSilenceDefinition.
             * @member {vibe.CallDetectionConfig.IAbsoluteSilenceDefinition|null|undefined} absoluteSilenceDefinition
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.absoluteSilenceDefinition = null;
    
            /**
             * CallDetectionConfig sessionId.
             * @member {string} sessionId
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.sessionId = "";
    
            /**
             * CallDetectionConfig systemInfo.
             * @member {vibe.ISystemInfo|null|undefined} systemInfo
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.systemInfo = null;
    
            /**
             * CallDetectionConfig encoding.
             * @member {vibe.AudioEncoding} encoding
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.encoding = 0;
    
            /**
             * CallDetectionConfig integrationMethod.
             * @member {vibe.CallDetectionConfig.IntegrationMethod} integrationMethod
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.integrationMethod = 0;
    
            /**
             * CallDetectionConfig explicitIntegrationSource.
             * @member {vibe.CallDetectionConfig.ExplicitIntegrationSource} explicitIntegrationSource
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.explicitIntegrationSource = 0;
    
            /**
             * CallDetectionConfig serviceVersion.
             * @member {vibe.IServiceVersion|null|undefined} serviceVersion
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.serviceVersion = null;
    
            /**
             * CallDetectionConfig optInGracePeriodMsec.
             * @member {number} optInGracePeriodMsec
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.optInGracePeriodMsec = 0;
    
            /**
             * CallDetectionConfig explicitPingPeriodMsec.
             * @member {number} explicitPingPeriodMsec
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.explicitPingPeriodMsec = 0;
    
            /**
             * CallDetectionConfig appWhitelist.
             * @member {Array.<string>} appWhitelist
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.appWhitelist = $util.emptyArray;
    
            /**
             * CallDetectionConfig enableCallMetadataOnly.
             * @member {boolean} enableCallMetadataOnly
             * @memberof vibe.CallDetectionConfig
             * @instance
             */
            CallDetectionConfig.prototype.enableCallMetadataOnly = false;
    
            /**
             * Creates a new CallDetectionConfig instance using the specified properties.
             * @function create
             * @memberof vibe.CallDetectionConfig
             * @static
             * @param {vibe.ICallDetectionConfig=} [properties] Properties to set
             * @returns {vibe.CallDetectionConfig} CallDetectionConfig instance
             */
            CallDetectionConfig.create = function create(properties) {
                return new CallDetectionConfig(properties);
            };
    
            /**
             * Encodes the specified CallDetectionConfig message. Does not implicitly {@link vibe.CallDetectionConfig.verify|verify} messages.
             * @function encode
             * @memberof vibe.CallDetectionConfig
             * @static
             * @param {vibe.ICallDetectionConfig} message CallDetectionConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CallDetectionConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.saveStream != null && Object.hasOwnProperty.call(message, "saveStream"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.saveStream);
                if (message.sampleRate != null && Object.hasOwnProperty.call(message, "sampleRate"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.sampleRate);
                if (message.absoluteSilenceDefinition != null && Object.hasOwnProperty.call(message, "absoluteSilenceDefinition"))
                    $root.vibe.CallDetectionConfig.AbsoluteSilenceDefinition.encode(message.absoluteSilenceDefinition, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.sessionId);
                if (message.systemInfo != null && Object.hasOwnProperty.call(message, "systemInfo"))
                    $root.vibe.SystemInfo.encode(message.systemInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.encoding != null && Object.hasOwnProperty.call(message, "encoding"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.encoding);
                if (message.integrationMethod != null && Object.hasOwnProperty.call(message, "integrationMethod"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.integrationMethod);
                if (message.explicitIntegrationSource != null && Object.hasOwnProperty.call(message, "explicitIntegrationSource"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.explicitIntegrationSource);
                if (message.serviceVersion != null && Object.hasOwnProperty.call(message, "serviceVersion"))
                    $root.vibe.ServiceVersion.encode(message.serviceVersion, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.optInGracePeriodMsec != null && Object.hasOwnProperty.call(message, "optInGracePeriodMsec"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.optInGracePeriodMsec);
                if (message.explicitPingPeriodMsec != null && Object.hasOwnProperty.call(message, "explicitPingPeriodMsec"))
                    writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.explicitPingPeriodMsec);
                if (message.appWhitelist != null && message.appWhitelist.length)
                    for (var i = 0; i < message.appWhitelist.length; ++i)
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.appWhitelist[i]);
                if (message.enableCallMetadataOnly != null && Object.hasOwnProperty.call(message, "enableCallMetadataOnly"))
                    writer.uint32(/* id 13, wireType 0 =*/104).bool(message.enableCallMetadataOnly);
                return writer;
            };
    
            /**
             * Encodes the specified CallDetectionConfig message, length delimited. Does not implicitly {@link vibe.CallDetectionConfig.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.CallDetectionConfig
             * @static
             * @param {vibe.ICallDetectionConfig} message CallDetectionConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CallDetectionConfig.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CallDetectionConfig message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.CallDetectionConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.CallDetectionConfig} CallDetectionConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CallDetectionConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.CallDetectionConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.saveStream = reader.bool();
                            break;
                        }
                    case 2: {
                            message.sampleRate = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.absoluteSilenceDefinition = $root.vibe.CallDetectionConfig.AbsoluteSilenceDefinition.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.sessionId = reader.string();
                            break;
                        }
                    case 5: {
                            message.systemInfo = $root.vibe.SystemInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.encoding = reader.int32();
                            break;
                        }
                    case 7: {
                            message.integrationMethod = reader.int32();
                            break;
                        }
                    case 8: {
                            message.explicitIntegrationSource = reader.int32();
                            break;
                        }
                    case 9: {
                            message.serviceVersion = $root.vibe.ServiceVersion.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.optInGracePeriodMsec = reader.uint32();
                            break;
                        }
                    case 11: {
                            message.explicitPingPeriodMsec = reader.uint32();
                            break;
                        }
                    case 12: {
                            if (!(message.appWhitelist && message.appWhitelist.length))
                                message.appWhitelist = [];
                            message.appWhitelist.push(reader.string());
                            break;
                        }
                    case 13: {
                            message.enableCallMetadataOnly = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CallDetectionConfig message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.CallDetectionConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.CallDetectionConfig} CallDetectionConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CallDetectionConfig.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CallDetectionConfig message.
             * @function verify
             * @memberof vibe.CallDetectionConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CallDetectionConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.saveStream != null && message.hasOwnProperty("saveStream"))
                    if (typeof message.saveStream !== "boolean")
                        return "saveStream: boolean expected";
                if (message.sampleRate != null && message.hasOwnProperty("sampleRate"))
                    if (!$util.isInteger(message.sampleRate))
                        return "sampleRate: integer expected";
                if (message.absoluteSilenceDefinition != null && message.hasOwnProperty("absoluteSilenceDefinition")) {
                    var error = $root.vibe.CallDetectionConfig.AbsoluteSilenceDefinition.verify(message.absoluteSilenceDefinition);
                    if (error)
                        return "absoluteSilenceDefinition." + error;
                }
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    if (!$util.isString(message.sessionId))
                        return "sessionId: string expected";
                if (message.systemInfo != null && message.hasOwnProperty("systemInfo")) {
                    var error = $root.vibe.SystemInfo.verify(message.systemInfo);
                    if (error)
                        return "systemInfo." + error;
                }
                if (message.encoding != null && message.hasOwnProperty("encoding"))
                    switch (message.encoding) {
                    default:
                        return "encoding: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.integrationMethod != null && message.hasOwnProperty("integrationMethod"))
                    switch (message.integrationMethod) {
                    default:
                        return "integrationMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.explicitIntegrationSource != null && message.hasOwnProperty("explicitIntegrationSource"))
                    switch (message.explicitIntegrationSource) {
                    default:
                        return "explicitIntegrationSource: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.serviceVersion != null && message.hasOwnProperty("serviceVersion")) {
                    var error = $root.vibe.ServiceVersion.verify(message.serviceVersion);
                    if (error)
                        return "serviceVersion." + error;
                }
                if (message.optInGracePeriodMsec != null && message.hasOwnProperty("optInGracePeriodMsec"))
                    if (!$util.isInteger(message.optInGracePeriodMsec))
                        return "optInGracePeriodMsec: integer expected";
                if (message.explicitPingPeriodMsec != null && message.hasOwnProperty("explicitPingPeriodMsec"))
                    if (!$util.isInteger(message.explicitPingPeriodMsec))
                        return "explicitPingPeriodMsec: integer expected";
                if (message.appWhitelist != null && message.hasOwnProperty("appWhitelist")) {
                    if (!Array.isArray(message.appWhitelist))
                        return "appWhitelist: array expected";
                    for (var i = 0; i < message.appWhitelist.length; ++i)
                        if (!$util.isString(message.appWhitelist[i]))
                            return "appWhitelist: string[] expected";
                }
                if (message.enableCallMetadataOnly != null && message.hasOwnProperty("enableCallMetadataOnly"))
                    if (typeof message.enableCallMetadataOnly !== "boolean")
                        return "enableCallMetadataOnly: boolean expected";
                return null;
            };
    
            /**
             * Creates a CallDetectionConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.CallDetectionConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.CallDetectionConfig} CallDetectionConfig
             */
            CallDetectionConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.CallDetectionConfig)
                    return object;
                var message = new $root.vibe.CallDetectionConfig();
                if (object.saveStream != null)
                    message.saveStream = Boolean(object.saveStream);
                if (object.sampleRate != null)
                    message.sampleRate = object.sampleRate >>> 0;
                if (object.absoluteSilenceDefinition != null) {
                    if (typeof object.absoluteSilenceDefinition !== "object")
                        throw TypeError(".vibe.CallDetectionConfig.absoluteSilenceDefinition: object expected");
                    message.absoluteSilenceDefinition = $root.vibe.CallDetectionConfig.AbsoluteSilenceDefinition.fromObject(object.absoluteSilenceDefinition);
                }
                if (object.sessionId != null)
                    message.sessionId = String(object.sessionId);
                if (object.systemInfo != null) {
                    if (typeof object.systemInfo !== "object")
                        throw TypeError(".vibe.CallDetectionConfig.systemInfo: object expected");
                    message.systemInfo = $root.vibe.SystemInfo.fromObject(object.systemInfo);
                }
                switch (object.encoding) {
                default:
                    if (typeof object.encoding === "number") {
                        message.encoding = object.encoding;
                        break;
                    }
                    break;
                case "LINEAR16":
                case 0:
                    message.encoding = 0;
                    break;
                case "FLAC":
                case 1:
                    message.encoding = 1;
                    break;
                case "OPUS":
                case 2:
                    message.encoding = 2;
                    break;
                }
                switch (object.integrationMethod) {
                default:
                    if (typeof object.integrationMethod === "number") {
                        message.integrationMethod = object.integrationMethod;
                        break;
                    }
                    break;
                case "UNKNOWN":
                case 0:
                    message.integrationMethod = 0;
                    break;
                case "SYNC":
                case 1:
                    message.integrationMethod = 1;
                    break;
                case "EXPLICIT":
                case 2:
                    message.integrationMethod = 2;
                    break;
                }
                switch (object.explicitIntegrationSource) {
                default:
                    if (typeof object.explicitIntegrationSource === "number") {
                        message.explicitIntegrationSource = object.explicitIntegrationSource;
                        break;
                    }
                    break;
                case "UNDEFINED":
                case 0:
                    message.explicitIntegrationSource = 0;
                    break;
                case "I2X":
                case 1:
                    message.explicitIntegrationSource = 1;
                    break;
                case "UCT":
                case 2:
                    message.explicitIntegrationSource = 2;
                    break;
                case "I2X_MANUAL":
                case 3:
                    message.explicitIntegrationSource = 3;
                    break;
                case "WIN_AUDIO_SESSION":
                case 4:
                    message.explicitIntegrationSource = 4;
                    break;
                case "COPS_SESSION":
                case 5:
                    message.explicitIntegrationSource = 5;
                    break;
                case "ORECX":
                case 6:
                    message.explicitIntegrationSource = 6;
                    break;
                }
                if (object.serviceVersion != null) {
                    if (typeof object.serviceVersion !== "object")
                        throw TypeError(".vibe.CallDetectionConfig.serviceVersion: object expected");
                    message.serviceVersion = $root.vibe.ServiceVersion.fromObject(object.serviceVersion);
                }
                if (object.optInGracePeriodMsec != null)
                    message.optInGracePeriodMsec = object.optInGracePeriodMsec >>> 0;
                if (object.explicitPingPeriodMsec != null)
                    message.explicitPingPeriodMsec = object.explicitPingPeriodMsec >>> 0;
                if (object.appWhitelist) {
                    if (!Array.isArray(object.appWhitelist))
                        throw TypeError(".vibe.CallDetectionConfig.appWhitelist: array expected");
                    message.appWhitelist = [];
                    for (var i = 0; i < object.appWhitelist.length; ++i)
                        message.appWhitelist[i] = String(object.appWhitelist[i]);
                }
                if (object.enableCallMetadataOnly != null)
                    message.enableCallMetadataOnly = Boolean(object.enableCallMetadataOnly);
                return message;
            };
    
            /**
             * Creates a plain object from a CallDetectionConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.CallDetectionConfig
             * @static
             * @param {vibe.CallDetectionConfig} message CallDetectionConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CallDetectionConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.appWhitelist = [];
                if (options.defaults) {
                    object.saveStream = false;
                    object.sampleRate = 0;
                    object.absoluteSilenceDefinition = null;
                    object.sessionId = "";
                    object.systemInfo = null;
                    object.encoding = options.enums === String ? "LINEAR16" : 0;
                    object.integrationMethod = options.enums === String ? "UNKNOWN" : 0;
                    object.explicitIntegrationSource = options.enums === String ? "UNDEFINED" : 0;
                    object.serviceVersion = null;
                    object.optInGracePeriodMsec = 0;
                    object.explicitPingPeriodMsec = 0;
                    object.enableCallMetadataOnly = false;
                }
                if (message.saveStream != null && message.hasOwnProperty("saveStream"))
                    object.saveStream = message.saveStream;
                if (message.sampleRate != null && message.hasOwnProperty("sampleRate"))
                    object.sampleRate = message.sampleRate;
                if (message.absoluteSilenceDefinition != null && message.hasOwnProperty("absoluteSilenceDefinition"))
                    object.absoluteSilenceDefinition = $root.vibe.CallDetectionConfig.AbsoluteSilenceDefinition.toObject(message.absoluteSilenceDefinition, options);
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    object.sessionId = message.sessionId;
                if (message.systemInfo != null && message.hasOwnProperty("systemInfo"))
                    object.systemInfo = $root.vibe.SystemInfo.toObject(message.systemInfo, options);
                if (message.encoding != null && message.hasOwnProperty("encoding"))
                    object.encoding = options.enums === String ? $root.vibe.AudioEncoding[message.encoding] === undefined ? message.encoding : $root.vibe.AudioEncoding[message.encoding] : message.encoding;
                if (message.integrationMethod != null && message.hasOwnProperty("integrationMethod"))
                    object.integrationMethod = options.enums === String ? $root.vibe.CallDetectionConfig.IntegrationMethod[message.integrationMethod] === undefined ? message.integrationMethod : $root.vibe.CallDetectionConfig.IntegrationMethod[message.integrationMethod] : message.integrationMethod;
                if (message.explicitIntegrationSource != null && message.hasOwnProperty("explicitIntegrationSource"))
                    object.explicitIntegrationSource = options.enums === String ? $root.vibe.CallDetectionConfig.ExplicitIntegrationSource[message.explicitIntegrationSource] === undefined ? message.explicitIntegrationSource : $root.vibe.CallDetectionConfig.ExplicitIntegrationSource[message.explicitIntegrationSource] : message.explicitIntegrationSource;
                if (message.serviceVersion != null && message.hasOwnProperty("serviceVersion"))
                    object.serviceVersion = $root.vibe.ServiceVersion.toObject(message.serviceVersion, options);
                if (message.optInGracePeriodMsec != null && message.hasOwnProperty("optInGracePeriodMsec"))
                    object.optInGracePeriodMsec = message.optInGracePeriodMsec;
                if (message.explicitPingPeriodMsec != null && message.hasOwnProperty("explicitPingPeriodMsec"))
                    object.explicitPingPeriodMsec = message.explicitPingPeriodMsec;
                if (message.appWhitelist && message.appWhitelist.length) {
                    object.appWhitelist = [];
                    for (var j = 0; j < message.appWhitelist.length; ++j)
                        object.appWhitelist[j] = message.appWhitelist[j];
                }
                if (message.enableCallMetadataOnly != null && message.hasOwnProperty("enableCallMetadataOnly"))
                    object.enableCallMetadataOnly = message.enableCallMetadataOnly;
                return object;
            };
    
            /**
             * Converts this CallDetectionConfig to JSON.
             * @function toJSON
             * @memberof vibe.CallDetectionConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CallDetectionConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CallDetectionConfig
             * @function getTypeUrl
             * @memberof vibe.CallDetectionConfig
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CallDetectionConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.CallDetectionConfig";
            };
    
            /**
             * IntegrationMethod enum.
             * @name vibe.CallDetectionConfig.IntegrationMethod
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} SYNC=1 SYNC value
             * @property {number} EXPLICIT=2 EXPLICIT value
             */
            CallDetectionConfig.IntegrationMethod = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "SYNC"] = 1;
                values[valuesById[2] = "EXPLICIT"] = 2;
                return values;
            })();
    
            /**
             * ExplicitIntegrationSource enum.
             * @name vibe.CallDetectionConfig.ExplicitIntegrationSource
             * @enum {number}
             * @property {number} UNDEFINED=0 UNDEFINED value
             * @property {number} I2X=1 I2X value
             * @property {number} UCT=2 UCT value
             * @property {number} I2X_MANUAL=3 I2X_MANUAL value
             * @property {number} WIN_AUDIO_SESSION=4 WIN_AUDIO_SESSION value
             * @property {number} COPS_SESSION=5 COPS_SESSION value
             * @property {number} ORECX=6 ORECX value
             */
            CallDetectionConfig.ExplicitIntegrationSource = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNDEFINED"] = 0;
                values[valuesById[1] = "I2X"] = 1;
                values[valuesById[2] = "UCT"] = 2;
                values[valuesById[3] = "I2X_MANUAL"] = 3;
                values[valuesById[4] = "WIN_AUDIO_SESSION"] = 4;
                values[valuesById[5] = "COPS_SESSION"] = 5;
                values[valuesById[6] = "ORECX"] = 6;
                return values;
            })();
    
            CallDetectionConfig.AbsoluteSilenceDefinition = (function() {
    
                /**
                 * Properties of an AbsoluteSilenceDefinition.
                 * @memberof vibe.CallDetectionConfig
                 * @interface IAbsoluteSilenceDefinition
                 * @property {boolean|null} [overrideDefaultInitialThreshold] AbsoluteSilenceDefinition overrideDefaultInitialThreshold
                 * @property {number|null} [initialThresholdMin] AbsoluteSilenceDefinition initialThresholdMin
                 * @property {number|null} [initialThresholdMax] AbsoluteSilenceDefinition initialThresholdMax
                 * @property {boolean|null} [disableDynamicThreshold] AbsoluteSilenceDefinition disableDynamicThreshold
                 * @property {Array.<vibe.IAbsoluteSilenceInterval>|null} [additionalIntervals] AbsoluteSilenceDefinition additionalIntervals
                 * @property {number|null} [consecutiveAbsoluteSilenceToEndVoicedChunkMsec] AbsoluteSilenceDefinition consecutiveAbsoluteSilenceToEndVoicedChunkMsec
                 */
    
                /**
                 * Constructs a new AbsoluteSilenceDefinition.
                 * @memberof vibe.CallDetectionConfig
                 * @classdesc Represents an AbsoluteSilenceDefinition.
                 * @implements IAbsoluteSilenceDefinition
                 * @constructor
                 * @param {vibe.CallDetectionConfig.IAbsoluteSilenceDefinition=} [properties] Properties to set
                 */
                function AbsoluteSilenceDefinition(properties) {
                    this.additionalIntervals = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AbsoluteSilenceDefinition overrideDefaultInitialThreshold.
                 * @member {boolean} overrideDefaultInitialThreshold
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @instance
                 */
                AbsoluteSilenceDefinition.prototype.overrideDefaultInitialThreshold = false;
    
                /**
                 * AbsoluteSilenceDefinition initialThresholdMin.
                 * @member {number} initialThresholdMin
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @instance
                 */
                AbsoluteSilenceDefinition.prototype.initialThresholdMin = 0;
    
                /**
                 * AbsoluteSilenceDefinition initialThresholdMax.
                 * @member {number} initialThresholdMax
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @instance
                 */
                AbsoluteSilenceDefinition.prototype.initialThresholdMax = 0;
    
                /**
                 * AbsoluteSilenceDefinition disableDynamicThreshold.
                 * @member {boolean} disableDynamicThreshold
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @instance
                 */
                AbsoluteSilenceDefinition.prototype.disableDynamicThreshold = false;
    
                /**
                 * AbsoluteSilenceDefinition additionalIntervals.
                 * @member {Array.<vibe.IAbsoluteSilenceInterval>} additionalIntervals
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @instance
                 */
                AbsoluteSilenceDefinition.prototype.additionalIntervals = $util.emptyArray;
    
                /**
                 * AbsoluteSilenceDefinition consecutiveAbsoluteSilenceToEndVoicedChunkMsec.
                 * @member {number} consecutiveAbsoluteSilenceToEndVoicedChunkMsec
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @instance
                 */
                AbsoluteSilenceDefinition.prototype.consecutiveAbsoluteSilenceToEndVoicedChunkMsec = 0;
    
                /**
                 * Creates a new AbsoluteSilenceDefinition instance using the specified properties.
                 * @function create
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @static
                 * @param {vibe.CallDetectionConfig.IAbsoluteSilenceDefinition=} [properties] Properties to set
                 * @returns {vibe.CallDetectionConfig.AbsoluteSilenceDefinition} AbsoluteSilenceDefinition instance
                 */
                AbsoluteSilenceDefinition.create = function create(properties) {
                    return new AbsoluteSilenceDefinition(properties);
                };
    
                /**
                 * Encodes the specified AbsoluteSilenceDefinition message. Does not implicitly {@link vibe.CallDetectionConfig.AbsoluteSilenceDefinition.verify|verify} messages.
                 * @function encode
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @static
                 * @param {vibe.CallDetectionConfig.IAbsoluteSilenceDefinition} message AbsoluteSilenceDefinition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbsoluteSilenceDefinition.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.overrideDefaultInitialThreshold != null && Object.hasOwnProperty.call(message, "overrideDefaultInitialThreshold"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.overrideDefaultInitialThreshold);
                    if (message.initialThresholdMin != null && Object.hasOwnProperty.call(message, "initialThresholdMin"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.initialThresholdMin);
                    if (message.initialThresholdMax != null && Object.hasOwnProperty.call(message, "initialThresholdMax"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.initialThresholdMax);
                    if (message.disableDynamicThreshold != null && Object.hasOwnProperty.call(message, "disableDynamicThreshold"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.disableDynamicThreshold);
                    if (message.additionalIntervals != null && message.additionalIntervals.length)
                        for (var i = 0; i < message.additionalIntervals.length; ++i)
                            $root.vibe.AbsoluteSilenceInterval.encode(message.additionalIntervals[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.consecutiveAbsoluteSilenceToEndVoicedChunkMsec != null && Object.hasOwnProperty.call(message, "consecutiveAbsoluteSilenceToEndVoicedChunkMsec"))
                        writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.consecutiveAbsoluteSilenceToEndVoicedChunkMsec);
                    return writer;
                };
    
                /**
                 * Encodes the specified AbsoluteSilenceDefinition message, length delimited. Does not implicitly {@link vibe.CallDetectionConfig.AbsoluteSilenceDefinition.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @static
                 * @param {vibe.CallDetectionConfig.IAbsoluteSilenceDefinition} message AbsoluteSilenceDefinition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbsoluteSilenceDefinition.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AbsoluteSilenceDefinition message from the specified reader or buffer.
                 * @function decode
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {vibe.CallDetectionConfig.AbsoluteSilenceDefinition} AbsoluteSilenceDefinition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbsoluteSilenceDefinition.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.CallDetectionConfig.AbsoluteSilenceDefinition();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.overrideDefaultInitialThreshold = reader.bool();
                                break;
                            }
                        case 2: {
                                message.initialThresholdMin = reader.int32();
                                break;
                            }
                        case 3: {
                                message.initialThresholdMax = reader.int32();
                                break;
                            }
                        case 4: {
                                message.disableDynamicThreshold = reader.bool();
                                break;
                            }
                        case 5: {
                                if (!(message.additionalIntervals && message.additionalIntervals.length))
                                    message.additionalIntervals = [];
                                message.additionalIntervals.push($root.vibe.AbsoluteSilenceInterval.decode(reader, reader.uint32()));
                                break;
                            }
                        case 6: {
                                message.consecutiveAbsoluteSilenceToEndVoicedChunkMsec = reader.uint32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AbsoluteSilenceDefinition message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {vibe.CallDetectionConfig.AbsoluteSilenceDefinition} AbsoluteSilenceDefinition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbsoluteSilenceDefinition.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AbsoluteSilenceDefinition message.
                 * @function verify
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbsoluteSilenceDefinition.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.overrideDefaultInitialThreshold != null && message.hasOwnProperty("overrideDefaultInitialThreshold"))
                        if (typeof message.overrideDefaultInitialThreshold !== "boolean")
                            return "overrideDefaultInitialThreshold: boolean expected";
                    if (message.initialThresholdMin != null && message.hasOwnProperty("initialThresholdMin"))
                        if (!$util.isInteger(message.initialThresholdMin))
                            return "initialThresholdMin: integer expected";
                    if (message.initialThresholdMax != null && message.hasOwnProperty("initialThresholdMax"))
                        if (!$util.isInteger(message.initialThresholdMax))
                            return "initialThresholdMax: integer expected";
                    if (message.disableDynamicThreshold != null && message.hasOwnProperty("disableDynamicThreshold"))
                        if (typeof message.disableDynamicThreshold !== "boolean")
                            return "disableDynamicThreshold: boolean expected";
                    if (message.additionalIntervals != null && message.hasOwnProperty("additionalIntervals")) {
                        if (!Array.isArray(message.additionalIntervals))
                            return "additionalIntervals: array expected";
                        for (var i = 0; i < message.additionalIntervals.length; ++i) {
                            var error = $root.vibe.AbsoluteSilenceInterval.verify(message.additionalIntervals[i]);
                            if (error)
                                return "additionalIntervals." + error;
                        }
                    }
                    if (message.consecutiveAbsoluteSilenceToEndVoicedChunkMsec != null && message.hasOwnProperty("consecutiveAbsoluteSilenceToEndVoicedChunkMsec"))
                        if (!$util.isInteger(message.consecutiveAbsoluteSilenceToEndVoicedChunkMsec))
                            return "consecutiveAbsoluteSilenceToEndVoicedChunkMsec: integer expected";
                    return null;
                };
    
                /**
                 * Creates an AbsoluteSilenceDefinition message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {vibe.CallDetectionConfig.AbsoluteSilenceDefinition} AbsoluteSilenceDefinition
                 */
                AbsoluteSilenceDefinition.fromObject = function fromObject(object) {
                    if (object instanceof $root.vibe.CallDetectionConfig.AbsoluteSilenceDefinition)
                        return object;
                    var message = new $root.vibe.CallDetectionConfig.AbsoluteSilenceDefinition();
                    if (object.overrideDefaultInitialThreshold != null)
                        message.overrideDefaultInitialThreshold = Boolean(object.overrideDefaultInitialThreshold);
                    if (object.initialThresholdMin != null)
                        message.initialThresholdMin = object.initialThresholdMin | 0;
                    if (object.initialThresholdMax != null)
                        message.initialThresholdMax = object.initialThresholdMax | 0;
                    if (object.disableDynamicThreshold != null)
                        message.disableDynamicThreshold = Boolean(object.disableDynamicThreshold);
                    if (object.additionalIntervals) {
                        if (!Array.isArray(object.additionalIntervals))
                            throw TypeError(".vibe.CallDetectionConfig.AbsoluteSilenceDefinition.additionalIntervals: array expected");
                        message.additionalIntervals = [];
                        for (var i = 0; i < object.additionalIntervals.length; ++i) {
                            if (typeof object.additionalIntervals[i] !== "object")
                                throw TypeError(".vibe.CallDetectionConfig.AbsoluteSilenceDefinition.additionalIntervals: object expected");
                            message.additionalIntervals[i] = $root.vibe.AbsoluteSilenceInterval.fromObject(object.additionalIntervals[i]);
                        }
                    }
                    if (object.consecutiveAbsoluteSilenceToEndVoicedChunkMsec != null)
                        message.consecutiveAbsoluteSilenceToEndVoicedChunkMsec = object.consecutiveAbsoluteSilenceToEndVoicedChunkMsec >>> 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from an AbsoluteSilenceDefinition message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @static
                 * @param {vibe.CallDetectionConfig.AbsoluteSilenceDefinition} message AbsoluteSilenceDefinition
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbsoluteSilenceDefinition.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.additionalIntervals = [];
                    if (options.defaults) {
                        object.overrideDefaultInitialThreshold = false;
                        object.initialThresholdMin = 0;
                        object.initialThresholdMax = 0;
                        object.disableDynamicThreshold = false;
                        object.consecutiveAbsoluteSilenceToEndVoicedChunkMsec = 0;
                    }
                    if (message.overrideDefaultInitialThreshold != null && message.hasOwnProperty("overrideDefaultInitialThreshold"))
                        object.overrideDefaultInitialThreshold = message.overrideDefaultInitialThreshold;
                    if (message.initialThresholdMin != null && message.hasOwnProperty("initialThresholdMin"))
                        object.initialThresholdMin = message.initialThresholdMin;
                    if (message.initialThresholdMax != null && message.hasOwnProperty("initialThresholdMax"))
                        object.initialThresholdMax = message.initialThresholdMax;
                    if (message.disableDynamicThreshold != null && message.hasOwnProperty("disableDynamicThreshold"))
                        object.disableDynamicThreshold = message.disableDynamicThreshold;
                    if (message.additionalIntervals && message.additionalIntervals.length) {
                        object.additionalIntervals = [];
                        for (var j = 0; j < message.additionalIntervals.length; ++j)
                            object.additionalIntervals[j] = $root.vibe.AbsoluteSilenceInterval.toObject(message.additionalIntervals[j], options);
                    }
                    if (message.consecutiveAbsoluteSilenceToEndVoicedChunkMsec != null && message.hasOwnProperty("consecutiveAbsoluteSilenceToEndVoicedChunkMsec"))
                        object.consecutiveAbsoluteSilenceToEndVoicedChunkMsec = message.consecutiveAbsoluteSilenceToEndVoicedChunkMsec;
                    return object;
                };
    
                /**
                 * Converts this AbsoluteSilenceDefinition to JSON.
                 * @function toJSON
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbsoluteSilenceDefinition.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AbsoluteSilenceDefinition
                 * @function getTypeUrl
                 * @memberof vibe.CallDetectionConfig.AbsoluteSilenceDefinition
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbsoluteSilenceDefinition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/vibe.CallDetectionConfig.AbsoluteSilenceDefinition";
                };
    
                return AbsoluteSilenceDefinition;
            })();
    
            return CallDetectionConfig;
        })();
    
        vibe.AbsoluteSilenceInterval = (function() {
    
            /**
             * Properties of an AbsoluteSilenceInterval.
             * @memberof vibe
             * @interface IAbsoluteSilenceInterval
             * @property {number|null} [thresholdMin] AbsoluteSilenceInterval thresholdMin
             * @property {number|null} [thresholdMax] AbsoluteSilenceInterval thresholdMax
             */
    
            /**
             * Constructs a new AbsoluteSilenceInterval.
             * @memberof vibe
             * @classdesc Represents an AbsoluteSilenceInterval.
             * @implements IAbsoluteSilenceInterval
             * @constructor
             * @param {vibe.IAbsoluteSilenceInterval=} [properties] Properties to set
             */
            function AbsoluteSilenceInterval(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AbsoluteSilenceInterval thresholdMin.
             * @member {number} thresholdMin
             * @memberof vibe.AbsoluteSilenceInterval
             * @instance
             */
            AbsoluteSilenceInterval.prototype.thresholdMin = 0;
    
            /**
             * AbsoluteSilenceInterval thresholdMax.
             * @member {number} thresholdMax
             * @memberof vibe.AbsoluteSilenceInterval
             * @instance
             */
            AbsoluteSilenceInterval.prototype.thresholdMax = 0;
    
            /**
             * Creates a new AbsoluteSilenceInterval instance using the specified properties.
             * @function create
             * @memberof vibe.AbsoluteSilenceInterval
             * @static
             * @param {vibe.IAbsoluteSilenceInterval=} [properties] Properties to set
             * @returns {vibe.AbsoluteSilenceInterval} AbsoluteSilenceInterval instance
             */
            AbsoluteSilenceInterval.create = function create(properties) {
                return new AbsoluteSilenceInterval(properties);
            };
    
            /**
             * Encodes the specified AbsoluteSilenceInterval message. Does not implicitly {@link vibe.AbsoluteSilenceInterval.verify|verify} messages.
             * @function encode
             * @memberof vibe.AbsoluteSilenceInterval
             * @static
             * @param {vibe.IAbsoluteSilenceInterval} message AbsoluteSilenceInterval message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AbsoluteSilenceInterval.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.thresholdMin != null && Object.hasOwnProperty.call(message, "thresholdMin"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.thresholdMin);
                if (message.thresholdMax != null && Object.hasOwnProperty.call(message, "thresholdMax"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.thresholdMax);
                return writer;
            };
    
            /**
             * Encodes the specified AbsoluteSilenceInterval message, length delimited. Does not implicitly {@link vibe.AbsoluteSilenceInterval.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.AbsoluteSilenceInterval
             * @static
             * @param {vibe.IAbsoluteSilenceInterval} message AbsoluteSilenceInterval message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AbsoluteSilenceInterval.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AbsoluteSilenceInterval message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.AbsoluteSilenceInterval
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.AbsoluteSilenceInterval} AbsoluteSilenceInterval
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AbsoluteSilenceInterval.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.AbsoluteSilenceInterval();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.thresholdMin = reader.int32();
                            break;
                        }
                    case 2: {
                            message.thresholdMax = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AbsoluteSilenceInterval message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.AbsoluteSilenceInterval
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.AbsoluteSilenceInterval} AbsoluteSilenceInterval
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AbsoluteSilenceInterval.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AbsoluteSilenceInterval message.
             * @function verify
             * @memberof vibe.AbsoluteSilenceInterval
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AbsoluteSilenceInterval.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.thresholdMin != null && message.hasOwnProperty("thresholdMin"))
                    if (!$util.isInteger(message.thresholdMin))
                        return "thresholdMin: integer expected";
                if (message.thresholdMax != null && message.hasOwnProperty("thresholdMax"))
                    if (!$util.isInteger(message.thresholdMax))
                        return "thresholdMax: integer expected";
                return null;
            };
    
            /**
             * Creates an AbsoluteSilenceInterval message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.AbsoluteSilenceInterval
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.AbsoluteSilenceInterval} AbsoluteSilenceInterval
             */
            AbsoluteSilenceInterval.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.AbsoluteSilenceInterval)
                    return object;
                var message = new $root.vibe.AbsoluteSilenceInterval();
                if (object.thresholdMin != null)
                    message.thresholdMin = object.thresholdMin | 0;
                if (object.thresholdMax != null)
                    message.thresholdMax = object.thresholdMax | 0;
                return message;
            };
    
            /**
             * Creates a plain object from an AbsoluteSilenceInterval message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.AbsoluteSilenceInterval
             * @static
             * @param {vibe.AbsoluteSilenceInterval} message AbsoluteSilenceInterval
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AbsoluteSilenceInterval.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.thresholdMin = 0;
                    object.thresholdMax = 0;
                }
                if (message.thresholdMin != null && message.hasOwnProperty("thresholdMin"))
                    object.thresholdMin = message.thresholdMin;
                if (message.thresholdMax != null && message.hasOwnProperty("thresholdMax"))
                    object.thresholdMax = message.thresholdMax;
                return object;
            };
    
            /**
             * Converts this AbsoluteSilenceInterval to JSON.
             * @function toJSON
             * @memberof vibe.AbsoluteSilenceInterval
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AbsoluteSilenceInterval.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AbsoluteSilenceInterval
             * @function getTypeUrl
             * @memberof vibe.AbsoluteSilenceInterval
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AbsoluteSilenceInterval.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.AbsoluteSilenceInterval";
            };
    
            return AbsoluteSilenceInterval;
        })();
    
        vibe.RecognitionConfig = (function() {
    
            /**
             * Properties of a RecognitionConfig.
             * @memberof vibe
             * @interface IRecognitionConfig
             * @property {vibe.AudioEncoding|null} [encoding] RecognitionConfig encoding
             * @property {number|null} [sampleRateHertz] RecognitionConfig sampleRateHertz
             * @property {string|null} [languageCode] RecognitionConfig languageCode
             * @property {vibe.IASRVendors|null} [asrVendors] RecognitionConfig asrVendors
             * @property {number|null} [maxAlternatives] RecognitionConfig maxAlternatives
             * @property {boolean|null} [enableInterimResults] RecognitionConfig enableInterimResults
             * @property {boolean|null} [enablePhraseSpottingForInterimResults] RecognitionConfig enablePhraseSpottingForInterimResults
             * @property {boolean|null} [enableInverseNormalization] RecognitionConfig enableInverseNormalization
             * @property {vibe.RecognitionConfig.OperationMode|null} [mode] RecognitionConfig mode
             * @property {vibe.IGrammarHints|null} [grammarHints] RecognitionConfig grammarHints
             * @property {vibe.ISessionInfo|null} [sessionInfo] RecognitionConfig sessionInfo
             * @property {vibe.ISystemInfo|null} [systemInfo] RecognitionConfig systemInfo
             * @property {boolean|null} [enableAnonymization] RecognitionConfig enableAnonymization
             * @property {boolean|null} [forgetStream] RecognitionConfig forgetStream
             * @property {boolean|null} [disableUtteranceChunking] RecognitionConfig disableUtteranceChunking
             * @property {vibe.ICallDetectionConfig|null} [callDetectionConfig] RecognitionConfig callDetectionConfig
             * @property {boolean|null} [enableNormalization] RecognitionConfig enableNormalization
             * @property {vibe.IServiceVersion|null} [serviceVersion] RecognitionConfig serviceVersion
             * @property {boolean|null} [enableAsrDebug] RecognitionConfig enableAsrDebug
             * @property {boolean|null} [enableCallMetadataOnly] RecognitionConfig enableCallMetadataOnly
             */
    
            /**
             * Constructs a new RecognitionConfig.
             * @memberof vibe
             * @classdesc Represents a RecognitionConfig.
             * @implements IRecognitionConfig
             * @constructor
             * @param {vibe.IRecognitionConfig=} [properties] Properties to set
             */
            function RecognitionConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RecognitionConfig encoding.
             * @member {vibe.AudioEncoding} encoding
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.encoding = 0;
    
            /**
             * RecognitionConfig sampleRateHertz.
             * @member {number} sampleRateHertz
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.sampleRateHertz = 0;
    
            /**
             * RecognitionConfig languageCode.
             * @member {string} languageCode
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.languageCode = "";
    
            /**
             * RecognitionConfig asrVendors.
             * @member {vibe.IASRVendors|null|undefined} asrVendors
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.asrVendors = null;
    
            /**
             * RecognitionConfig maxAlternatives.
             * @member {number} maxAlternatives
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.maxAlternatives = 0;
    
            /**
             * RecognitionConfig enableInterimResults.
             * @member {boolean} enableInterimResults
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.enableInterimResults = false;
    
            /**
             * RecognitionConfig enablePhraseSpottingForInterimResults.
             * @member {boolean} enablePhraseSpottingForInterimResults
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.enablePhraseSpottingForInterimResults = false;
    
            /**
             * RecognitionConfig enableInverseNormalization.
             * @member {boolean} enableInverseNormalization
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.enableInverseNormalization = false;
    
            /**
             * RecognitionConfig mode.
             * @member {vibe.RecognitionConfig.OperationMode} mode
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.mode = 0;
    
            /**
             * RecognitionConfig grammarHints.
             * @member {vibe.IGrammarHints|null|undefined} grammarHints
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.grammarHints = null;
    
            /**
             * RecognitionConfig sessionInfo.
             * @member {vibe.ISessionInfo|null|undefined} sessionInfo
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.sessionInfo = null;
    
            /**
             * RecognitionConfig systemInfo.
             * @member {vibe.ISystemInfo|null|undefined} systemInfo
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.systemInfo = null;
    
            /**
             * RecognitionConfig enableAnonymization.
             * @member {boolean} enableAnonymization
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.enableAnonymization = false;
    
            /**
             * RecognitionConfig forgetStream.
             * @member {boolean} forgetStream
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.forgetStream = false;
    
            /**
             * RecognitionConfig disableUtteranceChunking.
             * @member {boolean} disableUtteranceChunking
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.disableUtteranceChunking = false;
    
            /**
             * RecognitionConfig callDetectionConfig.
             * @member {vibe.ICallDetectionConfig|null|undefined} callDetectionConfig
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.callDetectionConfig = null;
    
            /**
             * RecognitionConfig enableNormalization.
             * @member {boolean} enableNormalization
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.enableNormalization = false;
    
            /**
             * RecognitionConfig serviceVersion.
             * @member {vibe.IServiceVersion|null|undefined} serviceVersion
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.serviceVersion = null;
    
            /**
             * RecognitionConfig enableAsrDebug.
             * @member {boolean} enableAsrDebug
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.enableAsrDebug = false;
    
            /**
             * RecognitionConfig enableCallMetadataOnly.
             * @member {boolean} enableCallMetadataOnly
             * @memberof vibe.RecognitionConfig
             * @instance
             */
            RecognitionConfig.prototype.enableCallMetadataOnly = false;
    
            /**
             * Creates a new RecognitionConfig instance using the specified properties.
             * @function create
             * @memberof vibe.RecognitionConfig
             * @static
             * @param {vibe.IRecognitionConfig=} [properties] Properties to set
             * @returns {vibe.RecognitionConfig} RecognitionConfig instance
             */
            RecognitionConfig.create = function create(properties) {
                return new RecognitionConfig(properties);
            };
    
            /**
             * Encodes the specified RecognitionConfig message. Does not implicitly {@link vibe.RecognitionConfig.verify|verify} messages.
             * @function encode
             * @memberof vibe.RecognitionConfig
             * @static
             * @param {vibe.IRecognitionConfig} message RecognitionConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecognitionConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.encoding != null && Object.hasOwnProperty.call(message, "encoding"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.encoding);
                if (message.sampleRateHertz != null && Object.hasOwnProperty.call(message, "sampleRateHertz"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.sampleRateHertz);
                if (message.languageCode != null && Object.hasOwnProperty.call(message, "languageCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.languageCode);
                if (message.asrVendors != null && Object.hasOwnProperty.call(message, "asrVendors"))
                    $root.vibe.ASRVendors.encode(message.asrVendors, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.maxAlternatives != null && Object.hasOwnProperty.call(message, "maxAlternatives"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.maxAlternatives);
                if (message.enableInterimResults != null && Object.hasOwnProperty.call(message, "enableInterimResults"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.enableInterimResults);
                if (message.enablePhraseSpottingForInterimResults != null && Object.hasOwnProperty.call(message, "enablePhraseSpottingForInterimResults"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.enablePhraseSpottingForInterimResults);
                if (message.enableInverseNormalization != null && Object.hasOwnProperty.call(message, "enableInverseNormalization"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.enableInverseNormalization);
                if (message.mode != null && Object.hasOwnProperty.call(message, "mode"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.mode);
                if (message.grammarHints != null && Object.hasOwnProperty.call(message, "grammarHints"))
                    $root.vibe.GrammarHints.encode(message.grammarHints, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.sessionInfo != null && Object.hasOwnProperty.call(message, "sessionInfo"))
                    $root.vibe.SessionInfo.encode(message.sessionInfo, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.systemInfo != null && Object.hasOwnProperty.call(message, "systemInfo"))
                    $root.vibe.SystemInfo.encode(message.systemInfo, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.enableAnonymization != null && Object.hasOwnProperty.call(message, "enableAnonymization"))
                    writer.uint32(/* id 13, wireType 0 =*/104).bool(message.enableAnonymization);
                if (message.forgetStream != null && Object.hasOwnProperty.call(message, "forgetStream"))
                    writer.uint32(/* id 14, wireType 0 =*/112).bool(message.forgetStream);
                if (message.disableUtteranceChunking != null && Object.hasOwnProperty.call(message, "disableUtteranceChunking"))
                    writer.uint32(/* id 15, wireType 0 =*/120).bool(message.disableUtteranceChunking);
                if (message.callDetectionConfig != null && Object.hasOwnProperty.call(message, "callDetectionConfig"))
                    $root.vibe.CallDetectionConfig.encode(message.callDetectionConfig, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.enableNormalization != null && Object.hasOwnProperty.call(message, "enableNormalization"))
                    writer.uint32(/* id 17, wireType 0 =*/136).bool(message.enableNormalization);
                if (message.serviceVersion != null && Object.hasOwnProperty.call(message, "serviceVersion"))
                    $root.vibe.ServiceVersion.encode(message.serviceVersion, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.enableAsrDebug != null && Object.hasOwnProperty.call(message, "enableAsrDebug"))
                    writer.uint32(/* id 19, wireType 0 =*/152).bool(message.enableAsrDebug);
                if (message.enableCallMetadataOnly != null && Object.hasOwnProperty.call(message, "enableCallMetadataOnly"))
                    writer.uint32(/* id 20, wireType 0 =*/160).bool(message.enableCallMetadataOnly);
                return writer;
            };
    
            /**
             * Encodes the specified RecognitionConfig message, length delimited. Does not implicitly {@link vibe.RecognitionConfig.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.RecognitionConfig
             * @static
             * @param {vibe.IRecognitionConfig} message RecognitionConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecognitionConfig.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RecognitionConfig message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.RecognitionConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.RecognitionConfig} RecognitionConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecognitionConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.RecognitionConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.encoding = reader.int32();
                            break;
                        }
                    case 2: {
                            message.sampleRateHertz = reader.int32();
                            break;
                        }
                    case 3: {
                            message.languageCode = reader.string();
                            break;
                        }
                    case 4: {
                            message.asrVendors = $root.vibe.ASRVendors.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.maxAlternatives = reader.int32();
                            break;
                        }
                    case 6: {
                            message.enableInterimResults = reader.bool();
                            break;
                        }
                    case 7: {
                            message.enablePhraseSpottingForInterimResults = reader.bool();
                            break;
                        }
                    case 8: {
                            message.enableInverseNormalization = reader.bool();
                            break;
                        }
                    case 9: {
                            message.mode = reader.int32();
                            break;
                        }
                    case 10: {
                            message.grammarHints = $root.vibe.GrammarHints.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.sessionInfo = $root.vibe.SessionInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.systemInfo = $root.vibe.SystemInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.enableAnonymization = reader.bool();
                            break;
                        }
                    case 14: {
                            message.forgetStream = reader.bool();
                            break;
                        }
                    case 15: {
                            message.disableUtteranceChunking = reader.bool();
                            break;
                        }
                    case 16: {
                            message.callDetectionConfig = $root.vibe.CallDetectionConfig.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.enableNormalization = reader.bool();
                            break;
                        }
                    case 18: {
                            message.serviceVersion = $root.vibe.ServiceVersion.decode(reader, reader.uint32());
                            break;
                        }
                    case 19: {
                            message.enableAsrDebug = reader.bool();
                            break;
                        }
                    case 20: {
                            message.enableCallMetadataOnly = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RecognitionConfig message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.RecognitionConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.RecognitionConfig} RecognitionConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecognitionConfig.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RecognitionConfig message.
             * @function verify
             * @memberof vibe.RecognitionConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RecognitionConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.encoding != null && message.hasOwnProperty("encoding"))
                    switch (message.encoding) {
                    default:
                        return "encoding: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.sampleRateHertz != null && message.hasOwnProperty("sampleRateHertz"))
                    if (!$util.isInteger(message.sampleRateHertz))
                        return "sampleRateHertz: integer expected";
                if (message.languageCode != null && message.hasOwnProperty("languageCode"))
                    if (!$util.isString(message.languageCode))
                        return "languageCode: string expected";
                if (message.asrVendors != null && message.hasOwnProperty("asrVendors")) {
                    var error = $root.vibe.ASRVendors.verify(message.asrVendors);
                    if (error)
                        return "asrVendors." + error;
                }
                if (message.maxAlternatives != null && message.hasOwnProperty("maxAlternatives"))
                    if (!$util.isInteger(message.maxAlternatives))
                        return "maxAlternatives: integer expected";
                if (message.enableInterimResults != null && message.hasOwnProperty("enableInterimResults"))
                    if (typeof message.enableInterimResults !== "boolean")
                        return "enableInterimResults: boolean expected";
                if (message.enablePhraseSpottingForInterimResults != null && message.hasOwnProperty("enablePhraseSpottingForInterimResults"))
                    if (typeof message.enablePhraseSpottingForInterimResults !== "boolean")
                        return "enablePhraseSpottingForInterimResults: boolean expected";
                if (message.enableInverseNormalization != null && message.hasOwnProperty("enableInverseNormalization"))
                    if (typeof message.enableInverseNormalization !== "boolean")
                        return "enableInverseNormalization: boolean expected";
                if (message.mode != null && message.hasOwnProperty("mode"))
                    switch (message.mode) {
                    default:
                        return "mode: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.grammarHints != null && message.hasOwnProperty("grammarHints")) {
                    var error = $root.vibe.GrammarHints.verify(message.grammarHints);
                    if (error)
                        return "grammarHints." + error;
                }
                if (message.sessionInfo != null && message.hasOwnProperty("sessionInfo")) {
                    var error = $root.vibe.SessionInfo.verify(message.sessionInfo);
                    if (error)
                        return "sessionInfo." + error;
                }
                if (message.systemInfo != null && message.hasOwnProperty("systemInfo")) {
                    var error = $root.vibe.SystemInfo.verify(message.systemInfo);
                    if (error)
                        return "systemInfo." + error;
                }
                if (message.enableAnonymization != null && message.hasOwnProperty("enableAnonymization"))
                    if (typeof message.enableAnonymization !== "boolean")
                        return "enableAnonymization: boolean expected";
                if (message.forgetStream != null && message.hasOwnProperty("forgetStream"))
                    if (typeof message.forgetStream !== "boolean")
                        return "forgetStream: boolean expected";
                if (message.disableUtteranceChunking != null && message.hasOwnProperty("disableUtteranceChunking"))
                    if (typeof message.disableUtteranceChunking !== "boolean")
                        return "disableUtteranceChunking: boolean expected";
                if (message.callDetectionConfig != null && message.hasOwnProperty("callDetectionConfig")) {
                    var error = $root.vibe.CallDetectionConfig.verify(message.callDetectionConfig);
                    if (error)
                        return "callDetectionConfig." + error;
                }
                if (message.enableNormalization != null && message.hasOwnProperty("enableNormalization"))
                    if (typeof message.enableNormalization !== "boolean")
                        return "enableNormalization: boolean expected";
                if (message.serviceVersion != null && message.hasOwnProperty("serviceVersion")) {
                    var error = $root.vibe.ServiceVersion.verify(message.serviceVersion);
                    if (error)
                        return "serviceVersion." + error;
                }
                if (message.enableAsrDebug != null && message.hasOwnProperty("enableAsrDebug"))
                    if (typeof message.enableAsrDebug !== "boolean")
                        return "enableAsrDebug: boolean expected";
                if (message.enableCallMetadataOnly != null && message.hasOwnProperty("enableCallMetadataOnly"))
                    if (typeof message.enableCallMetadataOnly !== "boolean")
                        return "enableCallMetadataOnly: boolean expected";
                return null;
            };
    
            /**
             * Creates a RecognitionConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.RecognitionConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.RecognitionConfig} RecognitionConfig
             */
            RecognitionConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.RecognitionConfig)
                    return object;
                var message = new $root.vibe.RecognitionConfig();
                switch (object.encoding) {
                default:
                    if (typeof object.encoding === "number") {
                        message.encoding = object.encoding;
                        break;
                    }
                    break;
                case "LINEAR16":
                case 0:
                    message.encoding = 0;
                    break;
                case "FLAC":
                case 1:
                    message.encoding = 1;
                    break;
                case "OPUS":
                case 2:
                    message.encoding = 2;
                    break;
                }
                if (object.sampleRateHertz != null)
                    message.sampleRateHertz = object.sampleRateHertz | 0;
                if (object.languageCode != null)
                    message.languageCode = String(object.languageCode);
                if (object.asrVendors != null) {
                    if (typeof object.asrVendors !== "object")
                        throw TypeError(".vibe.RecognitionConfig.asrVendors: object expected");
                    message.asrVendors = $root.vibe.ASRVendors.fromObject(object.asrVendors);
                }
                if (object.maxAlternatives != null)
                    message.maxAlternatives = object.maxAlternatives | 0;
                if (object.enableInterimResults != null)
                    message.enableInterimResults = Boolean(object.enableInterimResults);
                if (object.enablePhraseSpottingForInterimResults != null)
                    message.enablePhraseSpottingForInterimResults = Boolean(object.enablePhraseSpottingForInterimResults);
                if (object.enableInverseNormalization != null)
                    message.enableInverseNormalization = Boolean(object.enableInverseNormalization);
                switch (object.mode) {
                default:
                    if (typeof object.mode === "number") {
                        message.mode = object.mode;
                        break;
                    }
                    break;
                case "RECOGNITION":
                case 0:
                    message.mode = 0;
                    break;
                case "ALIGNMENT":
                case 1:
                    message.mode = 1;
                    break;
                }
                if (object.grammarHints != null) {
                    if (typeof object.grammarHints !== "object")
                        throw TypeError(".vibe.RecognitionConfig.grammarHints: object expected");
                    message.grammarHints = $root.vibe.GrammarHints.fromObject(object.grammarHints);
                }
                if (object.sessionInfo != null) {
                    if (typeof object.sessionInfo !== "object")
                        throw TypeError(".vibe.RecognitionConfig.sessionInfo: object expected");
                    message.sessionInfo = $root.vibe.SessionInfo.fromObject(object.sessionInfo);
                }
                if (object.systemInfo != null) {
                    if (typeof object.systemInfo !== "object")
                        throw TypeError(".vibe.RecognitionConfig.systemInfo: object expected");
                    message.systemInfo = $root.vibe.SystemInfo.fromObject(object.systemInfo);
                }
                if (object.enableAnonymization != null)
                    message.enableAnonymization = Boolean(object.enableAnonymization);
                if (object.forgetStream != null)
                    message.forgetStream = Boolean(object.forgetStream);
                if (object.disableUtteranceChunking != null)
                    message.disableUtteranceChunking = Boolean(object.disableUtteranceChunking);
                if (object.callDetectionConfig != null) {
                    if (typeof object.callDetectionConfig !== "object")
                        throw TypeError(".vibe.RecognitionConfig.callDetectionConfig: object expected");
                    message.callDetectionConfig = $root.vibe.CallDetectionConfig.fromObject(object.callDetectionConfig);
                }
                if (object.enableNormalization != null)
                    message.enableNormalization = Boolean(object.enableNormalization);
                if (object.serviceVersion != null) {
                    if (typeof object.serviceVersion !== "object")
                        throw TypeError(".vibe.RecognitionConfig.serviceVersion: object expected");
                    message.serviceVersion = $root.vibe.ServiceVersion.fromObject(object.serviceVersion);
                }
                if (object.enableAsrDebug != null)
                    message.enableAsrDebug = Boolean(object.enableAsrDebug);
                if (object.enableCallMetadataOnly != null)
                    message.enableCallMetadataOnly = Boolean(object.enableCallMetadataOnly);
                return message;
            };
    
            /**
             * Creates a plain object from a RecognitionConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.RecognitionConfig
             * @static
             * @param {vibe.RecognitionConfig} message RecognitionConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RecognitionConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.encoding = options.enums === String ? "LINEAR16" : 0;
                    object.sampleRateHertz = 0;
                    object.languageCode = "";
                    object.asrVendors = null;
                    object.maxAlternatives = 0;
                    object.enableInterimResults = false;
                    object.enablePhraseSpottingForInterimResults = false;
                    object.enableInverseNormalization = false;
                    object.mode = options.enums === String ? "RECOGNITION" : 0;
                    object.grammarHints = null;
                    object.sessionInfo = null;
                    object.systemInfo = null;
                    object.enableAnonymization = false;
                    object.forgetStream = false;
                    object.disableUtteranceChunking = false;
                    object.callDetectionConfig = null;
                    object.enableNormalization = false;
                    object.serviceVersion = null;
                    object.enableAsrDebug = false;
                    object.enableCallMetadataOnly = false;
                }
                if (message.encoding != null && message.hasOwnProperty("encoding"))
                    object.encoding = options.enums === String ? $root.vibe.AudioEncoding[message.encoding] === undefined ? message.encoding : $root.vibe.AudioEncoding[message.encoding] : message.encoding;
                if (message.sampleRateHertz != null && message.hasOwnProperty("sampleRateHertz"))
                    object.sampleRateHertz = message.sampleRateHertz;
                if (message.languageCode != null && message.hasOwnProperty("languageCode"))
                    object.languageCode = message.languageCode;
                if (message.asrVendors != null && message.hasOwnProperty("asrVendors"))
                    object.asrVendors = $root.vibe.ASRVendors.toObject(message.asrVendors, options);
                if (message.maxAlternatives != null && message.hasOwnProperty("maxAlternatives"))
                    object.maxAlternatives = message.maxAlternatives;
                if (message.enableInterimResults != null && message.hasOwnProperty("enableInterimResults"))
                    object.enableInterimResults = message.enableInterimResults;
                if (message.enablePhraseSpottingForInterimResults != null && message.hasOwnProperty("enablePhraseSpottingForInterimResults"))
                    object.enablePhraseSpottingForInterimResults = message.enablePhraseSpottingForInterimResults;
                if (message.enableInverseNormalization != null && message.hasOwnProperty("enableInverseNormalization"))
                    object.enableInverseNormalization = message.enableInverseNormalization;
                if (message.mode != null && message.hasOwnProperty("mode"))
                    object.mode = options.enums === String ? $root.vibe.RecognitionConfig.OperationMode[message.mode] === undefined ? message.mode : $root.vibe.RecognitionConfig.OperationMode[message.mode] : message.mode;
                if (message.grammarHints != null && message.hasOwnProperty("grammarHints"))
                    object.grammarHints = $root.vibe.GrammarHints.toObject(message.grammarHints, options);
                if (message.sessionInfo != null && message.hasOwnProperty("sessionInfo"))
                    object.sessionInfo = $root.vibe.SessionInfo.toObject(message.sessionInfo, options);
                if (message.systemInfo != null && message.hasOwnProperty("systemInfo"))
                    object.systemInfo = $root.vibe.SystemInfo.toObject(message.systemInfo, options);
                if (message.enableAnonymization != null && message.hasOwnProperty("enableAnonymization"))
                    object.enableAnonymization = message.enableAnonymization;
                if (message.forgetStream != null && message.hasOwnProperty("forgetStream"))
                    object.forgetStream = message.forgetStream;
                if (message.disableUtteranceChunking != null && message.hasOwnProperty("disableUtteranceChunking"))
                    object.disableUtteranceChunking = message.disableUtteranceChunking;
                if (message.callDetectionConfig != null && message.hasOwnProperty("callDetectionConfig"))
                    object.callDetectionConfig = $root.vibe.CallDetectionConfig.toObject(message.callDetectionConfig, options);
                if (message.enableNormalization != null && message.hasOwnProperty("enableNormalization"))
                    object.enableNormalization = message.enableNormalization;
                if (message.serviceVersion != null && message.hasOwnProperty("serviceVersion"))
                    object.serviceVersion = $root.vibe.ServiceVersion.toObject(message.serviceVersion, options);
                if (message.enableAsrDebug != null && message.hasOwnProperty("enableAsrDebug"))
                    object.enableAsrDebug = message.enableAsrDebug;
                if (message.enableCallMetadataOnly != null && message.hasOwnProperty("enableCallMetadataOnly"))
                    object.enableCallMetadataOnly = message.enableCallMetadataOnly;
                return object;
            };
    
            /**
             * Converts this RecognitionConfig to JSON.
             * @function toJSON
             * @memberof vibe.RecognitionConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RecognitionConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RecognitionConfig
             * @function getTypeUrl
             * @memberof vibe.RecognitionConfig
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RecognitionConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.RecognitionConfig";
            };
    
            /**
             * OperationMode enum.
             * @name vibe.RecognitionConfig.OperationMode
             * @enum {number}
             * @property {number} RECOGNITION=0 RECOGNITION value
             * @property {number} ALIGNMENT=1 ALIGNMENT value
             */
            RecognitionConfig.OperationMode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "RECOGNITION"] = 0;
                values[valuesById[1] = "ALIGNMENT"] = 1;
                return values;
            })();
    
            return RecognitionConfig;
        })();
    
        vibe.ASRVendors = (function() {
    
            /**
             * Properties of a ASRVendors.
             * @memberof vibe
             * @interface IASRVendors
             * @property {vibe.ASRVendors.II2XASRConfig|null} [i2x] ASRVendors i2x
             * @property {vibe.ASRVendors.IGoogleASRConfig|null} [google] ASRVendors google
             * @property {vibe.ASRVendors.IRivaASRConfig|null} [riva] ASRVendors riva
             */
    
            /**
             * Constructs a new ASRVendors.
             * @memberof vibe
             * @classdesc Represents a ASRVendors.
             * @implements IASRVendors
             * @constructor
             * @param {vibe.IASRVendors=} [properties] Properties to set
             */
            function ASRVendors(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ASRVendors i2x.
             * @member {vibe.ASRVendors.II2XASRConfig|null|undefined} i2x
             * @memberof vibe.ASRVendors
             * @instance
             */
            ASRVendors.prototype.i2x = null;
    
            /**
             * ASRVendors google.
             * @member {vibe.ASRVendors.IGoogleASRConfig|null|undefined} google
             * @memberof vibe.ASRVendors
             * @instance
             */
            ASRVendors.prototype.google = null;
    
            /**
             * ASRVendors riva.
             * @member {vibe.ASRVendors.IRivaASRConfig|null|undefined} riva
             * @memberof vibe.ASRVendors
             * @instance
             */
            ASRVendors.prototype.riva = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * ASRVendors config.
             * @member {"i2x"|"google"|"riva"|undefined} config
             * @memberof vibe.ASRVendors
             * @instance
             */
            Object.defineProperty(ASRVendors.prototype, "config", {
                get: $util.oneOfGetter($oneOfFields = ["i2x", "google", "riva"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new ASRVendors instance using the specified properties.
             * @function create
             * @memberof vibe.ASRVendors
             * @static
             * @param {vibe.IASRVendors=} [properties] Properties to set
             * @returns {vibe.ASRVendors} ASRVendors instance
             */
            ASRVendors.create = function create(properties) {
                return new ASRVendors(properties);
            };
    
            /**
             * Encodes the specified ASRVendors message. Does not implicitly {@link vibe.ASRVendors.verify|verify} messages.
             * @function encode
             * @memberof vibe.ASRVendors
             * @static
             * @param {vibe.IASRVendors} message ASRVendors message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ASRVendors.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.i2x != null && Object.hasOwnProperty.call(message, "i2x"))
                    $root.vibe.ASRVendors.I2XASRConfig.encode(message.i2x, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.google != null && Object.hasOwnProperty.call(message, "google"))
                    $root.vibe.ASRVendors.GoogleASRConfig.encode(message.google, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.riva != null && Object.hasOwnProperty.call(message, "riva"))
                    $root.vibe.ASRVendors.RivaASRConfig.encode(message.riva, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ASRVendors message, length delimited. Does not implicitly {@link vibe.ASRVendors.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.ASRVendors
             * @static
             * @param {vibe.IASRVendors} message ASRVendors message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ASRVendors.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ASRVendors message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.ASRVendors
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.ASRVendors} ASRVendors
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ASRVendors.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ASRVendors();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.i2x = $root.vibe.ASRVendors.I2XASRConfig.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.google = $root.vibe.ASRVendors.GoogleASRConfig.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.riva = $root.vibe.ASRVendors.RivaASRConfig.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ASRVendors message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.ASRVendors
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.ASRVendors} ASRVendors
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ASRVendors.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ASRVendors message.
             * @function verify
             * @memberof vibe.ASRVendors
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ASRVendors.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.i2x != null && message.hasOwnProperty("i2x")) {
                    properties.config = 1;
                    {
                        var error = $root.vibe.ASRVendors.I2XASRConfig.verify(message.i2x);
                        if (error)
                            return "i2x." + error;
                    }
                }
                if (message.google != null && message.hasOwnProperty("google")) {
                    if (properties.config === 1)
                        return "config: multiple values";
                    properties.config = 1;
                    {
                        var error = $root.vibe.ASRVendors.GoogleASRConfig.verify(message.google);
                        if (error)
                            return "google." + error;
                    }
                }
                if (message.riva != null && message.hasOwnProperty("riva")) {
                    if (properties.config === 1)
                        return "config: multiple values";
                    properties.config = 1;
                    {
                        var error = $root.vibe.ASRVendors.RivaASRConfig.verify(message.riva);
                        if (error)
                            return "riva." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ASRVendors message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.ASRVendors
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.ASRVendors} ASRVendors
             */
            ASRVendors.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.ASRVendors)
                    return object;
                var message = new $root.vibe.ASRVendors();
                if (object.i2x != null) {
                    if (typeof object.i2x !== "object")
                        throw TypeError(".vibe.ASRVendors.i2x: object expected");
                    message.i2x = $root.vibe.ASRVendors.I2XASRConfig.fromObject(object.i2x);
                }
                if (object.google != null) {
                    if (typeof object.google !== "object")
                        throw TypeError(".vibe.ASRVendors.google: object expected");
                    message.google = $root.vibe.ASRVendors.GoogleASRConfig.fromObject(object.google);
                }
                if (object.riva != null) {
                    if (typeof object.riva !== "object")
                        throw TypeError(".vibe.ASRVendors.riva: object expected");
                    message.riva = $root.vibe.ASRVendors.RivaASRConfig.fromObject(object.riva);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ASRVendors message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.ASRVendors
             * @static
             * @param {vibe.ASRVendors} message ASRVendors
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ASRVendors.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.i2x != null && message.hasOwnProperty("i2x")) {
                    object.i2x = $root.vibe.ASRVendors.I2XASRConfig.toObject(message.i2x, options);
                    if (options.oneofs)
                        object.config = "i2x";
                }
                if (message.google != null && message.hasOwnProperty("google")) {
                    object.google = $root.vibe.ASRVendors.GoogleASRConfig.toObject(message.google, options);
                    if (options.oneofs)
                        object.config = "google";
                }
                if (message.riva != null && message.hasOwnProperty("riva")) {
                    object.riva = $root.vibe.ASRVendors.RivaASRConfig.toObject(message.riva, options);
                    if (options.oneofs)
                        object.config = "riva";
                }
                return object;
            };
    
            /**
             * Converts this ASRVendors to JSON.
             * @function toJSON
             * @memberof vibe.ASRVendors
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ASRVendors.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ASRVendors
             * @function getTypeUrl
             * @memberof vibe.ASRVendors
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ASRVendors.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.ASRVendors";
            };
    
            ASRVendors.I2XASRConfig = (function() {
    
                /**
                 * Properties of a I2XASRConfig.
                 * @memberof vibe.ASRVendors
                 * @interface II2XASRConfig
                 * @property {string|null} [lingwareTopic] I2XASRConfig lingwareTopic
                 * @property {vibe.ASRVendors.I2XASRConfig.ILingwareVersion|null} [lingwareVersion] I2XASRConfig lingwareVersion
                 */
    
                /**
                 * Constructs a new I2XASRConfig.
                 * @memberof vibe.ASRVendors
                 * @classdesc Represents a I2XASRConfig.
                 * @implements II2XASRConfig
                 * @constructor
                 * @param {vibe.ASRVendors.II2XASRConfig=} [properties] Properties to set
                 */
                function I2XASRConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * I2XASRConfig lingwareTopic.
                 * @member {string} lingwareTopic
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @instance
                 */
                I2XASRConfig.prototype.lingwareTopic = "";
    
                /**
                 * I2XASRConfig lingwareVersion.
                 * @member {vibe.ASRVendors.I2XASRConfig.ILingwareVersion|null|undefined} lingwareVersion
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @instance
                 */
                I2XASRConfig.prototype.lingwareVersion = null;
    
                /**
                 * Creates a new I2XASRConfig instance using the specified properties.
                 * @function create
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @static
                 * @param {vibe.ASRVendors.II2XASRConfig=} [properties] Properties to set
                 * @returns {vibe.ASRVendors.I2XASRConfig} I2XASRConfig instance
                 */
                I2XASRConfig.create = function create(properties) {
                    return new I2XASRConfig(properties);
                };
    
                /**
                 * Encodes the specified I2XASRConfig message. Does not implicitly {@link vibe.ASRVendors.I2XASRConfig.verify|verify} messages.
                 * @function encode
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @static
                 * @param {vibe.ASRVendors.II2XASRConfig} message I2XASRConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                I2XASRConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.lingwareTopic != null && Object.hasOwnProperty.call(message, "lingwareTopic"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lingwareTopic);
                    if (message.lingwareVersion != null && Object.hasOwnProperty.call(message, "lingwareVersion"))
                        $root.vibe.ASRVendors.I2XASRConfig.LingwareVersion.encode(message.lingwareVersion, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified I2XASRConfig message, length delimited. Does not implicitly {@link vibe.ASRVendors.I2XASRConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @static
                 * @param {vibe.ASRVendors.II2XASRConfig} message I2XASRConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                I2XASRConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a I2XASRConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {vibe.ASRVendors.I2XASRConfig} I2XASRConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                I2XASRConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ASRVendors.I2XASRConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.lingwareTopic = reader.string();
                                break;
                            }
                        case 3: {
                                message.lingwareVersion = $root.vibe.ASRVendors.I2XASRConfig.LingwareVersion.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a I2XASRConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {vibe.ASRVendors.I2XASRConfig} I2XASRConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                I2XASRConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a I2XASRConfig message.
                 * @function verify
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                I2XASRConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.lingwareTopic != null && message.hasOwnProperty("lingwareTopic"))
                        if (!$util.isString(message.lingwareTopic))
                            return "lingwareTopic: string expected";
                    if (message.lingwareVersion != null && message.hasOwnProperty("lingwareVersion")) {
                        var error = $root.vibe.ASRVendors.I2XASRConfig.LingwareVersion.verify(message.lingwareVersion);
                        if (error)
                            return "lingwareVersion." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a I2XASRConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {vibe.ASRVendors.I2XASRConfig} I2XASRConfig
                 */
                I2XASRConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.vibe.ASRVendors.I2XASRConfig)
                        return object;
                    var message = new $root.vibe.ASRVendors.I2XASRConfig();
                    if (object.lingwareTopic != null)
                        message.lingwareTopic = String(object.lingwareTopic);
                    if (object.lingwareVersion != null) {
                        if (typeof object.lingwareVersion !== "object")
                            throw TypeError(".vibe.ASRVendors.I2XASRConfig.lingwareVersion: object expected");
                        message.lingwareVersion = $root.vibe.ASRVendors.I2XASRConfig.LingwareVersion.fromObject(object.lingwareVersion);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a I2XASRConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @static
                 * @param {vibe.ASRVendors.I2XASRConfig} message I2XASRConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                I2XASRConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.lingwareTopic = "";
                        object.lingwareVersion = null;
                    }
                    if (message.lingwareTopic != null && message.hasOwnProperty("lingwareTopic"))
                        object.lingwareTopic = message.lingwareTopic;
                    if (message.lingwareVersion != null && message.hasOwnProperty("lingwareVersion"))
                        object.lingwareVersion = $root.vibe.ASRVendors.I2XASRConfig.LingwareVersion.toObject(message.lingwareVersion, options);
                    return object;
                };
    
                /**
                 * Converts this I2XASRConfig to JSON.
                 * @function toJSON
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                I2XASRConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for I2XASRConfig
                 * @function getTypeUrl
                 * @memberof vibe.ASRVendors.I2XASRConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                I2XASRConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/vibe.ASRVendors.I2XASRConfig";
                };
    
                I2XASRConfig.LingwareVersion = (function() {
    
                    /**
                     * Properties of a LingwareVersion.
                     * @memberof vibe.ASRVendors.I2XASRConfig
                     * @interface ILingwareVersion
                     * @property {boolean|null} [isSpecified] LingwareVersion isSpecified
                     * @property {number|null} [major] LingwareVersion major
                     * @property {number|null} [minor] LingwareVersion minor
                     * @property {number|null} [patch] LingwareVersion patch
                     */
    
                    /**
                     * Constructs a new LingwareVersion.
                     * @memberof vibe.ASRVendors.I2XASRConfig
                     * @classdesc Represents a LingwareVersion.
                     * @implements ILingwareVersion
                     * @constructor
                     * @param {vibe.ASRVendors.I2XASRConfig.ILingwareVersion=} [properties] Properties to set
                     */
                    function LingwareVersion(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * LingwareVersion isSpecified.
                     * @member {boolean} isSpecified
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @instance
                     */
                    LingwareVersion.prototype.isSpecified = false;
    
                    /**
                     * LingwareVersion major.
                     * @member {number} major
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @instance
                     */
                    LingwareVersion.prototype.major = 0;
    
                    /**
                     * LingwareVersion minor.
                     * @member {number} minor
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @instance
                     */
                    LingwareVersion.prototype.minor = 0;
    
                    /**
                     * LingwareVersion patch.
                     * @member {number} patch
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @instance
                     */
                    LingwareVersion.prototype.patch = 0;
    
                    /**
                     * Creates a new LingwareVersion instance using the specified properties.
                     * @function create
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @static
                     * @param {vibe.ASRVendors.I2XASRConfig.ILingwareVersion=} [properties] Properties to set
                     * @returns {vibe.ASRVendors.I2XASRConfig.LingwareVersion} LingwareVersion instance
                     */
                    LingwareVersion.create = function create(properties) {
                        return new LingwareVersion(properties);
                    };
    
                    /**
                     * Encodes the specified LingwareVersion message. Does not implicitly {@link vibe.ASRVendors.I2XASRConfig.LingwareVersion.verify|verify} messages.
                     * @function encode
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @static
                     * @param {vibe.ASRVendors.I2XASRConfig.ILingwareVersion} message LingwareVersion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LingwareVersion.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.isSpecified != null && Object.hasOwnProperty.call(message, "isSpecified"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isSpecified);
                        if (message.major != null && Object.hasOwnProperty.call(message, "major"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.major);
                        if (message.minor != null && Object.hasOwnProperty.call(message, "minor"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.minor);
                        if (message.patch != null && Object.hasOwnProperty.call(message, "patch"))
                            writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.patch);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified LingwareVersion message, length delimited. Does not implicitly {@link vibe.ASRVendors.I2XASRConfig.LingwareVersion.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @static
                     * @param {vibe.ASRVendors.I2XASRConfig.ILingwareVersion} message LingwareVersion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LingwareVersion.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a LingwareVersion message from the specified reader or buffer.
                     * @function decode
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {vibe.ASRVendors.I2XASRConfig.LingwareVersion} LingwareVersion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LingwareVersion.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ASRVendors.I2XASRConfig.LingwareVersion();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.isSpecified = reader.bool();
                                    break;
                                }
                            case 2: {
                                    message.major = reader.uint32();
                                    break;
                                }
                            case 3: {
                                    message.minor = reader.uint32();
                                    break;
                                }
                            case 4: {
                                    message.patch = reader.uint32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a LingwareVersion message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {vibe.ASRVendors.I2XASRConfig.LingwareVersion} LingwareVersion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LingwareVersion.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a LingwareVersion message.
                     * @function verify
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    LingwareVersion.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isSpecified != null && message.hasOwnProperty("isSpecified"))
                            if (typeof message.isSpecified !== "boolean")
                                return "isSpecified: boolean expected";
                        if (message.major != null && message.hasOwnProperty("major"))
                            if (!$util.isInteger(message.major))
                                return "major: integer expected";
                        if (message.minor != null && message.hasOwnProperty("minor"))
                            if (!$util.isInteger(message.minor))
                                return "minor: integer expected";
                        if (message.patch != null && message.hasOwnProperty("patch"))
                            if (!$util.isInteger(message.patch))
                                return "patch: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a LingwareVersion message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {vibe.ASRVendors.I2XASRConfig.LingwareVersion} LingwareVersion
                     */
                    LingwareVersion.fromObject = function fromObject(object) {
                        if (object instanceof $root.vibe.ASRVendors.I2XASRConfig.LingwareVersion)
                            return object;
                        var message = new $root.vibe.ASRVendors.I2XASRConfig.LingwareVersion();
                        if (object.isSpecified != null)
                            message.isSpecified = Boolean(object.isSpecified);
                        if (object.major != null)
                            message.major = object.major >>> 0;
                        if (object.minor != null)
                            message.minor = object.minor >>> 0;
                        if (object.patch != null)
                            message.patch = object.patch >>> 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a LingwareVersion message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @static
                     * @param {vibe.ASRVendors.I2XASRConfig.LingwareVersion} message LingwareVersion
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LingwareVersion.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.isSpecified = false;
                            object.major = 0;
                            object.minor = 0;
                            object.patch = 0;
                        }
                        if (message.isSpecified != null && message.hasOwnProperty("isSpecified"))
                            object.isSpecified = message.isSpecified;
                        if (message.major != null && message.hasOwnProperty("major"))
                            object.major = message.major;
                        if (message.minor != null && message.hasOwnProperty("minor"))
                            object.minor = message.minor;
                        if (message.patch != null && message.hasOwnProperty("patch"))
                            object.patch = message.patch;
                        return object;
                    };
    
                    /**
                     * Converts this LingwareVersion to JSON.
                     * @function toJSON
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LingwareVersion.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for LingwareVersion
                     * @function getTypeUrl
                     * @memberof vibe.ASRVendors.I2XASRConfig.LingwareVersion
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    LingwareVersion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/vibe.ASRVendors.I2XASRConfig.LingwareVersion";
                    };
    
                    return LingwareVersion;
                })();
    
                return I2XASRConfig;
            })();
    
            ASRVendors.GoogleASRConfig = (function() {
    
                /**
                 * Properties of a GoogleASRConfig.
                 * @memberof vibe.ASRVendors
                 * @interface IGoogleASRConfig
                 */
    
                /**
                 * Constructs a new GoogleASRConfig.
                 * @memberof vibe.ASRVendors
                 * @classdesc Represents a GoogleASRConfig.
                 * @implements IGoogleASRConfig
                 * @constructor
                 * @param {vibe.ASRVendors.IGoogleASRConfig=} [properties] Properties to set
                 */
                function GoogleASRConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GoogleASRConfig instance using the specified properties.
                 * @function create
                 * @memberof vibe.ASRVendors.GoogleASRConfig
                 * @static
                 * @param {vibe.ASRVendors.IGoogleASRConfig=} [properties] Properties to set
                 * @returns {vibe.ASRVendors.GoogleASRConfig} GoogleASRConfig instance
                 */
                GoogleASRConfig.create = function create(properties) {
                    return new GoogleASRConfig(properties);
                };
    
                /**
                 * Encodes the specified GoogleASRConfig message. Does not implicitly {@link vibe.ASRVendors.GoogleASRConfig.verify|verify} messages.
                 * @function encode
                 * @memberof vibe.ASRVendors.GoogleASRConfig
                 * @static
                 * @param {vibe.ASRVendors.IGoogleASRConfig} message GoogleASRConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GoogleASRConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GoogleASRConfig message, length delimited. Does not implicitly {@link vibe.ASRVendors.GoogleASRConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof vibe.ASRVendors.GoogleASRConfig
                 * @static
                 * @param {vibe.ASRVendors.IGoogleASRConfig} message GoogleASRConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GoogleASRConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GoogleASRConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof vibe.ASRVendors.GoogleASRConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {vibe.ASRVendors.GoogleASRConfig} GoogleASRConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GoogleASRConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ASRVendors.GoogleASRConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GoogleASRConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof vibe.ASRVendors.GoogleASRConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {vibe.ASRVendors.GoogleASRConfig} GoogleASRConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GoogleASRConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GoogleASRConfig message.
                 * @function verify
                 * @memberof vibe.ASRVendors.GoogleASRConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GoogleASRConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GoogleASRConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof vibe.ASRVendors.GoogleASRConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {vibe.ASRVendors.GoogleASRConfig} GoogleASRConfig
                 */
                GoogleASRConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.vibe.ASRVendors.GoogleASRConfig)
                        return object;
                    return new $root.vibe.ASRVendors.GoogleASRConfig();
                };
    
                /**
                 * Creates a plain object from a GoogleASRConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof vibe.ASRVendors.GoogleASRConfig
                 * @static
                 * @param {vibe.ASRVendors.GoogleASRConfig} message GoogleASRConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GoogleASRConfig.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GoogleASRConfig to JSON.
                 * @function toJSON
                 * @memberof vibe.ASRVendors.GoogleASRConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GoogleASRConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GoogleASRConfig
                 * @function getTypeUrl
                 * @memberof vibe.ASRVendors.GoogleASRConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GoogleASRConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/vibe.ASRVendors.GoogleASRConfig";
                };
    
                return GoogleASRConfig;
            })();
    
            ASRVendors.RivaASRConfig = (function() {
    
                /**
                 * Properties of a RivaASRConfig.
                 * @memberof vibe.ASRVendors
                 * @interface IRivaASRConfig
                 * @property {Array.<vibe.ASRVendors.RivaASRConfig.ISpeechContext>|null} [speechContexts] RivaASRConfig speechContexts
                 */
    
                /**
                 * Constructs a new RivaASRConfig.
                 * @memberof vibe.ASRVendors
                 * @classdesc Represents a RivaASRConfig.
                 * @implements IRivaASRConfig
                 * @constructor
                 * @param {vibe.ASRVendors.IRivaASRConfig=} [properties] Properties to set
                 */
                function RivaASRConfig(properties) {
                    this.speechContexts = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RivaASRConfig speechContexts.
                 * @member {Array.<vibe.ASRVendors.RivaASRConfig.ISpeechContext>} speechContexts
                 * @memberof vibe.ASRVendors.RivaASRConfig
                 * @instance
                 */
                RivaASRConfig.prototype.speechContexts = $util.emptyArray;
    
                /**
                 * Creates a new RivaASRConfig instance using the specified properties.
                 * @function create
                 * @memberof vibe.ASRVendors.RivaASRConfig
                 * @static
                 * @param {vibe.ASRVendors.IRivaASRConfig=} [properties] Properties to set
                 * @returns {vibe.ASRVendors.RivaASRConfig} RivaASRConfig instance
                 */
                RivaASRConfig.create = function create(properties) {
                    return new RivaASRConfig(properties);
                };
    
                /**
                 * Encodes the specified RivaASRConfig message. Does not implicitly {@link vibe.ASRVendors.RivaASRConfig.verify|verify} messages.
                 * @function encode
                 * @memberof vibe.ASRVendors.RivaASRConfig
                 * @static
                 * @param {vibe.ASRVendors.IRivaASRConfig} message RivaASRConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RivaASRConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.speechContexts != null && message.speechContexts.length)
                        for (var i = 0; i < message.speechContexts.length; ++i)
                            $root.vibe.ASRVendors.RivaASRConfig.SpeechContext.encode(message.speechContexts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified RivaASRConfig message, length delimited. Does not implicitly {@link vibe.ASRVendors.RivaASRConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof vibe.ASRVendors.RivaASRConfig
                 * @static
                 * @param {vibe.ASRVendors.IRivaASRConfig} message RivaASRConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RivaASRConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RivaASRConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof vibe.ASRVendors.RivaASRConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {vibe.ASRVendors.RivaASRConfig} RivaASRConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RivaASRConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ASRVendors.RivaASRConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                if (!(message.speechContexts && message.speechContexts.length))
                                    message.speechContexts = [];
                                message.speechContexts.push($root.vibe.ASRVendors.RivaASRConfig.SpeechContext.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RivaASRConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof vibe.ASRVendors.RivaASRConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {vibe.ASRVendors.RivaASRConfig} RivaASRConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RivaASRConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RivaASRConfig message.
                 * @function verify
                 * @memberof vibe.ASRVendors.RivaASRConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RivaASRConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.speechContexts != null && message.hasOwnProperty("speechContexts")) {
                        if (!Array.isArray(message.speechContexts))
                            return "speechContexts: array expected";
                        for (var i = 0; i < message.speechContexts.length; ++i) {
                            var error = $root.vibe.ASRVendors.RivaASRConfig.SpeechContext.verify(message.speechContexts[i]);
                            if (error)
                                return "speechContexts." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a RivaASRConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof vibe.ASRVendors.RivaASRConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {vibe.ASRVendors.RivaASRConfig} RivaASRConfig
                 */
                RivaASRConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.vibe.ASRVendors.RivaASRConfig)
                        return object;
                    var message = new $root.vibe.ASRVendors.RivaASRConfig();
                    if (object.speechContexts) {
                        if (!Array.isArray(object.speechContexts))
                            throw TypeError(".vibe.ASRVendors.RivaASRConfig.speechContexts: array expected");
                        message.speechContexts = [];
                        for (var i = 0; i < object.speechContexts.length; ++i) {
                            if (typeof object.speechContexts[i] !== "object")
                                throw TypeError(".vibe.ASRVendors.RivaASRConfig.speechContexts: object expected");
                            message.speechContexts[i] = $root.vibe.ASRVendors.RivaASRConfig.SpeechContext.fromObject(object.speechContexts[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RivaASRConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof vibe.ASRVendors.RivaASRConfig
                 * @static
                 * @param {vibe.ASRVendors.RivaASRConfig} message RivaASRConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RivaASRConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.speechContexts = [];
                    if (message.speechContexts && message.speechContexts.length) {
                        object.speechContexts = [];
                        for (var j = 0; j < message.speechContexts.length; ++j)
                            object.speechContexts[j] = $root.vibe.ASRVendors.RivaASRConfig.SpeechContext.toObject(message.speechContexts[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this RivaASRConfig to JSON.
                 * @function toJSON
                 * @memberof vibe.ASRVendors.RivaASRConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RivaASRConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RivaASRConfig
                 * @function getTypeUrl
                 * @memberof vibe.ASRVendors.RivaASRConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RivaASRConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/vibe.ASRVendors.RivaASRConfig";
                };
    
                RivaASRConfig.SpeechContext = (function() {
    
                    /**
                     * Properties of a SpeechContext.
                     * @memberof vibe.ASRVendors.RivaASRConfig
                     * @interface ISpeechContext
                     * @property {Array.<string>|null} [phrases] SpeechContext phrases
                     * @property {number|null} [boost] SpeechContext boost
                     */
    
                    /**
                     * Constructs a new SpeechContext.
                     * @memberof vibe.ASRVendors.RivaASRConfig
                     * @classdesc Represents a SpeechContext.
                     * @implements ISpeechContext
                     * @constructor
                     * @param {vibe.ASRVendors.RivaASRConfig.ISpeechContext=} [properties] Properties to set
                     */
                    function SpeechContext(properties) {
                        this.phrases = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SpeechContext phrases.
                     * @member {Array.<string>} phrases
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @instance
                     */
                    SpeechContext.prototype.phrases = $util.emptyArray;
    
                    /**
                     * SpeechContext boost.
                     * @member {number} boost
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @instance
                     */
                    SpeechContext.prototype.boost = 0;
    
                    /**
                     * Creates a new SpeechContext instance using the specified properties.
                     * @function create
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @static
                     * @param {vibe.ASRVendors.RivaASRConfig.ISpeechContext=} [properties] Properties to set
                     * @returns {vibe.ASRVendors.RivaASRConfig.SpeechContext} SpeechContext instance
                     */
                    SpeechContext.create = function create(properties) {
                        return new SpeechContext(properties);
                    };
    
                    /**
                     * Encodes the specified SpeechContext message. Does not implicitly {@link vibe.ASRVendors.RivaASRConfig.SpeechContext.verify|verify} messages.
                     * @function encode
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @static
                     * @param {vibe.ASRVendors.RivaASRConfig.ISpeechContext} message SpeechContext message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SpeechContext.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.phrases != null && message.phrases.length)
                            for (var i = 0; i < message.phrases.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phrases[i]);
                        if (message.boost != null && Object.hasOwnProperty.call(message, "boost"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.boost);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SpeechContext message, length delimited. Does not implicitly {@link vibe.ASRVendors.RivaASRConfig.SpeechContext.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @static
                     * @param {vibe.ASRVendors.RivaASRConfig.ISpeechContext} message SpeechContext message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SpeechContext.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SpeechContext message from the specified reader or buffer.
                     * @function decode
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {vibe.ASRVendors.RivaASRConfig.SpeechContext} SpeechContext
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SpeechContext.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ASRVendors.RivaASRConfig.SpeechContext();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.phrases && message.phrases.length))
                                        message.phrases = [];
                                    message.phrases.push(reader.string());
                                    break;
                                }
                            case 4: {
                                    message.boost = reader.float();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SpeechContext message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {vibe.ASRVendors.RivaASRConfig.SpeechContext} SpeechContext
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SpeechContext.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SpeechContext message.
                     * @function verify
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SpeechContext.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.phrases != null && message.hasOwnProperty("phrases")) {
                            if (!Array.isArray(message.phrases))
                                return "phrases: array expected";
                            for (var i = 0; i < message.phrases.length; ++i)
                                if (!$util.isString(message.phrases[i]))
                                    return "phrases: string[] expected";
                        }
                        if (message.boost != null && message.hasOwnProperty("boost"))
                            if (typeof message.boost !== "number")
                                return "boost: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a SpeechContext message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {vibe.ASRVendors.RivaASRConfig.SpeechContext} SpeechContext
                     */
                    SpeechContext.fromObject = function fromObject(object) {
                        if (object instanceof $root.vibe.ASRVendors.RivaASRConfig.SpeechContext)
                            return object;
                        var message = new $root.vibe.ASRVendors.RivaASRConfig.SpeechContext();
                        if (object.phrases) {
                            if (!Array.isArray(object.phrases))
                                throw TypeError(".vibe.ASRVendors.RivaASRConfig.SpeechContext.phrases: array expected");
                            message.phrases = [];
                            for (var i = 0; i < object.phrases.length; ++i)
                                message.phrases[i] = String(object.phrases[i]);
                        }
                        if (object.boost != null)
                            message.boost = Number(object.boost);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SpeechContext message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @static
                     * @param {vibe.ASRVendors.RivaASRConfig.SpeechContext} message SpeechContext
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SpeechContext.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.phrases = [];
                        if (options.defaults)
                            object.boost = 0;
                        if (message.phrases && message.phrases.length) {
                            object.phrases = [];
                            for (var j = 0; j < message.phrases.length; ++j)
                                object.phrases[j] = message.phrases[j];
                        }
                        if (message.boost != null && message.hasOwnProperty("boost"))
                            object.boost = options.json && !isFinite(message.boost) ? String(message.boost) : message.boost;
                        return object;
                    };
    
                    /**
                     * Converts this SpeechContext to JSON.
                     * @function toJSON
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SpeechContext.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for SpeechContext
                     * @function getTypeUrl
                     * @memberof vibe.ASRVendors.RivaASRConfig.SpeechContext
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    SpeechContext.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/vibe.ASRVendors.RivaASRConfig.SpeechContext";
                    };
    
                    return SpeechContext;
                })();
    
                return RivaASRConfig;
            })();
    
            return ASRVendors;
        })();
    
        vibe.GrammarHints = (function() {
    
            /**
             * Properties of a GrammarHints.
             * @memberof vibe
             * @interface IGrammarHints
             * @property {Array.<vibe.GrammarHints.ILinearGrammar>|null} [linearGrammars] GrammarHints linearGrammars
             */
    
            /**
             * Constructs a new GrammarHints.
             * @memberof vibe
             * @classdesc Represents a GrammarHints.
             * @implements IGrammarHints
             * @constructor
             * @param {vibe.IGrammarHints=} [properties] Properties to set
             */
            function GrammarHints(properties) {
                this.linearGrammars = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GrammarHints linearGrammars.
             * @member {Array.<vibe.GrammarHints.ILinearGrammar>} linearGrammars
             * @memberof vibe.GrammarHints
             * @instance
             */
            GrammarHints.prototype.linearGrammars = $util.emptyArray;
    
            /**
             * Creates a new GrammarHints instance using the specified properties.
             * @function create
             * @memberof vibe.GrammarHints
             * @static
             * @param {vibe.IGrammarHints=} [properties] Properties to set
             * @returns {vibe.GrammarHints} GrammarHints instance
             */
            GrammarHints.create = function create(properties) {
                return new GrammarHints(properties);
            };
    
            /**
             * Encodes the specified GrammarHints message. Does not implicitly {@link vibe.GrammarHints.verify|verify} messages.
             * @function encode
             * @memberof vibe.GrammarHints
             * @static
             * @param {vibe.IGrammarHints} message GrammarHints message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GrammarHints.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.linearGrammars != null && message.linearGrammars.length)
                    for (var i = 0; i < message.linearGrammars.length; ++i)
                        $root.vibe.GrammarHints.LinearGrammar.encode(message.linearGrammars[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified GrammarHints message, length delimited. Does not implicitly {@link vibe.GrammarHints.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.GrammarHints
             * @static
             * @param {vibe.IGrammarHints} message GrammarHints message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GrammarHints.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GrammarHints message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.GrammarHints
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.GrammarHints} GrammarHints
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GrammarHints.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.GrammarHints();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.linearGrammars && message.linearGrammars.length))
                                message.linearGrammars = [];
                            message.linearGrammars.push($root.vibe.GrammarHints.LinearGrammar.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GrammarHints message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.GrammarHints
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.GrammarHints} GrammarHints
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GrammarHints.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GrammarHints message.
             * @function verify
             * @memberof vibe.GrammarHints
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GrammarHints.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.linearGrammars != null && message.hasOwnProperty("linearGrammars")) {
                    if (!Array.isArray(message.linearGrammars))
                        return "linearGrammars: array expected";
                    for (var i = 0; i < message.linearGrammars.length; ++i) {
                        var error = $root.vibe.GrammarHints.LinearGrammar.verify(message.linearGrammars[i]);
                        if (error)
                            return "linearGrammars." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a GrammarHints message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.GrammarHints
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.GrammarHints} GrammarHints
             */
            GrammarHints.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.GrammarHints)
                    return object;
                var message = new $root.vibe.GrammarHints();
                if (object.linearGrammars) {
                    if (!Array.isArray(object.linearGrammars))
                        throw TypeError(".vibe.GrammarHints.linearGrammars: array expected");
                    message.linearGrammars = [];
                    for (var i = 0; i < object.linearGrammars.length; ++i) {
                        if (typeof object.linearGrammars[i] !== "object")
                            throw TypeError(".vibe.GrammarHints.linearGrammars: object expected");
                        message.linearGrammars[i] = $root.vibe.GrammarHints.LinearGrammar.fromObject(object.linearGrammars[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GrammarHints message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.GrammarHints
             * @static
             * @param {vibe.GrammarHints} message GrammarHints
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GrammarHints.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.linearGrammars = [];
                if (message.linearGrammars && message.linearGrammars.length) {
                    object.linearGrammars = [];
                    for (var j = 0; j < message.linearGrammars.length; ++j)
                        object.linearGrammars[j] = $root.vibe.GrammarHints.LinearGrammar.toObject(message.linearGrammars[j], options);
                }
                return object;
            };
    
            /**
             * Converts this GrammarHints to JSON.
             * @function toJSON
             * @memberof vibe.GrammarHints
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GrammarHints.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GrammarHints
             * @function getTypeUrl
             * @memberof vibe.GrammarHints
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GrammarHints.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.GrammarHints";
            };
    
            GrammarHints.LinearGrammar = (function() {
    
                /**
                 * Properties of a LinearGrammar.
                 * @memberof vibe.GrammarHints
                 * @interface ILinearGrammar
                 * @property {Array.<string>|null} [words] LinearGrammar words
                 */
    
                /**
                 * Constructs a new LinearGrammar.
                 * @memberof vibe.GrammarHints
                 * @classdesc Represents a LinearGrammar.
                 * @implements ILinearGrammar
                 * @constructor
                 * @param {vibe.GrammarHints.ILinearGrammar=} [properties] Properties to set
                 */
                function LinearGrammar(properties) {
                    this.words = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * LinearGrammar words.
                 * @member {Array.<string>} words
                 * @memberof vibe.GrammarHints.LinearGrammar
                 * @instance
                 */
                LinearGrammar.prototype.words = $util.emptyArray;
    
                /**
                 * Creates a new LinearGrammar instance using the specified properties.
                 * @function create
                 * @memberof vibe.GrammarHints.LinearGrammar
                 * @static
                 * @param {vibe.GrammarHints.ILinearGrammar=} [properties] Properties to set
                 * @returns {vibe.GrammarHints.LinearGrammar} LinearGrammar instance
                 */
                LinearGrammar.create = function create(properties) {
                    return new LinearGrammar(properties);
                };
    
                /**
                 * Encodes the specified LinearGrammar message. Does not implicitly {@link vibe.GrammarHints.LinearGrammar.verify|verify} messages.
                 * @function encode
                 * @memberof vibe.GrammarHints.LinearGrammar
                 * @static
                 * @param {vibe.GrammarHints.ILinearGrammar} message LinearGrammar message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LinearGrammar.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.words != null && message.words.length)
                        for (var i = 0; i < message.words.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.words[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified LinearGrammar message, length delimited. Does not implicitly {@link vibe.GrammarHints.LinearGrammar.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof vibe.GrammarHints.LinearGrammar
                 * @static
                 * @param {vibe.GrammarHints.ILinearGrammar} message LinearGrammar message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LinearGrammar.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LinearGrammar message from the specified reader or buffer.
                 * @function decode
                 * @memberof vibe.GrammarHints.LinearGrammar
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {vibe.GrammarHints.LinearGrammar} LinearGrammar
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LinearGrammar.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.GrammarHints.LinearGrammar();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.words && message.words.length))
                                    message.words = [];
                                message.words.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LinearGrammar message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof vibe.GrammarHints.LinearGrammar
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {vibe.GrammarHints.LinearGrammar} LinearGrammar
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LinearGrammar.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LinearGrammar message.
                 * @function verify
                 * @memberof vibe.GrammarHints.LinearGrammar
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LinearGrammar.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.words != null && message.hasOwnProperty("words")) {
                        if (!Array.isArray(message.words))
                            return "words: array expected";
                        for (var i = 0; i < message.words.length; ++i)
                            if (!$util.isString(message.words[i]))
                                return "words: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a LinearGrammar message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof vibe.GrammarHints.LinearGrammar
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {vibe.GrammarHints.LinearGrammar} LinearGrammar
                 */
                LinearGrammar.fromObject = function fromObject(object) {
                    if (object instanceof $root.vibe.GrammarHints.LinearGrammar)
                        return object;
                    var message = new $root.vibe.GrammarHints.LinearGrammar();
                    if (object.words) {
                        if (!Array.isArray(object.words))
                            throw TypeError(".vibe.GrammarHints.LinearGrammar.words: array expected");
                        message.words = [];
                        for (var i = 0; i < object.words.length; ++i)
                            message.words[i] = String(object.words[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a LinearGrammar message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof vibe.GrammarHints.LinearGrammar
                 * @static
                 * @param {vibe.GrammarHints.LinearGrammar} message LinearGrammar
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LinearGrammar.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.words = [];
                    if (message.words && message.words.length) {
                        object.words = [];
                        for (var j = 0; j < message.words.length; ++j)
                            object.words[j] = message.words[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this LinearGrammar to JSON.
                 * @function toJSON
                 * @memberof vibe.GrammarHints.LinearGrammar
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LinearGrammar.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LinearGrammar
                 * @function getTypeUrl
                 * @memberof vibe.GrammarHints.LinearGrammar
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LinearGrammar.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/vibe.GrammarHints.LinearGrammar";
                };
    
                return LinearGrammar;
            })();
    
            return GrammarHints;
        })();
    
        vibe.StreamingRequest = (function() {
    
            /**
             * Properties of a StreamingRequest.
             * @memberof vibe
             * @interface IStreamingRequest
             * @property {vibe.IFinalizationToken|null} [finalizationToken] StreamingRequest finalizationToken
             * @property {vibe.IPhraseSpottingConfig|null} [phraseSpottingConfig] StreamingRequest phraseSpottingConfig
             * @property {vibe.IAudioChunkWithMetadata|null} [audioChunkWithMetadata] StreamingRequest audioChunkWithMetadata
             * @property {vibe.IUCTRequest|null} [uctRequest] StreamingRequest uctRequest
             * @property {vibe.IServiceMessage|null} [serviceMessage] StreamingRequest serviceMessage
             */
    
            /**
             * Constructs a new StreamingRequest.
             * @memberof vibe
             * @classdesc Represents a StreamingRequest.
             * @implements IStreamingRequest
             * @constructor
             * @param {vibe.IStreamingRequest=} [properties] Properties to set
             */
            function StreamingRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StreamingRequest finalizationToken.
             * @member {vibe.IFinalizationToken|null|undefined} finalizationToken
             * @memberof vibe.StreamingRequest
             * @instance
             */
            StreamingRequest.prototype.finalizationToken = null;
    
            /**
             * StreamingRequest phraseSpottingConfig.
             * @member {vibe.IPhraseSpottingConfig|null|undefined} phraseSpottingConfig
             * @memberof vibe.StreamingRequest
             * @instance
             */
            StreamingRequest.prototype.phraseSpottingConfig = null;
    
            /**
             * StreamingRequest audioChunkWithMetadata.
             * @member {vibe.IAudioChunkWithMetadata|null|undefined} audioChunkWithMetadata
             * @memberof vibe.StreamingRequest
             * @instance
             */
            StreamingRequest.prototype.audioChunkWithMetadata = null;
    
            /**
             * StreamingRequest uctRequest.
             * @member {vibe.IUCTRequest|null|undefined} uctRequest
             * @memberof vibe.StreamingRequest
             * @instance
             */
            StreamingRequest.prototype.uctRequest = null;
    
            /**
             * StreamingRequest serviceMessage.
             * @member {vibe.IServiceMessage|null|undefined} serviceMessage
             * @memberof vibe.StreamingRequest
             * @instance
             */
            StreamingRequest.prototype.serviceMessage = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * StreamingRequest streamingRequestType.
             * @member {"finalizationToken"|"phraseSpottingConfig"|"audioChunkWithMetadata"|"uctRequest"|"serviceMessage"|undefined} streamingRequestType
             * @memberof vibe.StreamingRequest
             * @instance
             */
            Object.defineProperty(StreamingRequest.prototype, "streamingRequestType", {
                get: $util.oneOfGetter($oneOfFields = ["finalizationToken", "phraseSpottingConfig", "audioChunkWithMetadata", "uctRequest", "serviceMessage"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new StreamingRequest instance using the specified properties.
             * @function create
             * @memberof vibe.StreamingRequest
             * @static
             * @param {vibe.IStreamingRequest=} [properties] Properties to set
             * @returns {vibe.StreamingRequest} StreamingRequest instance
             */
            StreamingRequest.create = function create(properties) {
                return new StreamingRequest(properties);
            };
    
            /**
             * Encodes the specified StreamingRequest message. Does not implicitly {@link vibe.StreamingRequest.verify|verify} messages.
             * @function encode
             * @memberof vibe.StreamingRequest
             * @static
             * @param {vibe.IStreamingRequest} message StreamingRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StreamingRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.finalizationToken != null && Object.hasOwnProperty.call(message, "finalizationToken"))
                    $root.vibe.FinalizationToken.encode(message.finalizationToken, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.phraseSpottingConfig != null && Object.hasOwnProperty.call(message, "phraseSpottingConfig"))
                    $root.vibe.PhraseSpottingConfig.encode(message.phraseSpottingConfig, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.audioChunkWithMetadata != null && Object.hasOwnProperty.call(message, "audioChunkWithMetadata"))
                    $root.vibe.AudioChunkWithMetadata.encode(message.audioChunkWithMetadata, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.uctRequest != null && Object.hasOwnProperty.call(message, "uctRequest"))
                    $root.vibe.UCTRequest.encode(message.uctRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.serviceMessage != null && Object.hasOwnProperty.call(message, "serviceMessage"))
                    $root.vibe.ServiceMessage.encode(message.serviceMessage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified StreamingRequest message, length delimited. Does not implicitly {@link vibe.StreamingRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.StreamingRequest
             * @static
             * @param {vibe.IStreamingRequest} message StreamingRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StreamingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StreamingRequest message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.StreamingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.StreamingRequest} StreamingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StreamingRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.StreamingRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.finalizationToken = $root.vibe.FinalizationToken.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.phraseSpottingConfig = $root.vibe.PhraseSpottingConfig.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.audioChunkWithMetadata = $root.vibe.AudioChunkWithMetadata.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.uctRequest = $root.vibe.UCTRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.serviceMessage = $root.vibe.ServiceMessage.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StreamingRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.StreamingRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.StreamingRequest} StreamingRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StreamingRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StreamingRequest message.
             * @function verify
             * @memberof vibe.StreamingRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StreamingRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.finalizationToken != null && message.hasOwnProperty("finalizationToken")) {
                    properties.streamingRequestType = 1;
                    {
                        var error = $root.vibe.FinalizationToken.verify(message.finalizationToken);
                        if (error)
                            return "finalizationToken." + error;
                    }
                }
                if (message.phraseSpottingConfig != null && message.hasOwnProperty("phraseSpottingConfig")) {
                    if (properties.streamingRequestType === 1)
                        return "streamingRequestType: multiple values";
                    properties.streamingRequestType = 1;
                    {
                        var error = $root.vibe.PhraseSpottingConfig.verify(message.phraseSpottingConfig);
                        if (error)
                            return "phraseSpottingConfig." + error;
                    }
                }
                if (message.audioChunkWithMetadata != null && message.hasOwnProperty("audioChunkWithMetadata")) {
                    if (properties.streamingRequestType === 1)
                        return "streamingRequestType: multiple values";
                    properties.streamingRequestType = 1;
                    {
                        var error = $root.vibe.AudioChunkWithMetadata.verify(message.audioChunkWithMetadata);
                        if (error)
                            return "audioChunkWithMetadata." + error;
                    }
                }
                if (message.uctRequest != null && message.hasOwnProperty("uctRequest")) {
                    if (properties.streamingRequestType === 1)
                        return "streamingRequestType: multiple values";
                    properties.streamingRequestType = 1;
                    {
                        var error = $root.vibe.UCTRequest.verify(message.uctRequest);
                        if (error)
                            return "uctRequest." + error;
                    }
                }
                if (message.serviceMessage != null && message.hasOwnProperty("serviceMessage")) {
                    if (properties.streamingRequestType === 1)
                        return "streamingRequestType: multiple values";
                    properties.streamingRequestType = 1;
                    {
                        var error = $root.vibe.ServiceMessage.verify(message.serviceMessage);
                        if (error)
                            return "serviceMessage." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a StreamingRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.StreamingRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.StreamingRequest} StreamingRequest
             */
            StreamingRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.StreamingRequest)
                    return object;
                var message = new $root.vibe.StreamingRequest();
                if (object.finalizationToken != null) {
                    if (typeof object.finalizationToken !== "object")
                        throw TypeError(".vibe.StreamingRequest.finalizationToken: object expected");
                    message.finalizationToken = $root.vibe.FinalizationToken.fromObject(object.finalizationToken);
                }
                if (object.phraseSpottingConfig != null) {
                    if (typeof object.phraseSpottingConfig !== "object")
                        throw TypeError(".vibe.StreamingRequest.phraseSpottingConfig: object expected");
                    message.phraseSpottingConfig = $root.vibe.PhraseSpottingConfig.fromObject(object.phraseSpottingConfig);
                }
                if (object.audioChunkWithMetadata != null) {
                    if (typeof object.audioChunkWithMetadata !== "object")
                        throw TypeError(".vibe.StreamingRequest.audioChunkWithMetadata: object expected");
                    message.audioChunkWithMetadata = $root.vibe.AudioChunkWithMetadata.fromObject(object.audioChunkWithMetadata);
                }
                if (object.uctRequest != null) {
                    if (typeof object.uctRequest !== "object")
                        throw TypeError(".vibe.StreamingRequest.uctRequest: object expected");
                    message.uctRequest = $root.vibe.UCTRequest.fromObject(object.uctRequest);
                }
                if (object.serviceMessage != null) {
                    if (typeof object.serviceMessage !== "object")
                        throw TypeError(".vibe.StreamingRequest.serviceMessage: object expected");
                    message.serviceMessage = $root.vibe.ServiceMessage.fromObject(object.serviceMessage);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a StreamingRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.StreamingRequest
             * @static
             * @param {vibe.StreamingRequest} message StreamingRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StreamingRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.finalizationToken != null && message.hasOwnProperty("finalizationToken")) {
                    object.finalizationToken = $root.vibe.FinalizationToken.toObject(message.finalizationToken, options);
                    if (options.oneofs)
                        object.streamingRequestType = "finalizationToken";
                }
                if (message.phraseSpottingConfig != null && message.hasOwnProperty("phraseSpottingConfig")) {
                    object.phraseSpottingConfig = $root.vibe.PhraseSpottingConfig.toObject(message.phraseSpottingConfig, options);
                    if (options.oneofs)
                        object.streamingRequestType = "phraseSpottingConfig";
                }
                if (message.audioChunkWithMetadata != null && message.hasOwnProperty("audioChunkWithMetadata")) {
                    object.audioChunkWithMetadata = $root.vibe.AudioChunkWithMetadata.toObject(message.audioChunkWithMetadata, options);
                    if (options.oneofs)
                        object.streamingRequestType = "audioChunkWithMetadata";
                }
                if (message.uctRequest != null && message.hasOwnProperty("uctRequest")) {
                    object.uctRequest = $root.vibe.UCTRequest.toObject(message.uctRequest, options);
                    if (options.oneofs)
                        object.streamingRequestType = "uctRequest";
                }
                if (message.serviceMessage != null && message.hasOwnProperty("serviceMessage")) {
                    object.serviceMessage = $root.vibe.ServiceMessage.toObject(message.serviceMessage, options);
                    if (options.oneofs)
                        object.streamingRequestType = "serviceMessage";
                }
                return object;
            };
    
            /**
             * Converts this StreamingRequest to JSON.
             * @function toJSON
             * @memberof vibe.StreamingRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StreamingRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StreamingRequest
             * @function getTypeUrl
             * @memberof vibe.StreamingRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StreamingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.StreamingRequest";
            };
    
            return StreamingRequest;
        })();
    
        vibe.AudioChunkWithMetadata = (function() {
    
            /**
             * Properties of an AudioChunkWithMetadata.
             * @memberof vibe
             * @interface IAudioChunkWithMetadata
             * @property {Uint8Array|null} [audioChunk] AudioChunkWithMetadata audioChunk
             * @property {vibe.AudioChunkWithMetadata.Channel|null} [channel] AudioChunkWithMetadata channel
             * @property {number|null} [timestamp] AudioChunkWithMetadata timestamp
             */
    
            /**
             * Constructs a new AudioChunkWithMetadata.
             * @memberof vibe
             * @classdesc Represents an AudioChunkWithMetadata.
             * @implements IAudioChunkWithMetadata
             * @constructor
             * @param {vibe.IAudioChunkWithMetadata=} [properties] Properties to set
             */
            function AudioChunkWithMetadata(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AudioChunkWithMetadata audioChunk.
             * @member {Uint8Array} audioChunk
             * @memberof vibe.AudioChunkWithMetadata
             * @instance
             */
            AudioChunkWithMetadata.prototype.audioChunk = $util.newBuffer([]);
    
            /**
             * AudioChunkWithMetadata channel.
             * @member {vibe.AudioChunkWithMetadata.Channel} channel
             * @memberof vibe.AudioChunkWithMetadata
             * @instance
             */
            AudioChunkWithMetadata.prototype.channel = 0;
    
            /**
             * AudioChunkWithMetadata timestamp.
             * @member {number} timestamp
             * @memberof vibe.AudioChunkWithMetadata
             * @instance
             */
            AudioChunkWithMetadata.prototype.timestamp = 0;
    
            /**
             * Creates a new AudioChunkWithMetadata instance using the specified properties.
             * @function create
             * @memberof vibe.AudioChunkWithMetadata
             * @static
             * @param {vibe.IAudioChunkWithMetadata=} [properties] Properties to set
             * @returns {vibe.AudioChunkWithMetadata} AudioChunkWithMetadata instance
             */
            AudioChunkWithMetadata.create = function create(properties) {
                return new AudioChunkWithMetadata(properties);
            };
    
            /**
             * Encodes the specified AudioChunkWithMetadata message. Does not implicitly {@link vibe.AudioChunkWithMetadata.verify|verify} messages.
             * @function encode
             * @memberof vibe.AudioChunkWithMetadata
             * @static
             * @param {vibe.IAudioChunkWithMetadata} message AudioChunkWithMetadata message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioChunkWithMetadata.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.audioChunk != null && Object.hasOwnProperty.call(message, "audioChunk"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.audioChunk);
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.channel);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.timestamp);
                return writer;
            };
    
            /**
             * Encodes the specified AudioChunkWithMetadata message, length delimited. Does not implicitly {@link vibe.AudioChunkWithMetadata.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.AudioChunkWithMetadata
             * @static
             * @param {vibe.IAudioChunkWithMetadata} message AudioChunkWithMetadata message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioChunkWithMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AudioChunkWithMetadata message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.AudioChunkWithMetadata
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.AudioChunkWithMetadata} AudioChunkWithMetadata
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioChunkWithMetadata.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.AudioChunkWithMetadata();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.audioChunk = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.channel = reader.int32();
                            break;
                        }
                    case 4: {
                            message.timestamp = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AudioChunkWithMetadata message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.AudioChunkWithMetadata
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.AudioChunkWithMetadata} AudioChunkWithMetadata
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioChunkWithMetadata.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AudioChunkWithMetadata message.
             * @function verify
             * @memberof vibe.AudioChunkWithMetadata
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AudioChunkWithMetadata.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.audioChunk != null && message.hasOwnProperty("audioChunk"))
                    if (!(message.audioChunk && typeof message.audioChunk.length === "number" || $util.isString(message.audioChunk)))
                        return "audioChunk: buffer expected";
                if (message.channel != null && message.hasOwnProperty("channel"))
                    switch (message.channel) {
                    default:
                        return "channel: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp))
                        return "timestamp: integer expected";
                return null;
            };
    
            /**
             * Creates an AudioChunkWithMetadata message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.AudioChunkWithMetadata
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.AudioChunkWithMetadata} AudioChunkWithMetadata
             */
            AudioChunkWithMetadata.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.AudioChunkWithMetadata)
                    return object;
                var message = new $root.vibe.AudioChunkWithMetadata();
                if (object.audioChunk != null)
                    if (typeof object.audioChunk === "string")
                        $util.base64.decode(object.audioChunk, message.audioChunk = $util.newBuffer($util.base64.length(object.audioChunk)), 0);
                    else if (object.audioChunk.length >= 0)
                        message.audioChunk = object.audioChunk;
                switch (object.channel) {
                default:
                    if (typeof object.channel === "number") {
                        message.channel = object.channel;
                        break;
                    }
                    break;
                case "UNKNOWN":
                case 0:
                    message.channel = 0;
                    break;
                case "MICROPHONE":
                case 1:
                    message.channel = 1;
                    break;
                case "SYSTEM":
                case 2:
                    message.channel = 2;
                    break;
                }
                if (object.timestamp != null)
                    message.timestamp = object.timestamp >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from an AudioChunkWithMetadata message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.AudioChunkWithMetadata
             * @static
             * @param {vibe.AudioChunkWithMetadata} message AudioChunkWithMetadata
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AudioChunkWithMetadata.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.audioChunk = "";
                    else {
                        object.audioChunk = [];
                        if (options.bytes !== Array)
                            object.audioChunk = $util.newBuffer(object.audioChunk);
                    }
                    object.channel = options.enums === String ? "UNKNOWN" : 0;
                    object.timestamp = 0;
                }
                if (message.audioChunk != null && message.hasOwnProperty("audioChunk"))
                    object.audioChunk = options.bytes === String ? $util.base64.encode(message.audioChunk, 0, message.audioChunk.length) : options.bytes === Array ? Array.prototype.slice.call(message.audioChunk) : message.audioChunk;
                if (message.channel != null && message.hasOwnProperty("channel"))
                    object.channel = options.enums === String ? $root.vibe.AudioChunkWithMetadata.Channel[message.channel] === undefined ? message.channel : $root.vibe.AudioChunkWithMetadata.Channel[message.channel] : message.channel;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    object.timestamp = message.timestamp;
                return object;
            };
    
            /**
             * Converts this AudioChunkWithMetadata to JSON.
             * @function toJSON
             * @memberof vibe.AudioChunkWithMetadata
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AudioChunkWithMetadata.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AudioChunkWithMetadata
             * @function getTypeUrl
             * @memberof vibe.AudioChunkWithMetadata
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AudioChunkWithMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.AudioChunkWithMetadata";
            };
    
            /**
             * Channel enum.
             * @name vibe.AudioChunkWithMetadata.Channel
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} MICROPHONE=1 MICROPHONE value
             * @property {number} SYSTEM=2 SYSTEM value
             */
            AudioChunkWithMetadata.Channel = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "MICROPHONE"] = 1;
                values[valuesById[2] = "SYSTEM"] = 2;
                return values;
            })();
    
            return AudioChunkWithMetadata;
        })();
    
        vibe.ChannelControl = (function() {
    
            /**
             * Properties of a ChannelControl.
             * @memberof vibe
             * @interface IChannelControl
             * @property {vibe.AudioChunkWithMetadata.Channel|null} [channel] ChannelControl channel
             * @property {vibe.ChannelControl.Operation|null} [operation] ChannelControl operation
             */
    
            /**
             * Constructs a new ChannelControl.
             * @memberof vibe
             * @classdesc Represents a ChannelControl.
             * @implements IChannelControl
             * @constructor
             * @param {vibe.IChannelControl=} [properties] Properties to set
             */
            function ChannelControl(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ChannelControl channel.
             * @member {vibe.AudioChunkWithMetadata.Channel} channel
             * @memberof vibe.ChannelControl
             * @instance
             */
            ChannelControl.prototype.channel = 0;
    
            /**
             * ChannelControl operation.
             * @member {vibe.ChannelControl.Operation} operation
             * @memberof vibe.ChannelControl
             * @instance
             */
            ChannelControl.prototype.operation = 0;
    
            /**
             * Creates a new ChannelControl instance using the specified properties.
             * @function create
             * @memberof vibe.ChannelControl
             * @static
             * @param {vibe.IChannelControl=} [properties] Properties to set
             * @returns {vibe.ChannelControl} ChannelControl instance
             */
            ChannelControl.create = function create(properties) {
                return new ChannelControl(properties);
            };
    
            /**
             * Encodes the specified ChannelControl message. Does not implicitly {@link vibe.ChannelControl.verify|verify} messages.
             * @function encode
             * @memberof vibe.ChannelControl
             * @static
             * @param {vibe.IChannelControl} message ChannelControl message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChannelControl.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.channel);
                if (message.operation != null && Object.hasOwnProperty.call(message, "operation"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.operation);
                return writer;
            };
    
            /**
             * Encodes the specified ChannelControl message, length delimited. Does not implicitly {@link vibe.ChannelControl.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.ChannelControl
             * @static
             * @param {vibe.IChannelControl} message ChannelControl message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChannelControl.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ChannelControl message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.ChannelControl
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.ChannelControl} ChannelControl
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChannelControl.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ChannelControl();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.channel = reader.int32();
                            break;
                        }
                    case 2: {
                            message.operation = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ChannelControl message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.ChannelControl
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.ChannelControl} ChannelControl
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChannelControl.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ChannelControl message.
             * @function verify
             * @memberof vibe.ChannelControl
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChannelControl.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.channel != null && message.hasOwnProperty("channel"))
                    switch (message.channel) {
                    default:
                        return "channel: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.operation != null && message.hasOwnProperty("operation"))
                    switch (message.operation) {
                    default:
                        return "operation: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a ChannelControl message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.ChannelControl
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.ChannelControl} ChannelControl
             */
            ChannelControl.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.ChannelControl)
                    return object;
                var message = new $root.vibe.ChannelControl();
                switch (object.channel) {
                default:
                    if (typeof object.channel === "number") {
                        message.channel = object.channel;
                        break;
                    }
                    break;
                case "UNKNOWN":
                case 0:
                    message.channel = 0;
                    break;
                case "MICROPHONE":
                case 1:
                    message.channel = 1;
                    break;
                case "SYSTEM":
                case 2:
                    message.channel = 2;
                    break;
                }
                switch (object.operation) {
                default:
                    if (typeof object.operation === "number") {
                        message.operation = object.operation;
                        break;
                    }
                    break;
                case "FORGET_ALL_DATA":
                case 0:
                    message.operation = 0;
                    break;
                case "FORGET_AUDIO_AND_TRANSCRIPT":
                case 1:
                    message.operation = 1;
                    break;
                case "FORGET_AUDIO":
                case 2:
                    message.operation = 2;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ChannelControl message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.ChannelControl
             * @static
             * @param {vibe.ChannelControl} message ChannelControl
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChannelControl.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.channel = options.enums === String ? "UNKNOWN" : 0;
                    object.operation = options.enums === String ? "FORGET_ALL_DATA" : 0;
                }
                if (message.channel != null && message.hasOwnProperty("channel"))
                    object.channel = options.enums === String ? $root.vibe.AudioChunkWithMetadata.Channel[message.channel] === undefined ? message.channel : $root.vibe.AudioChunkWithMetadata.Channel[message.channel] : message.channel;
                if (message.operation != null && message.hasOwnProperty("operation"))
                    object.operation = options.enums === String ? $root.vibe.ChannelControl.Operation[message.operation] === undefined ? message.operation : $root.vibe.ChannelControl.Operation[message.operation] : message.operation;
                return object;
            };
    
            /**
             * Converts this ChannelControl to JSON.
             * @function toJSON
             * @memberof vibe.ChannelControl
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChannelControl.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ChannelControl
             * @function getTypeUrl
             * @memberof vibe.ChannelControl
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChannelControl.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.ChannelControl";
            };
    
            /**
             * Operation enum.
             * @name vibe.ChannelControl.Operation
             * @enum {number}
             * @property {number} FORGET_ALL_DATA=0 FORGET_ALL_DATA value
             * @property {number} FORGET_AUDIO_AND_TRANSCRIPT=1 FORGET_AUDIO_AND_TRANSCRIPT value
             * @property {number} FORGET_AUDIO=2 FORGET_AUDIO value
             */
            ChannelControl.Operation = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "FORGET_ALL_DATA"] = 0;
                values[valuesById[1] = "FORGET_AUDIO_AND_TRANSCRIPT"] = 1;
                values[valuesById[2] = "FORGET_AUDIO"] = 2;
                return values;
            })();
    
            return ChannelControl;
        })();
    
        vibe.FinalizationToken = (function() {
    
            /**
             * Properties of a FinalizationToken.
             * @memberof vibe
             * @interface IFinalizationToken
             * @property {vibe.FinalizationToken.Reason|null} [reason] FinalizationToken reason
             */
    
            /**
             * Constructs a new FinalizationToken.
             * @memberof vibe
             * @classdesc Represents a FinalizationToken.
             * @implements IFinalizationToken
             * @constructor
             * @param {vibe.IFinalizationToken=} [properties] Properties to set
             */
            function FinalizationToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * FinalizationToken reason.
             * @member {vibe.FinalizationToken.Reason} reason
             * @memberof vibe.FinalizationToken
             * @instance
             */
            FinalizationToken.prototype.reason = 0;
    
            /**
             * Creates a new FinalizationToken instance using the specified properties.
             * @function create
             * @memberof vibe.FinalizationToken
             * @static
             * @param {vibe.IFinalizationToken=} [properties] Properties to set
             * @returns {vibe.FinalizationToken} FinalizationToken instance
             */
            FinalizationToken.create = function create(properties) {
                return new FinalizationToken(properties);
            };
    
            /**
             * Encodes the specified FinalizationToken message. Does not implicitly {@link vibe.FinalizationToken.verify|verify} messages.
             * @function encode
             * @memberof vibe.FinalizationToken
             * @static
             * @param {vibe.IFinalizationToken} message FinalizationToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FinalizationToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.reason);
                return writer;
            };
    
            /**
             * Encodes the specified FinalizationToken message, length delimited. Does not implicitly {@link vibe.FinalizationToken.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.FinalizationToken
             * @static
             * @param {vibe.IFinalizationToken} message FinalizationToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FinalizationToken.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a FinalizationToken message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.FinalizationToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.FinalizationToken} FinalizationToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FinalizationToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.FinalizationToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 4: {
                            message.reason = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a FinalizationToken message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.FinalizationToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.FinalizationToken} FinalizationToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FinalizationToken.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a FinalizationToken message.
             * @function verify
             * @memberof vibe.FinalizationToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FinalizationToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.reason != null && message.hasOwnProperty("reason"))
                    switch (message.reason) {
                    default:
                        return "reason: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a FinalizationToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.FinalizationToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.FinalizationToken} FinalizationToken
             */
            FinalizationToken.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.FinalizationToken)
                    return object;
                var message = new $root.vibe.FinalizationToken();
                switch (object.reason) {
                default:
                    if (typeof object.reason === "number") {
                        message.reason = object.reason;
                        break;
                    }
                    break;
                case "CALL_END_NORMAL":
                case 0:
                    message.reason = 0;
                    break;
                case "CALL_TIMEOUT":
                case 1:
                    message.reason = 1;
                    break;
                case "SESSION_RELOAD_ON_LOST":
                case 2:
                    message.reason = 2;
                    break;
                case "CLIENT_FORCE_RELOAD":
                case 3:
                    message.reason = 3;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a FinalizationToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.FinalizationToken
             * @static
             * @param {vibe.FinalizationToken} message FinalizationToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FinalizationToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.reason = options.enums === String ? "CALL_END_NORMAL" : 0;
                if (message.reason != null && message.hasOwnProperty("reason"))
                    object.reason = options.enums === String ? $root.vibe.FinalizationToken.Reason[message.reason] === undefined ? message.reason : $root.vibe.FinalizationToken.Reason[message.reason] : message.reason;
                return object;
            };
    
            /**
             * Converts this FinalizationToken to JSON.
             * @function toJSON
             * @memberof vibe.FinalizationToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FinalizationToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for FinalizationToken
             * @function getTypeUrl
             * @memberof vibe.FinalizationToken
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FinalizationToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.FinalizationToken";
            };
    
            /**
             * Reason enum.
             * @name vibe.FinalizationToken.Reason
             * @enum {number}
             * @property {number} CALL_END_NORMAL=0 CALL_END_NORMAL value
             * @property {number} CALL_TIMEOUT=1 CALL_TIMEOUT value
             * @property {number} SESSION_RELOAD_ON_LOST=2 SESSION_RELOAD_ON_LOST value
             * @property {number} CLIENT_FORCE_RELOAD=3 CLIENT_FORCE_RELOAD value
             */
            FinalizationToken.Reason = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CALL_END_NORMAL"] = 0;
                values[valuesById[1] = "CALL_TIMEOUT"] = 1;
                values[valuesById[2] = "SESSION_RELOAD_ON_LOST"] = 2;
                values[valuesById[3] = "CLIENT_FORCE_RELOAD"] = 3;
                return values;
            })();
    
            return FinalizationToken;
        })();
    
        vibe.PhraseSpottingConfig = (function() {
    
            /**
             * Properties of a PhraseSpottingConfig.
             * @memberof vibe
             * @interface IPhraseSpottingConfig
             * @property {Array.<vibe.IPhrase>|null} [whitelist] PhraseSpottingConfig whitelist
             * @property {Array.<vibe.IPhrase>|null} [blacklist] PhraseSpottingConfig blacklist
             */
    
            /**
             * Constructs a new PhraseSpottingConfig.
             * @memberof vibe
             * @classdesc Represents a PhraseSpottingConfig.
             * @implements IPhraseSpottingConfig
             * @constructor
             * @param {vibe.IPhraseSpottingConfig=} [properties] Properties to set
             */
            function PhraseSpottingConfig(properties) {
                this.whitelist = [];
                this.blacklist = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PhraseSpottingConfig whitelist.
             * @member {Array.<vibe.IPhrase>} whitelist
             * @memberof vibe.PhraseSpottingConfig
             * @instance
             */
            PhraseSpottingConfig.prototype.whitelist = $util.emptyArray;
    
            /**
             * PhraseSpottingConfig blacklist.
             * @member {Array.<vibe.IPhrase>} blacklist
             * @memberof vibe.PhraseSpottingConfig
             * @instance
             */
            PhraseSpottingConfig.prototype.blacklist = $util.emptyArray;
    
            /**
             * Creates a new PhraseSpottingConfig instance using the specified properties.
             * @function create
             * @memberof vibe.PhraseSpottingConfig
             * @static
             * @param {vibe.IPhraseSpottingConfig=} [properties] Properties to set
             * @returns {vibe.PhraseSpottingConfig} PhraseSpottingConfig instance
             */
            PhraseSpottingConfig.create = function create(properties) {
                return new PhraseSpottingConfig(properties);
            };
    
            /**
             * Encodes the specified PhraseSpottingConfig message. Does not implicitly {@link vibe.PhraseSpottingConfig.verify|verify} messages.
             * @function encode
             * @memberof vibe.PhraseSpottingConfig
             * @static
             * @param {vibe.IPhraseSpottingConfig} message PhraseSpottingConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PhraseSpottingConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.whitelist != null && message.whitelist.length)
                    for (var i = 0; i < message.whitelist.length; ++i)
                        $root.vibe.Phrase.encode(message.whitelist[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.blacklist != null && message.blacklist.length)
                    for (var i = 0; i < message.blacklist.length; ++i)
                        $root.vibe.Phrase.encode(message.blacklist[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified PhraseSpottingConfig message, length delimited. Does not implicitly {@link vibe.PhraseSpottingConfig.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.PhraseSpottingConfig
             * @static
             * @param {vibe.IPhraseSpottingConfig} message PhraseSpottingConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PhraseSpottingConfig.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PhraseSpottingConfig message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.PhraseSpottingConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.PhraseSpottingConfig} PhraseSpottingConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PhraseSpottingConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.PhraseSpottingConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            if (!(message.whitelist && message.whitelist.length))
                                message.whitelist = [];
                            message.whitelist.push($root.vibe.Phrase.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            if (!(message.blacklist && message.blacklist.length))
                                message.blacklist = [];
                            message.blacklist.push($root.vibe.Phrase.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PhraseSpottingConfig message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.PhraseSpottingConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.PhraseSpottingConfig} PhraseSpottingConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PhraseSpottingConfig.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PhraseSpottingConfig message.
             * @function verify
             * @memberof vibe.PhraseSpottingConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PhraseSpottingConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.whitelist != null && message.hasOwnProperty("whitelist")) {
                    if (!Array.isArray(message.whitelist))
                        return "whitelist: array expected";
                    for (var i = 0; i < message.whitelist.length; ++i) {
                        var error = $root.vibe.Phrase.verify(message.whitelist[i]);
                        if (error)
                            return "whitelist." + error;
                    }
                }
                if (message.blacklist != null && message.hasOwnProperty("blacklist")) {
                    if (!Array.isArray(message.blacklist))
                        return "blacklist: array expected";
                    for (var i = 0; i < message.blacklist.length; ++i) {
                        var error = $root.vibe.Phrase.verify(message.blacklist[i]);
                        if (error)
                            return "blacklist." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a PhraseSpottingConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.PhraseSpottingConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.PhraseSpottingConfig} PhraseSpottingConfig
             */
            PhraseSpottingConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.PhraseSpottingConfig)
                    return object;
                var message = new $root.vibe.PhraseSpottingConfig();
                if (object.whitelist) {
                    if (!Array.isArray(object.whitelist))
                        throw TypeError(".vibe.PhraseSpottingConfig.whitelist: array expected");
                    message.whitelist = [];
                    for (var i = 0; i < object.whitelist.length; ++i) {
                        if (typeof object.whitelist[i] !== "object")
                            throw TypeError(".vibe.PhraseSpottingConfig.whitelist: object expected");
                        message.whitelist[i] = $root.vibe.Phrase.fromObject(object.whitelist[i]);
                    }
                }
                if (object.blacklist) {
                    if (!Array.isArray(object.blacklist))
                        throw TypeError(".vibe.PhraseSpottingConfig.blacklist: array expected");
                    message.blacklist = [];
                    for (var i = 0; i < object.blacklist.length; ++i) {
                        if (typeof object.blacklist[i] !== "object")
                            throw TypeError(".vibe.PhraseSpottingConfig.blacklist: object expected");
                        message.blacklist[i] = $root.vibe.Phrase.fromObject(object.blacklist[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PhraseSpottingConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.PhraseSpottingConfig
             * @static
             * @param {vibe.PhraseSpottingConfig} message PhraseSpottingConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PhraseSpottingConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.whitelist = [];
                    object.blacklist = [];
                }
                if (message.whitelist && message.whitelist.length) {
                    object.whitelist = [];
                    for (var j = 0; j < message.whitelist.length; ++j)
                        object.whitelist[j] = $root.vibe.Phrase.toObject(message.whitelist[j], options);
                }
                if (message.blacklist && message.blacklist.length) {
                    object.blacklist = [];
                    for (var j = 0; j < message.blacklist.length; ++j)
                        object.blacklist[j] = $root.vibe.Phrase.toObject(message.blacklist[j], options);
                }
                return object;
            };
    
            /**
             * Converts this PhraseSpottingConfig to JSON.
             * @function toJSON
             * @memberof vibe.PhraseSpottingConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PhraseSpottingConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PhraseSpottingConfig
             * @function getTypeUrl
             * @memberof vibe.PhraseSpottingConfig
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PhraseSpottingConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.PhraseSpottingConfig";
            };
    
            return PhraseSpottingConfig;
        })();
    
        vibe.Phrase = (function() {
    
            /**
             * Properties of a Phrase.
             * @memberof vibe
             * @interface IPhrase
             * @property {number|null} [points] Phrase points
             * @property {string|null} [groupName] Phrase groupName
             * @property {Array.<string>|null} [head] Phrase head
             * @property {Array.<string>|null} [complement] Phrase complement
             * @property {boolean|null} [visible] Phrase visible
             * @property {phrase_spotting.SpotterKind|null} [kind] Phrase kind
             */
    
            /**
             * Constructs a new Phrase.
             * @memberof vibe
             * @classdesc Represents a Phrase.
             * @implements IPhrase
             * @constructor
             * @param {vibe.IPhrase=} [properties] Properties to set
             */
            function Phrase(properties) {
                this.head = [];
                this.complement = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Phrase points.
             * @member {number} points
             * @memberof vibe.Phrase
             * @instance
             */
            Phrase.prototype.points = 0;
    
            /**
             * Phrase groupName.
             * @member {string} groupName
             * @memberof vibe.Phrase
             * @instance
             */
            Phrase.prototype.groupName = "";
    
            /**
             * Phrase head.
             * @member {Array.<string>} head
             * @memberof vibe.Phrase
             * @instance
             */
            Phrase.prototype.head = $util.emptyArray;
    
            /**
             * Phrase complement.
             * @member {Array.<string>} complement
             * @memberof vibe.Phrase
             * @instance
             */
            Phrase.prototype.complement = $util.emptyArray;
    
            /**
             * Phrase visible.
             * @member {boolean} visible
             * @memberof vibe.Phrase
             * @instance
             */
            Phrase.prototype.visible = false;
    
            /**
             * Phrase kind.
             * @member {phrase_spotting.SpotterKind} kind
             * @memberof vibe.Phrase
             * @instance
             */
            Phrase.prototype.kind = 0;
    
            /**
             * Creates a new Phrase instance using the specified properties.
             * @function create
             * @memberof vibe.Phrase
             * @static
             * @param {vibe.IPhrase=} [properties] Properties to set
             * @returns {vibe.Phrase} Phrase instance
             */
            Phrase.create = function create(properties) {
                return new Phrase(properties);
            };
    
            /**
             * Encodes the specified Phrase message. Does not implicitly {@link vibe.Phrase.verify|verify} messages.
             * @function encode
             * @memberof vibe.Phrase
             * @static
             * @param {vibe.IPhrase} message Phrase message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Phrase.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.points != null && Object.hasOwnProperty.call(message, "points"))
                    writer.uint32(/* id 9, wireType 1 =*/73).double(message.points);
                if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.groupName);
                if (message.head != null && message.head.length)
                    for (var i = 0; i < message.head.length; ++i)
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.head[i]);
                if (message.complement != null && message.complement.length)
                    for (var i = 0; i < message.complement.length; ++i)
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.complement[i]);
                if (message.visible != null && Object.hasOwnProperty.call(message, "visible"))
                    writer.uint32(/* id 14, wireType 0 =*/112).bool(message.visible);
                if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int32(message.kind);
                return writer;
            };
    
            /**
             * Encodes the specified Phrase message, length delimited. Does not implicitly {@link vibe.Phrase.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.Phrase
             * @static
             * @param {vibe.IPhrase} message Phrase message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Phrase.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Phrase message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.Phrase
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.Phrase} Phrase
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Phrase.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.Phrase();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 9: {
                            message.points = reader.double();
                            break;
                        }
                    case 11: {
                            message.groupName = reader.string();
                            break;
                        }
                    case 12: {
                            if (!(message.head && message.head.length))
                                message.head = [];
                            message.head.push(reader.string());
                            break;
                        }
                    case 13: {
                            if (!(message.complement && message.complement.length))
                                message.complement = [];
                            message.complement.push(reader.string());
                            break;
                        }
                    case 14: {
                            message.visible = reader.bool();
                            break;
                        }
                    case 15: {
                            message.kind = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Phrase message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.Phrase
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.Phrase} Phrase
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Phrase.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Phrase message.
             * @function verify
             * @memberof vibe.Phrase
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Phrase.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.points != null && message.hasOwnProperty("points"))
                    if (typeof message.points !== "number")
                        return "points: number expected";
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    if (!$util.isString(message.groupName))
                        return "groupName: string expected";
                if (message.head != null && message.hasOwnProperty("head")) {
                    if (!Array.isArray(message.head))
                        return "head: array expected";
                    for (var i = 0; i < message.head.length; ++i)
                        if (!$util.isString(message.head[i]))
                            return "head: string[] expected";
                }
                if (message.complement != null && message.hasOwnProperty("complement")) {
                    if (!Array.isArray(message.complement))
                        return "complement: array expected";
                    for (var i = 0; i < message.complement.length; ++i)
                        if (!$util.isString(message.complement[i]))
                            return "complement: string[] expected";
                }
                if (message.visible != null && message.hasOwnProperty("visible"))
                    if (typeof message.visible !== "boolean")
                        return "visible: boolean expected";
                if (message.kind != null && message.hasOwnProperty("kind"))
                    switch (message.kind) {
                    default:
                        return "kind: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a Phrase message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.Phrase
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.Phrase} Phrase
             */
            Phrase.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.Phrase)
                    return object;
                var message = new $root.vibe.Phrase();
                if (object.points != null)
                    message.points = Number(object.points);
                if (object.groupName != null)
                    message.groupName = String(object.groupName);
                if (object.head) {
                    if (!Array.isArray(object.head))
                        throw TypeError(".vibe.Phrase.head: array expected");
                    message.head = [];
                    for (var i = 0; i < object.head.length; ++i)
                        message.head[i] = String(object.head[i]);
                }
                if (object.complement) {
                    if (!Array.isArray(object.complement))
                        throw TypeError(".vibe.Phrase.complement: array expected");
                    message.complement = [];
                    for (var i = 0; i < object.complement.length; ++i)
                        message.complement[i] = String(object.complement[i]);
                }
                if (object.visible != null)
                    message.visible = Boolean(object.visible);
                switch (object.kind) {
                default:
                    if (typeof object.kind === "number") {
                        message.kind = object.kind;
                        break;
                    }
                    break;
                case "UNKNOWN":
                case 0:
                    message.kind = 0;
                    break;
                case "STRICT":
                case 1:
                    message.kind = 1;
                    break;
                case "SEMANTIC":
                case 2:
                    message.kind = 2;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Phrase message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.Phrase
             * @static
             * @param {vibe.Phrase} message Phrase
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Phrase.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.head = [];
                    object.complement = [];
                }
                if (options.defaults) {
                    object.points = 0;
                    object.groupName = "";
                    object.visible = false;
                    object.kind = options.enums === String ? "UNKNOWN" : 0;
                }
                if (message.points != null && message.hasOwnProperty("points"))
                    object.points = options.json && !isFinite(message.points) ? String(message.points) : message.points;
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    object.groupName = message.groupName;
                if (message.head && message.head.length) {
                    object.head = [];
                    for (var j = 0; j < message.head.length; ++j)
                        object.head[j] = message.head[j];
                }
                if (message.complement && message.complement.length) {
                    object.complement = [];
                    for (var j = 0; j < message.complement.length; ++j)
                        object.complement[j] = message.complement[j];
                }
                if (message.visible != null && message.hasOwnProperty("visible"))
                    object.visible = message.visible;
                if (message.kind != null && message.hasOwnProperty("kind"))
                    object.kind = options.enums === String ? $root.phrase_spotting.SpotterKind[message.kind] === undefined ? message.kind : $root.phrase_spotting.SpotterKind[message.kind] : message.kind;
                return object;
            };
    
            /**
             * Converts this Phrase to JSON.
             * @function toJSON
             * @memberof vibe.Phrase
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Phrase.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Phrase
             * @function getTypeUrl
             * @memberof vibe.Phrase
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Phrase.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.Phrase";
            };
    
            return Phrase;
        })();
    
        vibe.RecognizeResponse = (function() {
    
            /**
             * Properties of a RecognizeResponse.
             * @memberof vibe
             * @interface IRecognizeResponse
             * @property {vibe.IResultsMessage|null} [resultsMessage] RecognizeResponse resultsMessage
             * @property {vibe.IServiceMessage|null} [serviceMessage] RecognizeResponse serviceMessage
             */
    
            /**
             * Constructs a new RecognizeResponse.
             * @memberof vibe
             * @classdesc Represents a RecognizeResponse.
             * @implements IRecognizeResponse
             * @constructor
             * @param {vibe.IRecognizeResponse=} [properties] Properties to set
             */
            function RecognizeResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RecognizeResponse resultsMessage.
             * @member {vibe.IResultsMessage|null|undefined} resultsMessage
             * @memberof vibe.RecognizeResponse
             * @instance
             */
            RecognizeResponse.prototype.resultsMessage = null;
    
            /**
             * RecognizeResponse serviceMessage.
             * @member {vibe.IServiceMessage|null|undefined} serviceMessage
             * @memberof vibe.RecognizeResponse
             * @instance
             */
            RecognizeResponse.prototype.serviceMessage = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * RecognizeResponse outputMsgType.
             * @member {"resultsMessage"|"serviceMessage"|undefined} outputMsgType
             * @memberof vibe.RecognizeResponse
             * @instance
             */
            Object.defineProperty(RecognizeResponse.prototype, "outputMsgType", {
                get: $util.oneOfGetter($oneOfFields = ["resultsMessage", "serviceMessage"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new RecognizeResponse instance using the specified properties.
             * @function create
             * @memberof vibe.RecognizeResponse
             * @static
             * @param {vibe.IRecognizeResponse=} [properties] Properties to set
             * @returns {vibe.RecognizeResponse} RecognizeResponse instance
             */
            RecognizeResponse.create = function create(properties) {
                return new RecognizeResponse(properties);
            };
    
            /**
             * Encodes the specified RecognizeResponse message. Does not implicitly {@link vibe.RecognizeResponse.verify|verify} messages.
             * @function encode
             * @memberof vibe.RecognizeResponse
             * @static
             * @param {vibe.IRecognizeResponse} message RecognizeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecognizeResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.resultsMessage != null && Object.hasOwnProperty.call(message, "resultsMessage"))
                    $root.vibe.ResultsMessage.encode(message.resultsMessage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.serviceMessage != null && Object.hasOwnProperty.call(message, "serviceMessage"))
                    $root.vibe.ServiceMessage.encode(message.serviceMessage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RecognizeResponse message, length delimited. Does not implicitly {@link vibe.RecognizeResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.RecognizeResponse
             * @static
             * @param {vibe.IRecognizeResponse} message RecognizeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecognizeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RecognizeResponse message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.RecognizeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.RecognizeResponse} RecognizeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecognizeResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.RecognizeResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.resultsMessage = $root.vibe.ResultsMessage.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.serviceMessage = $root.vibe.ServiceMessage.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RecognizeResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.RecognizeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.RecognizeResponse} RecognizeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecognizeResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RecognizeResponse message.
             * @function verify
             * @memberof vibe.RecognizeResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RecognizeResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.resultsMessage != null && message.hasOwnProperty("resultsMessage")) {
                    properties.outputMsgType = 1;
                    {
                        var error = $root.vibe.ResultsMessage.verify(message.resultsMessage);
                        if (error)
                            return "resultsMessage." + error;
                    }
                }
                if (message.serviceMessage != null && message.hasOwnProperty("serviceMessage")) {
                    if (properties.outputMsgType === 1)
                        return "outputMsgType: multiple values";
                    properties.outputMsgType = 1;
                    {
                        var error = $root.vibe.ServiceMessage.verify(message.serviceMessage);
                        if (error)
                            return "serviceMessage." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a RecognizeResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.RecognizeResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.RecognizeResponse} RecognizeResponse
             */
            RecognizeResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.RecognizeResponse)
                    return object;
                var message = new $root.vibe.RecognizeResponse();
                if (object.resultsMessage != null) {
                    if (typeof object.resultsMessage !== "object")
                        throw TypeError(".vibe.RecognizeResponse.resultsMessage: object expected");
                    message.resultsMessage = $root.vibe.ResultsMessage.fromObject(object.resultsMessage);
                }
                if (object.serviceMessage != null) {
                    if (typeof object.serviceMessage !== "object")
                        throw TypeError(".vibe.RecognizeResponse.serviceMessage: object expected");
                    message.serviceMessage = $root.vibe.ServiceMessage.fromObject(object.serviceMessage);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RecognizeResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.RecognizeResponse
             * @static
             * @param {vibe.RecognizeResponse} message RecognizeResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RecognizeResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.resultsMessage != null && message.hasOwnProperty("resultsMessage")) {
                    object.resultsMessage = $root.vibe.ResultsMessage.toObject(message.resultsMessage, options);
                    if (options.oneofs)
                        object.outputMsgType = "resultsMessage";
                }
                if (message.serviceMessage != null && message.hasOwnProperty("serviceMessage")) {
                    object.serviceMessage = $root.vibe.ServiceMessage.toObject(message.serviceMessage, options);
                    if (options.oneofs)
                        object.outputMsgType = "serviceMessage";
                }
                return object;
            };
    
            /**
             * Converts this RecognizeResponse to JSON.
             * @function toJSON
             * @memberof vibe.RecognizeResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RecognizeResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RecognizeResponse
             * @function getTypeUrl
             * @memberof vibe.RecognizeResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RecognizeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.RecognizeResponse";
            };
    
            return RecognizeResponse;
        })();
    
        vibe.ResultsMessage = (function() {
    
            /**
             * Properties of a ResultsMessage.
             * @memberof vibe
             * @interface IResultsMessage
             * @property {vibe.ISpeechRecognitionResult|null} [asrResult] ResultsMessage asrResult
             * @property {vibe.IVoiceMetrics|null} [voiceMetrics] ResultsMessage voiceMetrics
             * @property {vibe.IMetricsResult|null} [metrics] ResultsMessage metrics
             * @property {vibe.IPhraseSpottingResult|null} [phraseSpottingResult] ResultsMessage phraseSpottingResult
             */
    
            /**
             * Constructs a new ResultsMessage.
             * @memberof vibe
             * @classdesc Represents a ResultsMessage.
             * @implements IResultsMessage
             * @constructor
             * @param {vibe.IResultsMessage=} [properties] Properties to set
             */
            function ResultsMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ResultsMessage asrResult.
             * @member {vibe.ISpeechRecognitionResult|null|undefined} asrResult
             * @memberof vibe.ResultsMessage
             * @instance
             */
            ResultsMessage.prototype.asrResult = null;
    
            /**
             * ResultsMessage voiceMetrics.
             * @member {vibe.IVoiceMetrics|null|undefined} voiceMetrics
             * @memberof vibe.ResultsMessage
             * @instance
             */
            ResultsMessage.prototype.voiceMetrics = null;
    
            /**
             * ResultsMessage metrics.
             * @member {vibe.IMetricsResult|null|undefined} metrics
             * @memberof vibe.ResultsMessage
             * @instance
             */
            ResultsMessage.prototype.metrics = null;
    
            /**
             * ResultsMessage phraseSpottingResult.
             * @member {vibe.IPhraseSpottingResult|null|undefined} phraseSpottingResult
             * @memberof vibe.ResultsMessage
             * @instance
             */
            ResultsMessage.prototype.phraseSpottingResult = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * ResultsMessage resultsMsgType.
             * @member {"asrResult"|"voiceMetrics"|"metrics"|"phraseSpottingResult"|undefined} resultsMsgType
             * @memberof vibe.ResultsMessage
             * @instance
             */
            Object.defineProperty(ResultsMessage.prototype, "resultsMsgType", {
                get: $util.oneOfGetter($oneOfFields = ["asrResult", "voiceMetrics", "metrics", "phraseSpottingResult"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new ResultsMessage instance using the specified properties.
             * @function create
             * @memberof vibe.ResultsMessage
             * @static
             * @param {vibe.IResultsMessage=} [properties] Properties to set
             * @returns {vibe.ResultsMessage} ResultsMessage instance
             */
            ResultsMessage.create = function create(properties) {
                return new ResultsMessage(properties);
            };
    
            /**
             * Encodes the specified ResultsMessage message. Does not implicitly {@link vibe.ResultsMessage.verify|verify} messages.
             * @function encode
             * @memberof vibe.ResultsMessage
             * @static
             * @param {vibe.IResultsMessage} message ResultsMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResultsMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.asrResult != null && Object.hasOwnProperty.call(message, "asrResult"))
                    $root.vibe.SpeechRecognitionResult.encode(message.asrResult, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.voiceMetrics != null && Object.hasOwnProperty.call(message, "voiceMetrics"))
                    $root.vibe.VoiceMetrics.encode(message.voiceMetrics, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.metrics != null && Object.hasOwnProperty.call(message, "metrics"))
                    $root.vibe.MetricsResult.encode(message.metrics, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.phraseSpottingResult != null && Object.hasOwnProperty.call(message, "phraseSpottingResult"))
                    $root.vibe.PhraseSpottingResult.encode(message.phraseSpottingResult, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ResultsMessage message, length delimited. Does not implicitly {@link vibe.ResultsMessage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.ResultsMessage
             * @static
             * @param {vibe.IResultsMessage} message ResultsMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResultsMessage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ResultsMessage message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.ResultsMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.ResultsMessage} ResultsMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResultsMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ResultsMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.asrResult = $root.vibe.SpeechRecognitionResult.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.voiceMetrics = $root.vibe.VoiceMetrics.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.metrics = $root.vibe.MetricsResult.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.phraseSpottingResult = $root.vibe.PhraseSpottingResult.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ResultsMessage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.ResultsMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.ResultsMessage} ResultsMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResultsMessage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ResultsMessage message.
             * @function verify
             * @memberof vibe.ResultsMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResultsMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.asrResult != null && message.hasOwnProperty("asrResult")) {
                    properties.resultsMsgType = 1;
                    {
                        var error = $root.vibe.SpeechRecognitionResult.verify(message.asrResult);
                        if (error)
                            return "asrResult." + error;
                    }
                }
                if (message.voiceMetrics != null && message.hasOwnProperty("voiceMetrics")) {
                    if (properties.resultsMsgType === 1)
                        return "resultsMsgType: multiple values";
                    properties.resultsMsgType = 1;
                    {
                        var error = $root.vibe.VoiceMetrics.verify(message.voiceMetrics);
                        if (error)
                            return "voiceMetrics." + error;
                    }
                }
                if (message.metrics != null && message.hasOwnProperty("metrics")) {
                    if (properties.resultsMsgType === 1)
                        return "resultsMsgType: multiple values";
                    properties.resultsMsgType = 1;
                    {
                        var error = $root.vibe.MetricsResult.verify(message.metrics);
                        if (error)
                            return "metrics." + error;
                    }
                }
                if (message.phraseSpottingResult != null && message.hasOwnProperty("phraseSpottingResult")) {
                    if (properties.resultsMsgType === 1)
                        return "resultsMsgType: multiple values";
                    properties.resultsMsgType = 1;
                    {
                        var error = $root.vibe.PhraseSpottingResult.verify(message.phraseSpottingResult);
                        if (error)
                            return "phraseSpottingResult." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ResultsMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.ResultsMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.ResultsMessage} ResultsMessage
             */
            ResultsMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.ResultsMessage)
                    return object;
                var message = new $root.vibe.ResultsMessage();
                if (object.asrResult != null) {
                    if (typeof object.asrResult !== "object")
                        throw TypeError(".vibe.ResultsMessage.asrResult: object expected");
                    message.asrResult = $root.vibe.SpeechRecognitionResult.fromObject(object.asrResult);
                }
                if (object.voiceMetrics != null) {
                    if (typeof object.voiceMetrics !== "object")
                        throw TypeError(".vibe.ResultsMessage.voiceMetrics: object expected");
                    message.voiceMetrics = $root.vibe.VoiceMetrics.fromObject(object.voiceMetrics);
                }
                if (object.metrics != null) {
                    if (typeof object.metrics !== "object")
                        throw TypeError(".vibe.ResultsMessage.metrics: object expected");
                    message.metrics = $root.vibe.MetricsResult.fromObject(object.metrics);
                }
                if (object.phraseSpottingResult != null) {
                    if (typeof object.phraseSpottingResult !== "object")
                        throw TypeError(".vibe.ResultsMessage.phraseSpottingResult: object expected");
                    message.phraseSpottingResult = $root.vibe.PhraseSpottingResult.fromObject(object.phraseSpottingResult);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ResultsMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.ResultsMessage
             * @static
             * @param {vibe.ResultsMessage} message ResultsMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResultsMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.asrResult != null && message.hasOwnProperty("asrResult")) {
                    object.asrResult = $root.vibe.SpeechRecognitionResult.toObject(message.asrResult, options);
                    if (options.oneofs)
                        object.resultsMsgType = "asrResult";
                }
                if (message.voiceMetrics != null && message.hasOwnProperty("voiceMetrics")) {
                    object.voiceMetrics = $root.vibe.VoiceMetrics.toObject(message.voiceMetrics, options);
                    if (options.oneofs)
                        object.resultsMsgType = "voiceMetrics";
                }
                if (message.metrics != null && message.hasOwnProperty("metrics")) {
                    object.metrics = $root.vibe.MetricsResult.toObject(message.metrics, options);
                    if (options.oneofs)
                        object.resultsMsgType = "metrics";
                }
                if (message.phraseSpottingResult != null && message.hasOwnProperty("phraseSpottingResult")) {
                    object.phraseSpottingResult = $root.vibe.PhraseSpottingResult.toObject(message.phraseSpottingResult, options);
                    if (options.oneofs)
                        object.resultsMsgType = "phraseSpottingResult";
                }
                return object;
            };
    
            /**
             * Converts this ResultsMessage to JSON.
             * @function toJSON
             * @memberof vibe.ResultsMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResultsMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ResultsMessage
             * @function getTypeUrl
             * @memberof vibe.ResultsMessage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResultsMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.ResultsMessage";
            };
    
            return ResultsMessage;
        })();
    
        vibe.SpeechRecognitionResult = (function() {
    
            /**
             * Properties of a SpeechRecognitionResult.
             * @memberof vibe
             * @interface ISpeechRecognitionResult
             * @property {vibe.ISpeechRecognitionResultFinal|null} [final] SpeechRecognitionResult final
             * @property {vibe.ISpeechRecognitionResultPartial|null} [partial] SpeechRecognitionResult partial
             * @property {vibe.ITimings|null} [timings] SpeechRecognitionResult timings
             * @property {vibe.SpeechRecognitionResult.Vendor|null} [vendor] SpeechRecognitionResult vendor
             * @property {number|null} [channel] SpeechRecognitionResult channel
             * @property {string|null} [utteranceUid] SpeechRecognitionResult utteranceUid
             */
    
            /**
             * Constructs a new SpeechRecognitionResult.
             * @memberof vibe
             * @classdesc Represents a SpeechRecognitionResult.
             * @implements ISpeechRecognitionResult
             * @constructor
             * @param {vibe.ISpeechRecognitionResult=} [properties] Properties to set
             */
            function SpeechRecognitionResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SpeechRecognitionResult final.
             * @member {vibe.ISpeechRecognitionResultFinal|null|undefined} final
             * @memberof vibe.SpeechRecognitionResult
             * @instance
             */
            SpeechRecognitionResult.prototype.final = null;
    
            /**
             * SpeechRecognitionResult partial.
             * @member {vibe.ISpeechRecognitionResultPartial|null|undefined} partial
             * @memberof vibe.SpeechRecognitionResult
             * @instance
             */
            SpeechRecognitionResult.prototype.partial = null;
    
            /**
             * SpeechRecognitionResult timings.
             * @member {vibe.ITimings|null|undefined} timings
             * @memberof vibe.SpeechRecognitionResult
             * @instance
             */
            SpeechRecognitionResult.prototype.timings = null;
    
            /**
             * SpeechRecognitionResult vendor.
             * @member {vibe.SpeechRecognitionResult.Vendor} vendor
             * @memberof vibe.SpeechRecognitionResult
             * @instance
             */
            SpeechRecognitionResult.prototype.vendor = 0;
    
            /**
             * SpeechRecognitionResult channel.
             * @member {number} channel
             * @memberof vibe.SpeechRecognitionResult
             * @instance
             */
            SpeechRecognitionResult.prototype.channel = 0;
    
            /**
             * SpeechRecognitionResult utteranceUid.
             * @member {string} utteranceUid
             * @memberof vibe.SpeechRecognitionResult
             * @instance
             */
            SpeechRecognitionResult.prototype.utteranceUid = "";
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * SpeechRecognitionResult asrResultType.
             * @member {"final"|"partial"|undefined} asrResultType
             * @memberof vibe.SpeechRecognitionResult
             * @instance
             */
            Object.defineProperty(SpeechRecognitionResult.prototype, "asrResultType", {
                get: $util.oneOfGetter($oneOfFields = ["final", "partial"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new SpeechRecognitionResult instance using the specified properties.
             * @function create
             * @memberof vibe.SpeechRecognitionResult
             * @static
             * @param {vibe.ISpeechRecognitionResult=} [properties] Properties to set
             * @returns {vibe.SpeechRecognitionResult} SpeechRecognitionResult instance
             */
            SpeechRecognitionResult.create = function create(properties) {
                return new SpeechRecognitionResult(properties);
            };
    
            /**
             * Encodes the specified SpeechRecognitionResult message. Does not implicitly {@link vibe.SpeechRecognitionResult.verify|verify} messages.
             * @function encode
             * @memberof vibe.SpeechRecognitionResult
             * @static
             * @param {vibe.ISpeechRecognitionResult} message SpeechRecognitionResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpeechRecognitionResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.final != null && Object.hasOwnProperty.call(message, "final"))
                    $root.vibe.SpeechRecognitionResultFinal.encode(message.final, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.partial != null && Object.hasOwnProperty.call(message, "partial"))
                    $root.vibe.SpeechRecognitionResultPartial.encode(message.partial, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.timings != null && Object.hasOwnProperty.call(message, "timings"))
                    $root.vibe.Timings.encode(message.timings, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.vendor != null && Object.hasOwnProperty.call(message, "vendor"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.vendor);
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.channel);
                if (message.utteranceUid != null && Object.hasOwnProperty.call(message, "utteranceUid"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.utteranceUid);
                return writer;
            };
    
            /**
             * Encodes the specified SpeechRecognitionResult message, length delimited. Does not implicitly {@link vibe.SpeechRecognitionResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.SpeechRecognitionResult
             * @static
             * @param {vibe.ISpeechRecognitionResult} message SpeechRecognitionResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpeechRecognitionResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SpeechRecognitionResult message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.SpeechRecognitionResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.SpeechRecognitionResult} SpeechRecognitionResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpeechRecognitionResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.SpeechRecognitionResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.final = $root.vibe.SpeechRecognitionResultFinal.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.partial = $root.vibe.SpeechRecognitionResultPartial.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.timings = $root.vibe.Timings.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.vendor = reader.int32();
                            break;
                        }
                    case 6: {
                            message.channel = reader.uint32();
                            break;
                        }
                    case 9: {
                            message.utteranceUid = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SpeechRecognitionResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.SpeechRecognitionResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.SpeechRecognitionResult} SpeechRecognitionResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpeechRecognitionResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SpeechRecognitionResult message.
             * @function verify
             * @memberof vibe.SpeechRecognitionResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SpeechRecognitionResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.final != null && message.hasOwnProperty("final")) {
                    properties.asrResultType = 1;
                    {
                        var error = $root.vibe.SpeechRecognitionResultFinal.verify(message.final);
                        if (error)
                            return "final." + error;
                    }
                }
                if (message.partial != null && message.hasOwnProperty("partial")) {
                    if (properties.asrResultType === 1)
                        return "asrResultType: multiple values";
                    properties.asrResultType = 1;
                    {
                        var error = $root.vibe.SpeechRecognitionResultPartial.verify(message.partial);
                        if (error)
                            return "partial." + error;
                    }
                }
                if (message.timings != null && message.hasOwnProperty("timings")) {
                    var error = $root.vibe.Timings.verify(message.timings);
                    if (error)
                        return "timings." + error;
                }
                if (message.vendor != null && message.hasOwnProperty("vendor"))
                    switch (message.vendor) {
                    default:
                        return "vendor: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.channel != null && message.hasOwnProperty("channel"))
                    if (!$util.isInteger(message.channel))
                        return "channel: integer expected";
                if (message.utteranceUid != null && message.hasOwnProperty("utteranceUid"))
                    if (!$util.isString(message.utteranceUid))
                        return "utteranceUid: string expected";
                return null;
            };
    
            /**
             * Creates a SpeechRecognitionResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.SpeechRecognitionResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.SpeechRecognitionResult} SpeechRecognitionResult
             */
            SpeechRecognitionResult.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.SpeechRecognitionResult)
                    return object;
                var message = new $root.vibe.SpeechRecognitionResult();
                if (object.final != null) {
                    if (typeof object.final !== "object")
                        throw TypeError(".vibe.SpeechRecognitionResult.final: object expected");
                    message.final = $root.vibe.SpeechRecognitionResultFinal.fromObject(object.final);
                }
                if (object.partial != null) {
                    if (typeof object.partial !== "object")
                        throw TypeError(".vibe.SpeechRecognitionResult.partial: object expected");
                    message.partial = $root.vibe.SpeechRecognitionResultPartial.fromObject(object.partial);
                }
                if (object.timings != null) {
                    if (typeof object.timings !== "object")
                        throw TypeError(".vibe.SpeechRecognitionResult.timings: object expected");
                    message.timings = $root.vibe.Timings.fromObject(object.timings);
                }
                switch (object.vendor) {
                default:
                    if (typeof object.vendor === "number") {
                        message.vendor = object.vendor;
                        break;
                    }
                    break;
                case "UNDEFINED":
                case 0:
                    message.vendor = 0;
                    break;
                case "I2X":
                case 1:
                    message.vendor = 1;
                    break;
                case "GOOGLE":
                case 2:
                    message.vendor = 2;
                    break;
                case "RIVA":
                case 3:
                    message.vendor = 3;
                    break;
                }
                if (object.channel != null)
                    message.channel = object.channel >>> 0;
                if (object.utteranceUid != null)
                    message.utteranceUid = String(object.utteranceUid);
                return message;
            };
    
            /**
             * Creates a plain object from a SpeechRecognitionResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.SpeechRecognitionResult
             * @static
             * @param {vibe.SpeechRecognitionResult} message SpeechRecognitionResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SpeechRecognitionResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.timings = null;
                    object.vendor = options.enums === String ? "UNDEFINED" : 0;
                    object.channel = 0;
                    object.utteranceUid = "";
                }
                if (message.final != null && message.hasOwnProperty("final")) {
                    object.final = $root.vibe.SpeechRecognitionResultFinal.toObject(message.final, options);
                    if (options.oneofs)
                        object.asrResultType = "final";
                }
                if (message.partial != null && message.hasOwnProperty("partial")) {
                    object.partial = $root.vibe.SpeechRecognitionResultPartial.toObject(message.partial, options);
                    if (options.oneofs)
                        object.asrResultType = "partial";
                }
                if (message.timings != null && message.hasOwnProperty("timings"))
                    object.timings = $root.vibe.Timings.toObject(message.timings, options);
                if (message.vendor != null && message.hasOwnProperty("vendor"))
                    object.vendor = options.enums === String ? $root.vibe.SpeechRecognitionResult.Vendor[message.vendor] === undefined ? message.vendor : $root.vibe.SpeechRecognitionResult.Vendor[message.vendor] : message.vendor;
                if (message.channel != null && message.hasOwnProperty("channel"))
                    object.channel = message.channel;
                if (message.utteranceUid != null && message.hasOwnProperty("utteranceUid"))
                    object.utteranceUid = message.utteranceUid;
                return object;
            };
    
            /**
             * Converts this SpeechRecognitionResult to JSON.
             * @function toJSON
             * @memberof vibe.SpeechRecognitionResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SpeechRecognitionResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SpeechRecognitionResult
             * @function getTypeUrl
             * @memberof vibe.SpeechRecognitionResult
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SpeechRecognitionResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.SpeechRecognitionResult";
            };
    
            /**
             * Vendor enum.
             * @name vibe.SpeechRecognitionResult.Vendor
             * @enum {number}
             * @property {number} UNDEFINED=0 UNDEFINED value
             * @property {number} I2X=1 I2X value
             * @property {number} GOOGLE=2 GOOGLE value
             * @property {number} RIVA=3 RIVA value
             */
            SpeechRecognitionResult.Vendor = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNDEFINED"] = 0;
                values[valuesById[1] = "I2X"] = 1;
                values[valuesById[2] = "GOOGLE"] = 2;
                values[valuesById[3] = "RIVA"] = 3;
                return values;
            })();
    
            return SpeechRecognitionResult;
        })();
    
        vibe.Timings = (function() {
    
            /**
             * Properties of a Timings.
             * @memberof vibe
             * @interface ITimings
             * @property {number|null} [utteranceId] Timings utteranceId
             * @property {number|null} [responseId] Timings responseId
             * @property {number|null} [utteranceStartTimeMsec] Timings utteranceStartTimeMsec
             * @property {number|null} [currentTimeOffsetMsec] Timings currentTimeOffsetMsec
             */
    
            /**
             * Constructs a new Timings.
             * @memberof vibe
             * @classdesc Represents a Timings.
             * @implements ITimings
             * @constructor
             * @param {vibe.ITimings=} [properties] Properties to set
             */
            function Timings(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Timings utteranceId.
             * @member {number} utteranceId
             * @memberof vibe.Timings
             * @instance
             */
            Timings.prototype.utteranceId = 0;
    
            /**
             * Timings responseId.
             * @member {number} responseId
             * @memberof vibe.Timings
             * @instance
             */
            Timings.prototype.responseId = 0;
    
            /**
             * Timings utteranceStartTimeMsec.
             * @member {number} utteranceStartTimeMsec
             * @memberof vibe.Timings
             * @instance
             */
            Timings.prototype.utteranceStartTimeMsec = 0;
    
            /**
             * Timings currentTimeOffsetMsec.
             * @member {number} currentTimeOffsetMsec
             * @memberof vibe.Timings
             * @instance
             */
            Timings.prototype.currentTimeOffsetMsec = 0;
    
            /**
             * Creates a new Timings instance using the specified properties.
             * @function create
             * @memberof vibe.Timings
             * @static
             * @param {vibe.ITimings=} [properties] Properties to set
             * @returns {vibe.Timings} Timings instance
             */
            Timings.create = function create(properties) {
                return new Timings(properties);
            };
    
            /**
             * Encodes the specified Timings message. Does not implicitly {@link vibe.Timings.verify|verify} messages.
             * @function encode
             * @memberof vibe.Timings
             * @static
             * @param {vibe.ITimings} message Timings message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timings.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.utteranceId != null && Object.hasOwnProperty.call(message, "utteranceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.utteranceId);
                if (message.responseId != null && Object.hasOwnProperty.call(message, "responseId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.responseId);
                if (message.utteranceStartTimeMsec != null && Object.hasOwnProperty.call(message, "utteranceStartTimeMsec"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.utteranceStartTimeMsec);
                if (message.currentTimeOffsetMsec != null && Object.hasOwnProperty.call(message, "currentTimeOffsetMsec"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.currentTimeOffsetMsec);
                return writer;
            };
    
            /**
             * Encodes the specified Timings message, length delimited. Does not implicitly {@link vibe.Timings.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.Timings
             * @static
             * @param {vibe.ITimings} message Timings message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timings.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Timings message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.Timings
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.Timings} Timings
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timings.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.Timings();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.utteranceId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.responseId = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.utteranceStartTimeMsec = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.currentTimeOffsetMsec = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Timings message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.Timings
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.Timings} Timings
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timings.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Timings message.
             * @function verify
             * @memberof vibe.Timings
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timings.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.utteranceId != null && message.hasOwnProperty("utteranceId"))
                    if (!$util.isInteger(message.utteranceId))
                        return "utteranceId: integer expected";
                if (message.responseId != null && message.hasOwnProperty("responseId"))
                    if (!$util.isInteger(message.responseId))
                        return "responseId: integer expected";
                if (message.utteranceStartTimeMsec != null && message.hasOwnProperty("utteranceStartTimeMsec"))
                    if (!$util.isInteger(message.utteranceStartTimeMsec))
                        return "utteranceStartTimeMsec: integer expected";
                if (message.currentTimeOffsetMsec != null && message.hasOwnProperty("currentTimeOffsetMsec"))
                    if (!$util.isInteger(message.currentTimeOffsetMsec))
                        return "currentTimeOffsetMsec: integer expected";
                return null;
            };
    
            /**
             * Creates a Timings message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.Timings
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.Timings} Timings
             */
            Timings.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.Timings)
                    return object;
                var message = new $root.vibe.Timings();
                if (object.utteranceId != null)
                    message.utteranceId = object.utteranceId >>> 0;
                if (object.responseId != null)
                    message.responseId = object.responseId >>> 0;
                if (object.utteranceStartTimeMsec != null)
                    message.utteranceStartTimeMsec = object.utteranceStartTimeMsec >>> 0;
                if (object.currentTimeOffsetMsec != null)
                    message.currentTimeOffsetMsec = object.currentTimeOffsetMsec >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a Timings message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.Timings
             * @static
             * @param {vibe.Timings} message Timings
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timings.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.utteranceId = 0;
                    object.responseId = 0;
                    object.utteranceStartTimeMsec = 0;
                    object.currentTimeOffsetMsec = 0;
                }
                if (message.utteranceId != null && message.hasOwnProperty("utteranceId"))
                    object.utteranceId = message.utteranceId;
                if (message.responseId != null && message.hasOwnProperty("responseId"))
                    object.responseId = message.responseId;
                if (message.utteranceStartTimeMsec != null && message.hasOwnProperty("utteranceStartTimeMsec"))
                    object.utteranceStartTimeMsec = message.utteranceStartTimeMsec;
                if (message.currentTimeOffsetMsec != null && message.hasOwnProperty("currentTimeOffsetMsec"))
                    object.currentTimeOffsetMsec = message.currentTimeOffsetMsec;
                return object;
            };
    
            /**
             * Converts this Timings to JSON.
             * @function toJSON
             * @memberof vibe.Timings
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timings.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Timings
             * @function getTypeUrl
             * @memberof vibe.Timings
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Timings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.Timings";
            };
    
            return Timings;
        })();
    
        vibe.SpeechRecognitionResultFinal = (function() {
    
            /**
             * Properties of a SpeechRecognitionResultFinal.
             * @memberof vibe
             * @interface ISpeechRecognitionResultFinal
             * @property {Array.<vibe.ISpeechRecognitionAlternative>|null} [alternatives] SpeechRecognitionResultFinal alternatives
             * @property {number|null} [realTimeFactor] SpeechRecognitionResultFinal realTimeFactor
             */
    
            /**
             * Constructs a new SpeechRecognitionResultFinal.
             * @memberof vibe
             * @classdesc Represents a SpeechRecognitionResultFinal.
             * @implements ISpeechRecognitionResultFinal
             * @constructor
             * @param {vibe.ISpeechRecognitionResultFinal=} [properties] Properties to set
             */
            function SpeechRecognitionResultFinal(properties) {
                this.alternatives = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SpeechRecognitionResultFinal alternatives.
             * @member {Array.<vibe.ISpeechRecognitionAlternative>} alternatives
             * @memberof vibe.SpeechRecognitionResultFinal
             * @instance
             */
            SpeechRecognitionResultFinal.prototype.alternatives = $util.emptyArray;
    
            /**
             * SpeechRecognitionResultFinal realTimeFactor.
             * @member {number} realTimeFactor
             * @memberof vibe.SpeechRecognitionResultFinal
             * @instance
             */
            SpeechRecognitionResultFinal.prototype.realTimeFactor = 0;
    
            /**
             * Creates a new SpeechRecognitionResultFinal instance using the specified properties.
             * @function create
             * @memberof vibe.SpeechRecognitionResultFinal
             * @static
             * @param {vibe.ISpeechRecognitionResultFinal=} [properties] Properties to set
             * @returns {vibe.SpeechRecognitionResultFinal} SpeechRecognitionResultFinal instance
             */
            SpeechRecognitionResultFinal.create = function create(properties) {
                return new SpeechRecognitionResultFinal(properties);
            };
    
            /**
             * Encodes the specified SpeechRecognitionResultFinal message. Does not implicitly {@link vibe.SpeechRecognitionResultFinal.verify|verify} messages.
             * @function encode
             * @memberof vibe.SpeechRecognitionResultFinal
             * @static
             * @param {vibe.ISpeechRecognitionResultFinal} message SpeechRecognitionResultFinal message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpeechRecognitionResultFinal.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.alternatives != null && message.alternatives.length)
                    for (var i = 0; i < message.alternatives.length; ++i)
                        $root.vibe.SpeechRecognitionAlternative.encode(message.alternatives[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.realTimeFactor != null && Object.hasOwnProperty.call(message, "realTimeFactor"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.realTimeFactor);
                return writer;
            };
    
            /**
             * Encodes the specified SpeechRecognitionResultFinal message, length delimited. Does not implicitly {@link vibe.SpeechRecognitionResultFinal.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.SpeechRecognitionResultFinal
             * @static
             * @param {vibe.ISpeechRecognitionResultFinal} message SpeechRecognitionResultFinal message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpeechRecognitionResultFinal.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SpeechRecognitionResultFinal message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.SpeechRecognitionResultFinal
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.SpeechRecognitionResultFinal} SpeechRecognitionResultFinal
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpeechRecognitionResultFinal.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.SpeechRecognitionResultFinal();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.alternatives && message.alternatives.length))
                                message.alternatives = [];
                            message.alternatives.push($root.vibe.SpeechRecognitionAlternative.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.realTimeFactor = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SpeechRecognitionResultFinal message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.SpeechRecognitionResultFinal
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.SpeechRecognitionResultFinal} SpeechRecognitionResultFinal
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpeechRecognitionResultFinal.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SpeechRecognitionResultFinal message.
             * @function verify
             * @memberof vibe.SpeechRecognitionResultFinal
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SpeechRecognitionResultFinal.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.alternatives != null && message.hasOwnProperty("alternatives")) {
                    if (!Array.isArray(message.alternatives))
                        return "alternatives: array expected";
                    for (var i = 0; i < message.alternatives.length; ++i) {
                        var error = $root.vibe.SpeechRecognitionAlternative.verify(message.alternatives[i]);
                        if (error)
                            return "alternatives." + error;
                    }
                }
                if (message.realTimeFactor != null && message.hasOwnProperty("realTimeFactor"))
                    if (typeof message.realTimeFactor !== "number")
                        return "realTimeFactor: number expected";
                return null;
            };
    
            /**
             * Creates a SpeechRecognitionResultFinal message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.SpeechRecognitionResultFinal
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.SpeechRecognitionResultFinal} SpeechRecognitionResultFinal
             */
            SpeechRecognitionResultFinal.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.SpeechRecognitionResultFinal)
                    return object;
                var message = new $root.vibe.SpeechRecognitionResultFinal();
                if (object.alternatives) {
                    if (!Array.isArray(object.alternatives))
                        throw TypeError(".vibe.SpeechRecognitionResultFinal.alternatives: array expected");
                    message.alternatives = [];
                    for (var i = 0; i < object.alternatives.length; ++i) {
                        if (typeof object.alternatives[i] !== "object")
                            throw TypeError(".vibe.SpeechRecognitionResultFinal.alternatives: object expected");
                        message.alternatives[i] = $root.vibe.SpeechRecognitionAlternative.fromObject(object.alternatives[i]);
                    }
                }
                if (object.realTimeFactor != null)
                    message.realTimeFactor = Number(object.realTimeFactor);
                return message;
            };
    
            /**
             * Creates a plain object from a SpeechRecognitionResultFinal message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.SpeechRecognitionResultFinal
             * @static
             * @param {vibe.SpeechRecognitionResultFinal} message SpeechRecognitionResultFinal
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SpeechRecognitionResultFinal.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.alternatives = [];
                if (options.defaults)
                    object.realTimeFactor = 0;
                if (message.alternatives && message.alternatives.length) {
                    object.alternatives = [];
                    for (var j = 0; j < message.alternatives.length; ++j)
                        object.alternatives[j] = $root.vibe.SpeechRecognitionAlternative.toObject(message.alternatives[j], options);
                }
                if (message.realTimeFactor != null && message.hasOwnProperty("realTimeFactor"))
                    object.realTimeFactor = options.json && !isFinite(message.realTimeFactor) ? String(message.realTimeFactor) : message.realTimeFactor;
                return object;
            };
    
            /**
             * Converts this SpeechRecognitionResultFinal to JSON.
             * @function toJSON
             * @memberof vibe.SpeechRecognitionResultFinal
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SpeechRecognitionResultFinal.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SpeechRecognitionResultFinal
             * @function getTypeUrl
             * @memberof vibe.SpeechRecognitionResultFinal
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SpeechRecognitionResultFinal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.SpeechRecognitionResultFinal";
            };
    
            return SpeechRecognitionResultFinal;
        })();
    
        vibe.StreamContext = (function() {
    
            /**
             * Properties of a StreamContext.
             * @memberof vibe
             * @interface IStreamContext
             * @property {Array.<vibe.ISpeechRecognitionResult>|null} [asrResults] StreamContext asrResults
             */
    
            /**
             * Constructs a new StreamContext.
             * @memberof vibe
             * @classdesc Represents a StreamContext.
             * @implements IStreamContext
             * @constructor
             * @param {vibe.IStreamContext=} [properties] Properties to set
             */
            function StreamContext(properties) {
                this.asrResults = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * StreamContext asrResults.
             * @member {Array.<vibe.ISpeechRecognitionResult>} asrResults
             * @memberof vibe.StreamContext
             * @instance
             */
            StreamContext.prototype.asrResults = $util.emptyArray;
    
            /**
             * Creates a new StreamContext instance using the specified properties.
             * @function create
             * @memberof vibe.StreamContext
             * @static
             * @param {vibe.IStreamContext=} [properties] Properties to set
             * @returns {vibe.StreamContext} StreamContext instance
             */
            StreamContext.create = function create(properties) {
                return new StreamContext(properties);
            };
    
            /**
             * Encodes the specified StreamContext message. Does not implicitly {@link vibe.StreamContext.verify|verify} messages.
             * @function encode
             * @memberof vibe.StreamContext
             * @static
             * @param {vibe.IStreamContext} message StreamContext message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StreamContext.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.asrResults != null && message.asrResults.length)
                    for (var i = 0; i < message.asrResults.length; ++i)
                        $root.vibe.SpeechRecognitionResult.encode(message.asrResults[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified StreamContext message, length delimited. Does not implicitly {@link vibe.StreamContext.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.StreamContext
             * @static
             * @param {vibe.IStreamContext} message StreamContext message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StreamContext.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a StreamContext message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.StreamContext
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.StreamContext} StreamContext
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StreamContext.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.StreamContext();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.asrResults && message.asrResults.length))
                                message.asrResults = [];
                            message.asrResults.push($root.vibe.SpeechRecognitionResult.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a StreamContext message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.StreamContext
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.StreamContext} StreamContext
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StreamContext.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a StreamContext message.
             * @function verify
             * @memberof vibe.StreamContext
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StreamContext.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.asrResults != null && message.hasOwnProperty("asrResults")) {
                    if (!Array.isArray(message.asrResults))
                        return "asrResults: array expected";
                    for (var i = 0; i < message.asrResults.length; ++i) {
                        var error = $root.vibe.SpeechRecognitionResult.verify(message.asrResults[i]);
                        if (error)
                            return "asrResults." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a StreamContext message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.StreamContext
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.StreamContext} StreamContext
             */
            StreamContext.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.StreamContext)
                    return object;
                var message = new $root.vibe.StreamContext();
                if (object.asrResults) {
                    if (!Array.isArray(object.asrResults))
                        throw TypeError(".vibe.StreamContext.asrResults: array expected");
                    message.asrResults = [];
                    for (var i = 0; i < object.asrResults.length; ++i) {
                        if (typeof object.asrResults[i] !== "object")
                            throw TypeError(".vibe.StreamContext.asrResults: object expected");
                        message.asrResults[i] = $root.vibe.SpeechRecognitionResult.fromObject(object.asrResults[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a StreamContext message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.StreamContext
             * @static
             * @param {vibe.StreamContext} message StreamContext
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StreamContext.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.asrResults = [];
                if (message.asrResults && message.asrResults.length) {
                    object.asrResults = [];
                    for (var j = 0; j < message.asrResults.length; ++j)
                        object.asrResults[j] = $root.vibe.SpeechRecognitionResult.toObject(message.asrResults[j], options);
                }
                return object;
            };
    
            /**
             * Converts this StreamContext to JSON.
             * @function toJSON
             * @memberof vibe.StreamContext
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StreamContext.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for StreamContext
             * @function getTypeUrl
             * @memberof vibe.StreamContext
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StreamContext.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.StreamContext";
            };
    
            return StreamContext;
        })();
    
        vibe.SpeechRecognitionResultPartial = (function() {
    
            /**
             * Properties of a SpeechRecognitionResultPartial.
             * @memberof vibe
             * @interface ISpeechRecognitionResultPartial
             * @property {string|null} [transcript] SpeechRecognitionResultPartial transcript
             * @property {number|null} [wordsPerMinute] SpeechRecognitionResultPartial wordsPerMinute
             */
    
            /**
             * Constructs a new SpeechRecognitionResultPartial.
             * @memberof vibe
             * @classdesc Represents a SpeechRecognitionResultPartial.
             * @implements ISpeechRecognitionResultPartial
             * @constructor
             * @param {vibe.ISpeechRecognitionResultPartial=} [properties] Properties to set
             */
            function SpeechRecognitionResultPartial(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SpeechRecognitionResultPartial transcript.
             * @member {string} transcript
             * @memberof vibe.SpeechRecognitionResultPartial
             * @instance
             */
            SpeechRecognitionResultPartial.prototype.transcript = "";
    
            /**
             * SpeechRecognitionResultPartial wordsPerMinute.
             * @member {number} wordsPerMinute
             * @memberof vibe.SpeechRecognitionResultPartial
             * @instance
             */
            SpeechRecognitionResultPartial.prototype.wordsPerMinute = 0;
    
            /**
             * Creates a new SpeechRecognitionResultPartial instance using the specified properties.
             * @function create
             * @memberof vibe.SpeechRecognitionResultPartial
             * @static
             * @param {vibe.ISpeechRecognitionResultPartial=} [properties] Properties to set
             * @returns {vibe.SpeechRecognitionResultPartial} SpeechRecognitionResultPartial instance
             */
            SpeechRecognitionResultPartial.create = function create(properties) {
                return new SpeechRecognitionResultPartial(properties);
            };
    
            /**
             * Encodes the specified SpeechRecognitionResultPartial message. Does not implicitly {@link vibe.SpeechRecognitionResultPartial.verify|verify} messages.
             * @function encode
             * @memberof vibe.SpeechRecognitionResultPartial
             * @static
             * @param {vibe.ISpeechRecognitionResultPartial} message SpeechRecognitionResultPartial message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpeechRecognitionResultPartial.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.transcript != null && Object.hasOwnProperty.call(message, "transcript"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.transcript);
                if (message.wordsPerMinute != null && Object.hasOwnProperty.call(message, "wordsPerMinute"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.wordsPerMinute);
                return writer;
            };
    
            /**
             * Encodes the specified SpeechRecognitionResultPartial message, length delimited. Does not implicitly {@link vibe.SpeechRecognitionResultPartial.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.SpeechRecognitionResultPartial
             * @static
             * @param {vibe.ISpeechRecognitionResultPartial} message SpeechRecognitionResultPartial message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpeechRecognitionResultPartial.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SpeechRecognitionResultPartial message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.SpeechRecognitionResultPartial
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.SpeechRecognitionResultPartial} SpeechRecognitionResultPartial
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpeechRecognitionResultPartial.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.SpeechRecognitionResultPartial();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.transcript = reader.string();
                            break;
                        }
                    case 2: {
                            message.wordsPerMinute = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SpeechRecognitionResultPartial message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.SpeechRecognitionResultPartial
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.SpeechRecognitionResultPartial} SpeechRecognitionResultPartial
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpeechRecognitionResultPartial.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SpeechRecognitionResultPartial message.
             * @function verify
             * @memberof vibe.SpeechRecognitionResultPartial
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SpeechRecognitionResultPartial.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.transcript != null && message.hasOwnProperty("transcript"))
                    if (!$util.isString(message.transcript))
                        return "transcript: string expected";
                if (message.wordsPerMinute != null && message.hasOwnProperty("wordsPerMinute"))
                    if (typeof message.wordsPerMinute !== "number")
                        return "wordsPerMinute: number expected";
                return null;
            };
    
            /**
             * Creates a SpeechRecognitionResultPartial message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.SpeechRecognitionResultPartial
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.SpeechRecognitionResultPartial} SpeechRecognitionResultPartial
             */
            SpeechRecognitionResultPartial.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.SpeechRecognitionResultPartial)
                    return object;
                var message = new $root.vibe.SpeechRecognitionResultPartial();
                if (object.transcript != null)
                    message.transcript = String(object.transcript);
                if (object.wordsPerMinute != null)
                    message.wordsPerMinute = Number(object.wordsPerMinute);
                return message;
            };
    
            /**
             * Creates a plain object from a SpeechRecognitionResultPartial message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.SpeechRecognitionResultPartial
             * @static
             * @param {vibe.SpeechRecognitionResultPartial} message SpeechRecognitionResultPartial
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SpeechRecognitionResultPartial.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.transcript = "";
                    object.wordsPerMinute = 0;
                }
                if (message.transcript != null && message.hasOwnProperty("transcript"))
                    object.transcript = message.transcript;
                if (message.wordsPerMinute != null && message.hasOwnProperty("wordsPerMinute"))
                    object.wordsPerMinute = options.json && !isFinite(message.wordsPerMinute) ? String(message.wordsPerMinute) : message.wordsPerMinute;
                return object;
            };
    
            /**
             * Converts this SpeechRecognitionResultPartial to JSON.
             * @function toJSON
             * @memberof vibe.SpeechRecognitionResultPartial
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SpeechRecognitionResultPartial.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SpeechRecognitionResultPartial
             * @function getTypeUrl
             * @memberof vibe.SpeechRecognitionResultPartial
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SpeechRecognitionResultPartial.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.SpeechRecognitionResultPartial";
            };
    
            return SpeechRecognitionResultPartial;
        })();
    
        vibe.SpeechRecognitionAlternative = (function() {
    
            /**
             * Properties of a SpeechRecognitionAlternative.
             * @memberof vibe
             * @interface ISpeechRecognitionAlternative
             * @property {string|null} [transcript] SpeechRecognitionAlternative transcript
             * @property {number|null} [confidence] SpeechRecognitionAlternative confidence
             * @property {Array.<vibe.IWordInfo>|null} [words] SpeechRecognitionAlternative words
             * @property {number|null} [wordsPerMinute] SpeechRecognitionAlternative wordsPerMinute
             * @property {string|null} [normalizedTranscript] SpeechRecognitionAlternative normalizedTranscript
             * @property {Array.<vibe.INormWordInfo>|null} [normWords] SpeechRecognitionAlternative normWords
             */
    
            /**
             * Constructs a new SpeechRecognitionAlternative.
             * @memberof vibe
             * @classdesc Represents a SpeechRecognitionAlternative.
             * @implements ISpeechRecognitionAlternative
             * @constructor
             * @param {vibe.ISpeechRecognitionAlternative=} [properties] Properties to set
             */
            function SpeechRecognitionAlternative(properties) {
                this.words = [];
                this.normWords = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SpeechRecognitionAlternative transcript.
             * @member {string} transcript
             * @memberof vibe.SpeechRecognitionAlternative
             * @instance
             */
            SpeechRecognitionAlternative.prototype.transcript = "";
    
            /**
             * SpeechRecognitionAlternative confidence.
             * @member {number} confidence
             * @memberof vibe.SpeechRecognitionAlternative
             * @instance
             */
            SpeechRecognitionAlternative.prototype.confidence = 0;
    
            /**
             * SpeechRecognitionAlternative words.
             * @member {Array.<vibe.IWordInfo>} words
             * @memberof vibe.SpeechRecognitionAlternative
             * @instance
             */
            SpeechRecognitionAlternative.prototype.words = $util.emptyArray;
    
            /**
             * SpeechRecognitionAlternative wordsPerMinute.
             * @member {number} wordsPerMinute
             * @memberof vibe.SpeechRecognitionAlternative
             * @instance
             */
            SpeechRecognitionAlternative.prototype.wordsPerMinute = 0;
    
            /**
             * SpeechRecognitionAlternative normalizedTranscript.
             * @member {string} normalizedTranscript
             * @memberof vibe.SpeechRecognitionAlternative
             * @instance
             */
            SpeechRecognitionAlternative.prototype.normalizedTranscript = "";
    
            /**
             * SpeechRecognitionAlternative normWords.
             * @member {Array.<vibe.INormWordInfo>} normWords
             * @memberof vibe.SpeechRecognitionAlternative
             * @instance
             */
            SpeechRecognitionAlternative.prototype.normWords = $util.emptyArray;
    
            /**
             * Creates a new SpeechRecognitionAlternative instance using the specified properties.
             * @function create
             * @memberof vibe.SpeechRecognitionAlternative
             * @static
             * @param {vibe.ISpeechRecognitionAlternative=} [properties] Properties to set
             * @returns {vibe.SpeechRecognitionAlternative} SpeechRecognitionAlternative instance
             */
            SpeechRecognitionAlternative.create = function create(properties) {
                return new SpeechRecognitionAlternative(properties);
            };
    
            /**
             * Encodes the specified SpeechRecognitionAlternative message. Does not implicitly {@link vibe.SpeechRecognitionAlternative.verify|verify} messages.
             * @function encode
             * @memberof vibe.SpeechRecognitionAlternative
             * @static
             * @param {vibe.ISpeechRecognitionAlternative} message SpeechRecognitionAlternative message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpeechRecognitionAlternative.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.transcript != null && Object.hasOwnProperty.call(message, "transcript"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.transcript);
                if (message.confidence != null && Object.hasOwnProperty.call(message, "confidence"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.confidence);
                if (message.words != null && message.words.length)
                    for (var i = 0; i < message.words.length; ++i)
                        $root.vibe.WordInfo.encode(message.words[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.wordsPerMinute != null && Object.hasOwnProperty.call(message, "wordsPerMinute"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.wordsPerMinute);
                if (message.normalizedTranscript != null && Object.hasOwnProperty.call(message, "normalizedTranscript"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.normalizedTranscript);
                if (message.normWords != null && message.normWords.length)
                    for (var i = 0; i < message.normWords.length; ++i)
                        $root.vibe.NormWordInfo.encode(message.normWords[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified SpeechRecognitionAlternative message, length delimited. Does not implicitly {@link vibe.SpeechRecognitionAlternative.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.SpeechRecognitionAlternative
             * @static
             * @param {vibe.ISpeechRecognitionAlternative} message SpeechRecognitionAlternative message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpeechRecognitionAlternative.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SpeechRecognitionAlternative message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.SpeechRecognitionAlternative
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.SpeechRecognitionAlternative} SpeechRecognitionAlternative
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpeechRecognitionAlternative.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.SpeechRecognitionAlternative();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.transcript = reader.string();
                            break;
                        }
                    case 2: {
                            message.confidence = reader.float();
                            break;
                        }
                    case 3: {
                            if (!(message.words && message.words.length))
                                message.words = [];
                            message.words.push($root.vibe.WordInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            message.wordsPerMinute = reader.float();
                            break;
                        }
                    case 5: {
                            message.normalizedTranscript = reader.string();
                            break;
                        }
                    case 6: {
                            if (!(message.normWords && message.normWords.length))
                                message.normWords = [];
                            message.normWords.push($root.vibe.NormWordInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SpeechRecognitionAlternative message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.SpeechRecognitionAlternative
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.SpeechRecognitionAlternative} SpeechRecognitionAlternative
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpeechRecognitionAlternative.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SpeechRecognitionAlternative message.
             * @function verify
             * @memberof vibe.SpeechRecognitionAlternative
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SpeechRecognitionAlternative.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.transcript != null && message.hasOwnProperty("transcript"))
                    if (!$util.isString(message.transcript))
                        return "transcript: string expected";
                if (message.confidence != null && message.hasOwnProperty("confidence"))
                    if (typeof message.confidence !== "number")
                        return "confidence: number expected";
                if (message.words != null && message.hasOwnProperty("words")) {
                    if (!Array.isArray(message.words))
                        return "words: array expected";
                    for (var i = 0; i < message.words.length; ++i) {
                        var error = $root.vibe.WordInfo.verify(message.words[i]);
                        if (error)
                            return "words." + error;
                    }
                }
                if (message.wordsPerMinute != null && message.hasOwnProperty("wordsPerMinute"))
                    if (typeof message.wordsPerMinute !== "number")
                        return "wordsPerMinute: number expected";
                if (message.normalizedTranscript != null && message.hasOwnProperty("normalizedTranscript"))
                    if (!$util.isString(message.normalizedTranscript))
                        return "normalizedTranscript: string expected";
                if (message.normWords != null && message.hasOwnProperty("normWords")) {
                    if (!Array.isArray(message.normWords))
                        return "normWords: array expected";
                    for (var i = 0; i < message.normWords.length; ++i) {
                        var error = $root.vibe.NormWordInfo.verify(message.normWords[i]);
                        if (error)
                            return "normWords." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a SpeechRecognitionAlternative message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.SpeechRecognitionAlternative
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.SpeechRecognitionAlternative} SpeechRecognitionAlternative
             */
            SpeechRecognitionAlternative.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.SpeechRecognitionAlternative)
                    return object;
                var message = new $root.vibe.SpeechRecognitionAlternative();
                if (object.transcript != null)
                    message.transcript = String(object.transcript);
                if (object.confidence != null)
                    message.confidence = Number(object.confidence);
                if (object.words) {
                    if (!Array.isArray(object.words))
                        throw TypeError(".vibe.SpeechRecognitionAlternative.words: array expected");
                    message.words = [];
                    for (var i = 0; i < object.words.length; ++i) {
                        if (typeof object.words[i] !== "object")
                            throw TypeError(".vibe.SpeechRecognitionAlternative.words: object expected");
                        message.words[i] = $root.vibe.WordInfo.fromObject(object.words[i]);
                    }
                }
                if (object.wordsPerMinute != null)
                    message.wordsPerMinute = Number(object.wordsPerMinute);
                if (object.normalizedTranscript != null)
                    message.normalizedTranscript = String(object.normalizedTranscript);
                if (object.normWords) {
                    if (!Array.isArray(object.normWords))
                        throw TypeError(".vibe.SpeechRecognitionAlternative.normWords: array expected");
                    message.normWords = [];
                    for (var i = 0; i < object.normWords.length; ++i) {
                        if (typeof object.normWords[i] !== "object")
                            throw TypeError(".vibe.SpeechRecognitionAlternative.normWords: object expected");
                        message.normWords[i] = $root.vibe.NormWordInfo.fromObject(object.normWords[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a SpeechRecognitionAlternative message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.SpeechRecognitionAlternative
             * @static
             * @param {vibe.SpeechRecognitionAlternative} message SpeechRecognitionAlternative
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SpeechRecognitionAlternative.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.words = [];
                    object.normWords = [];
                }
                if (options.defaults) {
                    object.transcript = "";
                    object.confidence = 0;
                    object.wordsPerMinute = 0;
                    object.normalizedTranscript = "";
                }
                if (message.transcript != null && message.hasOwnProperty("transcript"))
                    object.transcript = message.transcript;
                if (message.confidence != null && message.hasOwnProperty("confidence"))
                    object.confidence = options.json && !isFinite(message.confidence) ? String(message.confidence) : message.confidence;
                if (message.words && message.words.length) {
                    object.words = [];
                    for (var j = 0; j < message.words.length; ++j)
                        object.words[j] = $root.vibe.WordInfo.toObject(message.words[j], options);
                }
                if (message.wordsPerMinute != null && message.hasOwnProperty("wordsPerMinute"))
                    object.wordsPerMinute = options.json && !isFinite(message.wordsPerMinute) ? String(message.wordsPerMinute) : message.wordsPerMinute;
                if (message.normalizedTranscript != null && message.hasOwnProperty("normalizedTranscript"))
                    object.normalizedTranscript = message.normalizedTranscript;
                if (message.normWords && message.normWords.length) {
                    object.normWords = [];
                    for (var j = 0; j < message.normWords.length; ++j)
                        object.normWords[j] = $root.vibe.NormWordInfo.toObject(message.normWords[j], options);
                }
                return object;
            };
    
            /**
             * Converts this SpeechRecognitionAlternative to JSON.
             * @function toJSON
             * @memberof vibe.SpeechRecognitionAlternative
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SpeechRecognitionAlternative.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SpeechRecognitionAlternative
             * @function getTypeUrl
             * @memberof vibe.SpeechRecognitionAlternative
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SpeechRecognitionAlternative.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.SpeechRecognitionAlternative";
            };
    
            return SpeechRecognitionAlternative;
        })();
    
        vibe.ValidationResult = (function() {
    
            /**
             * Properties of a ValidationResult.
             * @memberof vibe
             * @interface IValidationResult
             * @property {number|null} [validationScore] ValidationResult validationScore
             * @property {vibe.ValidationResult.ValidationReturnCode|null} [validationReturnCode] ValidationResult validationReturnCode
             */
    
            /**
             * Constructs a new ValidationResult.
             * @memberof vibe
             * @classdesc Represents a ValidationResult.
             * @implements IValidationResult
             * @constructor
             * @param {vibe.IValidationResult=} [properties] Properties to set
             */
            function ValidationResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ValidationResult validationScore.
             * @member {number} validationScore
             * @memberof vibe.ValidationResult
             * @instance
             */
            ValidationResult.prototype.validationScore = 0;
    
            /**
             * ValidationResult validationReturnCode.
             * @member {vibe.ValidationResult.ValidationReturnCode} validationReturnCode
             * @memberof vibe.ValidationResult
             * @instance
             */
            ValidationResult.prototype.validationReturnCode = 0;
    
            /**
             * Creates a new ValidationResult instance using the specified properties.
             * @function create
             * @memberof vibe.ValidationResult
             * @static
             * @param {vibe.IValidationResult=} [properties] Properties to set
             * @returns {vibe.ValidationResult} ValidationResult instance
             */
            ValidationResult.create = function create(properties) {
                return new ValidationResult(properties);
            };
    
            /**
             * Encodes the specified ValidationResult message. Does not implicitly {@link vibe.ValidationResult.verify|verify} messages.
             * @function encode
             * @memberof vibe.ValidationResult
             * @static
             * @param {vibe.IValidationResult} message ValidationResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidationResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.validationScore != null && Object.hasOwnProperty.call(message, "validationScore"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.validationScore);
                if (message.validationReturnCode != null && Object.hasOwnProperty.call(message, "validationReturnCode"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.validationReturnCode);
                return writer;
            };
    
            /**
             * Encodes the specified ValidationResult message, length delimited. Does not implicitly {@link vibe.ValidationResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.ValidationResult
             * @static
             * @param {vibe.IValidationResult} message ValidationResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidationResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ValidationResult message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.ValidationResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.ValidationResult} ValidationResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidationResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ValidationResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.validationScore = reader.float();
                            break;
                        }
                    case 2: {
                            message.validationReturnCode = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ValidationResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.ValidationResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.ValidationResult} ValidationResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidationResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ValidationResult message.
             * @function verify
             * @memberof vibe.ValidationResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ValidationResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.validationScore != null && message.hasOwnProperty("validationScore"))
                    if (typeof message.validationScore !== "number")
                        return "validationScore: number expected";
                if (message.validationReturnCode != null && message.hasOwnProperty("validationReturnCode"))
                    switch (message.validationReturnCode) {
                    default:
                        return "validationReturnCode: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a ValidationResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.ValidationResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.ValidationResult} ValidationResult
             */
            ValidationResult.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.ValidationResult)
                    return object;
                var message = new $root.vibe.ValidationResult();
                if (object.validationScore != null)
                    message.validationScore = Number(object.validationScore);
                switch (object.validationReturnCode) {
                default:
                    if (typeof object.validationReturnCode === "number") {
                        message.validationReturnCode = object.validationReturnCode;
                        break;
                    }
                    break;
                case "SUCCESS":
                case 0:
                    message.validationReturnCode = 0;
                    break;
                case "NOT_REQUESTED":
                case 1:
                    message.validationReturnCode = 1;
                    break;
                case "NO_MODEL":
                case 2:
                    message.validationReturnCode = 2;
                    break;
                case "EMPTY_PHONE":
                case 3:
                    message.validationReturnCode = 3;
                    break;
                case "NOT_LOADED":
                case 4:
                    message.validationReturnCode = 4;
                    break;
                case "NO_VALID_PHONE":
                case 5:
                    message.validationReturnCode = 5;
                    break;
                case "FAILURE":
                case 6:
                    message.validationReturnCode = 6;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ValidationResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.ValidationResult
             * @static
             * @param {vibe.ValidationResult} message ValidationResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ValidationResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.validationScore = 0;
                    object.validationReturnCode = options.enums === String ? "SUCCESS" : 0;
                }
                if (message.validationScore != null && message.hasOwnProperty("validationScore"))
                    object.validationScore = options.json && !isFinite(message.validationScore) ? String(message.validationScore) : message.validationScore;
                if (message.validationReturnCode != null && message.hasOwnProperty("validationReturnCode"))
                    object.validationReturnCode = options.enums === String ? $root.vibe.ValidationResult.ValidationReturnCode[message.validationReturnCode] === undefined ? message.validationReturnCode : $root.vibe.ValidationResult.ValidationReturnCode[message.validationReturnCode] : message.validationReturnCode;
                return object;
            };
    
            /**
             * Converts this ValidationResult to JSON.
             * @function toJSON
             * @memberof vibe.ValidationResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ValidationResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ValidationResult
             * @function getTypeUrl
             * @memberof vibe.ValidationResult
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ValidationResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.ValidationResult";
            };
    
            /**
             * ValidationReturnCode enum.
             * @name vibe.ValidationResult.ValidationReturnCode
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} NOT_REQUESTED=1 NOT_REQUESTED value
             * @property {number} NO_MODEL=2 NO_MODEL value
             * @property {number} EMPTY_PHONE=3 EMPTY_PHONE value
             * @property {number} NOT_LOADED=4 NOT_LOADED value
             * @property {number} NO_VALID_PHONE=5 NO_VALID_PHONE value
             * @property {number} FAILURE=6 FAILURE value
             */
            ValidationResult.ValidationReturnCode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "NOT_REQUESTED"] = 1;
                values[valuesById[2] = "NO_MODEL"] = 2;
                values[valuesById[3] = "EMPTY_PHONE"] = 3;
                values[valuesById[4] = "NOT_LOADED"] = 4;
                values[valuesById[5] = "NO_VALID_PHONE"] = 5;
                values[valuesById[6] = "FAILURE"] = 6;
                return values;
            })();
    
            return ValidationResult;
        })();
    
        vibe.Phone = (function() {
    
            /**
             * Properties of a Phone.
             * @memberof vibe
             * @interface IPhone
             * @property {string|null} [phone] Phone phone
             * @property {number|null} [durationMsec] Phone durationMsec
             * @property {Array.<number>|null} [loglikeDiff] Phone loglikeDiff
             * @property {vibe.IValidationResult|null} [validationResult] Phone validationResult
             * @property {number|null} [phoneId] Phone phoneId
             */
    
            /**
             * Constructs a new Phone.
             * @memberof vibe
             * @classdesc Represents a Phone.
             * @implements IPhone
             * @constructor
             * @param {vibe.IPhone=} [properties] Properties to set
             */
            function Phone(properties) {
                this.loglikeDiff = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Phone phone.
             * @member {string} phone
             * @memberof vibe.Phone
             * @instance
             */
            Phone.prototype.phone = "";
    
            /**
             * Phone durationMsec.
             * @member {number} durationMsec
             * @memberof vibe.Phone
             * @instance
             */
            Phone.prototype.durationMsec = 0;
    
            /**
             * Phone loglikeDiff.
             * @member {Array.<number>} loglikeDiff
             * @memberof vibe.Phone
             * @instance
             */
            Phone.prototype.loglikeDiff = $util.emptyArray;
    
            /**
             * Phone validationResult.
             * @member {vibe.IValidationResult|null|undefined} validationResult
             * @memberof vibe.Phone
             * @instance
             */
            Phone.prototype.validationResult = null;
    
            /**
             * Phone phoneId.
             * @member {number} phoneId
             * @memberof vibe.Phone
             * @instance
             */
            Phone.prototype.phoneId = 0;
    
            /**
             * Creates a new Phone instance using the specified properties.
             * @function create
             * @memberof vibe.Phone
             * @static
             * @param {vibe.IPhone=} [properties] Properties to set
             * @returns {vibe.Phone} Phone instance
             */
            Phone.create = function create(properties) {
                return new Phone(properties);
            };
    
            /**
             * Encodes the specified Phone message. Does not implicitly {@link vibe.Phone.verify|verify} messages.
             * @function encode
             * @memberof vibe.Phone
             * @static
             * @param {vibe.IPhone} message Phone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Phone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
                if (message.durationMsec != null && Object.hasOwnProperty.call(message, "durationMsec"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.durationMsec);
                if (message.loglikeDiff != null && message.loglikeDiff.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.loglikeDiff.length; ++i)
                        writer.float(message.loglikeDiff[i]);
                    writer.ldelim();
                }
                if (message.validationResult != null && Object.hasOwnProperty.call(message, "validationResult"))
                    $root.vibe.ValidationResult.encode(message.validationResult, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.phoneId != null && Object.hasOwnProperty.call(message, "phoneId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.phoneId);
                return writer;
            };
    
            /**
             * Encodes the specified Phone message, length delimited. Does not implicitly {@link vibe.Phone.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.Phone
             * @static
             * @param {vibe.IPhone} message Phone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Phone.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Phone message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.Phone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.Phone} Phone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Phone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.Phone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.phone = reader.string();
                            break;
                        }
                    case 2: {
                            message.durationMsec = reader.uint32();
                            break;
                        }
                    case 3: {
                            if (!(message.loglikeDiff && message.loglikeDiff.length))
                                message.loglikeDiff = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.loglikeDiff.push(reader.float());
                            } else
                                message.loglikeDiff.push(reader.float());
                            break;
                        }
                    case 4: {
                            message.validationResult = $root.vibe.ValidationResult.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.phoneId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Phone message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.Phone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.Phone} Phone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Phone.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Phone message.
             * @function verify
             * @memberof vibe.Phone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Phone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.durationMsec != null && message.hasOwnProperty("durationMsec"))
                    if (!$util.isInteger(message.durationMsec))
                        return "durationMsec: integer expected";
                if (message.loglikeDiff != null && message.hasOwnProperty("loglikeDiff")) {
                    if (!Array.isArray(message.loglikeDiff))
                        return "loglikeDiff: array expected";
                    for (var i = 0; i < message.loglikeDiff.length; ++i)
                        if (typeof message.loglikeDiff[i] !== "number")
                            return "loglikeDiff: number[] expected";
                }
                if (message.validationResult != null && message.hasOwnProperty("validationResult")) {
                    var error = $root.vibe.ValidationResult.verify(message.validationResult);
                    if (error)
                        return "validationResult." + error;
                }
                if (message.phoneId != null && message.hasOwnProperty("phoneId"))
                    if (!$util.isInteger(message.phoneId))
                        return "phoneId: integer expected";
                return null;
            };
    
            /**
             * Creates a Phone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.Phone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.Phone} Phone
             */
            Phone.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.Phone)
                    return object;
                var message = new $root.vibe.Phone();
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.durationMsec != null)
                    message.durationMsec = object.durationMsec >>> 0;
                if (object.loglikeDiff) {
                    if (!Array.isArray(object.loglikeDiff))
                        throw TypeError(".vibe.Phone.loglikeDiff: array expected");
                    message.loglikeDiff = [];
                    for (var i = 0; i < object.loglikeDiff.length; ++i)
                        message.loglikeDiff[i] = Number(object.loglikeDiff[i]);
                }
                if (object.validationResult != null) {
                    if (typeof object.validationResult !== "object")
                        throw TypeError(".vibe.Phone.validationResult: object expected");
                    message.validationResult = $root.vibe.ValidationResult.fromObject(object.validationResult);
                }
                if (object.phoneId != null)
                    message.phoneId = object.phoneId >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a Phone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.Phone
             * @static
             * @param {vibe.Phone} message Phone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Phone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.loglikeDiff = [];
                if (options.defaults) {
                    object.phone = "";
                    object.durationMsec = 0;
                    object.validationResult = null;
                    object.phoneId = 0;
                }
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.durationMsec != null && message.hasOwnProperty("durationMsec"))
                    object.durationMsec = message.durationMsec;
                if (message.loglikeDiff && message.loglikeDiff.length) {
                    object.loglikeDiff = [];
                    for (var j = 0; j < message.loglikeDiff.length; ++j)
                        object.loglikeDiff[j] = options.json && !isFinite(message.loglikeDiff[j]) ? String(message.loglikeDiff[j]) : message.loglikeDiff[j];
                }
                if (message.validationResult != null && message.hasOwnProperty("validationResult"))
                    object.validationResult = $root.vibe.ValidationResult.toObject(message.validationResult, options);
                if (message.phoneId != null && message.hasOwnProperty("phoneId"))
                    object.phoneId = message.phoneId;
                return object;
            };
    
            /**
             * Converts this Phone to JSON.
             * @function toJSON
             * @memberof vibe.Phone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Phone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Phone
             * @function getTypeUrl
             * @memberof vibe.Phone
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Phone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.Phone";
            };
    
            return Phone;
        })();
    
        vibe.GraphemeToPhonemeInfo = (function() {
    
            /**
             * Properties of a GraphemeToPhonemeInfo.
             * @memberof vibe
             * @interface IGraphemeToPhonemeInfo
             * @property {vibe.GraphemeToPhonemeInfo.ServiceTypes|null} [serviceType] GraphemeToPhonemeInfo serviceType
             * @property {vibe.GraphemeToPhonemeInfo.Status|null} [status] GraphemeToPhonemeInfo status
             * @property {Array.<string>|null} [unknownSymbols] GraphemeToPhonemeInfo unknownSymbols
             */
    
            /**
             * Constructs a new GraphemeToPhonemeInfo.
             * @memberof vibe
             * @classdesc Represents a GraphemeToPhonemeInfo.
             * @implements IGraphemeToPhonemeInfo
             * @constructor
             * @param {vibe.IGraphemeToPhonemeInfo=} [properties] Properties to set
             */
            function GraphemeToPhonemeInfo(properties) {
                this.unknownSymbols = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GraphemeToPhonemeInfo serviceType.
             * @member {vibe.GraphemeToPhonemeInfo.ServiceTypes} serviceType
             * @memberof vibe.GraphemeToPhonemeInfo
             * @instance
             */
            GraphemeToPhonemeInfo.prototype.serviceType = 0;
    
            /**
             * GraphemeToPhonemeInfo status.
             * @member {vibe.GraphemeToPhonemeInfo.Status} status
             * @memberof vibe.GraphemeToPhonemeInfo
             * @instance
             */
            GraphemeToPhonemeInfo.prototype.status = 0;
    
            /**
             * GraphemeToPhonemeInfo unknownSymbols.
             * @member {Array.<string>} unknownSymbols
             * @memberof vibe.GraphemeToPhonemeInfo
             * @instance
             */
            GraphemeToPhonemeInfo.prototype.unknownSymbols = $util.emptyArray;
    
            /**
             * Creates a new GraphemeToPhonemeInfo instance using the specified properties.
             * @function create
             * @memberof vibe.GraphemeToPhonemeInfo
             * @static
             * @param {vibe.IGraphemeToPhonemeInfo=} [properties] Properties to set
             * @returns {vibe.GraphemeToPhonemeInfo} GraphemeToPhonemeInfo instance
             */
            GraphemeToPhonemeInfo.create = function create(properties) {
                return new GraphemeToPhonemeInfo(properties);
            };
    
            /**
             * Encodes the specified GraphemeToPhonemeInfo message. Does not implicitly {@link vibe.GraphemeToPhonemeInfo.verify|verify} messages.
             * @function encode
             * @memberof vibe.GraphemeToPhonemeInfo
             * @static
             * @param {vibe.IGraphemeToPhonemeInfo} message GraphemeToPhonemeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GraphemeToPhonemeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.serviceType);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                if (message.unknownSymbols != null && message.unknownSymbols.length)
                    for (var i = 0; i < message.unknownSymbols.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.unknownSymbols[i]);
                return writer;
            };
    
            /**
             * Encodes the specified GraphemeToPhonemeInfo message, length delimited. Does not implicitly {@link vibe.GraphemeToPhonemeInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.GraphemeToPhonemeInfo
             * @static
             * @param {vibe.IGraphemeToPhonemeInfo} message GraphemeToPhonemeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GraphemeToPhonemeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GraphemeToPhonemeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.GraphemeToPhonemeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.GraphemeToPhonemeInfo} GraphemeToPhonemeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GraphemeToPhonemeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.GraphemeToPhonemeInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.serviceType = reader.int32();
                            break;
                        }
                    case 2: {
                            message.status = reader.int32();
                            break;
                        }
                    case 3: {
                            if (!(message.unknownSymbols && message.unknownSymbols.length))
                                message.unknownSymbols = [];
                            message.unknownSymbols.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GraphemeToPhonemeInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.GraphemeToPhonemeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.GraphemeToPhonemeInfo} GraphemeToPhonemeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GraphemeToPhonemeInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GraphemeToPhonemeInfo message.
             * @function verify
             * @memberof vibe.GraphemeToPhonemeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GraphemeToPhonemeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.serviceType != null && message.hasOwnProperty("serviceType"))
                    switch (message.serviceType) {
                    default:
                        return "serviceType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.unknownSymbols != null && message.hasOwnProperty("unknownSymbols")) {
                    if (!Array.isArray(message.unknownSymbols))
                        return "unknownSymbols: array expected";
                    for (var i = 0; i < message.unknownSymbols.length; ++i)
                        if (!$util.isString(message.unknownSymbols[i]))
                            return "unknownSymbols: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a GraphemeToPhonemeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.GraphemeToPhonemeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.GraphemeToPhonemeInfo} GraphemeToPhonemeInfo
             */
            GraphemeToPhonemeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.GraphemeToPhonemeInfo)
                    return object;
                var message = new $root.vibe.GraphemeToPhonemeInfo();
                switch (object.serviceType) {
                default:
                    if (typeof object.serviceType === "number") {
                        message.serviceType = object.serviceType;
                        break;
                    }
                    break;
                case "NO_G2P_USED":
                case 0:
                    message.serviceType = 0;
                    break;
                case "G2P_SERVER":
                case 1:
                    message.serviceType = 1;
                    break;
                case "LINGWARE_LEXICON":
                case 2:
                    message.serviceType = 2;
                    break;
                }
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "OTHER":
                case 0:
                    message.status = 0;
                    break;
                case "OK":
                case 1:
                    message.status = 1;
                    break;
                case "OOV_WORD":
                case 2:
                    message.status = 2;
                    break;
                case "UNKNOWN_SYMBOLS":
                case 3:
                    message.status = 3;
                    break;
                }
                if (object.unknownSymbols) {
                    if (!Array.isArray(object.unknownSymbols))
                        throw TypeError(".vibe.GraphemeToPhonemeInfo.unknownSymbols: array expected");
                    message.unknownSymbols = [];
                    for (var i = 0; i < object.unknownSymbols.length; ++i)
                        message.unknownSymbols[i] = String(object.unknownSymbols[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GraphemeToPhonemeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.GraphemeToPhonemeInfo
             * @static
             * @param {vibe.GraphemeToPhonemeInfo} message GraphemeToPhonemeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GraphemeToPhonemeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.unknownSymbols = [];
                if (options.defaults) {
                    object.serviceType = options.enums === String ? "NO_G2P_USED" : 0;
                    object.status = options.enums === String ? "OTHER" : 0;
                }
                if (message.serviceType != null && message.hasOwnProperty("serviceType"))
                    object.serviceType = options.enums === String ? $root.vibe.GraphemeToPhonemeInfo.ServiceTypes[message.serviceType] === undefined ? message.serviceType : $root.vibe.GraphemeToPhonemeInfo.ServiceTypes[message.serviceType] : message.serviceType;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.vibe.GraphemeToPhonemeInfo.Status[message.status] === undefined ? message.status : $root.vibe.GraphemeToPhonemeInfo.Status[message.status] : message.status;
                if (message.unknownSymbols && message.unknownSymbols.length) {
                    object.unknownSymbols = [];
                    for (var j = 0; j < message.unknownSymbols.length; ++j)
                        object.unknownSymbols[j] = message.unknownSymbols[j];
                }
                return object;
            };
    
            /**
             * Converts this GraphemeToPhonemeInfo to JSON.
             * @function toJSON
             * @memberof vibe.GraphemeToPhonemeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GraphemeToPhonemeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GraphemeToPhonemeInfo
             * @function getTypeUrl
             * @memberof vibe.GraphemeToPhonemeInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GraphemeToPhonemeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.GraphemeToPhonemeInfo";
            };
    
            /**
             * ServiceTypes enum.
             * @name vibe.GraphemeToPhonemeInfo.ServiceTypes
             * @enum {number}
             * @property {number} NO_G2P_USED=0 NO_G2P_USED value
             * @property {number} G2P_SERVER=1 G2P_SERVER value
             * @property {number} LINGWARE_LEXICON=2 LINGWARE_LEXICON value
             */
            GraphemeToPhonemeInfo.ServiceTypes = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NO_G2P_USED"] = 0;
                values[valuesById[1] = "G2P_SERVER"] = 1;
                values[valuesById[2] = "LINGWARE_LEXICON"] = 2;
                return values;
            })();
    
            /**
             * Status enum.
             * @name vibe.GraphemeToPhonemeInfo.Status
             * @enum {number}
             * @property {number} OTHER=0 OTHER value
             * @property {number} OK=1 OK value
             * @property {number} OOV_WORD=2 OOV_WORD value
             * @property {number} UNKNOWN_SYMBOLS=3 UNKNOWN_SYMBOLS value
             */
            GraphemeToPhonemeInfo.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "OTHER"] = 0;
                values[valuesById[1] = "OK"] = 1;
                values[valuesById[2] = "OOV_WORD"] = 2;
                values[valuesById[3] = "UNKNOWN_SYMBOLS"] = 3;
                return values;
            })();
    
            return GraphemeToPhonemeInfo;
        })();
    
        vibe.WordInfo = (function() {
    
            /**
             * Properties of a WordInfo.
             * @memberof vibe
             * @interface IWordInfo
             * @property {number|null} [startTimeMsec] WordInfo startTimeMsec
             * @property {number|null} [endTimeMsec] WordInfo endTimeMsec
             * @property {string|null} [word] WordInfo word
             * @property {number|null} [confidence] WordInfo confidence
             * @property {Array.<vibe.IPhone>|null} [phones] WordInfo phones
             * @property {boolean|null} [anonymized] WordInfo anonymized
             * @property {vibe.IValidationResult|null} [validationResult] WordInfo validationResult
             * @property {vibe.IGraphemeToPhonemeInfo|null} [g2pInfo] WordInfo g2pInfo
             * @property {vibe.WordInfo.ITag|null} [anonInfo] WordInfo anonInfo
             */
    
            /**
             * Constructs a new WordInfo.
             * @memberof vibe
             * @classdesc Represents a WordInfo.
             * @implements IWordInfo
             * @constructor
             * @param {vibe.IWordInfo=} [properties] Properties to set
             */
            function WordInfo(properties) {
                this.phones = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * WordInfo startTimeMsec.
             * @member {number} startTimeMsec
             * @memberof vibe.WordInfo
             * @instance
             */
            WordInfo.prototype.startTimeMsec = 0;
    
            /**
             * WordInfo endTimeMsec.
             * @member {number} endTimeMsec
             * @memberof vibe.WordInfo
             * @instance
             */
            WordInfo.prototype.endTimeMsec = 0;
    
            /**
             * WordInfo word.
             * @member {string} word
             * @memberof vibe.WordInfo
             * @instance
             */
            WordInfo.prototype.word = "";
    
            /**
             * WordInfo confidence.
             * @member {number} confidence
             * @memberof vibe.WordInfo
             * @instance
             */
            WordInfo.prototype.confidence = 0;
    
            /**
             * WordInfo phones.
             * @member {Array.<vibe.IPhone>} phones
             * @memberof vibe.WordInfo
             * @instance
             */
            WordInfo.prototype.phones = $util.emptyArray;
    
            /**
             * WordInfo anonymized.
             * @member {boolean} anonymized
             * @memberof vibe.WordInfo
             * @instance
             */
            WordInfo.prototype.anonymized = false;
    
            /**
             * WordInfo validationResult.
             * @member {vibe.IValidationResult|null|undefined} validationResult
             * @memberof vibe.WordInfo
             * @instance
             */
            WordInfo.prototype.validationResult = null;
    
            /**
             * WordInfo g2pInfo.
             * @member {vibe.IGraphemeToPhonemeInfo|null|undefined} g2pInfo
             * @memberof vibe.WordInfo
             * @instance
             */
            WordInfo.prototype.g2pInfo = null;
    
            /**
             * WordInfo anonInfo.
             * @member {vibe.WordInfo.ITag|null|undefined} anonInfo
             * @memberof vibe.WordInfo
             * @instance
             */
            WordInfo.prototype.anonInfo = null;
    
            /**
             * Creates a new WordInfo instance using the specified properties.
             * @function create
             * @memberof vibe.WordInfo
             * @static
             * @param {vibe.IWordInfo=} [properties] Properties to set
             * @returns {vibe.WordInfo} WordInfo instance
             */
            WordInfo.create = function create(properties) {
                return new WordInfo(properties);
            };
    
            /**
             * Encodes the specified WordInfo message. Does not implicitly {@link vibe.WordInfo.verify|verify} messages.
             * @function encode
             * @memberof vibe.WordInfo
             * @static
             * @param {vibe.IWordInfo} message WordInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WordInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.startTimeMsec != null && Object.hasOwnProperty.call(message, "startTimeMsec"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.startTimeMsec);
                if (message.endTimeMsec != null && Object.hasOwnProperty.call(message, "endTimeMsec"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.endTimeMsec);
                if (message.word != null && Object.hasOwnProperty.call(message, "word"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.word);
                if (message.confidence != null && Object.hasOwnProperty.call(message, "confidence"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.confidence);
                if (message.phones != null && message.phones.length)
                    for (var i = 0; i < message.phones.length; ++i)
                        $root.vibe.Phone.encode(message.phones[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.anonymized != null && Object.hasOwnProperty.call(message, "anonymized"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.anonymized);
                if (message.validationResult != null && Object.hasOwnProperty.call(message, "validationResult"))
                    $root.vibe.ValidationResult.encode(message.validationResult, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.g2pInfo != null && Object.hasOwnProperty.call(message, "g2pInfo"))
                    $root.vibe.GraphemeToPhonemeInfo.encode(message.g2pInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.anonInfo != null && Object.hasOwnProperty.call(message, "anonInfo"))
                    $root.vibe.WordInfo.Tag.encode(message.anonInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified WordInfo message, length delimited. Does not implicitly {@link vibe.WordInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.WordInfo
             * @static
             * @param {vibe.IWordInfo} message WordInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WordInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a WordInfo message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.WordInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.WordInfo} WordInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WordInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.WordInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.startTimeMsec = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.endTimeMsec = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.word = reader.string();
                            break;
                        }
                    case 4: {
                            message.confidence = reader.float();
                            break;
                        }
                    case 5: {
                            if (!(message.phones && message.phones.length))
                                message.phones = [];
                            message.phones.push($root.vibe.Phone.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            message.anonymized = reader.bool();
                            break;
                        }
                    case 7: {
                            message.validationResult = $root.vibe.ValidationResult.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.g2pInfo = $root.vibe.GraphemeToPhonemeInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.anonInfo = $root.vibe.WordInfo.Tag.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a WordInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.WordInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.WordInfo} WordInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WordInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a WordInfo message.
             * @function verify
             * @memberof vibe.WordInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WordInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.startTimeMsec != null && message.hasOwnProperty("startTimeMsec"))
                    if (!$util.isInteger(message.startTimeMsec))
                        return "startTimeMsec: integer expected";
                if (message.endTimeMsec != null && message.hasOwnProperty("endTimeMsec"))
                    if (!$util.isInteger(message.endTimeMsec))
                        return "endTimeMsec: integer expected";
                if (message.word != null && message.hasOwnProperty("word"))
                    if (!$util.isString(message.word))
                        return "word: string expected";
                if (message.confidence != null && message.hasOwnProperty("confidence"))
                    if (typeof message.confidence !== "number")
                        return "confidence: number expected";
                if (message.phones != null && message.hasOwnProperty("phones")) {
                    if (!Array.isArray(message.phones))
                        return "phones: array expected";
                    for (var i = 0; i < message.phones.length; ++i) {
                        var error = $root.vibe.Phone.verify(message.phones[i]);
                        if (error)
                            return "phones." + error;
                    }
                }
                if (message.anonymized != null && message.hasOwnProperty("anonymized"))
                    if (typeof message.anonymized !== "boolean")
                        return "anonymized: boolean expected";
                if (message.validationResult != null && message.hasOwnProperty("validationResult")) {
                    var error = $root.vibe.ValidationResult.verify(message.validationResult);
                    if (error)
                        return "validationResult." + error;
                }
                if (message.g2pInfo != null && message.hasOwnProperty("g2pInfo")) {
                    var error = $root.vibe.GraphemeToPhonemeInfo.verify(message.g2pInfo);
                    if (error)
                        return "g2pInfo." + error;
                }
                if (message.anonInfo != null && message.hasOwnProperty("anonInfo")) {
                    var error = $root.vibe.WordInfo.Tag.verify(message.anonInfo);
                    if (error)
                        return "anonInfo." + error;
                }
                return null;
            };
    
            /**
             * Creates a WordInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.WordInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.WordInfo} WordInfo
             */
            WordInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.WordInfo)
                    return object;
                var message = new $root.vibe.WordInfo();
                if (object.startTimeMsec != null)
                    message.startTimeMsec = object.startTimeMsec >>> 0;
                if (object.endTimeMsec != null)
                    message.endTimeMsec = object.endTimeMsec >>> 0;
                if (object.word != null)
                    message.word = String(object.word);
                if (object.confidence != null)
                    message.confidence = Number(object.confidence);
                if (object.phones) {
                    if (!Array.isArray(object.phones))
                        throw TypeError(".vibe.WordInfo.phones: array expected");
                    message.phones = [];
                    for (var i = 0; i < object.phones.length; ++i) {
                        if (typeof object.phones[i] !== "object")
                            throw TypeError(".vibe.WordInfo.phones: object expected");
                        message.phones[i] = $root.vibe.Phone.fromObject(object.phones[i]);
                    }
                }
                if (object.anonymized != null)
                    message.anonymized = Boolean(object.anonymized);
                if (object.validationResult != null) {
                    if (typeof object.validationResult !== "object")
                        throw TypeError(".vibe.WordInfo.validationResult: object expected");
                    message.validationResult = $root.vibe.ValidationResult.fromObject(object.validationResult);
                }
                if (object.g2pInfo != null) {
                    if (typeof object.g2pInfo !== "object")
                        throw TypeError(".vibe.WordInfo.g2pInfo: object expected");
                    message.g2pInfo = $root.vibe.GraphemeToPhonemeInfo.fromObject(object.g2pInfo);
                }
                if (object.anonInfo != null) {
                    if (typeof object.anonInfo !== "object")
                        throw TypeError(".vibe.WordInfo.anonInfo: object expected");
                    message.anonInfo = $root.vibe.WordInfo.Tag.fromObject(object.anonInfo);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a WordInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.WordInfo
             * @static
             * @param {vibe.WordInfo} message WordInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WordInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.phones = [];
                if (options.defaults) {
                    object.startTimeMsec = 0;
                    object.endTimeMsec = 0;
                    object.word = "";
                    object.confidence = 0;
                    object.anonymized = false;
                    object.validationResult = null;
                    object.g2pInfo = null;
                    object.anonInfo = null;
                }
                if (message.startTimeMsec != null && message.hasOwnProperty("startTimeMsec"))
                    object.startTimeMsec = message.startTimeMsec;
                if (message.endTimeMsec != null && message.hasOwnProperty("endTimeMsec"))
                    object.endTimeMsec = message.endTimeMsec;
                if (message.word != null && message.hasOwnProperty("word"))
                    object.word = message.word;
                if (message.confidence != null && message.hasOwnProperty("confidence"))
                    object.confidence = options.json && !isFinite(message.confidence) ? String(message.confidence) : message.confidence;
                if (message.phones && message.phones.length) {
                    object.phones = [];
                    for (var j = 0; j < message.phones.length; ++j)
                        object.phones[j] = $root.vibe.Phone.toObject(message.phones[j], options);
                }
                if (message.anonymized != null && message.hasOwnProperty("anonymized"))
                    object.anonymized = message.anonymized;
                if (message.validationResult != null && message.hasOwnProperty("validationResult"))
                    object.validationResult = $root.vibe.ValidationResult.toObject(message.validationResult, options);
                if (message.g2pInfo != null && message.hasOwnProperty("g2pInfo"))
                    object.g2pInfo = $root.vibe.GraphemeToPhonemeInfo.toObject(message.g2pInfo, options);
                if (message.anonInfo != null && message.hasOwnProperty("anonInfo"))
                    object.anonInfo = $root.vibe.WordInfo.Tag.toObject(message.anonInfo, options);
                return object;
            };
    
            /**
             * Converts this WordInfo to JSON.
             * @function toJSON
             * @memberof vibe.WordInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WordInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for WordInfo
             * @function getTypeUrl
             * @memberof vibe.WordInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WordInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.WordInfo";
            };
    
            WordInfo.Tag = (function() {
    
                /**
                 * Properties of a Tag.
                 * @memberof vibe.WordInfo
                 * @interface ITag
                 * @property {string|null} [name] Tag name
                 * @property {number|null} [confidence] Tag confidence
                 */
    
                /**
                 * Constructs a new Tag.
                 * @memberof vibe.WordInfo
                 * @classdesc Represents a Tag.
                 * @implements ITag
                 * @constructor
                 * @param {vibe.WordInfo.ITag=} [properties] Properties to set
                 */
                function Tag(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Tag name.
                 * @member {string} name
                 * @memberof vibe.WordInfo.Tag
                 * @instance
                 */
                Tag.prototype.name = "";
    
                /**
                 * Tag confidence.
                 * @member {number} confidence
                 * @memberof vibe.WordInfo.Tag
                 * @instance
                 */
                Tag.prototype.confidence = 0;
    
                /**
                 * Creates a new Tag instance using the specified properties.
                 * @function create
                 * @memberof vibe.WordInfo.Tag
                 * @static
                 * @param {vibe.WordInfo.ITag=} [properties] Properties to set
                 * @returns {vibe.WordInfo.Tag} Tag instance
                 */
                Tag.create = function create(properties) {
                    return new Tag(properties);
                };
    
                /**
                 * Encodes the specified Tag message. Does not implicitly {@link vibe.WordInfo.Tag.verify|verify} messages.
                 * @function encode
                 * @memberof vibe.WordInfo.Tag
                 * @static
                 * @param {vibe.WordInfo.ITag} message Tag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Tag.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.confidence != null && Object.hasOwnProperty.call(message, "confidence"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.confidence);
                    return writer;
                };
    
                /**
                 * Encodes the specified Tag message, length delimited. Does not implicitly {@link vibe.WordInfo.Tag.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof vibe.WordInfo.Tag
                 * @static
                 * @param {vibe.WordInfo.ITag} message Tag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Tag.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Tag message from the specified reader or buffer.
                 * @function decode
                 * @memberof vibe.WordInfo.Tag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {vibe.WordInfo.Tag} Tag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Tag.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.WordInfo.Tag();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.confidence = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Tag message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof vibe.WordInfo.Tag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {vibe.WordInfo.Tag} Tag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Tag.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Tag message.
                 * @function verify
                 * @memberof vibe.WordInfo.Tag
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Tag.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.confidence != null && message.hasOwnProperty("confidence"))
                        if (typeof message.confidence !== "number")
                            return "confidence: number expected";
                    return null;
                };
    
                /**
                 * Creates a Tag message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof vibe.WordInfo.Tag
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {vibe.WordInfo.Tag} Tag
                 */
                Tag.fromObject = function fromObject(object) {
                    if (object instanceof $root.vibe.WordInfo.Tag)
                        return object;
                    var message = new $root.vibe.WordInfo.Tag();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.confidence != null)
                        message.confidence = Number(object.confidence);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Tag message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof vibe.WordInfo.Tag
                 * @static
                 * @param {vibe.WordInfo.Tag} message Tag
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Tag.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.confidence = 0;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.confidence != null && message.hasOwnProperty("confidence"))
                        object.confidence = options.json && !isFinite(message.confidence) ? String(message.confidence) : message.confidence;
                    return object;
                };
    
                /**
                 * Converts this Tag to JSON.
                 * @function toJSON
                 * @memberof vibe.WordInfo.Tag
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Tag.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Tag
                 * @function getTypeUrl
                 * @memberof vibe.WordInfo.Tag
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Tag.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/vibe.WordInfo.Tag";
                };
    
                return Tag;
            })();
    
            return WordInfo;
        })();
    
        vibe.NormWordInfo = (function() {
    
            /**
             * Properties of a NormWordInfo.
             * @memberof vibe
             * @interface INormWordInfo
             * @property {string|null} [word] NormWordInfo word
             * @property {boolean|null} [anonymized] NormWordInfo anonymized
             * @property {vibe.NormWordInfo.IIndexSpan|null} [span] NormWordInfo span
             * @property {vibe.NormWordInfo.ITag|null} [partOfSpeech] NormWordInfo partOfSpeech
             * @property {vibe.NormWordInfo.ITag|null} [punctuation] NormWordInfo punctuation
             * @property {vibe.NormWordInfo.ITag|null} [anonInfo] NormWordInfo anonInfo
             */
    
            /**
             * Constructs a new NormWordInfo.
             * @memberof vibe
             * @classdesc Represents a NormWordInfo.
             * @implements INormWordInfo
             * @constructor
             * @param {vibe.INormWordInfo=} [properties] Properties to set
             */
            function NormWordInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * NormWordInfo word.
             * @member {string} word
             * @memberof vibe.NormWordInfo
             * @instance
             */
            NormWordInfo.prototype.word = "";
    
            /**
             * NormWordInfo anonymized.
             * @member {boolean} anonymized
             * @memberof vibe.NormWordInfo
             * @instance
             */
            NormWordInfo.prototype.anonymized = false;
    
            /**
             * NormWordInfo span.
             * @member {vibe.NormWordInfo.IIndexSpan|null|undefined} span
             * @memberof vibe.NormWordInfo
             * @instance
             */
            NormWordInfo.prototype.span = null;
    
            /**
             * NormWordInfo partOfSpeech.
             * @member {vibe.NormWordInfo.ITag|null|undefined} partOfSpeech
             * @memberof vibe.NormWordInfo
             * @instance
             */
            NormWordInfo.prototype.partOfSpeech = null;
    
            /**
             * NormWordInfo punctuation.
             * @member {vibe.NormWordInfo.ITag|null|undefined} punctuation
             * @memberof vibe.NormWordInfo
             * @instance
             */
            NormWordInfo.prototype.punctuation = null;
    
            /**
             * NormWordInfo anonInfo.
             * @member {vibe.NormWordInfo.ITag|null|undefined} anonInfo
             * @memberof vibe.NormWordInfo
             * @instance
             */
            NormWordInfo.prototype.anonInfo = null;
    
            /**
             * Creates a new NormWordInfo instance using the specified properties.
             * @function create
             * @memberof vibe.NormWordInfo
             * @static
             * @param {vibe.INormWordInfo=} [properties] Properties to set
             * @returns {vibe.NormWordInfo} NormWordInfo instance
             */
            NormWordInfo.create = function create(properties) {
                return new NormWordInfo(properties);
            };
    
            /**
             * Encodes the specified NormWordInfo message. Does not implicitly {@link vibe.NormWordInfo.verify|verify} messages.
             * @function encode
             * @memberof vibe.NormWordInfo
             * @static
             * @param {vibe.INormWordInfo} message NormWordInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NormWordInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.word != null && Object.hasOwnProperty.call(message, "word"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.word);
                if (message.anonymized != null && Object.hasOwnProperty.call(message, "anonymized"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.anonymized);
                if (message.span != null && Object.hasOwnProperty.call(message, "span"))
                    $root.vibe.NormWordInfo.IndexSpan.encode(message.span, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.partOfSpeech != null && Object.hasOwnProperty.call(message, "partOfSpeech"))
                    $root.vibe.NormWordInfo.Tag.encode(message.partOfSpeech, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.punctuation != null && Object.hasOwnProperty.call(message, "punctuation"))
                    $root.vibe.NormWordInfo.Tag.encode(message.punctuation, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.anonInfo != null && Object.hasOwnProperty.call(message, "anonInfo"))
                    $root.vibe.NormWordInfo.Tag.encode(message.anonInfo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified NormWordInfo message, length delimited. Does not implicitly {@link vibe.NormWordInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.NormWordInfo
             * @static
             * @param {vibe.INormWordInfo} message NormWordInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NormWordInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a NormWordInfo message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.NormWordInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.NormWordInfo} NormWordInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NormWordInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.NormWordInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.word = reader.string();
                            break;
                        }
                    case 2: {
                            message.anonymized = reader.bool();
                            break;
                        }
                    case 3: {
                            message.span = $root.vibe.NormWordInfo.IndexSpan.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.partOfSpeech = $root.vibe.NormWordInfo.Tag.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.punctuation = $root.vibe.NormWordInfo.Tag.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.anonInfo = $root.vibe.NormWordInfo.Tag.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a NormWordInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.NormWordInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.NormWordInfo} NormWordInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NormWordInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a NormWordInfo message.
             * @function verify
             * @memberof vibe.NormWordInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NormWordInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.word != null && message.hasOwnProperty("word"))
                    if (!$util.isString(message.word))
                        return "word: string expected";
                if (message.anonymized != null && message.hasOwnProperty("anonymized"))
                    if (typeof message.anonymized !== "boolean")
                        return "anonymized: boolean expected";
                if (message.span != null && message.hasOwnProperty("span")) {
                    var error = $root.vibe.NormWordInfo.IndexSpan.verify(message.span);
                    if (error)
                        return "span." + error;
                }
                if (message.partOfSpeech != null && message.hasOwnProperty("partOfSpeech")) {
                    var error = $root.vibe.NormWordInfo.Tag.verify(message.partOfSpeech);
                    if (error)
                        return "partOfSpeech." + error;
                }
                if (message.punctuation != null && message.hasOwnProperty("punctuation")) {
                    var error = $root.vibe.NormWordInfo.Tag.verify(message.punctuation);
                    if (error)
                        return "punctuation." + error;
                }
                if (message.anonInfo != null && message.hasOwnProperty("anonInfo")) {
                    var error = $root.vibe.NormWordInfo.Tag.verify(message.anonInfo);
                    if (error)
                        return "anonInfo." + error;
                }
                return null;
            };
    
            /**
             * Creates a NormWordInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.NormWordInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.NormWordInfo} NormWordInfo
             */
            NormWordInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.NormWordInfo)
                    return object;
                var message = new $root.vibe.NormWordInfo();
                if (object.word != null)
                    message.word = String(object.word);
                if (object.anonymized != null)
                    message.anonymized = Boolean(object.anonymized);
                if (object.span != null) {
                    if (typeof object.span !== "object")
                        throw TypeError(".vibe.NormWordInfo.span: object expected");
                    message.span = $root.vibe.NormWordInfo.IndexSpan.fromObject(object.span);
                }
                if (object.partOfSpeech != null) {
                    if (typeof object.partOfSpeech !== "object")
                        throw TypeError(".vibe.NormWordInfo.partOfSpeech: object expected");
                    message.partOfSpeech = $root.vibe.NormWordInfo.Tag.fromObject(object.partOfSpeech);
                }
                if (object.punctuation != null) {
                    if (typeof object.punctuation !== "object")
                        throw TypeError(".vibe.NormWordInfo.punctuation: object expected");
                    message.punctuation = $root.vibe.NormWordInfo.Tag.fromObject(object.punctuation);
                }
                if (object.anonInfo != null) {
                    if (typeof object.anonInfo !== "object")
                        throw TypeError(".vibe.NormWordInfo.anonInfo: object expected");
                    message.anonInfo = $root.vibe.NormWordInfo.Tag.fromObject(object.anonInfo);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a NormWordInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.NormWordInfo
             * @static
             * @param {vibe.NormWordInfo} message NormWordInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NormWordInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.word = "";
                    object.anonymized = false;
                    object.span = null;
                    object.partOfSpeech = null;
                    object.punctuation = null;
                    object.anonInfo = null;
                }
                if (message.word != null && message.hasOwnProperty("word"))
                    object.word = message.word;
                if (message.anonymized != null && message.hasOwnProperty("anonymized"))
                    object.anonymized = message.anonymized;
                if (message.span != null && message.hasOwnProperty("span"))
                    object.span = $root.vibe.NormWordInfo.IndexSpan.toObject(message.span, options);
                if (message.partOfSpeech != null && message.hasOwnProperty("partOfSpeech"))
                    object.partOfSpeech = $root.vibe.NormWordInfo.Tag.toObject(message.partOfSpeech, options);
                if (message.punctuation != null && message.hasOwnProperty("punctuation"))
                    object.punctuation = $root.vibe.NormWordInfo.Tag.toObject(message.punctuation, options);
                if (message.anonInfo != null && message.hasOwnProperty("anonInfo"))
                    object.anonInfo = $root.vibe.NormWordInfo.Tag.toObject(message.anonInfo, options);
                return object;
            };
    
            /**
             * Converts this NormWordInfo to JSON.
             * @function toJSON
             * @memberof vibe.NormWordInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NormWordInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for NormWordInfo
             * @function getTypeUrl
             * @memberof vibe.NormWordInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NormWordInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.NormWordInfo";
            };
    
            NormWordInfo.IndexSpan = (function() {
    
                /**
                 * Properties of an IndexSpan.
                 * @memberof vibe.NormWordInfo
                 * @interface IIndexSpan
                 * @property {number|null} [begin] IndexSpan begin
                 * @property {number|null} [end] IndexSpan end
                 */
    
                /**
                 * Constructs a new IndexSpan.
                 * @memberof vibe.NormWordInfo
                 * @classdesc Represents an IndexSpan.
                 * @implements IIndexSpan
                 * @constructor
                 * @param {vibe.NormWordInfo.IIndexSpan=} [properties] Properties to set
                 */
                function IndexSpan(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IndexSpan begin.
                 * @member {number} begin
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @instance
                 */
                IndexSpan.prototype.begin = 0;
    
                /**
                 * IndexSpan end.
                 * @member {number} end
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @instance
                 */
                IndexSpan.prototype.end = 0;
    
                /**
                 * Creates a new IndexSpan instance using the specified properties.
                 * @function create
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @static
                 * @param {vibe.NormWordInfo.IIndexSpan=} [properties] Properties to set
                 * @returns {vibe.NormWordInfo.IndexSpan} IndexSpan instance
                 */
                IndexSpan.create = function create(properties) {
                    return new IndexSpan(properties);
                };
    
                /**
                 * Encodes the specified IndexSpan message. Does not implicitly {@link vibe.NormWordInfo.IndexSpan.verify|verify} messages.
                 * @function encode
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @static
                 * @param {vibe.NormWordInfo.IIndexSpan} message IndexSpan message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IndexSpan.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.begin);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };
    
                /**
                 * Encodes the specified IndexSpan message, length delimited. Does not implicitly {@link vibe.NormWordInfo.IndexSpan.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @static
                 * @param {vibe.NormWordInfo.IIndexSpan} message IndexSpan message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IndexSpan.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an IndexSpan message from the specified reader or buffer.
                 * @function decode
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {vibe.NormWordInfo.IndexSpan} IndexSpan
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IndexSpan.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.NormWordInfo.IndexSpan();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.begin = reader.int32();
                                break;
                            }
                        case 2: {
                                message.end = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an IndexSpan message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {vibe.NormWordInfo.IndexSpan} IndexSpan
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IndexSpan.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an IndexSpan message.
                 * @function verify
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IndexSpan.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        if (!$util.isInteger(message.begin))
                            return "begin: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };
    
                /**
                 * Creates an IndexSpan message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {vibe.NormWordInfo.IndexSpan} IndexSpan
                 */
                IndexSpan.fromObject = function fromObject(object) {
                    if (object instanceof $root.vibe.NormWordInfo.IndexSpan)
                        return object;
                    var message = new $root.vibe.NormWordInfo.IndexSpan();
                    if (object.begin != null)
                        message.begin = object.begin | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from an IndexSpan message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @static
                 * @param {vibe.NormWordInfo.IndexSpan} message IndexSpan
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IndexSpan.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.begin = 0;
                        object.end = 0;
                    }
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        object.begin = message.begin;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };
    
                /**
                 * Converts this IndexSpan to JSON.
                 * @function toJSON
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IndexSpan.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IndexSpan
                 * @function getTypeUrl
                 * @memberof vibe.NormWordInfo.IndexSpan
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IndexSpan.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/vibe.NormWordInfo.IndexSpan";
                };
    
                return IndexSpan;
            })();
    
            NormWordInfo.Tag = (function() {
    
                /**
                 * Properties of a Tag.
                 * @memberof vibe.NormWordInfo
                 * @interface ITag
                 * @property {string|null} [name] Tag name
                 * @property {number|null} [confidence] Tag confidence
                 */
    
                /**
                 * Constructs a new Tag.
                 * @memberof vibe.NormWordInfo
                 * @classdesc Represents a Tag.
                 * @implements ITag
                 * @constructor
                 * @param {vibe.NormWordInfo.ITag=} [properties] Properties to set
                 */
                function Tag(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Tag name.
                 * @member {string} name
                 * @memberof vibe.NormWordInfo.Tag
                 * @instance
                 */
                Tag.prototype.name = "";
    
                /**
                 * Tag confidence.
                 * @member {number} confidence
                 * @memberof vibe.NormWordInfo.Tag
                 * @instance
                 */
                Tag.prototype.confidence = 0;
    
                /**
                 * Creates a new Tag instance using the specified properties.
                 * @function create
                 * @memberof vibe.NormWordInfo.Tag
                 * @static
                 * @param {vibe.NormWordInfo.ITag=} [properties] Properties to set
                 * @returns {vibe.NormWordInfo.Tag} Tag instance
                 */
                Tag.create = function create(properties) {
                    return new Tag(properties);
                };
    
                /**
                 * Encodes the specified Tag message. Does not implicitly {@link vibe.NormWordInfo.Tag.verify|verify} messages.
                 * @function encode
                 * @memberof vibe.NormWordInfo.Tag
                 * @static
                 * @param {vibe.NormWordInfo.ITag} message Tag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Tag.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.confidence != null && Object.hasOwnProperty.call(message, "confidence"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.confidence);
                    return writer;
                };
    
                /**
                 * Encodes the specified Tag message, length delimited. Does not implicitly {@link vibe.NormWordInfo.Tag.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof vibe.NormWordInfo.Tag
                 * @static
                 * @param {vibe.NormWordInfo.ITag} message Tag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Tag.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Tag message from the specified reader or buffer.
                 * @function decode
                 * @memberof vibe.NormWordInfo.Tag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {vibe.NormWordInfo.Tag} Tag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Tag.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.NormWordInfo.Tag();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.confidence = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Tag message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof vibe.NormWordInfo.Tag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {vibe.NormWordInfo.Tag} Tag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Tag.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Tag message.
                 * @function verify
                 * @memberof vibe.NormWordInfo.Tag
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Tag.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.confidence != null && message.hasOwnProperty("confidence"))
                        if (typeof message.confidence !== "number")
                            return "confidence: number expected";
                    return null;
                };
    
                /**
                 * Creates a Tag message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof vibe.NormWordInfo.Tag
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {vibe.NormWordInfo.Tag} Tag
                 */
                Tag.fromObject = function fromObject(object) {
                    if (object instanceof $root.vibe.NormWordInfo.Tag)
                        return object;
                    var message = new $root.vibe.NormWordInfo.Tag();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.confidence != null)
                        message.confidence = Number(object.confidence);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Tag message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof vibe.NormWordInfo.Tag
                 * @static
                 * @param {vibe.NormWordInfo.Tag} message Tag
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Tag.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.confidence = 0;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.confidence != null && message.hasOwnProperty("confidence"))
                        object.confidence = options.json && !isFinite(message.confidence) ? String(message.confidence) : message.confidence;
                    return object;
                };
    
                /**
                 * Converts this Tag to JSON.
                 * @function toJSON
                 * @memberof vibe.NormWordInfo.Tag
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Tag.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Tag
                 * @function getTypeUrl
                 * @memberof vibe.NormWordInfo.Tag
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Tag.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/vibe.NormWordInfo.Tag";
                };
    
                return Tag;
            })();
    
            return NormWordInfo;
        })();
    
        vibe.UtteranceTranscript = (function() {
    
            /**
             * Properties of an UtteranceTranscript.
             * @memberof vibe
             * @interface IUtteranceTranscript
             * @property {string|null} [uttId] UtteranceTranscript uttId
             * @property {Array.<vibe.IWordInfo>|null} [words] UtteranceTranscript words
             */
    
            /**
             * Constructs a new UtteranceTranscript.
             * @memberof vibe
             * @classdesc Represents an UtteranceTranscript.
             * @implements IUtteranceTranscript
             * @constructor
             * @param {vibe.IUtteranceTranscript=} [properties] Properties to set
             */
            function UtteranceTranscript(properties) {
                this.words = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UtteranceTranscript uttId.
             * @member {string} uttId
             * @memberof vibe.UtteranceTranscript
             * @instance
             */
            UtteranceTranscript.prototype.uttId = "";
    
            /**
             * UtteranceTranscript words.
             * @member {Array.<vibe.IWordInfo>} words
             * @memberof vibe.UtteranceTranscript
             * @instance
             */
            UtteranceTranscript.prototype.words = $util.emptyArray;
    
            /**
             * Creates a new UtteranceTranscript instance using the specified properties.
             * @function create
             * @memberof vibe.UtteranceTranscript
             * @static
             * @param {vibe.IUtteranceTranscript=} [properties] Properties to set
             * @returns {vibe.UtteranceTranscript} UtteranceTranscript instance
             */
            UtteranceTranscript.create = function create(properties) {
                return new UtteranceTranscript(properties);
            };
    
            /**
             * Encodes the specified UtteranceTranscript message. Does not implicitly {@link vibe.UtteranceTranscript.verify|verify} messages.
             * @function encode
             * @memberof vibe.UtteranceTranscript
             * @static
             * @param {vibe.IUtteranceTranscript} message UtteranceTranscript message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UtteranceTranscript.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uttId != null && Object.hasOwnProperty.call(message, "uttId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.uttId);
                if (message.words != null && message.words.length)
                    for (var i = 0; i < message.words.length; ++i)
                        $root.vibe.WordInfo.encode(message.words[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified UtteranceTranscript message, length delimited. Does not implicitly {@link vibe.UtteranceTranscript.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.UtteranceTranscript
             * @static
             * @param {vibe.IUtteranceTranscript} message UtteranceTranscript message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UtteranceTranscript.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UtteranceTranscript message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.UtteranceTranscript
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.UtteranceTranscript} UtteranceTranscript
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UtteranceTranscript.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.UtteranceTranscript();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.uttId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.words && message.words.length))
                                message.words = [];
                            message.words.push($root.vibe.WordInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UtteranceTranscript message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.UtteranceTranscript
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.UtteranceTranscript} UtteranceTranscript
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UtteranceTranscript.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UtteranceTranscript message.
             * @function verify
             * @memberof vibe.UtteranceTranscript
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UtteranceTranscript.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uttId != null && message.hasOwnProperty("uttId"))
                    if (!$util.isString(message.uttId))
                        return "uttId: string expected";
                if (message.words != null && message.hasOwnProperty("words")) {
                    if (!Array.isArray(message.words))
                        return "words: array expected";
                    for (var i = 0; i < message.words.length; ++i) {
                        var error = $root.vibe.WordInfo.verify(message.words[i]);
                        if (error)
                            return "words." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an UtteranceTranscript message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.UtteranceTranscript
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.UtteranceTranscript} UtteranceTranscript
             */
            UtteranceTranscript.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.UtteranceTranscript)
                    return object;
                var message = new $root.vibe.UtteranceTranscript();
                if (object.uttId != null)
                    message.uttId = String(object.uttId);
                if (object.words) {
                    if (!Array.isArray(object.words))
                        throw TypeError(".vibe.UtteranceTranscript.words: array expected");
                    message.words = [];
                    for (var i = 0; i < object.words.length; ++i) {
                        if (typeof object.words[i] !== "object")
                            throw TypeError(".vibe.UtteranceTranscript.words: object expected");
                        message.words[i] = $root.vibe.WordInfo.fromObject(object.words[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UtteranceTranscript message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.UtteranceTranscript
             * @static
             * @param {vibe.UtteranceTranscript} message UtteranceTranscript
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UtteranceTranscript.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.words = [];
                if (options.defaults)
                    object.uttId = "";
                if (message.uttId != null && message.hasOwnProperty("uttId"))
                    object.uttId = message.uttId;
                if (message.words && message.words.length) {
                    object.words = [];
                    for (var j = 0; j < message.words.length; ++j)
                        object.words[j] = $root.vibe.WordInfo.toObject(message.words[j], options);
                }
                return object;
            };
    
            /**
             * Converts this UtteranceTranscript to JSON.
             * @function toJSON
             * @memberof vibe.UtteranceTranscript
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UtteranceTranscript.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UtteranceTranscript
             * @function getTypeUrl
             * @memberof vibe.UtteranceTranscript
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UtteranceTranscript.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.UtteranceTranscript";
            };
    
            return UtteranceTranscript;
        })();
    
        vibe.UtteranceTranscriptBatch = (function() {
    
            /**
             * Properties of an UtteranceTranscriptBatch.
             * @memberof vibe
             * @interface IUtteranceTranscriptBatch
             * @property {Array.<vibe.IUtteranceTranscript>|null} [utteranceTranscripts] UtteranceTranscriptBatch utteranceTranscripts
             */
    
            /**
             * Constructs a new UtteranceTranscriptBatch.
             * @memberof vibe
             * @classdesc Represents an UtteranceTranscriptBatch.
             * @implements IUtteranceTranscriptBatch
             * @constructor
             * @param {vibe.IUtteranceTranscriptBatch=} [properties] Properties to set
             */
            function UtteranceTranscriptBatch(properties) {
                this.utteranceTranscripts = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UtteranceTranscriptBatch utteranceTranscripts.
             * @member {Array.<vibe.IUtteranceTranscript>} utteranceTranscripts
             * @memberof vibe.UtteranceTranscriptBatch
             * @instance
             */
            UtteranceTranscriptBatch.prototype.utteranceTranscripts = $util.emptyArray;
    
            /**
             * Creates a new UtteranceTranscriptBatch instance using the specified properties.
             * @function create
             * @memberof vibe.UtteranceTranscriptBatch
             * @static
             * @param {vibe.IUtteranceTranscriptBatch=} [properties] Properties to set
             * @returns {vibe.UtteranceTranscriptBatch} UtteranceTranscriptBatch instance
             */
            UtteranceTranscriptBatch.create = function create(properties) {
                return new UtteranceTranscriptBatch(properties);
            };
    
            /**
             * Encodes the specified UtteranceTranscriptBatch message. Does not implicitly {@link vibe.UtteranceTranscriptBatch.verify|verify} messages.
             * @function encode
             * @memberof vibe.UtteranceTranscriptBatch
             * @static
             * @param {vibe.IUtteranceTranscriptBatch} message UtteranceTranscriptBatch message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UtteranceTranscriptBatch.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.utteranceTranscripts != null && message.utteranceTranscripts.length)
                    for (var i = 0; i < message.utteranceTranscripts.length; ++i)
                        $root.vibe.UtteranceTranscript.encode(message.utteranceTranscripts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified UtteranceTranscriptBatch message, length delimited. Does not implicitly {@link vibe.UtteranceTranscriptBatch.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.UtteranceTranscriptBatch
             * @static
             * @param {vibe.IUtteranceTranscriptBatch} message UtteranceTranscriptBatch message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UtteranceTranscriptBatch.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UtteranceTranscriptBatch message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.UtteranceTranscriptBatch
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.UtteranceTranscriptBatch} UtteranceTranscriptBatch
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UtteranceTranscriptBatch.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.UtteranceTranscriptBatch();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.utteranceTranscripts && message.utteranceTranscripts.length))
                                message.utteranceTranscripts = [];
                            message.utteranceTranscripts.push($root.vibe.UtteranceTranscript.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UtteranceTranscriptBatch message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.UtteranceTranscriptBatch
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.UtteranceTranscriptBatch} UtteranceTranscriptBatch
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UtteranceTranscriptBatch.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UtteranceTranscriptBatch message.
             * @function verify
             * @memberof vibe.UtteranceTranscriptBatch
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UtteranceTranscriptBatch.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.utteranceTranscripts != null && message.hasOwnProperty("utteranceTranscripts")) {
                    if (!Array.isArray(message.utteranceTranscripts))
                        return "utteranceTranscripts: array expected";
                    for (var i = 0; i < message.utteranceTranscripts.length; ++i) {
                        var error = $root.vibe.UtteranceTranscript.verify(message.utteranceTranscripts[i]);
                        if (error)
                            return "utteranceTranscripts." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an UtteranceTranscriptBatch message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.UtteranceTranscriptBatch
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.UtteranceTranscriptBatch} UtteranceTranscriptBatch
             */
            UtteranceTranscriptBatch.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.UtteranceTranscriptBatch)
                    return object;
                var message = new $root.vibe.UtteranceTranscriptBatch();
                if (object.utteranceTranscripts) {
                    if (!Array.isArray(object.utteranceTranscripts))
                        throw TypeError(".vibe.UtteranceTranscriptBatch.utteranceTranscripts: array expected");
                    message.utteranceTranscripts = [];
                    for (var i = 0; i < object.utteranceTranscripts.length; ++i) {
                        if (typeof object.utteranceTranscripts[i] !== "object")
                            throw TypeError(".vibe.UtteranceTranscriptBatch.utteranceTranscripts: object expected");
                        message.utteranceTranscripts[i] = $root.vibe.UtteranceTranscript.fromObject(object.utteranceTranscripts[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UtteranceTranscriptBatch message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.UtteranceTranscriptBatch
             * @static
             * @param {vibe.UtteranceTranscriptBatch} message UtteranceTranscriptBatch
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UtteranceTranscriptBatch.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.utteranceTranscripts = [];
                if (message.utteranceTranscripts && message.utteranceTranscripts.length) {
                    object.utteranceTranscripts = [];
                    for (var j = 0; j < message.utteranceTranscripts.length; ++j)
                        object.utteranceTranscripts[j] = $root.vibe.UtteranceTranscript.toObject(message.utteranceTranscripts[j], options);
                }
                return object;
            };
    
            /**
             * Converts this UtteranceTranscriptBatch to JSON.
             * @function toJSON
             * @memberof vibe.UtteranceTranscriptBatch
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UtteranceTranscriptBatch.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UtteranceTranscriptBatch
             * @function getTypeUrl
             * @memberof vibe.UtteranceTranscriptBatch
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UtteranceTranscriptBatch.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.UtteranceTranscriptBatch";
            };
    
            return UtteranceTranscriptBatch;
        })();
    
        vibe.UtteranceRecognizeResponse = (function() {
    
            /**
             * Properties of an UtteranceRecognizeResponse.
             * @memberof vibe
             * @interface IUtteranceRecognizeResponse
             * @property {string|null} [uttId] UtteranceRecognizeResponse uttId
             * @property {Array.<vibe.IRecognizeResponse>|null} [responses] UtteranceRecognizeResponse responses
             */
    
            /**
             * Constructs a new UtteranceRecognizeResponse.
             * @memberof vibe
             * @classdesc Represents an UtteranceRecognizeResponse.
             * @implements IUtteranceRecognizeResponse
             * @constructor
             * @param {vibe.IUtteranceRecognizeResponse=} [properties] Properties to set
             */
            function UtteranceRecognizeResponse(properties) {
                this.responses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UtteranceRecognizeResponse uttId.
             * @member {string} uttId
             * @memberof vibe.UtteranceRecognizeResponse
             * @instance
             */
            UtteranceRecognizeResponse.prototype.uttId = "";
    
            /**
             * UtteranceRecognizeResponse responses.
             * @member {Array.<vibe.IRecognizeResponse>} responses
             * @memberof vibe.UtteranceRecognizeResponse
             * @instance
             */
            UtteranceRecognizeResponse.prototype.responses = $util.emptyArray;
    
            /**
             * Creates a new UtteranceRecognizeResponse instance using the specified properties.
             * @function create
             * @memberof vibe.UtteranceRecognizeResponse
             * @static
             * @param {vibe.IUtteranceRecognizeResponse=} [properties] Properties to set
             * @returns {vibe.UtteranceRecognizeResponse} UtteranceRecognizeResponse instance
             */
            UtteranceRecognizeResponse.create = function create(properties) {
                return new UtteranceRecognizeResponse(properties);
            };
    
            /**
             * Encodes the specified UtteranceRecognizeResponse message. Does not implicitly {@link vibe.UtteranceRecognizeResponse.verify|verify} messages.
             * @function encode
             * @memberof vibe.UtteranceRecognizeResponse
             * @static
             * @param {vibe.IUtteranceRecognizeResponse} message UtteranceRecognizeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UtteranceRecognizeResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uttId != null && Object.hasOwnProperty.call(message, "uttId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.uttId);
                if (message.responses != null && message.responses.length)
                    for (var i = 0; i < message.responses.length; ++i)
                        $root.vibe.RecognizeResponse.encode(message.responses[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified UtteranceRecognizeResponse message, length delimited. Does not implicitly {@link vibe.UtteranceRecognizeResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.UtteranceRecognizeResponse
             * @static
             * @param {vibe.IUtteranceRecognizeResponse} message UtteranceRecognizeResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UtteranceRecognizeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UtteranceRecognizeResponse message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.UtteranceRecognizeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.UtteranceRecognizeResponse} UtteranceRecognizeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UtteranceRecognizeResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.UtteranceRecognizeResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.uttId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.responses && message.responses.length))
                                message.responses = [];
                            message.responses.push($root.vibe.RecognizeResponse.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UtteranceRecognizeResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.UtteranceRecognizeResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.UtteranceRecognizeResponse} UtteranceRecognizeResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UtteranceRecognizeResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UtteranceRecognizeResponse message.
             * @function verify
             * @memberof vibe.UtteranceRecognizeResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UtteranceRecognizeResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uttId != null && message.hasOwnProperty("uttId"))
                    if (!$util.isString(message.uttId))
                        return "uttId: string expected";
                if (message.responses != null && message.hasOwnProperty("responses")) {
                    if (!Array.isArray(message.responses))
                        return "responses: array expected";
                    for (var i = 0; i < message.responses.length; ++i) {
                        var error = $root.vibe.RecognizeResponse.verify(message.responses[i]);
                        if (error)
                            return "responses." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an UtteranceRecognizeResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.UtteranceRecognizeResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.UtteranceRecognizeResponse} UtteranceRecognizeResponse
             */
            UtteranceRecognizeResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.UtteranceRecognizeResponse)
                    return object;
                var message = new $root.vibe.UtteranceRecognizeResponse();
                if (object.uttId != null)
                    message.uttId = String(object.uttId);
                if (object.responses) {
                    if (!Array.isArray(object.responses))
                        throw TypeError(".vibe.UtteranceRecognizeResponse.responses: array expected");
                    message.responses = [];
                    for (var i = 0; i < object.responses.length; ++i) {
                        if (typeof object.responses[i] !== "object")
                            throw TypeError(".vibe.UtteranceRecognizeResponse.responses: object expected");
                        message.responses[i] = $root.vibe.RecognizeResponse.fromObject(object.responses[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UtteranceRecognizeResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.UtteranceRecognizeResponse
             * @static
             * @param {vibe.UtteranceRecognizeResponse} message UtteranceRecognizeResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UtteranceRecognizeResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.responses = [];
                if (options.defaults)
                    object.uttId = "";
                if (message.uttId != null && message.hasOwnProperty("uttId"))
                    object.uttId = message.uttId;
                if (message.responses && message.responses.length) {
                    object.responses = [];
                    for (var j = 0; j < message.responses.length; ++j)
                        object.responses[j] = $root.vibe.RecognizeResponse.toObject(message.responses[j], options);
                }
                return object;
            };
    
            /**
             * Converts this UtteranceRecognizeResponse to JSON.
             * @function toJSON
             * @memberof vibe.UtteranceRecognizeResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UtteranceRecognizeResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UtteranceRecognizeResponse
             * @function getTypeUrl
             * @memberof vibe.UtteranceRecognizeResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UtteranceRecognizeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.UtteranceRecognizeResponse";
            };
    
            return UtteranceRecognizeResponse;
        })();
    
        vibe.UtteranceRecognizeResponseBatch = (function() {
    
            /**
             * Properties of an UtteranceRecognizeResponseBatch.
             * @memberof vibe
             * @interface IUtteranceRecognizeResponseBatch
             * @property {Array.<vibe.IUtteranceRecognizeResponse>|null} [utteranceResponses] UtteranceRecognizeResponseBatch utteranceResponses
             */
    
            /**
             * Constructs a new UtteranceRecognizeResponseBatch.
             * @memberof vibe
             * @classdesc Represents an UtteranceRecognizeResponseBatch.
             * @implements IUtteranceRecognizeResponseBatch
             * @constructor
             * @param {vibe.IUtteranceRecognizeResponseBatch=} [properties] Properties to set
             */
            function UtteranceRecognizeResponseBatch(properties) {
                this.utteranceResponses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UtteranceRecognizeResponseBatch utteranceResponses.
             * @member {Array.<vibe.IUtteranceRecognizeResponse>} utteranceResponses
             * @memberof vibe.UtteranceRecognizeResponseBatch
             * @instance
             */
            UtteranceRecognizeResponseBatch.prototype.utteranceResponses = $util.emptyArray;
    
            /**
             * Creates a new UtteranceRecognizeResponseBatch instance using the specified properties.
             * @function create
             * @memberof vibe.UtteranceRecognizeResponseBatch
             * @static
             * @param {vibe.IUtteranceRecognizeResponseBatch=} [properties] Properties to set
             * @returns {vibe.UtteranceRecognizeResponseBatch} UtteranceRecognizeResponseBatch instance
             */
            UtteranceRecognizeResponseBatch.create = function create(properties) {
                return new UtteranceRecognizeResponseBatch(properties);
            };
    
            /**
             * Encodes the specified UtteranceRecognizeResponseBatch message. Does not implicitly {@link vibe.UtteranceRecognizeResponseBatch.verify|verify} messages.
             * @function encode
             * @memberof vibe.UtteranceRecognizeResponseBatch
             * @static
             * @param {vibe.IUtteranceRecognizeResponseBatch} message UtteranceRecognizeResponseBatch message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UtteranceRecognizeResponseBatch.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.utteranceResponses != null && message.utteranceResponses.length)
                    for (var i = 0; i < message.utteranceResponses.length; ++i)
                        $root.vibe.UtteranceRecognizeResponse.encode(message.utteranceResponses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified UtteranceRecognizeResponseBatch message, length delimited. Does not implicitly {@link vibe.UtteranceRecognizeResponseBatch.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.UtteranceRecognizeResponseBatch
             * @static
             * @param {vibe.IUtteranceRecognizeResponseBatch} message UtteranceRecognizeResponseBatch message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UtteranceRecognizeResponseBatch.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UtteranceRecognizeResponseBatch message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.UtteranceRecognizeResponseBatch
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.UtteranceRecognizeResponseBatch} UtteranceRecognizeResponseBatch
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UtteranceRecognizeResponseBatch.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.UtteranceRecognizeResponseBatch();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.utteranceResponses && message.utteranceResponses.length))
                                message.utteranceResponses = [];
                            message.utteranceResponses.push($root.vibe.UtteranceRecognizeResponse.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UtteranceRecognizeResponseBatch message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.UtteranceRecognizeResponseBatch
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.UtteranceRecognizeResponseBatch} UtteranceRecognizeResponseBatch
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UtteranceRecognizeResponseBatch.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UtteranceRecognizeResponseBatch message.
             * @function verify
             * @memberof vibe.UtteranceRecognizeResponseBatch
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UtteranceRecognizeResponseBatch.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.utteranceResponses != null && message.hasOwnProperty("utteranceResponses")) {
                    if (!Array.isArray(message.utteranceResponses))
                        return "utteranceResponses: array expected";
                    for (var i = 0; i < message.utteranceResponses.length; ++i) {
                        var error = $root.vibe.UtteranceRecognizeResponse.verify(message.utteranceResponses[i]);
                        if (error)
                            return "utteranceResponses." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an UtteranceRecognizeResponseBatch message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.UtteranceRecognizeResponseBatch
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.UtteranceRecognizeResponseBatch} UtteranceRecognizeResponseBatch
             */
            UtteranceRecognizeResponseBatch.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.UtteranceRecognizeResponseBatch)
                    return object;
                var message = new $root.vibe.UtteranceRecognizeResponseBatch();
                if (object.utteranceResponses) {
                    if (!Array.isArray(object.utteranceResponses))
                        throw TypeError(".vibe.UtteranceRecognizeResponseBatch.utteranceResponses: array expected");
                    message.utteranceResponses = [];
                    for (var i = 0; i < object.utteranceResponses.length; ++i) {
                        if (typeof object.utteranceResponses[i] !== "object")
                            throw TypeError(".vibe.UtteranceRecognizeResponseBatch.utteranceResponses: object expected");
                        message.utteranceResponses[i] = $root.vibe.UtteranceRecognizeResponse.fromObject(object.utteranceResponses[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UtteranceRecognizeResponseBatch message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.UtteranceRecognizeResponseBatch
             * @static
             * @param {vibe.UtteranceRecognizeResponseBatch} message UtteranceRecognizeResponseBatch
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UtteranceRecognizeResponseBatch.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.utteranceResponses = [];
                if (message.utteranceResponses && message.utteranceResponses.length) {
                    object.utteranceResponses = [];
                    for (var j = 0; j < message.utteranceResponses.length; ++j)
                        object.utteranceResponses[j] = $root.vibe.UtteranceRecognizeResponse.toObject(message.utteranceResponses[j], options);
                }
                return object;
            };
    
            /**
             * Converts this UtteranceRecognizeResponseBatch to JSON.
             * @function toJSON
             * @memberof vibe.UtteranceRecognizeResponseBatch
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UtteranceRecognizeResponseBatch.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UtteranceRecognizeResponseBatch
             * @function getTypeUrl
             * @memberof vibe.UtteranceRecognizeResponseBatch
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UtteranceRecognizeResponseBatch.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.UtteranceRecognizeResponseBatch";
            };
    
            return UtteranceRecognizeResponseBatch;
        })();
    
        vibe.BattleCard = (function() {
    
            /**
             * Properties of a BattleCard.
             * @memberof vibe
             * @interface IBattleCard
             * @property {string|null} [text] BattleCard text
             * @property {string|null} [url] BattleCard url
             */
    
            /**
             * Constructs a new BattleCard.
             * @memberof vibe
             * @classdesc Represents a BattleCard.
             * @implements IBattleCard
             * @constructor
             * @param {vibe.IBattleCard=} [properties] Properties to set
             */
            function BattleCard(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BattleCard text.
             * @member {string} text
             * @memberof vibe.BattleCard
             * @instance
             */
            BattleCard.prototype.text = "";
    
            /**
             * BattleCard url.
             * @member {string} url
             * @memberof vibe.BattleCard
             * @instance
             */
            BattleCard.prototype.url = "";
    
            /**
             * Creates a new BattleCard instance using the specified properties.
             * @function create
             * @memberof vibe.BattleCard
             * @static
             * @param {vibe.IBattleCard=} [properties] Properties to set
             * @returns {vibe.BattleCard} BattleCard instance
             */
            BattleCard.create = function create(properties) {
                return new BattleCard(properties);
            };
    
            /**
             * Encodes the specified BattleCard message. Does not implicitly {@link vibe.BattleCard.verify|verify} messages.
             * @function encode
             * @memberof vibe.BattleCard
             * @static
             * @param {vibe.IBattleCard} message BattleCard message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BattleCard.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
                return writer;
            };
    
            /**
             * Encodes the specified BattleCard message, length delimited. Does not implicitly {@link vibe.BattleCard.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.BattleCard
             * @static
             * @param {vibe.IBattleCard} message BattleCard message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BattleCard.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BattleCard message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.BattleCard
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.BattleCard} BattleCard
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BattleCard.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.BattleCard();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.text = reader.string();
                            break;
                        }
                    case 2: {
                            message.url = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BattleCard message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.BattleCard
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.BattleCard} BattleCard
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BattleCard.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BattleCard message.
             * @function verify
             * @memberof vibe.BattleCard
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BattleCard.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                if (message.url != null && message.hasOwnProperty("url"))
                    if (!$util.isString(message.url))
                        return "url: string expected";
                return null;
            };
    
            /**
             * Creates a BattleCard message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.BattleCard
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.BattleCard} BattleCard
             */
            BattleCard.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.BattleCard)
                    return object;
                var message = new $root.vibe.BattleCard();
                if (object.text != null)
                    message.text = String(object.text);
                if (object.url != null)
                    message.url = String(object.url);
                return message;
            };
    
            /**
             * Creates a plain object from a BattleCard message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.BattleCard
             * @static
             * @param {vibe.BattleCard} message BattleCard
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BattleCard.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.text = "";
                    object.url = "";
                }
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                return object;
            };
    
            /**
             * Converts this BattleCard to JSON.
             * @function toJSON
             * @memberof vibe.BattleCard
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BattleCard.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BattleCard
             * @function getTypeUrl
             * @memberof vibe.BattleCard
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BattleCard.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.BattleCard";
            };
    
            return BattleCard;
        })();
    
        vibe.PhraseSpottingResult = (function() {
    
            /**
             * Properties of a PhraseSpottingResult.
             * @memberof vibe
             * @interface IPhraseSpottingResult
             * @property {Array.<vibe.IPhraseSpottingResultEntry>|null} [whitelist] PhraseSpottingResult whitelist
             * @property {Array.<vibe.IPhraseSpottingResultEntry>|null} [blacklist] PhraseSpottingResult blacklist
             */
    
            /**
             * Constructs a new PhraseSpottingResult.
             * @memberof vibe
             * @classdesc Represents a PhraseSpottingResult.
             * @implements IPhraseSpottingResult
             * @constructor
             * @param {vibe.IPhraseSpottingResult=} [properties] Properties to set
             */
            function PhraseSpottingResult(properties) {
                this.whitelist = [];
                this.blacklist = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PhraseSpottingResult whitelist.
             * @member {Array.<vibe.IPhraseSpottingResultEntry>} whitelist
             * @memberof vibe.PhraseSpottingResult
             * @instance
             */
            PhraseSpottingResult.prototype.whitelist = $util.emptyArray;
    
            /**
             * PhraseSpottingResult blacklist.
             * @member {Array.<vibe.IPhraseSpottingResultEntry>} blacklist
             * @memberof vibe.PhraseSpottingResult
             * @instance
             */
            PhraseSpottingResult.prototype.blacklist = $util.emptyArray;
    
            /**
             * Creates a new PhraseSpottingResult instance using the specified properties.
             * @function create
             * @memberof vibe.PhraseSpottingResult
             * @static
             * @param {vibe.IPhraseSpottingResult=} [properties] Properties to set
             * @returns {vibe.PhraseSpottingResult} PhraseSpottingResult instance
             */
            PhraseSpottingResult.create = function create(properties) {
                return new PhraseSpottingResult(properties);
            };
    
            /**
             * Encodes the specified PhraseSpottingResult message. Does not implicitly {@link vibe.PhraseSpottingResult.verify|verify} messages.
             * @function encode
             * @memberof vibe.PhraseSpottingResult
             * @static
             * @param {vibe.IPhraseSpottingResult} message PhraseSpottingResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PhraseSpottingResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.whitelist != null && message.whitelist.length)
                    for (var i = 0; i < message.whitelist.length; ++i)
                        $root.vibe.PhraseSpottingResultEntry.encode(message.whitelist[i], writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.blacklist != null && message.blacklist.length)
                    for (var i = 0; i < message.blacklist.length; ++i)
                        $root.vibe.PhraseSpottingResultEntry.encode(message.blacklist[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified PhraseSpottingResult message, length delimited. Does not implicitly {@link vibe.PhraseSpottingResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.PhraseSpottingResult
             * @static
             * @param {vibe.IPhraseSpottingResult} message PhraseSpottingResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PhraseSpottingResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PhraseSpottingResult message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.PhraseSpottingResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.PhraseSpottingResult} PhraseSpottingResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PhraseSpottingResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.PhraseSpottingResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 18: {
                            if (!(message.whitelist && message.whitelist.length))
                                message.whitelist = [];
                            message.whitelist.push($root.vibe.PhraseSpottingResultEntry.decode(reader, reader.uint32()));
                            break;
                        }
                    case 19: {
                            if (!(message.blacklist && message.blacklist.length))
                                message.blacklist = [];
                            message.blacklist.push($root.vibe.PhraseSpottingResultEntry.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PhraseSpottingResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.PhraseSpottingResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.PhraseSpottingResult} PhraseSpottingResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PhraseSpottingResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PhraseSpottingResult message.
             * @function verify
             * @memberof vibe.PhraseSpottingResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PhraseSpottingResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.whitelist != null && message.hasOwnProperty("whitelist")) {
                    if (!Array.isArray(message.whitelist))
                        return "whitelist: array expected";
                    for (var i = 0; i < message.whitelist.length; ++i) {
                        var error = $root.vibe.PhraseSpottingResultEntry.verify(message.whitelist[i]);
                        if (error)
                            return "whitelist." + error;
                    }
                }
                if (message.blacklist != null && message.hasOwnProperty("blacklist")) {
                    if (!Array.isArray(message.blacklist))
                        return "blacklist: array expected";
                    for (var i = 0; i < message.blacklist.length; ++i) {
                        var error = $root.vibe.PhraseSpottingResultEntry.verify(message.blacklist[i]);
                        if (error)
                            return "blacklist." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a PhraseSpottingResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.PhraseSpottingResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.PhraseSpottingResult} PhraseSpottingResult
             */
            PhraseSpottingResult.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.PhraseSpottingResult)
                    return object;
                var message = new $root.vibe.PhraseSpottingResult();
                if (object.whitelist) {
                    if (!Array.isArray(object.whitelist))
                        throw TypeError(".vibe.PhraseSpottingResult.whitelist: array expected");
                    message.whitelist = [];
                    for (var i = 0; i < object.whitelist.length; ++i) {
                        if (typeof object.whitelist[i] !== "object")
                            throw TypeError(".vibe.PhraseSpottingResult.whitelist: object expected");
                        message.whitelist[i] = $root.vibe.PhraseSpottingResultEntry.fromObject(object.whitelist[i]);
                    }
                }
                if (object.blacklist) {
                    if (!Array.isArray(object.blacklist))
                        throw TypeError(".vibe.PhraseSpottingResult.blacklist: array expected");
                    message.blacklist = [];
                    for (var i = 0; i < object.blacklist.length; ++i) {
                        if (typeof object.blacklist[i] !== "object")
                            throw TypeError(".vibe.PhraseSpottingResult.blacklist: object expected");
                        message.blacklist[i] = $root.vibe.PhraseSpottingResultEntry.fromObject(object.blacklist[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PhraseSpottingResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.PhraseSpottingResult
             * @static
             * @param {vibe.PhraseSpottingResult} message PhraseSpottingResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PhraseSpottingResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.whitelist = [];
                    object.blacklist = [];
                }
                if (message.whitelist && message.whitelist.length) {
                    object.whitelist = [];
                    for (var j = 0; j < message.whitelist.length; ++j)
                        object.whitelist[j] = $root.vibe.PhraseSpottingResultEntry.toObject(message.whitelist[j], options);
                }
                if (message.blacklist && message.blacklist.length) {
                    object.blacklist = [];
                    for (var j = 0; j < message.blacklist.length; ++j)
                        object.blacklist[j] = $root.vibe.PhraseSpottingResultEntry.toObject(message.blacklist[j], options);
                }
                return object;
            };
    
            /**
             * Converts this PhraseSpottingResult to JSON.
             * @function toJSON
             * @memberof vibe.PhraseSpottingResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PhraseSpottingResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PhraseSpottingResult
             * @function getTypeUrl
             * @memberof vibe.PhraseSpottingResult
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PhraseSpottingResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.PhraseSpottingResult";
            };
    
            return PhraseSpottingResult;
        })();
    
        vibe.PhraseSpottingResultEntry = (function() {
    
            /**
             * Properties of a PhraseSpottingResultEntry.
             * @memberof vibe
             * @interface IPhraseSpottingResultEntry
             * @property {number|null} [count] PhraseSpottingResultEntry count
             * @property {string|null} [groupName] PhraseSpottingResultEntry groupName
             * @property {number|null} [points] PhraseSpottingResultEntry points
             * @property {Array.<string>|null} [segments] PhraseSpottingResultEntry segments
             * @property {string|null} [utteranceUid] PhraseSpottingResultEntry utteranceUid
             * @property {phrase_spotting.SpotterKind|null} [kind] PhraseSpottingResultEntry kind
             */
    
            /**
             * Constructs a new PhraseSpottingResultEntry.
             * @memberof vibe
             * @classdesc Represents a PhraseSpottingResultEntry.
             * @implements IPhraseSpottingResultEntry
             * @constructor
             * @param {vibe.IPhraseSpottingResultEntry=} [properties] Properties to set
             */
            function PhraseSpottingResultEntry(properties) {
                this.segments = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PhraseSpottingResultEntry count.
             * @member {number} count
             * @memberof vibe.PhraseSpottingResultEntry
             * @instance
             */
            PhraseSpottingResultEntry.prototype.count = 0;
    
            /**
             * PhraseSpottingResultEntry groupName.
             * @member {string} groupName
             * @memberof vibe.PhraseSpottingResultEntry
             * @instance
             */
            PhraseSpottingResultEntry.prototype.groupName = "";
    
            /**
             * PhraseSpottingResultEntry points.
             * @member {number} points
             * @memberof vibe.PhraseSpottingResultEntry
             * @instance
             */
            PhraseSpottingResultEntry.prototype.points = 0;
    
            /**
             * PhraseSpottingResultEntry segments.
             * @member {Array.<string>} segments
             * @memberof vibe.PhraseSpottingResultEntry
             * @instance
             */
            PhraseSpottingResultEntry.prototype.segments = $util.emptyArray;
    
            /**
             * PhraseSpottingResultEntry utteranceUid.
             * @member {string} utteranceUid
             * @memberof vibe.PhraseSpottingResultEntry
             * @instance
             */
            PhraseSpottingResultEntry.prototype.utteranceUid = "";
    
            /**
             * PhraseSpottingResultEntry kind.
             * @member {phrase_spotting.SpotterKind} kind
             * @memberof vibe.PhraseSpottingResultEntry
             * @instance
             */
            PhraseSpottingResultEntry.prototype.kind = 0;
    
            /**
             * Creates a new PhraseSpottingResultEntry instance using the specified properties.
             * @function create
             * @memberof vibe.PhraseSpottingResultEntry
             * @static
             * @param {vibe.IPhraseSpottingResultEntry=} [properties] Properties to set
             * @returns {vibe.PhraseSpottingResultEntry} PhraseSpottingResultEntry instance
             */
            PhraseSpottingResultEntry.create = function create(properties) {
                return new PhraseSpottingResultEntry(properties);
            };
    
            /**
             * Encodes the specified PhraseSpottingResultEntry message. Does not implicitly {@link vibe.PhraseSpottingResultEntry.verify|verify} messages.
             * @function encode
             * @memberof vibe.PhraseSpottingResultEntry
             * @static
             * @param {vibe.IPhraseSpottingResultEntry} message PhraseSpottingResultEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PhraseSpottingResultEntry.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.count);
                if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.groupName);
                if (message.points != null && Object.hasOwnProperty.call(message, "points"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.points);
                if (message.segments != null && message.segments.length)
                    for (var i = 0; i < message.segments.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.segments[i]);
                if (message.utteranceUid != null && Object.hasOwnProperty.call(message, "utteranceUid"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.utteranceUid);
                if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.kind);
                return writer;
            };
    
            /**
             * Encodes the specified PhraseSpottingResultEntry message, length delimited. Does not implicitly {@link vibe.PhraseSpottingResultEntry.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.PhraseSpottingResultEntry
             * @static
             * @param {vibe.IPhraseSpottingResultEntry} message PhraseSpottingResultEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PhraseSpottingResultEntry.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PhraseSpottingResultEntry message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.PhraseSpottingResultEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.PhraseSpottingResultEntry} PhraseSpottingResultEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PhraseSpottingResultEntry.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.PhraseSpottingResultEntry();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.count = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.groupName = reader.string();
                            break;
                        }
                    case 3: {
                            message.points = reader.uint32();
                            break;
                        }
                    case 4: {
                            if (!(message.segments && message.segments.length))
                                message.segments = [];
                            message.segments.push(reader.string());
                            break;
                        }
                    case 5: {
                            message.utteranceUid = reader.string();
                            break;
                        }
                    case 6: {
                            message.kind = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PhraseSpottingResultEntry message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.PhraseSpottingResultEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.PhraseSpottingResultEntry} PhraseSpottingResultEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PhraseSpottingResultEntry.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PhraseSpottingResultEntry message.
             * @function verify
             * @memberof vibe.PhraseSpottingResultEntry
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PhraseSpottingResultEntry.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    if (!$util.isString(message.groupName))
                        return "groupName: string expected";
                if (message.points != null && message.hasOwnProperty("points"))
                    if (!$util.isInteger(message.points))
                        return "points: integer expected";
                if (message.segments != null && message.hasOwnProperty("segments")) {
                    if (!Array.isArray(message.segments))
                        return "segments: array expected";
                    for (var i = 0; i < message.segments.length; ++i)
                        if (!$util.isString(message.segments[i]))
                            return "segments: string[] expected";
                }
                if (message.utteranceUid != null && message.hasOwnProperty("utteranceUid"))
                    if (!$util.isString(message.utteranceUid))
                        return "utteranceUid: string expected";
                if (message.kind != null && message.hasOwnProperty("kind"))
                    switch (message.kind) {
                    default:
                        return "kind: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a PhraseSpottingResultEntry message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.PhraseSpottingResultEntry
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.PhraseSpottingResultEntry} PhraseSpottingResultEntry
             */
            PhraseSpottingResultEntry.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.PhraseSpottingResultEntry)
                    return object;
                var message = new $root.vibe.PhraseSpottingResultEntry();
                if (object.count != null)
                    message.count = object.count >>> 0;
                if (object.groupName != null)
                    message.groupName = String(object.groupName);
                if (object.points != null)
                    message.points = object.points >>> 0;
                if (object.segments) {
                    if (!Array.isArray(object.segments))
                        throw TypeError(".vibe.PhraseSpottingResultEntry.segments: array expected");
                    message.segments = [];
                    for (var i = 0; i < object.segments.length; ++i)
                        message.segments[i] = String(object.segments[i]);
                }
                if (object.utteranceUid != null)
                    message.utteranceUid = String(object.utteranceUid);
                switch (object.kind) {
                default:
                    if (typeof object.kind === "number") {
                        message.kind = object.kind;
                        break;
                    }
                    break;
                case "UNKNOWN":
                case 0:
                    message.kind = 0;
                    break;
                case "STRICT":
                case 1:
                    message.kind = 1;
                    break;
                case "SEMANTIC":
                case 2:
                    message.kind = 2;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PhraseSpottingResultEntry message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.PhraseSpottingResultEntry
             * @static
             * @param {vibe.PhraseSpottingResultEntry} message PhraseSpottingResultEntry
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PhraseSpottingResultEntry.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.segments = [];
                if (options.defaults) {
                    object.count = 0;
                    object.groupName = "";
                    object.points = 0;
                    object.utteranceUid = "";
                    object.kind = options.enums === String ? "UNKNOWN" : 0;
                }
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    object.groupName = message.groupName;
                if (message.points != null && message.hasOwnProperty("points"))
                    object.points = message.points;
                if (message.segments && message.segments.length) {
                    object.segments = [];
                    for (var j = 0; j < message.segments.length; ++j)
                        object.segments[j] = message.segments[j];
                }
                if (message.utteranceUid != null && message.hasOwnProperty("utteranceUid"))
                    object.utteranceUid = message.utteranceUid;
                if (message.kind != null && message.hasOwnProperty("kind"))
                    object.kind = options.enums === String ? $root.phrase_spotting.SpotterKind[message.kind] === undefined ? message.kind : $root.phrase_spotting.SpotterKind[message.kind] : message.kind;
                return object;
            };
    
            /**
             * Converts this PhraseSpottingResultEntry to JSON.
             * @function toJSON
             * @memberof vibe.PhraseSpottingResultEntry
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PhraseSpottingResultEntry.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for PhraseSpottingResultEntry
             * @function getTypeUrl
             * @memberof vibe.PhraseSpottingResultEntry
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PhraseSpottingResultEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.PhraseSpottingResultEntry";
            };
    
            return PhraseSpottingResultEntry;
        })();
    
        vibe.MetricsResult = (function() {
    
            /**
             * Properties of a MetricsResult.
             * @memberof vibe
             * @interface IMetricsResult
             * @property {vibe.IVoiceMetrics|null} [voiceMetrics] MetricsResult voiceMetrics
             * @property {vibe.ISpeechPaceMetrics|null} [speechPaceMetrics] MetricsResult speechPaceMetrics
             */
    
            /**
             * Constructs a new MetricsResult.
             * @memberof vibe
             * @classdesc Represents a MetricsResult.
             * @implements IMetricsResult
             * @constructor
             * @param {vibe.IMetricsResult=} [properties] Properties to set
             */
            function MetricsResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * MetricsResult voiceMetrics.
             * @member {vibe.IVoiceMetrics|null|undefined} voiceMetrics
             * @memberof vibe.MetricsResult
             * @instance
             */
            MetricsResult.prototype.voiceMetrics = null;
    
            /**
             * MetricsResult speechPaceMetrics.
             * @member {vibe.ISpeechPaceMetrics|null|undefined} speechPaceMetrics
             * @memberof vibe.MetricsResult
             * @instance
             */
            MetricsResult.prototype.speechPaceMetrics = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * MetricsResult metricType.
             * @member {"voiceMetrics"|"speechPaceMetrics"|undefined} metricType
             * @memberof vibe.MetricsResult
             * @instance
             */
            Object.defineProperty(MetricsResult.prototype, "metricType", {
                get: $util.oneOfGetter($oneOfFields = ["voiceMetrics", "speechPaceMetrics"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new MetricsResult instance using the specified properties.
             * @function create
             * @memberof vibe.MetricsResult
             * @static
             * @param {vibe.IMetricsResult=} [properties] Properties to set
             * @returns {vibe.MetricsResult} MetricsResult instance
             */
            MetricsResult.create = function create(properties) {
                return new MetricsResult(properties);
            };
    
            /**
             * Encodes the specified MetricsResult message. Does not implicitly {@link vibe.MetricsResult.verify|verify} messages.
             * @function encode
             * @memberof vibe.MetricsResult
             * @static
             * @param {vibe.IMetricsResult} message MetricsResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MetricsResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.voiceMetrics != null && Object.hasOwnProperty.call(message, "voiceMetrics"))
                    $root.vibe.VoiceMetrics.encode(message.voiceMetrics, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.speechPaceMetrics != null && Object.hasOwnProperty.call(message, "speechPaceMetrics"))
                    $root.vibe.SpeechPaceMetrics.encode(message.speechPaceMetrics, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified MetricsResult message, length delimited. Does not implicitly {@link vibe.MetricsResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.MetricsResult
             * @static
             * @param {vibe.IMetricsResult} message MetricsResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MetricsResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a MetricsResult message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.MetricsResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.MetricsResult} MetricsResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MetricsResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.MetricsResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.voiceMetrics = $root.vibe.VoiceMetrics.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.speechPaceMetrics = $root.vibe.SpeechPaceMetrics.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a MetricsResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.MetricsResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.MetricsResult} MetricsResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MetricsResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a MetricsResult message.
             * @function verify
             * @memberof vibe.MetricsResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MetricsResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.voiceMetrics != null && message.hasOwnProperty("voiceMetrics")) {
                    properties.metricType = 1;
                    {
                        var error = $root.vibe.VoiceMetrics.verify(message.voiceMetrics);
                        if (error)
                            return "voiceMetrics." + error;
                    }
                }
                if (message.speechPaceMetrics != null && message.hasOwnProperty("speechPaceMetrics")) {
                    if (properties.metricType === 1)
                        return "metricType: multiple values";
                    properties.metricType = 1;
                    {
                        var error = $root.vibe.SpeechPaceMetrics.verify(message.speechPaceMetrics);
                        if (error)
                            return "speechPaceMetrics." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a MetricsResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.MetricsResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.MetricsResult} MetricsResult
             */
            MetricsResult.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.MetricsResult)
                    return object;
                var message = new $root.vibe.MetricsResult();
                if (object.voiceMetrics != null) {
                    if (typeof object.voiceMetrics !== "object")
                        throw TypeError(".vibe.MetricsResult.voiceMetrics: object expected");
                    message.voiceMetrics = $root.vibe.VoiceMetrics.fromObject(object.voiceMetrics);
                }
                if (object.speechPaceMetrics != null) {
                    if (typeof object.speechPaceMetrics !== "object")
                        throw TypeError(".vibe.MetricsResult.speechPaceMetrics: object expected");
                    message.speechPaceMetrics = $root.vibe.SpeechPaceMetrics.fromObject(object.speechPaceMetrics);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a MetricsResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.MetricsResult
             * @static
             * @param {vibe.MetricsResult} message MetricsResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MetricsResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.voiceMetrics != null && message.hasOwnProperty("voiceMetrics")) {
                    object.voiceMetrics = $root.vibe.VoiceMetrics.toObject(message.voiceMetrics, options);
                    if (options.oneofs)
                        object.metricType = "voiceMetrics";
                }
                if (message.speechPaceMetrics != null && message.hasOwnProperty("speechPaceMetrics")) {
                    object.speechPaceMetrics = $root.vibe.SpeechPaceMetrics.toObject(message.speechPaceMetrics, options);
                    if (options.oneofs)
                        object.metricType = "speechPaceMetrics";
                }
                return object;
            };
    
            /**
             * Converts this MetricsResult to JSON.
             * @function toJSON
             * @memberof vibe.MetricsResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MetricsResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for MetricsResult
             * @function getTypeUrl
             * @memberof vibe.MetricsResult
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MetricsResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.MetricsResult";
            };
    
            return MetricsResult;
        })();
    
        vibe.VoiceMetrics = (function() {
    
            /**
             * Properties of a VoiceMetrics.
             * @memberof vibe
             * @interface IVoiceMetrics
             * @property {number|null} [measurementTimeMsec] VoiceMetrics measurementTimeMsec
             * @property {number|null} [loudnessLufs] VoiceMetrics loudnessLufs
             * @property {number|null} [speechRatio] VoiceMetrics speechRatio
             * @property {boolean|null} [ourSideIsSpeaking] VoiceMetrics ourSideIsSpeaking
             * @property {number|null} [channel] VoiceMetrics channel
             */
    
            /**
             * Constructs a new VoiceMetrics.
             * @memberof vibe
             * @classdesc Represents a VoiceMetrics.
             * @implements IVoiceMetrics
             * @constructor
             * @param {vibe.IVoiceMetrics=} [properties] Properties to set
             */
            function VoiceMetrics(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * VoiceMetrics measurementTimeMsec.
             * @member {number} measurementTimeMsec
             * @memberof vibe.VoiceMetrics
             * @instance
             */
            VoiceMetrics.prototype.measurementTimeMsec = 0;
    
            /**
             * VoiceMetrics loudnessLufs.
             * @member {number} loudnessLufs
             * @memberof vibe.VoiceMetrics
             * @instance
             */
            VoiceMetrics.prototype.loudnessLufs = 0;
    
            /**
             * VoiceMetrics speechRatio.
             * @member {number} speechRatio
             * @memberof vibe.VoiceMetrics
             * @instance
             */
            VoiceMetrics.prototype.speechRatio = 0;
    
            /**
             * VoiceMetrics ourSideIsSpeaking.
             * @member {boolean} ourSideIsSpeaking
             * @memberof vibe.VoiceMetrics
             * @instance
             */
            VoiceMetrics.prototype.ourSideIsSpeaking = false;
    
            /**
             * VoiceMetrics channel.
             * @member {number} channel
             * @memberof vibe.VoiceMetrics
             * @instance
             */
            VoiceMetrics.prototype.channel = 0;
    
            /**
             * Creates a new VoiceMetrics instance using the specified properties.
             * @function create
             * @memberof vibe.VoiceMetrics
             * @static
             * @param {vibe.IVoiceMetrics=} [properties] Properties to set
             * @returns {vibe.VoiceMetrics} VoiceMetrics instance
             */
            VoiceMetrics.create = function create(properties) {
                return new VoiceMetrics(properties);
            };
    
            /**
             * Encodes the specified VoiceMetrics message. Does not implicitly {@link vibe.VoiceMetrics.verify|verify} messages.
             * @function encode
             * @memberof vibe.VoiceMetrics
             * @static
             * @param {vibe.IVoiceMetrics} message VoiceMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VoiceMetrics.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.measurementTimeMsec != null && Object.hasOwnProperty.call(message, "measurementTimeMsec"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.measurementTimeMsec);
                if (message.loudnessLufs != null && Object.hasOwnProperty.call(message, "loudnessLufs"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.loudnessLufs);
                if (message.speechRatio != null && Object.hasOwnProperty.call(message, "speechRatio"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.speechRatio);
                if (message.ourSideIsSpeaking != null && Object.hasOwnProperty.call(message, "ourSideIsSpeaking"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.ourSideIsSpeaking);
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.channel);
                return writer;
            };
    
            /**
             * Encodes the specified VoiceMetrics message, length delimited. Does not implicitly {@link vibe.VoiceMetrics.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.VoiceMetrics
             * @static
             * @param {vibe.IVoiceMetrics} message VoiceMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VoiceMetrics.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a VoiceMetrics message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.VoiceMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.VoiceMetrics} VoiceMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VoiceMetrics.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.VoiceMetrics();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.measurementTimeMsec = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.loudnessLufs = reader.float();
                            break;
                        }
                    case 3: {
                            message.speechRatio = reader.float();
                            break;
                        }
                    case 4: {
                            message.ourSideIsSpeaking = reader.bool();
                            break;
                        }
                    case 5: {
                            message.channel = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a VoiceMetrics message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.VoiceMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.VoiceMetrics} VoiceMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VoiceMetrics.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a VoiceMetrics message.
             * @function verify
             * @memberof vibe.VoiceMetrics
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VoiceMetrics.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.measurementTimeMsec != null && message.hasOwnProperty("measurementTimeMsec"))
                    if (!$util.isInteger(message.measurementTimeMsec))
                        return "measurementTimeMsec: integer expected";
                if (message.loudnessLufs != null && message.hasOwnProperty("loudnessLufs"))
                    if (typeof message.loudnessLufs !== "number")
                        return "loudnessLufs: number expected";
                if (message.speechRatio != null && message.hasOwnProperty("speechRatio"))
                    if (typeof message.speechRatio !== "number")
                        return "speechRatio: number expected";
                if (message.ourSideIsSpeaking != null && message.hasOwnProperty("ourSideIsSpeaking"))
                    if (typeof message.ourSideIsSpeaking !== "boolean")
                        return "ourSideIsSpeaking: boolean expected";
                if (message.channel != null && message.hasOwnProperty("channel"))
                    if (!$util.isInteger(message.channel))
                        return "channel: integer expected";
                return null;
            };
    
            /**
             * Creates a VoiceMetrics message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.VoiceMetrics
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.VoiceMetrics} VoiceMetrics
             */
            VoiceMetrics.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.VoiceMetrics)
                    return object;
                var message = new $root.vibe.VoiceMetrics();
                if (object.measurementTimeMsec != null)
                    message.measurementTimeMsec = object.measurementTimeMsec >>> 0;
                if (object.loudnessLufs != null)
                    message.loudnessLufs = Number(object.loudnessLufs);
                if (object.speechRatio != null)
                    message.speechRatio = Number(object.speechRatio);
                if (object.ourSideIsSpeaking != null)
                    message.ourSideIsSpeaking = Boolean(object.ourSideIsSpeaking);
                if (object.channel != null)
                    message.channel = object.channel >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a VoiceMetrics message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.VoiceMetrics
             * @static
             * @param {vibe.VoiceMetrics} message VoiceMetrics
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VoiceMetrics.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.measurementTimeMsec = 0;
                    object.loudnessLufs = 0;
                    object.speechRatio = 0;
                    object.ourSideIsSpeaking = false;
                    object.channel = 0;
                }
                if (message.measurementTimeMsec != null && message.hasOwnProperty("measurementTimeMsec"))
                    object.measurementTimeMsec = message.measurementTimeMsec;
                if (message.loudnessLufs != null && message.hasOwnProperty("loudnessLufs"))
                    object.loudnessLufs = options.json && !isFinite(message.loudnessLufs) ? String(message.loudnessLufs) : message.loudnessLufs;
                if (message.speechRatio != null && message.hasOwnProperty("speechRatio"))
                    object.speechRatio = options.json && !isFinite(message.speechRatio) ? String(message.speechRatio) : message.speechRatio;
                if (message.ourSideIsSpeaking != null && message.hasOwnProperty("ourSideIsSpeaking"))
                    object.ourSideIsSpeaking = message.ourSideIsSpeaking;
                if (message.channel != null && message.hasOwnProperty("channel"))
                    object.channel = message.channel;
                return object;
            };
    
            /**
             * Converts this VoiceMetrics to JSON.
             * @function toJSON
             * @memberof vibe.VoiceMetrics
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VoiceMetrics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for VoiceMetrics
             * @function getTypeUrl
             * @memberof vibe.VoiceMetrics
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VoiceMetrics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.VoiceMetrics";
            };
    
            return VoiceMetrics;
        })();
    
        vibe.SpeechPaceMetrics = (function() {
    
            /**
             * Properties of a SpeechPaceMetrics.
             * @memberof vibe
             * @interface ISpeechPaceMetrics
             * @property {number|null} [measurementTimeMsec] SpeechPaceMetrics measurementTimeMsec
             * @property {number|null} [channel] SpeechPaceMetrics channel
             * @property {vibe.SpeechRecognitionResult.Vendor|null} [vendor] SpeechPaceMetrics vendor
             * @property {number|null} [wordsPerMinute] SpeechPaceMetrics wordsPerMinute
             * @property {number|null} [numWords] SpeechPaceMetrics numWords
             * @property {number|null} [durationMsec] SpeechPaceMetrics durationMsec
             * @property {vibe.SpeechPaceMetrics.Pace|null} [pace] SpeechPaceMetrics pace
             */
    
            /**
             * Constructs a new SpeechPaceMetrics.
             * @memberof vibe
             * @classdesc Represents a SpeechPaceMetrics.
             * @implements ISpeechPaceMetrics
             * @constructor
             * @param {vibe.ISpeechPaceMetrics=} [properties] Properties to set
             */
            function SpeechPaceMetrics(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SpeechPaceMetrics measurementTimeMsec.
             * @member {number} measurementTimeMsec
             * @memberof vibe.SpeechPaceMetrics
             * @instance
             */
            SpeechPaceMetrics.prototype.measurementTimeMsec = 0;
    
            /**
             * SpeechPaceMetrics channel.
             * @member {number} channel
             * @memberof vibe.SpeechPaceMetrics
             * @instance
             */
            SpeechPaceMetrics.prototype.channel = 0;
    
            /**
             * SpeechPaceMetrics vendor.
             * @member {vibe.SpeechRecognitionResult.Vendor} vendor
             * @memberof vibe.SpeechPaceMetrics
             * @instance
             */
            SpeechPaceMetrics.prototype.vendor = 0;
    
            /**
             * SpeechPaceMetrics wordsPerMinute.
             * @member {number} wordsPerMinute
             * @memberof vibe.SpeechPaceMetrics
             * @instance
             */
            SpeechPaceMetrics.prototype.wordsPerMinute = 0;
    
            /**
             * SpeechPaceMetrics numWords.
             * @member {number} numWords
             * @memberof vibe.SpeechPaceMetrics
             * @instance
             */
            SpeechPaceMetrics.prototype.numWords = 0;
    
            /**
             * SpeechPaceMetrics durationMsec.
             * @member {number} durationMsec
             * @memberof vibe.SpeechPaceMetrics
             * @instance
             */
            SpeechPaceMetrics.prototype.durationMsec = 0;
    
            /**
             * SpeechPaceMetrics pace.
             * @member {vibe.SpeechPaceMetrics.Pace} pace
             * @memberof vibe.SpeechPaceMetrics
             * @instance
             */
            SpeechPaceMetrics.prototype.pace = 0;
    
            /**
             * Creates a new SpeechPaceMetrics instance using the specified properties.
             * @function create
             * @memberof vibe.SpeechPaceMetrics
             * @static
             * @param {vibe.ISpeechPaceMetrics=} [properties] Properties to set
             * @returns {vibe.SpeechPaceMetrics} SpeechPaceMetrics instance
             */
            SpeechPaceMetrics.create = function create(properties) {
                return new SpeechPaceMetrics(properties);
            };
    
            /**
             * Encodes the specified SpeechPaceMetrics message. Does not implicitly {@link vibe.SpeechPaceMetrics.verify|verify} messages.
             * @function encode
             * @memberof vibe.SpeechPaceMetrics
             * @static
             * @param {vibe.ISpeechPaceMetrics} message SpeechPaceMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpeechPaceMetrics.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.measurementTimeMsec != null && Object.hasOwnProperty.call(message, "measurementTimeMsec"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.measurementTimeMsec);
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.channel);
                if (message.vendor != null && Object.hasOwnProperty.call(message, "vendor"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.vendor);
                if (message.wordsPerMinute != null && Object.hasOwnProperty.call(message, "wordsPerMinute"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.wordsPerMinute);
                if (message.numWords != null && Object.hasOwnProperty.call(message, "numWords"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.numWords);
                if (message.durationMsec != null && Object.hasOwnProperty.call(message, "durationMsec"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.durationMsec);
                if (message.pace != null && Object.hasOwnProperty.call(message, "pace"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.pace);
                return writer;
            };
    
            /**
             * Encodes the specified SpeechPaceMetrics message, length delimited. Does not implicitly {@link vibe.SpeechPaceMetrics.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.SpeechPaceMetrics
             * @static
             * @param {vibe.ISpeechPaceMetrics} message SpeechPaceMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpeechPaceMetrics.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SpeechPaceMetrics message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.SpeechPaceMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.SpeechPaceMetrics} SpeechPaceMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpeechPaceMetrics.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.SpeechPaceMetrics();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.measurementTimeMsec = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.channel = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.vendor = reader.int32();
                            break;
                        }
                    case 4: {
                            message.wordsPerMinute = reader.float();
                            break;
                        }
                    case 5: {
                            message.numWords = reader.uint32();
                            break;
                        }
                    case 6: {
                            message.durationMsec = reader.uint32();
                            break;
                        }
                    case 7: {
                            message.pace = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SpeechPaceMetrics message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.SpeechPaceMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.SpeechPaceMetrics} SpeechPaceMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpeechPaceMetrics.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SpeechPaceMetrics message.
             * @function verify
             * @memberof vibe.SpeechPaceMetrics
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SpeechPaceMetrics.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.measurementTimeMsec != null && message.hasOwnProperty("measurementTimeMsec"))
                    if (!$util.isInteger(message.measurementTimeMsec))
                        return "measurementTimeMsec: integer expected";
                if (message.channel != null && message.hasOwnProperty("channel"))
                    if (!$util.isInteger(message.channel))
                        return "channel: integer expected";
                if (message.vendor != null && message.hasOwnProperty("vendor"))
                    switch (message.vendor) {
                    default:
                        return "vendor: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.wordsPerMinute != null && message.hasOwnProperty("wordsPerMinute"))
                    if (typeof message.wordsPerMinute !== "number")
                        return "wordsPerMinute: number expected";
                if (message.numWords != null && message.hasOwnProperty("numWords"))
                    if (!$util.isInteger(message.numWords))
                        return "numWords: integer expected";
                if (message.durationMsec != null && message.hasOwnProperty("durationMsec"))
                    if (!$util.isInteger(message.durationMsec))
                        return "durationMsec: integer expected";
                if (message.pace != null && message.hasOwnProperty("pace"))
                    switch (message.pace) {
                    default:
                        return "pace: enum value expected";
                    case 0:
                    case 10:
                    case 20:
                    case 30:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a SpeechPaceMetrics message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.SpeechPaceMetrics
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.SpeechPaceMetrics} SpeechPaceMetrics
             */
            SpeechPaceMetrics.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.SpeechPaceMetrics)
                    return object;
                var message = new $root.vibe.SpeechPaceMetrics();
                if (object.measurementTimeMsec != null)
                    message.measurementTimeMsec = object.measurementTimeMsec >>> 0;
                if (object.channel != null)
                    message.channel = object.channel >>> 0;
                switch (object.vendor) {
                default:
                    if (typeof object.vendor === "number") {
                        message.vendor = object.vendor;
                        break;
                    }
                    break;
                case "UNDEFINED":
                case 0:
                    message.vendor = 0;
                    break;
                case "I2X":
                case 1:
                    message.vendor = 1;
                    break;
                case "GOOGLE":
                case 2:
                    message.vendor = 2;
                    break;
                case "RIVA":
                case 3:
                    message.vendor = 3;
                    break;
                }
                if (object.wordsPerMinute != null)
                    message.wordsPerMinute = Number(object.wordsPerMinute);
                if (object.numWords != null)
                    message.numWords = object.numWords >>> 0;
                if (object.durationMsec != null)
                    message.durationMsec = object.durationMsec >>> 0;
                switch (object.pace) {
                default:
                    if (typeof object.pace === "number") {
                        message.pace = object.pace;
                        break;
                    }
                    break;
                case "UNDEFINED":
                case 0:
                    message.pace = 0;
                    break;
                case "SLOW":
                case 10:
                    message.pace = 10;
                    break;
                case "MEDIUM":
                case 20:
                    message.pace = 20;
                    break;
                case "FAST":
                case 30:
                    message.pace = 30;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a SpeechPaceMetrics message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.SpeechPaceMetrics
             * @static
             * @param {vibe.SpeechPaceMetrics} message SpeechPaceMetrics
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SpeechPaceMetrics.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.measurementTimeMsec = 0;
                    object.channel = 0;
                    object.vendor = options.enums === String ? "UNDEFINED" : 0;
                    object.wordsPerMinute = 0;
                    object.numWords = 0;
                    object.durationMsec = 0;
                    object.pace = options.enums === String ? "UNDEFINED" : 0;
                }
                if (message.measurementTimeMsec != null && message.hasOwnProperty("measurementTimeMsec"))
                    object.measurementTimeMsec = message.measurementTimeMsec;
                if (message.channel != null && message.hasOwnProperty("channel"))
                    object.channel = message.channel;
                if (message.vendor != null && message.hasOwnProperty("vendor"))
                    object.vendor = options.enums === String ? $root.vibe.SpeechRecognitionResult.Vendor[message.vendor] === undefined ? message.vendor : $root.vibe.SpeechRecognitionResult.Vendor[message.vendor] : message.vendor;
                if (message.wordsPerMinute != null && message.hasOwnProperty("wordsPerMinute"))
                    object.wordsPerMinute = options.json && !isFinite(message.wordsPerMinute) ? String(message.wordsPerMinute) : message.wordsPerMinute;
                if (message.numWords != null && message.hasOwnProperty("numWords"))
                    object.numWords = message.numWords;
                if (message.durationMsec != null && message.hasOwnProperty("durationMsec"))
                    object.durationMsec = message.durationMsec;
                if (message.pace != null && message.hasOwnProperty("pace"))
                    object.pace = options.enums === String ? $root.vibe.SpeechPaceMetrics.Pace[message.pace] === undefined ? message.pace : $root.vibe.SpeechPaceMetrics.Pace[message.pace] : message.pace;
                return object;
            };
    
            /**
             * Converts this SpeechPaceMetrics to JSON.
             * @function toJSON
             * @memberof vibe.SpeechPaceMetrics
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SpeechPaceMetrics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SpeechPaceMetrics
             * @function getTypeUrl
             * @memberof vibe.SpeechPaceMetrics
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SpeechPaceMetrics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.SpeechPaceMetrics";
            };
    
            /**
             * Pace enum.
             * @name vibe.SpeechPaceMetrics.Pace
             * @enum {number}
             * @property {number} UNDEFINED=0 UNDEFINED value
             * @property {number} SLOW=10 SLOW value
             * @property {number} MEDIUM=20 MEDIUM value
             * @property {number} FAST=30 FAST value
             */
            SpeechPaceMetrics.Pace = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNDEFINED"] = 0;
                values[valuesById[10] = "SLOW"] = 10;
                values[valuesById[20] = "MEDIUM"] = 20;
                values[valuesById[30] = "FAST"] = 30;
                return values;
            })();
    
            return SpeechPaceMetrics;
        })();
    
        vibe.ServiceMessage = (function() {
    
            /**
             * Properties of a ServiceMessage.
             * @memberof vibe
             * @interface IServiceMessage
             * @property {boolean|null} [serviceIsFinalized] ServiceMessage serviceIsFinalized
             * @property {vibe.ServiceMessage.Status|null} [status] ServiceMessage status
             * @property {Array.<string>|null} [metainfo] ServiceMessage metainfo
             * @property {string|null} [callId] ServiceMessage callId
             * @property {Object.<string,string>|null} [callMetainfo] ServiceMessage callMetainfo
             */
    
            /**
             * Constructs a new ServiceMessage.
             * @memberof vibe
             * @classdesc Represents a ServiceMessage.
             * @implements IServiceMessage
             * @constructor
             * @param {vibe.IServiceMessage=} [properties] Properties to set
             */
            function ServiceMessage(properties) {
                this.metainfo = [];
                this.callMetainfo = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ServiceMessage serviceIsFinalized.
             * @member {boolean} serviceIsFinalized
             * @memberof vibe.ServiceMessage
             * @instance
             */
            ServiceMessage.prototype.serviceIsFinalized = false;
    
            /**
             * ServiceMessage status.
             * @member {vibe.ServiceMessage.Status} status
             * @memberof vibe.ServiceMessage
             * @instance
             */
            ServiceMessage.prototype.status = 0;
    
            /**
             * ServiceMessage metainfo.
             * @member {Array.<string>} metainfo
             * @memberof vibe.ServiceMessage
             * @instance
             */
            ServiceMessage.prototype.metainfo = $util.emptyArray;
    
            /**
             * ServiceMessage callId.
             * @member {string|null|undefined} callId
             * @memberof vibe.ServiceMessage
             * @instance
             */
            ServiceMessage.prototype.callId = null;
    
            /**
             * ServiceMessage callMetainfo.
             * @member {Object.<string,string>} callMetainfo
             * @memberof vibe.ServiceMessage
             * @instance
             */
            ServiceMessage.prototype.callMetainfo = $util.emptyObject;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * ServiceMessage _callId.
             * @member {"callId"|undefined} _callId
             * @memberof vibe.ServiceMessage
             * @instance
             */
            Object.defineProperty(ServiceMessage.prototype, "_callId", {
                get: $util.oneOfGetter($oneOfFields = ["callId"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new ServiceMessage instance using the specified properties.
             * @function create
             * @memberof vibe.ServiceMessage
             * @static
             * @param {vibe.IServiceMessage=} [properties] Properties to set
             * @returns {vibe.ServiceMessage} ServiceMessage instance
             */
            ServiceMessage.create = function create(properties) {
                return new ServiceMessage(properties);
            };
    
            /**
             * Encodes the specified ServiceMessage message. Does not implicitly {@link vibe.ServiceMessage.verify|verify} messages.
             * @function encode
             * @memberof vibe.ServiceMessage
             * @static
             * @param {vibe.IServiceMessage} message ServiceMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.serviceIsFinalized != null && Object.hasOwnProperty.call(message, "serviceIsFinalized"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.serviceIsFinalized);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                if (message.metainfo != null && message.metainfo.length)
                    for (var i = 0; i < message.metainfo.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.metainfo[i]);
                if (message.callId != null && Object.hasOwnProperty.call(message, "callId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.callId);
                if (message.callMetainfo != null && Object.hasOwnProperty.call(message, "callMetainfo"))
                    for (var keys = Object.keys(message.callMetainfo), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.callMetainfo[keys[i]]).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ServiceMessage message, length delimited. Does not implicitly {@link vibe.ServiceMessage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof vibe.ServiceMessage
             * @static
             * @param {vibe.IServiceMessage} message ServiceMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceMessage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ServiceMessage message from the specified reader or buffer.
             * @function decode
             * @memberof vibe.ServiceMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {vibe.ServiceMessage} ServiceMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.vibe.ServiceMessage(), key, value;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.serviceIsFinalized = reader.bool();
                            break;
                        }
                    case 2: {
                            message.status = reader.int32();
                            break;
                        }
                    case 3: {
                            if (!(message.metainfo && message.metainfo.length))
                                message.metainfo = [];
                            message.metainfo.push(reader.string());
                            break;
                        }
                    case 5: {
                            message.callId = reader.string();
                            break;
                        }
                    case 6: {
                            if (message.callMetainfo === $util.emptyObject)
                                message.callMetainfo = {};
                            var end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                var tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.callMetainfo[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ServiceMessage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof vibe.ServiceMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {vibe.ServiceMessage} ServiceMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceMessage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ServiceMessage message.
             * @function verify
             * @memberof vibe.ServiceMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.serviceIsFinalized != null && message.hasOwnProperty("serviceIsFinalized"))
                    if (typeof message.serviceIsFinalized !== "boolean")
                        return "serviceIsFinalized: boolean expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.metainfo != null && message.hasOwnProperty("metainfo")) {
                    if (!Array.isArray(message.metainfo))
                        return "metainfo: array expected";
                    for (var i = 0; i < message.metainfo.length; ++i)
                        if (!$util.isString(message.metainfo[i]))
                            return "metainfo: string[] expected";
                }
                if (message.callId != null && message.hasOwnProperty("callId")) {
                    properties._callId = 1;
                    if (!$util.isString(message.callId))
                        return "callId: string expected";
                }
                if (message.callMetainfo != null && message.hasOwnProperty("callMetainfo")) {
                    if (!$util.isObject(message.callMetainfo))
                        return "callMetainfo: object expected";
                    var key = Object.keys(message.callMetainfo);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.callMetainfo[key[i]]))
                            return "callMetainfo: string{k:string} expected";
                }
                return null;
            };
    
            /**
             * Creates a ServiceMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof vibe.ServiceMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {vibe.ServiceMessage} ServiceMessage
             */
            ServiceMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.vibe.ServiceMessage)
                    return object;
                var message = new $root.vibe.ServiceMessage();
                if (object.serviceIsFinalized != null)
                    message.serviceIsFinalized = Boolean(object.serviceIsFinalized);
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "OK":
                case 0:
                    message.status = 0;
                    break;
                case "ERROR_OTHER":
                case 1:
                    message.status = 1;
                    break;
                case "ERROR_INVALID_REQUEST":
                case 2:
                    message.status = 2;
                    break;
                case "CALL_HOLD":
                case 3:
                    message.status = 3;
                    break;
                case "CALL_UNHOLD":
                case 4:
                    message.status = 4;
                    break;
                case "PING":
                case 5:
                    message.status = 5;
                    break;
                case "CHANGE":
                case 6:
                    message.status = 6;
                    break;
                case "CALL_START":
                case 7:
                    message.status = 7;
                    break;
                }
                if (object.metainfo) {
                    if (!Array.isArray(object.metainfo))
                        throw TypeError(".vibe.ServiceMessage.metainfo: array expected");
                    message.metainfo = [];
                    for (var i = 0; i < object.metainfo.length; ++i)
                        message.metainfo[i] = String(object.metainfo[i]);
                }
                if (object.callId != null)
                    message.callId = String(object.callId);
                if (object.callMetainfo) {
                    if (typeof object.callMetainfo !== "object")
                        throw TypeError(".vibe.ServiceMessage.callMetainfo: object expected");
                    message.callMetainfo = {};
                    for (var keys = Object.keys(object.callMetainfo), i = 0; i < keys.length; ++i)
                        message.callMetainfo[keys[i]] = String(object.callMetainfo[keys[i]]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ServiceMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof vibe.ServiceMessage
             * @static
             * @param {vibe.ServiceMessage} message ServiceMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.metainfo = [];
                if (options.objects || options.defaults)
                    object.callMetainfo = {};
                if (options.defaults) {
                    object.serviceIsFinalized = false;
                    object.status = options.enums === String ? "OK" : 0;
                }
                if (message.serviceIsFinalized != null && message.hasOwnProperty("serviceIsFinalized"))
                    object.serviceIsFinalized = message.serviceIsFinalized;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.vibe.ServiceMessage.Status[message.status] === undefined ? message.status : $root.vibe.ServiceMessage.Status[message.status] : message.status;
                if (message.metainfo && message.metainfo.length) {
                    object.metainfo = [];
                    for (var j = 0; j < message.metainfo.length; ++j)
                        object.metainfo[j] = message.metainfo[j];
                }
                if (message.callId != null && message.hasOwnProperty("callId")) {
                    object.callId = message.callId;
                    if (options.oneofs)
                        object._callId = "callId";
                }
                var keys2;
                if (message.callMetainfo && (keys2 = Object.keys(message.callMetainfo)).length) {
                    object.callMetainfo = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.callMetainfo[keys2[j]] = message.callMetainfo[keys2[j]];
                }
                return object;
            };
    
            /**
             * Converts this ServiceMessage to JSON.
             * @function toJSON
             * @memberof vibe.ServiceMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ServiceMessage
             * @function getTypeUrl
             * @memberof vibe.ServiceMessage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/vibe.ServiceMessage";
            };
    
            /**
             * Status enum.
             * @name vibe.ServiceMessage.Status
             * @enum {number}
             * @property {number} OK=0 OK value
             * @property {number} ERROR_OTHER=1 ERROR_OTHER value
             * @property {number} ERROR_INVALID_REQUEST=2 ERROR_INVALID_REQUEST value
             * @property {number} CALL_HOLD=3 CALL_HOLD value
             * @property {number} CALL_UNHOLD=4 CALL_UNHOLD value
             * @property {number} PING=5 PING value
             * @property {number} CHANGE=6 CHANGE value
             * @property {number} CALL_START=7 CALL_START value
             */
            ServiceMessage.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "OK"] = 0;
                values[valuesById[1] = "ERROR_OTHER"] = 1;
                values[valuesById[2] = "ERROR_INVALID_REQUEST"] = 2;
                values[valuesById[3] = "CALL_HOLD"] = 3;
                values[valuesById[4] = "CALL_UNHOLD"] = 4;
                values[valuesById[5] = "PING"] = 5;
                values[valuesById[6] = "CHANGE"] = 6;
                values[valuesById[7] = "CALL_START"] = 7;
                return values;
            })();
    
            return ServiceMessage;
        })();
    
        return vibe;
    })();
    
    $root.phrase_spotting = (function() {
    
        /**
         * Namespace phrase_spotting.
         * @exports phrase_spotting
         * @namespace
         */
        var phrase_spotting = {};
    
        phrase_spotting.StrictPhraseSpotter = (function() {
    
            /**
             * Constructs a new StrictPhraseSpotter service.
             * @memberof phrase_spotting
             * @classdesc Represents a StrictPhraseSpotter
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function StrictPhraseSpotter(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }
    
            (StrictPhraseSpotter.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StrictPhraseSpotter;
    
            /**
             * Creates new StrictPhraseSpotter service using the specified rpc implementation.
             * @function create
             * @memberof phrase_spotting.StrictPhraseSpotter
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {StrictPhraseSpotter} RPC service. Useful where requests and/or responses are streamed.
             */
            StrictPhraseSpotter.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };
    
            /**
             * Callback as used by {@link phrase_spotting.StrictPhraseSpotter#spot}.
             * @memberof phrase_spotting.StrictPhraseSpotter
             * @typedef SpotCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {phrase_spotting.Response} [response] Response
             */
    
            /**
             * Calls Spot.
             * @function spot
             * @memberof phrase_spotting.StrictPhraseSpotter
             * @instance
             * @param {phrase_spotting.IRequest} request Request message or plain object
             * @param {phrase_spotting.StrictPhraseSpotter.SpotCallback} callback Node-style callback called with the error, if any, and Response
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(StrictPhraseSpotter.prototype.spot = function spot(request, callback) {
                return this.rpcCall(spot, $root.phrase_spotting.Request, $root.phrase_spotting.Response, request, callback);
            }, "name", { value: "Spot" });
    
            /**
             * Calls Spot.
             * @function spot
             * @memberof phrase_spotting.StrictPhraseSpotter
             * @instance
             * @param {phrase_spotting.IRequest} request Request message or plain object
             * @returns {Promise<phrase_spotting.Response>} Promise
             * @variation 2
             */
    
            return StrictPhraseSpotter;
        })();
    
        phrase_spotting.SemanticPhraseSpotter = (function() {
    
            /**
             * Constructs a new SemanticPhraseSpotter service.
             * @memberof phrase_spotting
             * @classdesc Represents a SemanticPhraseSpotter
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function SemanticPhraseSpotter(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }
    
            (SemanticPhraseSpotter.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SemanticPhraseSpotter;
    
            /**
             * Creates new SemanticPhraseSpotter service using the specified rpc implementation.
             * @function create
             * @memberof phrase_spotting.SemanticPhraseSpotter
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {SemanticPhraseSpotter} RPC service. Useful where requests and/or responses are streamed.
             */
            SemanticPhraseSpotter.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };
    
            /**
             * Callback as used by {@link phrase_spotting.SemanticPhraseSpotter#spot}.
             * @memberof phrase_spotting.SemanticPhraseSpotter
             * @typedef SpotCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {phrase_spotting.Response} [response] Response
             */
    
            /**
             * Calls Spot.
             * @function spot
             * @memberof phrase_spotting.SemanticPhraseSpotter
             * @instance
             * @param {phrase_spotting.IRequest} request Request message or plain object
             * @param {phrase_spotting.SemanticPhraseSpotter.SpotCallback} callback Node-style callback called with the error, if any, and Response
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(SemanticPhraseSpotter.prototype.spot = function spot(request, callback) {
                return this.rpcCall(spot, $root.phrase_spotting.Request, $root.phrase_spotting.Response, request, callback);
            }, "name", { value: "Spot" });
    
            /**
             * Calls Spot.
             * @function spot
             * @memberof phrase_spotting.SemanticPhraseSpotter
             * @instance
             * @param {phrase_spotting.IRequest} request Request message or plain object
             * @returns {Promise<phrase_spotting.Response>} Promise
             * @variation 2
             */
    
            return SemanticPhraseSpotter;
        })();
    
        phrase_spotting.Span = (function() {
    
            /**
             * Properties of a Span.
             * @memberof phrase_spotting
             * @interface ISpan
             * @property {number|null} [startIdx] Span startIdx
             * @property {number|null} [endIdx] Span endIdx
             */
    
            /**
             * Constructs a new Span.
             * @memberof phrase_spotting
             * @classdesc Represents a Span.
             * @implements ISpan
             * @constructor
             * @param {phrase_spotting.ISpan=} [properties] Properties to set
             */
            function Span(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Span startIdx.
             * @member {number} startIdx
             * @memberof phrase_spotting.Span
             * @instance
             */
            Span.prototype.startIdx = 0;
    
            /**
             * Span endIdx.
             * @member {number} endIdx
             * @memberof phrase_spotting.Span
             * @instance
             */
            Span.prototype.endIdx = 0;
    
            /**
             * Creates a new Span instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Span
             * @static
             * @param {phrase_spotting.ISpan=} [properties] Properties to set
             * @returns {phrase_spotting.Span} Span instance
             */
            Span.create = function create(properties) {
                return new Span(properties);
            };
    
            /**
             * Encodes the specified Span message. Does not implicitly {@link phrase_spotting.Span.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Span
             * @static
             * @param {phrase_spotting.ISpan} message Span message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Span.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.startIdx != null && Object.hasOwnProperty.call(message, "startIdx"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.startIdx);
                if (message.endIdx != null && Object.hasOwnProperty.call(message, "endIdx"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.endIdx);
                return writer;
            };
    
            /**
             * Encodes the specified Span message, length delimited. Does not implicitly {@link phrase_spotting.Span.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Span
             * @static
             * @param {phrase_spotting.ISpan} message Span message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Span.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Span message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Span
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Span} Span
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Span.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Span();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.startIdx = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.endIdx = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Span message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Span
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Span} Span
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Span.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Span message.
             * @function verify
             * @memberof phrase_spotting.Span
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Span.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.startIdx != null && message.hasOwnProperty("startIdx"))
                    if (!$util.isInteger(message.startIdx))
                        return "startIdx: integer expected";
                if (message.endIdx != null && message.hasOwnProperty("endIdx"))
                    if (!$util.isInteger(message.endIdx))
                        return "endIdx: integer expected";
                return null;
            };
    
            /**
             * Creates a Span message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Span
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Span} Span
             */
            Span.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Span)
                    return object;
                var message = new $root.phrase_spotting.Span();
                if (object.startIdx != null)
                    message.startIdx = object.startIdx >>> 0;
                if (object.endIdx != null)
                    message.endIdx = object.endIdx >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a Span message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Span
             * @static
             * @param {phrase_spotting.Span} message Span
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Span.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.startIdx = 0;
                    object.endIdx = 0;
                }
                if (message.startIdx != null && message.hasOwnProperty("startIdx"))
                    object.startIdx = message.startIdx;
                if (message.endIdx != null && message.hasOwnProperty("endIdx"))
                    object.endIdx = message.endIdx;
                return object;
            };
    
            /**
             * Converts this Span to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Span
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Span.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Span
             * @function getTypeUrl
             * @memberof phrase_spotting.Span
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Span.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Span";
            };
    
            return Span;
        })();
    
        phrase_spotting.ResponseInfoStrict = (function() {
    
            /**
             * Properties of a ResponseInfoStrict.
             * @memberof phrase_spotting
             * @interface IResponseInfoStrict
             * @property {Array.<string>|null} [segments] ResponseInfoStrict segments
             */
    
            /**
             * Constructs a new ResponseInfoStrict.
             * @memberof phrase_spotting
             * @classdesc Represents a ResponseInfoStrict.
             * @implements IResponseInfoStrict
             * @constructor
             * @param {phrase_spotting.IResponseInfoStrict=} [properties] Properties to set
             */
            function ResponseInfoStrict(properties) {
                this.segments = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ResponseInfoStrict segments.
             * @member {Array.<string>} segments
             * @memberof phrase_spotting.ResponseInfoStrict
             * @instance
             */
            ResponseInfoStrict.prototype.segments = $util.emptyArray;
    
            /**
             * Creates a new ResponseInfoStrict instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {phrase_spotting.IResponseInfoStrict=} [properties] Properties to set
             * @returns {phrase_spotting.ResponseInfoStrict} ResponseInfoStrict instance
             */
            ResponseInfoStrict.create = function create(properties) {
                return new ResponseInfoStrict(properties);
            };
    
            /**
             * Encodes the specified ResponseInfoStrict message. Does not implicitly {@link phrase_spotting.ResponseInfoStrict.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {phrase_spotting.IResponseInfoStrict} message ResponseInfoStrict message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResponseInfoStrict.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.segments != null && message.segments.length)
                    for (var i = 0; i < message.segments.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.segments[i]);
                return writer;
            };
    
            /**
             * Encodes the specified ResponseInfoStrict message, length delimited. Does not implicitly {@link phrase_spotting.ResponseInfoStrict.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {phrase_spotting.IResponseInfoStrict} message ResponseInfoStrict message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResponseInfoStrict.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ResponseInfoStrict message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.ResponseInfoStrict} ResponseInfoStrict
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResponseInfoStrict.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.ResponseInfoStrict();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.segments && message.segments.length))
                                message.segments = [];
                            message.segments.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ResponseInfoStrict message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.ResponseInfoStrict} ResponseInfoStrict
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResponseInfoStrict.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ResponseInfoStrict message.
             * @function verify
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResponseInfoStrict.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.segments != null && message.hasOwnProperty("segments")) {
                    if (!Array.isArray(message.segments))
                        return "segments: array expected";
                    for (var i = 0; i < message.segments.length; ++i)
                        if (!$util.isString(message.segments[i]))
                            return "segments: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a ResponseInfoStrict message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.ResponseInfoStrict} ResponseInfoStrict
             */
            ResponseInfoStrict.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.ResponseInfoStrict)
                    return object;
                var message = new $root.phrase_spotting.ResponseInfoStrict();
                if (object.segments) {
                    if (!Array.isArray(object.segments))
                        throw TypeError(".phrase_spotting.ResponseInfoStrict.segments: array expected");
                    message.segments = [];
                    for (var i = 0; i < object.segments.length; ++i)
                        message.segments[i] = String(object.segments[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ResponseInfoStrict message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {phrase_spotting.ResponseInfoStrict} message ResponseInfoStrict
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResponseInfoStrict.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.segments = [];
                if (message.segments && message.segments.length) {
                    object.segments = [];
                    for (var j = 0; j < message.segments.length; ++j)
                        object.segments[j] = message.segments[j];
                }
                return object;
            };
    
            /**
             * Converts this ResponseInfoStrict to JSON.
             * @function toJSON
             * @memberof phrase_spotting.ResponseInfoStrict
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResponseInfoStrict.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ResponseInfoStrict
             * @function getTypeUrl
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResponseInfoStrict.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.ResponseInfoStrict";
            };
    
            return ResponseInfoStrict;
        })();
    
        phrase_spotting.ResponseInfoSemantic = (function() {
    
            /**
             * Properties of a ResponseInfoSemantic.
             * @memberof phrase_spotting
             * @interface IResponseInfoSemantic
             */
    
            /**
             * Constructs a new ResponseInfoSemantic.
             * @memberof phrase_spotting
             * @classdesc Represents a ResponseInfoSemantic.
             * @implements IResponseInfoSemantic
             * @constructor
             * @param {phrase_spotting.IResponseInfoSemantic=} [properties] Properties to set
             */
            function ResponseInfoSemantic(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ResponseInfoSemantic instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {phrase_spotting.IResponseInfoSemantic=} [properties] Properties to set
             * @returns {phrase_spotting.ResponseInfoSemantic} ResponseInfoSemantic instance
             */
            ResponseInfoSemantic.create = function create(properties) {
                return new ResponseInfoSemantic(properties);
            };
    
            /**
             * Encodes the specified ResponseInfoSemantic message. Does not implicitly {@link phrase_spotting.ResponseInfoSemantic.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {phrase_spotting.IResponseInfoSemantic} message ResponseInfoSemantic message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResponseInfoSemantic.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ResponseInfoSemantic message, length delimited. Does not implicitly {@link phrase_spotting.ResponseInfoSemantic.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {phrase_spotting.IResponseInfoSemantic} message ResponseInfoSemantic message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResponseInfoSemantic.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ResponseInfoSemantic message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.ResponseInfoSemantic} ResponseInfoSemantic
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResponseInfoSemantic.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.ResponseInfoSemantic();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ResponseInfoSemantic message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.ResponseInfoSemantic} ResponseInfoSemantic
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResponseInfoSemantic.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ResponseInfoSemantic message.
             * @function verify
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResponseInfoSemantic.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ResponseInfoSemantic message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.ResponseInfoSemantic} ResponseInfoSemantic
             */
            ResponseInfoSemantic.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.ResponseInfoSemantic)
                    return object;
                return new $root.phrase_spotting.ResponseInfoSemantic();
            };
    
            /**
             * Creates a plain object from a ResponseInfoSemantic message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {phrase_spotting.ResponseInfoSemantic} message ResponseInfoSemantic
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResponseInfoSemantic.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ResponseInfoSemantic to JSON.
             * @function toJSON
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResponseInfoSemantic.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ResponseInfoSemantic
             * @function getTypeUrl
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResponseInfoSemantic.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.ResponseInfoSemantic";
            };
    
            return ResponseInfoSemantic;
        })();
    
        phrase_spotting.Metadata = (function() {
    
            /**
             * Properties of a Metadata.
             * @memberof phrase_spotting
             * @interface IMetadata
             * @property {string|null} [utteranceUid] Metadata utteranceUid
             * @property {string|null} [callId] Metadata callId
             */
    
            /**
             * Constructs a new Metadata.
             * @memberof phrase_spotting
             * @classdesc Represents a Metadata.
             * @implements IMetadata
             * @constructor
             * @param {phrase_spotting.IMetadata=} [properties] Properties to set
             */
            function Metadata(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Metadata utteranceUid.
             * @member {string|null|undefined} utteranceUid
             * @memberof phrase_spotting.Metadata
             * @instance
             */
            Metadata.prototype.utteranceUid = null;
    
            /**
             * Metadata callId.
             * @member {string|null|undefined} callId
             * @memberof phrase_spotting.Metadata
             * @instance
             */
            Metadata.prototype.callId = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Metadata _utteranceUid.
             * @member {"utteranceUid"|undefined} _utteranceUid
             * @memberof phrase_spotting.Metadata
             * @instance
             */
            Object.defineProperty(Metadata.prototype, "_utteranceUid", {
                get: $util.oneOfGetter($oneOfFields = ["utteranceUid"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Metadata _callId.
             * @member {"callId"|undefined} _callId
             * @memberof phrase_spotting.Metadata
             * @instance
             */
            Object.defineProperty(Metadata.prototype, "_callId", {
                get: $util.oneOfGetter($oneOfFields = ["callId"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Metadata instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {phrase_spotting.IMetadata=} [properties] Properties to set
             * @returns {phrase_spotting.Metadata} Metadata instance
             */
            Metadata.create = function create(properties) {
                return new Metadata(properties);
            };
    
            /**
             * Encodes the specified Metadata message. Does not implicitly {@link phrase_spotting.Metadata.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {phrase_spotting.IMetadata} message Metadata message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Metadata.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.utteranceUid != null && Object.hasOwnProperty.call(message, "utteranceUid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.utteranceUid);
                if (message.callId != null && Object.hasOwnProperty.call(message, "callId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.callId);
                return writer;
            };
    
            /**
             * Encodes the specified Metadata message, length delimited. Does not implicitly {@link phrase_spotting.Metadata.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {phrase_spotting.IMetadata} message Metadata message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Metadata.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Metadata message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Metadata} Metadata
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Metadata.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Metadata();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.utteranceUid = reader.string();
                            break;
                        }
                    case 2: {
                            message.callId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Metadata message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Metadata} Metadata
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Metadata.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Metadata message.
             * @function verify
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Metadata.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.utteranceUid != null && message.hasOwnProperty("utteranceUid")) {
                    properties._utteranceUid = 1;
                    if (!$util.isString(message.utteranceUid))
                        return "utteranceUid: string expected";
                }
                if (message.callId != null && message.hasOwnProperty("callId")) {
                    properties._callId = 1;
                    if (!$util.isString(message.callId))
                        return "callId: string expected";
                }
                return null;
            };
    
            /**
             * Creates a Metadata message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Metadata} Metadata
             */
            Metadata.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Metadata)
                    return object;
                var message = new $root.phrase_spotting.Metadata();
                if (object.utteranceUid != null)
                    message.utteranceUid = String(object.utteranceUid);
                if (object.callId != null)
                    message.callId = String(object.callId);
                return message;
            };
    
            /**
             * Creates a plain object from a Metadata message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {phrase_spotting.Metadata} message Metadata
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Metadata.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.utteranceUid != null && message.hasOwnProperty("utteranceUid")) {
                    object.utteranceUid = message.utteranceUid;
                    if (options.oneofs)
                        object._utteranceUid = "utteranceUid";
                }
                if (message.callId != null && message.hasOwnProperty("callId")) {
                    object.callId = message.callId;
                    if (options.oneofs)
                        object._callId = "callId";
                }
                return object;
            };
    
            /**
             * Converts this Metadata to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Metadata
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Metadata.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Metadata
             * @function getTypeUrl
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Metadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Metadata";
            };
    
            return Metadata;
        })();
    
        phrase_spotting.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof phrase_spotting
             * @interface IRequest
             * @property {phrase_spotting.IConfiguration|null} [config] Request config
             * @property {phrase_spotting.IPayload|null} [payload] Request payload
             */
    
            /**
             * Constructs a new Request.
             * @memberof phrase_spotting
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {phrase_spotting.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request config.
             * @member {phrase_spotting.IConfiguration|null|undefined} config
             * @memberof phrase_spotting.Request
             * @instance
             */
            Request.prototype.config = null;
    
            /**
             * Request payload.
             * @member {phrase_spotting.IPayload|null|undefined} payload
             * @memberof phrase_spotting.Request
             * @instance
             */
            Request.prototype.payload = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Request data.
             * @member {"config"|"payload"|undefined} data
             * @memberof phrase_spotting.Request
             * @instance
             */
            Object.defineProperty(Request.prototype, "data", {
                get: $util.oneOfGetter($oneOfFields = ["config", "payload"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Request
             * @static
             * @param {phrase_spotting.IRequest=} [properties] Properties to set
             * @returns {phrase_spotting.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link phrase_spotting.Request.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Request
             * @static
             * @param {phrase_spotting.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                    $root.phrase_spotting.Configuration.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                    $root.phrase_spotting.Payload.encode(message.payload, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link phrase_spotting.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Request
             * @static
             * @param {phrase_spotting.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.config = $root.phrase_spotting.Configuration.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.payload = $root.phrase_spotting.Payload.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof phrase_spotting.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.config != null && message.hasOwnProperty("config")) {
                    properties.data = 1;
                    {
                        var error = $root.phrase_spotting.Configuration.verify(message.config);
                        if (error)
                            return "config." + error;
                    }
                }
                if (message.payload != null && message.hasOwnProperty("payload")) {
                    if (properties.data === 1)
                        return "data: multiple values";
                    properties.data = 1;
                    {
                        var error = $root.phrase_spotting.Payload.verify(message.payload);
                        if (error)
                            return "payload." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Request)
                    return object;
                var message = new $root.phrase_spotting.Request();
                if (object.config != null) {
                    if (typeof object.config !== "object")
                        throw TypeError(".phrase_spotting.Request.config: object expected");
                    message.config = $root.phrase_spotting.Configuration.fromObject(object.config);
                }
                if (object.payload != null) {
                    if (typeof object.payload !== "object")
                        throw TypeError(".phrase_spotting.Request.payload: object expected");
                    message.payload = $root.phrase_spotting.Payload.fromObject(object.payload);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Request
             * @static
             * @param {phrase_spotting.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.config != null && message.hasOwnProperty("config")) {
                    object.config = $root.phrase_spotting.Configuration.toObject(message.config, options);
                    if (options.oneofs)
                        object.data = "config";
                }
                if (message.payload != null && message.hasOwnProperty("payload")) {
                    object.payload = $root.phrase_spotting.Payload.toObject(message.payload, options);
                    if (options.oneofs)
                        object.data = "payload";
                }
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof phrase_spotting.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Request";
            };
    
            return Request;
        })();
    
        phrase_spotting.Payload = (function() {
    
            /**
             * Properties of a Payload.
             * @memberof phrase_spotting
             * @interface IPayload
             * @property {Array.<phrase_spotting.Payload.IRequestItem>|null} [items] Payload items
             * @property {boolean|null} [verbose] Payload verbose
             */
    
            /**
             * Constructs a new Payload.
             * @memberof phrase_spotting
             * @classdesc Represents a Payload.
             * @implements IPayload
             * @constructor
             * @param {phrase_spotting.IPayload=} [properties] Properties to set
             */
            function Payload(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Payload items.
             * @member {Array.<phrase_spotting.Payload.IRequestItem>} items
             * @memberof phrase_spotting.Payload
             * @instance
             */
            Payload.prototype.items = $util.emptyArray;
    
            /**
             * Payload verbose.
             * @member {boolean} verbose
             * @memberof phrase_spotting.Payload
             * @instance
             */
            Payload.prototype.verbose = false;
    
            /**
             * Creates a new Payload instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Payload
             * @static
             * @param {phrase_spotting.IPayload=} [properties] Properties to set
             * @returns {phrase_spotting.Payload} Payload instance
             */
            Payload.create = function create(properties) {
                return new Payload(properties);
            };
    
            /**
             * Encodes the specified Payload message. Does not implicitly {@link phrase_spotting.Payload.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Payload
             * @static
             * @param {phrase_spotting.IPayload} message Payload message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Payload.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.phrase_spotting.Payload.RequestItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.verbose != null && Object.hasOwnProperty.call(message, "verbose"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.verbose);
                return writer;
            };
    
            /**
             * Encodes the specified Payload message, length delimited. Does not implicitly {@link phrase_spotting.Payload.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Payload
             * @static
             * @param {phrase_spotting.IPayload} message Payload message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Payload.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Payload message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Payload
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Payload} Payload
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Payload.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Payload();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.phrase_spotting.Payload.RequestItem.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.verbose = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Payload message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Payload
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Payload} Payload
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Payload.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Payload message.
             * @function verify
             * @memberof phrase_spotting.Payload
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Payload.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.phrase_spotting.Payload.RequestItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.verbose != null && message.hasOwnProperty("verbose"))
                    if (typeof message.verbose !== "boolean")
                        return "verbose: boolean expected";
                return null;
            };
    
            /**
             * Creates a Payload message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Payload
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Payload} Payload
             */
            Payload.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Payload)
                    return object;
                var message = new $root.phrase_spotting.Payload();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".phrase_spotting.Payload.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".phrase_spotting.Payload.items: object expected");
                        message.items[i] = $root.phrase_spotting.Payload.RequestItem.fromObject(object.items[i]);
                    }
                }
                if (object.verbose != null)
                    message.verbose = Boolean(object.verbose);
                return message;
            };
    
            /**
             * Creates a plain object from a Payload message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Payload
             * @static
             * @param {phrase_spotting.Payload} message Payload
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Payload.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (options.defaults)
                    object.verbose = false;
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.phrase_spotting.Payload.RequestItem.toObject(message.items[j], options);
                }
                if (message.verbose != null && message.hasOwnProperty("verbose"))
                    object.verbose = message.verbose;
                return object;
            };
    
            /**
             * Converts this Payload to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Payload
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Payload.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Payload
             * @function getTypeUrl
             * @memberof phrase_spotting.Payload
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Payload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Payload";
            };
    
            Payload.RequestItem = (function() {
    
                /**
                 * Properties of a RequestItem.
                 * @memberof phrase_spotting.Payload
                 * @interface IRequestItem
                 * @property {string|null} [text] RequestItem text
                 * @property {phrase_spotting.IMetadata|null} [meta] RequestItem meta
                 */
    
                /**
                 * Constructs a new RequestItem.
                 * @memberof phrase_spotting.Payload
                 * @classdesc Represents a RequestItem.
                 * @implements IRequestItem
                 * @constructor
                 * @param {phrase_spotting.Payload.IRequestItem=} [properties] Properties to set
                 */
                function RequestItem(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RequestItem text.
                 * @member {string} text
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @instance
                 */
                RequestItem.prototype.text = "";
    
                /**
                 * RequestItem meta.
                 * @member {phrase_spotting.IMetadata|null|undefined} meta
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @instance
                 */
                RequestItem.prototype.meta = null;
    
                /**
                 * Creates a new RequestItem instance using the specified properties.
                 * @function create
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {phrase_spotting.Payload.IRequestItem=} [properties] Properties to set
                 * @returns {phrase_spotting.Payload.RequestItem} RequestItem instance
                 */
                RequestItem.create = function create(properties) {
                    return new RequestItem(properties);
                };
    
                /**
                 * Encodes the specified RequestItem message. Does not implicitly {@link phrase_spotting.Payload.RequestItem.verify|verify} messages.
                 * @function encode
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {phrase_spotting.Payload.IRequestItem} message RequestItem message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestItem.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                    if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
                        $root.phrase_spotting.Metadata.encode(message.meta, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified RequestItem message, length delimited. Does not implicitly {@link phrase_spotting.Payload.RequestItem.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {phrase_spotting.Payload.IRequestItem} message RequestItem message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestItem.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RequestItem message from the specified reader or buffer.
                 * @function decode
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {phrase_spotting.Payload.RequestItem} RequestItem
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestItem.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Payload.RequestItem();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.text = reader.string();
                                break;
                            }
                        case 2: {
                                message.meta = $root.phrase_spotting.Metadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RequestItem message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {phrase_spotting.Payload.RequestItem} RequestItem
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestItem.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RequestItem message.
                 * @function verify
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.text != null && message.hasOwnProperty("text"))
                        if (!$util.isString(message.text))
                            return "text: string expected";
                    if (message.meta != null && message.hasOwnProperty("meta")) {
                        var error = $root.phrase_spotting.Metadata.verify(message.meta);
                        if (error)
                            return "meta." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a RequestItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {phrase_spotting.Payload.RequestItem} RequestItem
                 */
                RequestItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.phrase_spotting.Payload.RequestItem)
                        return object;
                    var message = new $root.phrase_spotting.Payload.RequestItem();
                    if (object.text != null)
                        message.text = String(object.text);
                    if (object.meta != null) {
                        if (typeof object.meta !== "object")
                            throw TypeError(".phrase_spotting.Payload.RequestItem.meta: object expected");
                        message.meta = $root.phrase_spotting.Metadata.fromObject(object.meta);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RequestItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {phrase_spotting.Payload.RequestItem} message RequestItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.text = "";
                        object.meta = null;
                    }
                    if (message.text != null && message.hasOwnProperty("text"))
                        object.text = message.text;
                    if (message.meta != null && message.hasOwnProperty("meta"))
                        object.meta = $root.phrase_spotting.Metadata.toObject(message.meta, options);
                    return object;
                };
    
                /**
                 * Converts this RequestItem to JSON.
                 * @function toJSON
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RequestItem
                 * @function getTypeUrl
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RequestItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/phrase_spotting.Payload.RequestItem";
                };
    
                return RequestItem;
            })();
    
            return Payload;
        })();
    
        phrase_spotting.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof phrase_spotting
             * @interface IResponse
             * @property {Array.<phrase_spotting.Response.IResponseResult>|null} [results] Response results
             */
    
            /**
             * Constructs a new Response.
             * @memberof phrase_spotting
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {phrase_spotting.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                this.results = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response results.
             * @member {Array.<phrase_spotting.Response.IResponseResult>} results
             * @memberof phrase_spotting.Response
             * @instance
             */
            Response.prototype.results = $util.emptyArray;
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Response
             * @static
             * @param {phrase_spotting.IResponse=} [properties] Properties to set
             * @returns {phrase_spotting.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link phrase_spotting.Response.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Response
             * @static
             * @param {phrase_spotting.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.results != null && message.results.length)
                    for (var i = 0; i < message.results.length; ++i)
                        $root.phrase_spotting.Response.ResponseResult.encode(message.results[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link phrase_spotting.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Response
             * @static
             * @param {phrase_spotting.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.results && message.results.length))
                                message.results = [];
                            message.results.push($root.phrase_spotting.Response.ResponseResult.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof phrase_spotting.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.results != null && message.hasOwnProperty("results")) {
                    if (!Array.isArray(message.results))
                        return "results: array expected";
                    for (var i = 0; i < message.results.length; ++i) {
                        var error = $root.phrase_spotting.Response.ResponseResult.verify(message.results[i]);
                        if (error)
                            return "results." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Response)
                    return object;
                var message = new $root.phrase_spotting.Response();
                if (object.results) {
                    if (!Array.isArray(object.results))
                        throw TypeError(".phrase_spotting.Response.results: array expected");
                    message.results = [];
                    for (var i = 0; i < object.results.length; ++i) {
                        if (typeof object.results[i] !== "object")
                            throw TypeError(".phrase_spotting.Response.results: object expected");
                        message.results[i] = $root.phrase_spotting.Response.ResponseResult.fromObject(object.results[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Response
             * @static
             * @param {phrase_spotting.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.results = [];
                if (message.results && message.results.length) {
                    object.results = [];
                    for (var j = 0; j < message.results.length; ++j)
                        object.results[j] = $root.phrase_spotting.Response.ResponseResult.toObject(message.results[j], options);
                }
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof phrase_spotting.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Response";
            };
    
            Response.ResponseResult = (function() {
    
                /**
                 * Properties of a ResponseResult.
                 * @memberof phrase_spotting.Response
                 * @interface IResponseResult
                 * @property {string|null} [text] ResponseResult text
                 * @property {number|null} [score] ResponseResult score
                 * @property {Array.<phrase_spotting.ISpan>|null} [spans] ResponseResult spans
                 * @property {string|null} [configurationItemId] ResponseResult configurationItemId
                 * @property {phrase_spotting.IConfigurationItem|null} [configurationItem] ResponseResult configurationItem
                 * @property {phrase_spotting.IMetadata|null} [meta] ResponseResult meta
                 * @property {phrase_spotting.IResponseInfoStrict|null} [strict] ResponseResult strict
                 * @property {phrase_spotting.IResponseInfoSemantic|null} [semantic] ResponseResult semantic
                 * @property {string|null} [configurationItemTag] ResponseResult configurationItemTag
                 */
    
                /**
                 * Constructs a new ResponseResult.
                 * @memberof phrase_spotting.Response
                 * @classdesc Represents a ResponseResult.
                 * @implements IResponseResult
                 * @constructor
                 * @param {phrase_spotting.Response.IResponseResult=} [properties] Properties to set
                 */
                function ResponseResult(properties) {
                    this.spans = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ResponseResult text.
                 * @member {string|null|undefined} text
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.text = null;
    
                /**
                 * ResponseResult score.
                 * @member {number|null|undefined} score
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.score = null;
    
                /**
                 * ResponseResult spans.
                 * @member {Array.<phrase_spotting.ISpan>} spans
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.spans = $util.emptyArray;
    
                /**
                 * ResponseResult configurationItemId.
                 * @member {string} configurationItemId
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.configurationItemId = "";
    
                /**
                 * ResponseResult configurationItem.
                 * @member {phrase_spotting.IConfigurationItem|null|undefined} configurationItem
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.configurationItem = null;
    
                /**
                 * ResponseResult meta.
                 * @member {phrase_spotting.IMetadata|null|undefined} meta
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.meta = null;
    
                /**
                 * ResponseResult strict.
                 * @member {phrase_spotting.IResponseInfoStrict|null|undefined} strict
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.strict = null;
    
                /**
                 * ResponseResult semantic.
                 * @member {phrase_spotting.IResponseInfoSemantic|null|undefined} semantic
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.semantic = null;
    
                /**
                 * ResponseResult configurationItemTag.
                 * @member {string|null|undefined} configurationItemTag
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.configurationItemTag = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * ResponseResult _text.
                 * @member {"text"|undefined} _text
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                Object.defineProperty(ResponseResult.prototype, "_text", {
                    get: $util.oneOfGetter($oneOfFields = ["text"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * ResponseResult _score.
                 * @member {"score"|undefined} _score
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                Object.defineProperty(ResponseResult.prototype, "_score", {
                    get: $util.oneOfGetter($oneOfFields = ["score"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * ResponseResult _configurationItem.
                 * @member {"configurationItem"|undefined} _configurationItem
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                Object.defineProperty(ResponseResult.prototype, "_configurationItem", {
                    get: $util.oneOfGetter($oneOfFields = ["configurationItem"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * ResponseResult spotterResponse.
                 * @member {"strict"|"semantic"|undefined} spotterResponse
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                Object.defineProperty(ResponseResult.prototype, "spotterResponse", {
                    get: $util.oneOfGetter($oneOfFields = ["strict", "semantic"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * ResponseResult _configurationItemTag.
                 * @member {"configurationItemTag"|undefined} _configurationItemTag
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                Object.defineProperty(ResponseResult.prototype, "_configurationItemTag", {
                    get: $util.oneOfGetter($oneOfFields = ["configurationItemTag"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new ResponseResult instance using the specified properties.
                 * @function create
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {phrase_spotting.Response.IResponseResult=} [properties] Properties to set
                 * @returns {phrase_spotting.Response.ResponseResult} ResponseResult instance
                 */
                ResponseResult.create = function create(properties) {
                    return new ResponseResult(properties);
                };
    
                /**
                 * Encodes the specified ResponseResult message. Does not implicitly {@link phrase_spotting.Response.ResponseResult.verify|verify} messages.
                 * @function encode
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {phrase_spotting.Response.IResponseResult} message ResponseResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseResult.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                    if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.score);
                    if (message.spans != null && message.spans.length)
                        for (var i = 0; i < message.spans.length; ++i)
                            $root.phrase_spotting.Span.encode(message.spans[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.configurationItemId != null && Object.hasOwnProperty.call(message, "configurationItemId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.configurationItemId);
                    if (message.configurationItem != null && Object.hasOwnProperty.call(message, "configurationItem"))
                        $root.phrase_spotting.ConfigurationItem.encode(message.configurationItem, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
                        $root.phrase_spotting.Metadata.encode(message.meta, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.strict != null && Object.hasOwnProperty.call(message, "strict"))
                        $root.phrase_spotting.ResponseInfoStrict.encode(message.strict, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.semantic != null && Object.hasOwnProperty.call(message, "semantic"))
                        $root.phrase_spotting.ResponseInfoSemantic.encode(message.semantic, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.configurationItemTag != null && Object.hasOwnProperty.call(message, "configurationItemTag"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.configurationItemTag);
                    return writer;
                };
    
                /**
                 * Encodes the specified ResponseResult message, length delimited. Does not implicitly {@link phrase_spotting.Response.ResponseResult.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {phrase_spotting.Response.IResponseResult} message ResponseResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseResult.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ResponseResult message from the specified reader or buffer.
                 * @function decode
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {phrase_spotting.Response.ResponseResult} ResponseResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseResult.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Response.ResponseResult();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.text = reader.string();
                                break;
                            }
                        case 2: {
                                message.score = reader.float();
                                break;
                            }
                        case 3: {
                                if (!(message.spans && message.spans.length))
                                    message.spans = [];
                                message.spans.push($root.phrase_spotting.Span.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.configurationItemId = reader.string();
                                break;
                            }
                        case 5: {
                                message.configurationItem = $root.phrase_spotting.ConfigurationItem.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.meta = $root.phrase_spotting.Metadata.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.strict = $root.phrase_spotting.ResponseInfoStrict.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.semantic = $root.phrase_spotting.ResponseInfoSemantic.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.configurationItemTag = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ResponseResult message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {phrase_spotting.Response.ResponseResult} ResponseResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseResult.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ResponseResult message.
                 * @function verify
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponseResult.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.text != null && message.hasOwnProperty("text")) {
                        properties._text = 1;
                        if (!$util.isString(message.text))
                            return "text: string expected";
                    }
                    if (message.score != null && message.hasOwnProperty("score")) {
                        properties._score = 1;
                        if (typeof message.score !== "number")
                            return "score: number expected";
                    }
                    if (message.spans != null && message.hasOwnProperty("spans")) {
                        if (!Array.isArray(message.spans))
                            return "spans: array expected";
                        for (var i = 0; i < message.spans.length; ++i) {
                            var error = $root.phrase_spotting.Span.verify(message.spans[i]);
                            if (error)
                                return "spans." + error;
                        }
                    }
                    if (message.configurationItemId != null && message.hasOwnProperty("configurationItemId"))
                        if (!$util.isString(message.configurationItemId))
                            return "configurationItemId: string expected";
                    if (message.configurationItem != null && message.hasOwnProperty("configurationItem")) {
                        properties._configurationItem = 1;
                        {
                            var error = $root.phrase_spotting.ConfigurationItem.verify(message.configurationItem);
                            if (error)
                                return "configurationItem." + error;
                        }
                    }
                    if (message.meta != null && message.hasOwnProperty("meta")) {
                        var error = $root.phrase_spotting.Metadata.verify(message.meta);
                        if (error)
                            return "meta." + error;
                    }
                    if (message.strict != null && message.hasOwnProperty("strict")) {
                        properties.spotterResponse = 1;
                        {
                            var error = $root.phrase_spotting.ResponseInfoStrict.verify(message.strict);
                            if (error)
                                return "strict." + error;
                        }
                    }
                    if (message.semantic != null && message.hasOwnProperty("semantic")) {
                        if (properties.spotterResponse === 1)
                            return "spotterResponse: multiple values";
                        properties.spotterResponse = 1;
                        {
                            var error = $root.phrase_spotting.ResponseInfoSemantic.verify(message.semantic);
                            if (error)
                                return "semantic." + error;
                        }
                    }
                    if (message.configurationItemTag != null && message.hasOwnProperty("configurationItemTag")) {
                        properties._configurationItemTag = 1;
                        if (!$util.isString(message.configurationItemTag))
                            return "configurationItemTag: string expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a ResponseResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {phrase_spotting.Response.ResponseResult} ResponseResult
                 */
                ResponseResult.fromObject = function fromObject(object) {
                    if (object instanceof $root.phrase_spotting.Response.ResponseResult)
                        return object;
                    var message = new $root.phrase_spotting.Response.ResponseResult();
                    if (object.text != null)
                        message.text = String(object.text);
                    if (object.score != null)
                        message.score = Number(object.score);
                    if (object.spans) {
                        if (!Array.isArray(object.spans))
                            throw TypeError(".phrase_spotting.Response.ResponseResult.spans: array expected");
                        message.spans = [];
                        for (var i = 0; i < object.spans.length; ++i) {
                            if (typeof object.spans[i] !== "object")
                                throw TypeError(".phrase_spotting.Response.ResponseResult.spans: object expected");
                            message.spans[i] = $root.phrase_spotting.Span.fromObject(object.spans[i]);
                        }
                    }
                    if (object.configurationItemId != null)
                        message.configurationItemId = String(object.configurationItemId);
                    if (object.configurationItem != null) {
                        if (typeof object.configurationItem !== "object")
                            throw TypeError(".phrase_spotting.Response.ResponseResult.configurationItem: object expected");
                        message.configurationItem = $root.phrase_spotting.ConfigurationItem.fromObject(object.configurationItem);
                    }
                    if (object.meta != null) {
                        if (typeof object.meta !== "object")
                            throw TypeError(".phrase_spotting.Response.ResponseResult.meta: object expected");
                        message.meta = $root.phrase_spotting.Metadata.fromObject(object.meta);
                    }
                    if (object.strict != null) {
                        if (typeof object.strict !== "object")
                            throw TypeError(".phrase_spotting.Response.ResponseResult.strict: object expected");
                        message.strict = $root.phrase_spotting.ResponseInfoStrict.fromObject(object.strict);
                    }
                    if (object.semantic != null) {
                        if (typeof object.semantic !== "object")
                            throw TypeError(".phrase_spotting.Response.ResponseResult.semantic: object expected");
                        message.semantic = $root.phrase_spotting.ResponseInfoSemantic.fromObject(object.semantic);
                    }
                    if (object.configurationItemTag != null)
                        message.configurationItemTag = String(object.configurationItemTag);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ResponseResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {phrase_spotting.Response.ResponseResult} message ResponseResult
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponseResult.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.spans = [];
                    if (options.defaults) {
                        object.configurationItemId = "";
                        object.meta = null;
                    }
                    if (message.text != null && message.hasOwnProperty("text")) {
                        object.text = message.text;
                        if (options.oneofs)
                            object._text = "text";
                    }
                    if (message.score != null && message.hasOwnProperty("score")) {
                        object.score = options.json && !isFinite(message.score) ? String(message.score) : message.score;
                        if (options.oneofs)
                            object._score = "score";
                    }
                    if (message.spans && message.spans.length) {
                        object.spans = [];
                        for (var j = 0; j < message.spans.length; ++j)
                            object.spans[j] = $root.phrase_spotting.Span.toObject(message.spans[j], options);
                    }
                    if (message.configurationItemId != null && message.hasOwnProperty("configurationItemId"))
                        object.configurationItemId = message.configurationItemId;
                    if (message.configurationItem != null && message.hasOwnProperty("configurationItem")) {
                        object.configurationItem = $root.phrase_spotting.ConfigurationItem.toObject(message.configurationItem, options);
                        if (options.oneofs)
                            object._configurationItem = "configurationItem";
                    }
                    if (message.meta != null && message.hasOwnProperty("meta"))
                        object.meta = $root.phrase_spotting.Metadata.toObject(message.meta, options);
                    if (message.strict != null && message.hasOwnProperty("strict")) {
                        object.strict = $root.phrase_spotting.ResponseInfoStrict.toObject(message.strict, options);
                        if (options.oneofs)
                            object.spotterResponse = "strict";
                    }
                    if (message.semantic != null && message.hasOwnProperty("semantic")) {
                        object.semantic = $root.phrase_spotting.ResponseInfoSemantic.toObject(message.semantic, options);
                        if (options.oneofs)
                            object.spotterResponse = "semantic";
                    }
                    if (message.configurationItemTag != null && message.hasOwnProperty("configurationItemTag")) {
                        object.configurationItemTag = message.configurationItemTag;
                        if (options.oneofs)
                            object._configurationItemTag = "configurationItemTag";
                    }
                    return object;
                };
    
                /**
                 * Converts this ResponseResult to JSON.
                 * @function toJSON
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponseResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ResponseResult
                 * @function getTypeUrl
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ResponseResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/phrase_spotting.Response.ResponseResult";
                };
    
                return ResponseResult;
            })();
    
            return Response;
        })();
    
        phrase_spotting.SpotterConfigurationStrict = (function() {
    
            /**
             * Properties of a SpotterConfigurationStrict.
             * @memberof phrase_spotting
             * @interface ISpotterConfigurationStrict
             * @property {number|null} [collapseLen] SpotterConfigurationStrict collapseLen
             */
    
            /**
             * Constructs a new SpotterConfigurationStrict.
             * @memberof phrase_spotting
             * @classdesc Represents a SpotterConfigurationStrict.
             * @implements ISpotterConfigurationStrict
             * @constructor
             * @param {phrase_spotting.ISpotterConfigurationStrict=} [properties] Properties to set
             */
            function SpotterConfigurationStrict(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SpotterConfigurationStrict collapseLen.
             * @member {number} collapseLen
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @instance
             */
            SpotterConfigurationStrict.prototype.collapseLen = 0;
    
            /**
             * Creates a new SpotterConfigurationStrict instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {phrase_spotting.ISpotterConfigurationStrict=} [properties] Properties to set
             * @returns {phrase_spotting.SpotterConfigurationStrict} SpotterConfigurationStrict instance
             */
            SpotterConfigurationStrict.create = function create(properties) {
                return new SpotterConfigurationStrict(properties);
            };
    
            /**
             * Encodes the specified SpotterConfigurationStrict message. Does not implicitly {@link phrase_spotting.SpotterConfigurationStrict.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {phrase_spotting.ISpotterConfigurationStrict} message SpotterConfigurationStrict message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpotterConfigurationStrict.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.collapseLen != null && Object.hasOwnProperty.call(message, "collapseLen"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.collapseLen);
                return writer;
            };
    
            /**
             * Encodes the specified SpotterConfigurationStrict message, length delimited. Does not implicitly {@link phrase_spotting.SpotterConfigurationStrict.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {phrase_spotting.ISpotterConfigurationStrict} message SpotterConfigurationStrict message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpotterConfigurationStrict.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SpotterConfigurationStrict message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.SpotterConfigurationStrict} SpotterConfigurationStrict
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpotterConfigurationStrict.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.SpotterConfigurationStrict();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.collapseLen = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SpotterConfigurationStrict message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.SpotterConfigurationStrict} SpotterConfigurationStrict
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpotterConfigurationStrict.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SpotterConfigurationStrict message.
             * @function verify
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SpotterConfigurationStrict.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.collapseLen != null && message.hasOwnProperty("collapseLen"))
                    if (!$util.isInteger(message.collapseLen))
                        return "collapseLen: integer expected";
                return null;
            };
    
            /**
             * Creates a SpotterConfigurationStrict message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.SpotterConfigurationStrict} SpotterConfigurationStrict
             */
            SpotterConfigurationStrict.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.SpotterConfigurationStrict)
                    return object;
                var message = new $root.phrase_spotting.SpotterConfigurationStrict();
                if (object.collapseLen != null)
                    message.collapseLen = object.collapseLen | 0;
                return message;
            };
    
            /**
             * Creates a plain object from a SpotterConfigurationStrict message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {phrase_spotting.SpotterConfigurationStrict} message SpotterConfigurationStrict
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SpotterConfigurationStrict.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.collapseLen = 0;
                if (message.collapseLen != null && message.hasOwnProperty("collapseLen"))
                    object.collapseLen = message.collapseLen;
                return object;
            };
    
            /**
             * Converts this SpotterConfigurationStrict to JSON.
             * @function toJSON
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SpotterConfigurationStrict.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SpotterConfigurationStrict
             * @function getTypeUrl
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SpotterConfigurationStrict.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.SpotterConfigurationStrict";
            };
    
            return SpotterConfigurationStrict;
        })();
    
        phrase_spotting.SpotterConfigurationSematic = (function() {
    
            /**
             * Properties of a SpotterConfigurationSematic.
             * @memberof phrase_spotting
             * @interface ISpotterConfigurationSematic
             * @property {number|null} [threshold] SpotterConfigurationSematic threshold
             */
    
            /**
             * Constructs a new SpotterConfigurationSematic.
             * @memberof phrase_spotting
             * @classdesc Represents a SpotterConfigurationSematic.
             * @implements ISpotterConfigurationSematic
             * @constructor
             * @param {phrase_spotting.ISpotterConfigurationSematic=} [properties] Properties to set
             */
            function SpotterConfigurationSematic(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SpotterConfigurationSematic threshold.
             * @member {number} threshold
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @instance
             */
            SpotterConfigurationSematic.prototype.threshold = 0;
    
            /**
             * Creates a new SpotterConfigurationSematic instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {phrase_spotting.ISpotterConfigurationSematic=} [properties] Properties to set
             * @returns {phrase_spotting.SpotterConfigurationSematic} SpotterConfigurationSematic instance
             */
            SpotterConfigurationSematic.create = function create(properties) {
                return new SpotterConfigurationSematic(properties);
            };
    
            /**
             * Encodes the specified SpotterConfigurationSematic message. Does not implicitly {@link phrase_spotting.SpotterConfigurationSematic.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {phrase_spotting.ISpotterConfigurationSematic} message SpotterConfigurationSematic message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpotterConfigurationSematic.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.threshold != null && Object.hasOwnProperty.call(message, "threshold"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.threshold);
                return writer;
            };
    
            /**
             * Encodes the specified SpotterConfigurationSematic message, length delimited. Does not implicitly {@link phrase_spotting.SpotterConfigurationSematic.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {phrase_spotting.ISpotterConfigurationSematic} message SpotterConfigurationSematic message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpotterConfigurationSematic.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SpotterConfigurationSematic message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.SpotterConfigurationSematic} SpotterConfigurationSematic
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpotterConfigurationSematic.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.SpotterConfigurationSematic();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.threshold = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SpotterConfigurationSematic message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.SpotterConfigurationSematic} SpotterConfigurationSematic
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpotterConfigurationSematic.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SpotterConfigurationSematic message.
             * @function verify
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SpotterConfigurationSematic.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.threshold != null && message.hasOwnProperty("threshold"))
                    if (typeof message.threshold !== "number")
                        return "threshold: number expected";
                return null;
            };
    
            /**
             * Creates a SpotterConfigurationSematic message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.SpotterConfigurationSematic} SpotterConfigurationSematic
             */
            SpotterConfigurationSematic.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.SpotterConfigurationSematic)
                    return object;
                var message = new $root.phrase_spotting.SpotterConfigurationSematic();
                if (object.threshold != null)
                    message.threshold = Number(object.threshold);
                return message;
            };
    
            /**
             * Creates a plain object from a SpotterConfigurationSematic message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {phrase_spotting.SpotterConfigurationSematic} message SpotterConfigurationSematic
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SpotterConfigurationSematic.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.threshold = 0;
                if (message.threshold != null && message.hasOwnProperty("threshold"))
                    object.threshold = options.json && !isFinite(message.threshold) ? String(message.threshold) : message.threshold;
                return object;
            };
    
            /**
             * Converts this SpotterConfigurationSematic to JSON.
             * @function toJSON
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SpotterConfigurationSematic.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SpotterConfigurationSematic
             * @function getTypeUrl
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SpotterConfigurationSematic.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.SpotterConfigurationSematic";
            };
    
            return SpotterConfigurationSematic;
        })();
    
        /**
         * SpotterKind enum.
         * @name phrase_spotting.SpotterKind
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} STRICT=1 STRICT value
         * @property {number} SEMANTIC=2 SEMANTIC value
         */
        phrase_spotting.SpotterKind = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "STRICT"] = 1;
            values[valuesById[2] = "SEMANTIC"] = 2;
            return values;
        })();
    
        phrase_spotting.ConfigurationItem = (function() {
    
            /**
             * Properties of a ConfigurationItem.
             * @memberof phrase_spotting
             * @interface IConfigurationItem
             * @property {string|null} [id] ConfigurationItem id
             * @property {phrase_spotting.SpotterKind|null} [kind] ConfigurationItem kind
             * @property {string|null} [groupName] ConfigurationItem groupName
             * @property {Array.<string>|null} [head] ConfigurationItem head
             * @property {Array.<string>|null} [complement] ConfigurationItem complement
             * @property {string|null} [tag] ConfigurationItem tag
             */
    
            /**
             * Constructs a new ConfigurationItem.
             * @memberof phrase_spotting
             * @classdesc Represents a ConfigurationItem.
             * @implements IConfigurationItem
             * @constructor
             * @param {phrase_spotting.IConfigurationItem=} [properties] Properties to set
             */
            function ConfigurationItem(properties) {
                this.head = [];
                this.complement = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ConfigurationItem id.
             * @member {string} id
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.id = "";
    
            /**
             * ConfigurationItem kind.
             * @member {phrase_spotting.SpotterKind} kind
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.kind = 0;
    
            /**
             * ConfigurationItem groupName.
             * @member {string} groupName
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.groupName = "";
    
            /**
             * ConfigurationItem head.
             * @member {Array.<string>} head
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.head = $util.emptyArray;
    
            /**
             * ConfigurationItem complement.
             * @member {Array.<string>} complement
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.complement = $util.emptyArray;
    
            /**
             * ConfigurationItem tag.
             * @member {string|null|undefined} tag
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.tag = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * ConfigurationItem _tag.
             * @member {"tag"|undefined} _tag
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            Object.defineProperty(ConfigurationItem.prototype, "_tag", {
                get: $util.oneOfGetter($oneOfFields = ["tag"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new ConfigurationItem instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {phrase_spotting.IConfigurationItem=} [properties] Properties to set
             * @returns {phrase_spotting.ConfigurationItem} ConfigurationItem instance
             */
            ConfigurationItem.create = function create(properties) {
                return new ConfigurationItem(properties);
            };
    
            /**
             * Encodes the specified ConfigurationItem message. Does not implicitly {@link phrase_spotting.ConfigurationItem.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {phrase_spotting.IConfigurationItem} message ConfigurationItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfigurationItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.kind);
                if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.groupName);
                if (message.head != null && message.head.length)
                    for (var i = 0; i < message.head.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.head[i]);
                if (message.complement != null && message.complement.length)
                    for (var i = 0; i < message.complement.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.complement[i]);
                if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.tag);
                return writer;
            };
    
            /**
             * Encodes the specified ConfigurationItem message, length delimited. Does not implicitly {@link phrase_spotting.ConfigurationItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {phrase_spotting.IConfigurationItem} message ConfigurationItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfigurationItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ConfigurationItem message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.ConfigurationItem} ConfigurationItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfigurationItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.ConfigurationItem();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.kind = reader.int32();
                            break;
                        }
                    case 3: {
                            message.groupName = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.head && message.head.length))
                                message.head = [];
                            message.head.push(reader.string());
                            break;
                        }
                    case 5: {
                            if (!(message.complement && message.complement.length))
                                message.complement = [];
                            message.complement.push(reader.string());
                            break;
                        }
                    case 6: {
                            message.tag = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ConfigurationItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.ConfigurationItem} ConfigurationItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfigurationItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ConfigurationItem message.
             * @function verify
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConfigurationItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.kind != null && message.hasOwnProperty("kind"))
                    switch (message.kind) {
                    default:
                        return "kind: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    if (!$util.isString(message.groupName))
                        return "groupName: string expected";
                if (message.head != null && message.hasOwnProperty("head")) {
                    if (!Array.isArray(message.head))
                        return "head: array expected";
                    for (var i = 0; i < message.head.length; ++i)
                        if (!$util.isString(message.head[i]))
                            return "head: string[] expected";
                }
                if (message.complement != null && message.hasOwnProperty("complement")) {
                    if (!Array.isArray(message.complement))
                        return "complement: array expected";
                    for (var i = 0; i < message.complement.length; ++i)
                        if (!$util.isString(message.complement[i]))
                            return "complement: string[] expected";
                }
                if (message.tag != null && message.hasOwnProperty("tag")) {
                    properties._tag = 1;
                    if (!$util.isString(message.tag))
                        return "tag: string expected";
                }
                return null;
            };
    
            /**
             * Creates a ConfigurationItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.ConfigurationItem} ConfigurationItem
             */
            ConfigurationItem.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.ConfigurationItem)
                    return object;
                var message = new $root.phrase_spotting.ConfigurationItem();
                if (object.id != null)
                    message.id = String(object.id);
                switch (object.kind) {
                default:
                    if (typeof object.kind === "number") {
                        message.kind = object.kind;
                        break;
                    }
                    break;
                case "UNKNOWN":
                case 0:
                    message.kind = 0;
                    break;
                case "STRICT":
                case 1:
                    message.kind = 1;
                    break;
                case "SEMANTIC":
                case 2:
                    message.kind = 2;
                    break;
                }
                if (object.groupName != null)
                    message.groupName = String(object.groupName);
                if (object.head) {
                    if (!Array.isArray(object.head))
                        throw TypeError(".phrase_spotting.ConfigurationItem.head: array expected");
                    message.head = [];
                    for (var i = 0; i < object.head.length; ++i)
                        message.head[i] = String(object.head[i]);
                }
                if (object.complement) {
                    if (!Array.isArray(object.complement))
                        throw TypeError(".phrase_spotting.ConfigurationItem.complement: array expected");
                    message.complement = [];
                    for (var i = 0; i < object.complement.length; ++i)
                        message.complement[i] = String(object.complement[i]);
                }
                if (object.tag != null)
                    message.tag = String(object.tag);
                return message;
            };
    
            /**
             * Creates a plain object from a ConfigurationItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {phrase_spotting.ConfigurationItem} message ConfigurationItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConfigurationItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.head = [];
                    object.complement = [];
                }
                if (options.defaults) {
                    object.id = "";
                    object.kind = options.enums === String ? "UNKNOWN" : 0;
                    object.groupName = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.kind != null && message.hasOwnProperty("kind"))
                    object.kind = options.enums === String ? $root.phrase_spotting.SpotterKind[message.kind] === undefined ? message.kind : $root.phrase_spotting.SpotterKind[message.kind] : message.kind;
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    object.groupName = message.groupName;
                if (message.head && message.head.length) {
                    object.head = [];
                    for (var j = 0; j < message.head.length; ++j)
                        object.head[j] = message.head[j];
                }
                if (message.complement && message.complement.length) {
                    object.complement = [];
                    for (var j = 0; j < message.complement.length; ++j)
                        object.complement[j] = message.complement[j];
                }
                if (message.tag != null && message.hasOwnProperty("tag")) {
                    object.tag = message.tag;
                    if (options.oneofs)
                        object._tag = "tag";
                }
                return object;
            };
    
            /**
             * Converts this ConfigurationItem to JSON.
             * @function toJSON
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConfigurationItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ConfigurationItem
             * @function getTypeUrl
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ConfigurationItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.ConfigurationItem";
            };
    
            return ConfigurationItem;
        })();
    
        phrase_spotting.Configuration = (function() {
    
            /**
             * Properties of a Configuration.
             * @memberof phrase_spotting
             * @interface IConfiguration
             * @property {Array.<phrase_spotting.IConfigurationItem>|null} [items] Configuration items
             * @property {phrase_spotting.ISpotterConfigurationStrict|null} [strict] Configuration strict
             * @property {phrase_spotting.ISpotterConfigurationSematic|null} [semantic] Configuration semantic
             */
    
            /**
             * Constructs a new Configuration.
             * @memberof phrase_spotting
             * @classdesc Represents a Configuration.
             * @implements IConfiguration
             * @constructor
             * @param {phrase_spotting.IConfiguration=} [properties] Properties to set
             */
            function Configuration(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Configuration items.
             * @member {Array.<phrase_spotting.IConfigurationItem>} items
             * @memberof phrase_spotting.Configuration
             * @instance
             */
            Configuration.prototype.items = $util.emptyArray;
    
            /**
             * Configuration strict.
             * @member {phrase_spotting.ISpotterConfigurationStrict|null|undefined} strict
             * @memberof phrase_spotting.Configuration
             * @instance
             */
            Configuration.prototype.strict = null;
    
            /**
             * Configuration semantic.
             * @member {phrase_spotting.ISpotterConfigurationSematic|null|undefined} semantic
             * @memberof phrase_spotting.Configuration
             * @instance
             */
            Configuration.prototype.semantic = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Configuration spotterConfiguration.
             * @member {"strict"|"semantic"|undefined} spotterConfiguration
             * @memberof phrase_spotting.Configuration
             * @instance
             */
            Object.defineProperty(Configuration.prototype, "spotterConfiguration", {
                get: $util.oneOfGetter($oneOfFields = ["strict", "semantic"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Configuration instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {phrase_spotting.IConfiguration=} [properties] Properties to set
             * @returns {phrase_spotting.Configuration} Configuration instance
             */
            Configuration.create = function create(properties) {
                return new Configuration(properties);
            };
    
            /**
             * Encodes the specified Configuration message. Does not implicitly {@link phrase_spotting.Configuration.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {phrase_spotting.IConfiguration} message Configuration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Configuration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.phrase_spotting.ConfigurationItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.strict != null && Object.hasOwnProperty.call(message, "strict"))
                    $root.phrase_spotting.SpotterConfigurationStrict.encode(message.strict, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.semantic != null && Object.hasOwnProperty.call(message, "semantic"))
                    $root.phrase_spotting.SpotterConfigurationSematic.encode(message.semantic, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Configuration message, length delimited. Does not implicitly {@link phrase_spotting.Configuration.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {phrase_spotting.IConfiguration} message Configuration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Configuration.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Configuration message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Configuration} Configuration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Configuration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Configuration();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.phrase_spotting.ConfigurationItem.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.strict = $root.phrase_spotting.SpotterConfigurationStrict.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.semantic = $root.phrase_spotting.SpotterConfigurationSematic.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Configuration message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Configuration} Configuration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Configuration.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Configuration message.
             * @function verify
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Configuration.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.phrase_spotting.ConfigurationItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.strict != null && message.hasOwnProperty("strict")) {
                    properties.spotterConfiguration = 1;
                    {
                        var error = $root.phrase_spotting.SpotterConfigurationStrict.verify(message.strict);
                        if (error)
                            return "strict." + error;
                    }
                }
                if (message.semantic != null && message.hasOwnProperty("semantic")) {
                    if (properties.spotterConfiguration === 1)
                        return "spotterConfiguration: multiple values";
                    properties.spotterConfiguration = 1;
                    {
                        var error = $root.phrase_spotting.SpotterConfigurationSematic.verify(message.semantic);
                        if (error)
                            return "semantic." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Configuration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Configuration} Configuration
             */
            Configuration.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Configuration)
                    return object;
                var message = new $root.phrase_spotting.Configuration();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".phrase_spotting.Configuration.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".phrase_spotting.Configuration.items: object expected");
                        message.items[i] = $root.phrase_spotting.ConfigurationItem.fromObject(object.items[i]);
                    }
                }
                if (object.strict != null) {
                    if (typeof object.strict !== "object")
                        throw TypeError(".phrase_spotting.Configuration.strict: object expected");
                    message.strict = $root.phrase_spotting.SpotterConfigurationStrict.fromObject(object.strict);
                }
                if (object.semantic != null) {
                    if (typeof object.semantic !== "object")
                        throw TypeError(".phrase_spotting.Configuration.semantic: object expected");
                    message.semantic = $root.phrase_spotting.SpotterConfigurationSematic.fromObject(object.semantic);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Configuration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {phrase_spotting.Configuration} message Configuration
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Configuration.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.phrase_spotting.ConfigurationItem.toObject(message.items[j], options);
                }
                if (message.strict != null && message.hasOwnProperty("strict")) {
                    object.strict = $root.phrase_spotting.SpotterConfigurationStrict.toObject(message.strict, options);
                    if (options.oneofs)
                        object.spotterConfiguration = "strict";
                }
                if (message.semantic != null && message.hasOwnProperty("semantic")) {
                    object.semantic = $root.phrase_spotting.SpotterConfigurationSematic.toObject(message.semantic, options);
                    if (options.oneofs)
                        object.spotterConfiguration = "semantic";
                }
                return object;
            };
    
            /**
             * Converts this Configuration to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Configuration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Configuration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Configuration
             * @function getTypeUrl
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Configuration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Configuration";
            };
    
            return Configuration;
        })();
    
        return phrase_spotting;
    })();

    return $root;
});
