/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.phrase_spotting = (function() {
    
        /**
         * Namespace phrase_spotting.
         * @exports phrase_spotting
         * @namespace
         */
        var phrase_spotting = {};
    
        phrase_spotting.StrictPhraseSpotter = (function() {
    
            /**
             * Constructs a new StrictPhraseSpotter service.
             * @memberof phrase_spotting
             * @classdesc Represents a StrictPhraseSpotter
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function StrictPhraseSpotter(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }
    
            (StrictPhraseSpotter.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StrictPhraseSpotter;
    
            /**
             * Creates new StrictPhraseSpotter service using the specified rpc implementation.
             * @function create
             * @memberof phrase_spotting.StrictPhraseSpotter
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {StrictPhraseSpotter} RPC service. Useful where requests and/or responses are streamed.
             */
            StrictPhraseSpotter.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };
    
            /**
             * Callback as used by {@link phrase_spotting.StrictPhraseSpotter#spot}.
             * @memberof phrase_spotting.StrictPhraseSpotter
             * @typedef SpotCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {phrase_spotting.Response} [response] Response
             */
    
            /**
             * Calls Spot.
             * @function spot
             * @memberof phrase_spotting.StrictPhraseSpotter
             * @instance
             * @param {phrase_spotting.IRequest} request Request message or plain object
             * @param {phrase_spotting.StrictPhraseSpotter.SpotCallback} callback Node-style callback called with the error, if any, and Response
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(StrictPhraseSpotter.prototype.spot = function spot(request, callback) {
                return this.rpcCall(spot, $root.phrase_spotting.Request, $root.phrase_spotting.Response, request, callback);
            }, "name", { value: "Spot" });
    
            /**
             * Calls Spot.
             * @function spot
             * @memberof phrase_spotting.StrictPhraseSpotter
             * @instance
             * @param {phrase_spotting.IRequest} request Request message or plain object
             * @returns {Promise<phrase_spotting.Response>} Promise
             * @variation 2
             */
    
            return StrictPhraseSpotter;
        })();
    
        phrase_spotting.SemanticPhraseSpotter = (function() {
    
            /**
             * Constructs a new SemanticPhraseSpotter service.
             * @memberof phrase_spotting
             * @classdesc Represents a SemanticPhraseSpotter
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function SemanticPhraseSpotter(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }
    
            (SemanticPhraseSpotter.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SemanticPhraseSpotter;
    
            /**
             * Creates new SemanticPhraseSpotter service using the specified rpc implementation.
             * @function create
             * @memberof phrase_spotting.SemanticPhraseSpotter
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {SemanticPhraseSpotter} RPC service. Useful where requests and/or responses are streamed.
             */
            SemanticPhraseSpotter.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };
    
            /**
             * Callback as used by {@link phrase_spotting.SemanticPhraseSpotter#spot}.
             * @memberof phrase_spotting.SemanticPhraseSpotter
             * @typedef SpotCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {phrase_spotting.Response} [response] Response
             */
    
            /**
             * Calls Spot.
             * @function spot
             * @memberof phrase_spotting.SemanticPhraseSpotter
             * @instance
             * @param {phrase_spotting.IRequest} request Request message or plain object
             * @param {phrase_spotting.SemanticPhraseSpotter.SpotCallback} callback Node-style callback called with the error, if any, and Response
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(SemanticPhraseSpotter.prototype.spot = function spot(request, callback) {
                return this.rpcCall(spot, $root.phrase_spotting.Request, $root.phrase_spotting.Response, request, callback);
            }, "name", { value: "Spot" });
    
            /**
             * Calls Spot.
             * @function spot
             * @memberof phrase_spotting.SemanticPhraseSpotter
             * @instance
             * @param {phrase_spotting.IRequest} request Request message or plain object
             * @returns {Promise<phrase_spotting.Response>} Promise
             * @variation 2
             */
    
            return SemanticPhraseSpotter;
        })();
    
        phrase_spotting.Span = (function() {
    
            /**
             * Properties of a Span.
             * @memberof phrase_spotting
             * @interface ISpan
             * @property {number|null} [startIdx] Span startIdx
             * @property {number|null} [endIdx] Span endIdx
             */
    
            /**
             * Constructs a new Span.
             * @memberof phrase_spotting
             * @classdesc Represents a Span.
             * @implements ISpan
             * @constructor
             * @param {phrase_spotting.ISpan=} [properties] Properties to set
             */
            function Span(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Span startIdx.
             * @member {number} startIdx
             * @memberof phrase_spotting.Span
             * @instance
             */
            Span.prototype.startIdx = 0;
    
            /**
             * Span endIdx.
             * @member {number} endIdx
             * @memberof phrase_spotting.Span
             * @instance
             */
            Span.prototype.endIdx = 0;
    
            /**
             * Creates a new Span instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Span
             * @static
             * @param {phrase_spotting.ISpan=} [properties] Properties to set
             * @returns {phrase_spotting.Span} Span instance
             */
            Span.create = function create(properties) {
                return new Span(properties);
            };
    
            /**
             * Encodes the specified Span message. Does not implicitly {@link phrase_spotting.Span.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Span
             * @static
             * @param {phrase_spotting.ISpan} message Span message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Span.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.startIdx != null && Object.hasOwnProperty.call(message, "startIdx"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.startIdx);
                if (message.endIdx != null && Object.hasOwnProperty.call(message, "endIdx"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.endIdx);
                return writer;
            };
    
            /**
             * Encodes the specified Span message, length delimited. Does not implicitly {@link phrase_spotting.Span.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Span
             * @static
             * @param {phrase_spotting.ISpan} message Span message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Span.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Span message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Span
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Span} Span
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Span.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Span();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.startIdx = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.endIdx = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Span message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Span
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Span} Span
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Span.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Span message.
             * @function verify
             * @memberof phrase_spotting.Span
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Span.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.startIdx != null && message.hasOwnProperty("startIdx"))
                    if (!$util.isInteger(message.startIdx))
                        return "startIdx: integer expected";
                if (message.endIdx != null && message.hasOwnProperty("endIdx"))
                    if (!$util.isInteger(message.endIdx))
                        return "endIdx: integer expected";
                return null;
            };
    
            /**
             * Creates a Span message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Span
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Span} Span
             */
            Span.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Span)
                    return object;
                var message = new $root.phrase_spotting.Span();
                if (object.startIdx != null)
                    message.startIdx = object.startIdx >>> 0;
                if (object.endIdx != null)
                    message.endIdx = object.endIdx >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a Span message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Span
             * @static
             * @param {phrase_spotting.Span} message Span
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Span.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.startIdx = 0;
                    object.endIdx = 0;
                }
                if (message.startIdx != null && message.hasOwnProperty("startIdx"))
                    object.startIdx = message.startIdx;
                if (message.endIdx != null && message.hasOwnProperty("endIdx"))
                    object.endIdx = message.endIdx;
                return object;
            };
    
            /**
             * Converts this Span to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Span
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Span.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Span
             * @function getTypeUrl
             * @memberof phrase_spotting.Span
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Span.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Span";
            };
    
            return Span;
        })();
    
        phrase_spotting.ResponseInfoStrict = (function() {
    
            /**
             * Properties of a ResponseInfoStrict.
             * @memberof phrase_spotting
             * @interface IResponseInfoStrict
             * @property {Array.<string>|null} [segments] ResponseInfoStrict segments
             */
    
            /**
             * Constructs a new ResponseInfoStrict.
             * @memberof phrase_spotting
             * @classdesc Represents a ResponseInfoStrict.
             * @implements IResponseInfoStrict
             * @constructor
             * @param {phrase_spotting.IResponseInfoStrict=} [properties] Properties to set
             */
            function ResponseInfoStrict(properties) {
                this.segments = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ResponseInfoStrict segments.
             * @member {Array.<string>} segments
             * @memberof phrase_spotting.ResponseInfoStrict
             * @instance
             */
            ResponseInfoStrict.prototype.segments = $util.emptyArray;
    
            /**
             * Creates a new ResponseInfoStrict instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {phrase_spotting.IResponseInfoStrict=} [properties] Properties to set
             * @returns {phrase_spotting.ResponseInfoStrict} ResponseInfoStrict instance
             */
            ResponseInfoStrict.create = function create(properties) {
                return new ResponseInfoStrict(properties);
            };
    
            /**
             * Encodes the specified ResponseInfoStrict message. Does not implicitly {@link phrase_spotting.ResponseInfoStrict.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {phrase_spotting.IResponseInfoStrict} message ResponseInfoStrict message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResponseInfoStrict.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.segments != null && message.segments.length)
                    for (var i = 0; i < message.segments.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.segments[i]);
                return writer;
            };
    
            /**
             * Encodes the specified ResponseInfoStrict message, length delimited. Does not implicitly {@link phrase_spotting.ResponseInfoStrict.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {phrase_spotting.IResponseInfoStrict} message ResponseInfoStrict message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResponseInfoStrict.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ResponseInfoStrict message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.ResponseInfoStrict} ResponseInfoStrict
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResponseInfoStrict.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.ResponseInfoStrict();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.segments && message.segments.length))
                                message.segments = [];
                            message.segments.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ResponseInfoStrict message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.ResponseInfoStrict} ResponseInfoStrict
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResponseInfoStrict.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ResponseInfoStrict message.
             * @function verify
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResponseInfoStrict.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.segments != null && message.hasOwnProperty("segments")) {
                    if (!Array.isArray(message.segments))
                        return "segments: array expected";
                    for (var i = 0; i < message.segments.length; ++i)
                        if (!$util.isString(message.segments[i]))
                            return "segments: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a ResponseInfoStrict message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.ResponseInfoStrict} ResponseInfoStrict
             */
            ResponseInfoStrict.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.ResponseInfoStrict)
                    return object;
                var message = new $root.phrase_spotting.ResponseInfoStrict();
                if (object.segments) {
                    if (!Array.isArray(object.segments))
                        throw TypeError(".phrase_spotting.ResponseInfoStrict.segments: array expected");
                    message.segments = [];
                    for (var i = 0; i < object.segments.length; ++i)
                        message.segments[i] = String(object.segments[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ResponseInfoStrict message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {phrase_spotting.ResponseInfoStrict} message ResponseInfoStrict
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResponseInfoStrict.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.segments = [];
                if (message.segments && message.segments.length) {
                    object.segments = [];
                    for (var j = 0; j < message.segments.length; ++j)
                        object.segments[j] = message.segments[j];
                }
                return object;
            };
    
            /**
             * Converts this ResponseInfoStrict to JSON.
             * @function toJSON
             * @memberof phrase_spotting.ResponseInfoStrict
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResponseInfoStrict.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ResponseInfoStrict
             * @function getTypeUrl
             * @memberof phrase_spotting.ResponseInfoStrict
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResponseInfoStrict.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.ResponseInfoStrict";
            };
    
            return ResponseInfoStrict;
        })();
    
        phrase_spotting.ResponseInfoSemantic = (function() {
    
            /**
             * Properties of a ResponseInfoSemantic.
             * @memberof phrase_spotting
             * @interface IResponseInfoSemantic
             */
    
            /**
             * Constructs a new ResponseInfoSemantic.
             * @memberof phrase_spotting
             * @classdesc Represents a ResponseInfoSemantic.
             * @implements IResponseInfoSemantic
             * @constructor
             * @param {phrase_spotting.IResponseInfoSemantic=} [properties] Properties to set
             */
            function ResponseInfoSemantic(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ResponseInfoSemantic instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {phrase_spotting.IResponseInfoSemantic=} [properties] Properties to set
             * @returns {phrase_spotting.ResponseInfoSemantic} ResponseInfoSemantic instance
             */
            ResponseInfoSemantic.create = function create(properties) {
                return new ResponseInfoSemantic(properties);
            };
    
            /**
             * Encodes the specified ResponseInfoSemantic message. Does not implicitly {@link phrase_spotting.ResponseInfoSemantic.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {phrase_spotting.IResponseInfoSemantic} message ResponseInfoSemantic message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResponseInfoSemantic.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ResponseInfoSemantic message, length delimited. Does not implicitly {@link phrase_spotting.ResponseInfoSemantic.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {phrase_spotting.IResponseInfoSemantic} message ResponseInfoSemantic message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResponseInfoSemantic.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ResponseInfoSemantic message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.ResponseInfoSemantic} ResponseInfoSemantic
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResponseInfoSemantic.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.ResponseInfoSemantic();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ResponseInfoSemantic message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.ResponseInfoSemantic} ResponseInfoSemantic
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResponseInfoSemantic.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ResponseInfoSemantic message.
             * @function verify
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResponseInfoSemantic.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ResponseInfoSemantic message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.ResponseInfoSemantic} ResponseInfoSemantic
             */
            ResponseInfoSemantic.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.ResponseInfoSemantic)
                    return object;
                return new $root.phrase_spotting.ResponseInfoSemantic();
            };
    
            /**
             * Creates a plain object from a ResponseInfoSemantic message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {phrase_spotting.ResponseInfoSemantic} message ResponseInfoSemantic
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResponseInfoSemantic.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ResponseInfoSemantic to JSON.
             * @function toJSON
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResponseInfoSemantic.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ResponseInfoSemantic
             * @function getTypeUrl
             * @memberof phrase_spotting.ResponseInfoSemantic
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResponseInfoSemantic.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.ResponseInfoSemantic";
            };
    
            return ResponseInfoSemantic;
        })();
    
        phrase_spotting.Metadata = (function() {
    
            /**
             * Properties of a Metadata.
             * @memberof phrase_spotting
             * @interface IMetadata
             * @property {string|null} [utteranceUid] Metadata utteranceUid
             * @property {string|null} [callId] Metadata callId
             */
    
            /**
             * Constructs a new Metadata.
             * @memberof phrase_spotting
             * @classdesc Represents a Metadata.
             * @implements IMetadata
             * @constructor
             * @param {phrase_spotting.IMetadata=} [properties] Properties to set
             */
            function Metadata(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Metadata utteranceUid.
             * @member {string|null|undefined} utteranceUid
             * @memberof phrase_spotting.Metadata
             * @instance
             */
            Metadata.prototype.utteranceUid = null;
    
            /**
             * Metadata callId.
             * @member {string|null|undefined} callId
             * @memberof phrase_spotting.Metadata
             * @instance
             */
            Metadata.prototype.callId = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Metadata _utteranceUid.
             * @member {"utteranceUid"|undefined} _utteranceUid
             * @memberof phrase_spotting.Metadata
             * @instance
             */
            Object.defineProperty(Metadata.prototype, "_utteranceUid", {
                get: $util.oneOfGetter($oneOfFields = ["utteranceUid"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Metadata _callId.
             * @member {"callId"|undefined} _callId
             * @memberof phrase_spotting.Metadata
             * @instance
             */
            Object.defineProperty(Metadata.prototype, "_callId", {
                get: $util.oneOfGetter($oneOfFields = ["callId"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Metadata instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {phrase_spotting.IMetadata=} [properties] Properties to set
             * @returns {phrase_spotting.Metadata} Metadata instance
             */
            Metadata.create = function create(properties) {
                return new Metadata(properties);
            };
    
            /**
             * Encodes the specified Metadata message. Does not implicitly {@link phrase_spotting.Metadata.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {phrase_spotting.IMetadata} message Metadata message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Metadata.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.utteranceUid != null && Object.hasOwnProperty.call(message, "utteranceUid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.utteranceUid);
                if (message.callId != null && Object.hasOwnProperty.call(message, "callId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.callId);
                return writer;
            };
    
            /**
             * Encodes the specified Metadata message, length delimited. Does not implicitly {@link phrase_spotting.Metadata.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {phrase_spotting.IMetadata} message Metadata message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Metadata.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Metadata message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Metadata} Metadata
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Metadata.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Metadata();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.utteranceUid = reader.string();
                            break;
                        }
                    case 2: {
                            message.callId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Metadata message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Metadata} Metadata
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Metadata.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Metadata message.
             * @function verify
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Metadata.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.utteranceUid != null && message.hasOwnProperty("utteranceUid")) {
                    properties._utteranceUid = 1;
                    if (!$util.isString(message.utteranceUid))
                        return "utteranceUid: string expected";
                }
                if (message.callId != null && message.hasOwnProperty("callId")) {
                    properties._callId = 1;
                    if (!$util.isString(message.callId))
                        return "callId: string expected";
                }
                return null;
            };
    
            /**
             * Creates a Metadata message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Metadata} Metadata
             */
            Metadata.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Metadata)
                    return object;
                var message = new $root.phrase_spotting.Metadata();
                if (object.utteranceUid != null)
                    message.utteranceUid = String(object.utteranceUid);
                if (object.callId != null)
                    message.callId = String(object.callId);
                return message;
            };
    
            /**
             * Creates a plain object from a Metadata message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {phrase_spotting.Metadata} message Metadata
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Metadata.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.utteranceUid != null && message.hasOwnProperty("utteranceUid")) {
                    object.utteranceUid = message.utteranceUid;
                    if (options.oneofs)
                        object._utteranceUid = "utteranceUid";
                }
                if (message.callId != null && message.hasOwnProperty("callId")) {
                    object.callId = message.callId;
                    if (options.oneofs)
                        object._callId = "callId";
                }
                return object;
            };
    
            /**
             * Converts this Metadata to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Metadata
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Metadata.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Metadata
             * @function getTypeUrl
             * @memberof phrase_spotting.Metadata
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Metadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Metadata";
            };
    
            return Metadata;
        })();
    
        phrase_spotting.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof phrase_spotting
             * @interface IRequest
             * @property {phrase_spotting.IConfiguration|null} [config] Request config
             * @property {phrase_spotting.IPayload|null} [payload] Request payload
             */
    
            /**
             * Constructs a new Request.
             * @memberof phrase_spotting
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {phrase_spotting.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request config.
             * @member {phrase_spotting.IConfiguration|null|undefined} config
             * @memberof phrase_spotting.Request
             * @instance
             */
            Request.prototype.config = null;
    
            /**
             * Request payload.
             * @member {phrase_spotting.IPayload|null|undefined} payload
             * @memberof phrase_spotting.Request
             * @instance
             */
            Request.prototype.payload = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Request data.
             * @member {"config"|"payload"|undefined} data
             * @memberof phrase_spotting.Request
             * @instance
             */
            Object.defineProperty(Request.prototype, "data", {
                get: $util.oneOfGetter($oneOfFields = ["config", "payload"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Request
             * @static
             * @param {phrase_spotting.IRequest=} [properties] Properties to set
             * @returns {phrase_spotting.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link phrase_spotting.Request.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Request
             * @static
             * @param {phrase_spotting.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                    $root.phrase_spotting.Configuration.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                    $root.phrase_spotting.Payload.encode(message.payload, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link phrase_spotting.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Request
             * @static
             * @param {phrase_spotting.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.config = $root.phrase_spotting.Configuration.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.payload = $root.phrase_spotting.Payload.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof phrase_spotting.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.config != null && message.hasOwnProperty("config")) {
                    properties.data = 1;
                    {
                        var error = $root.phrase_spotting.Configuration.verify(message.config);
                        if (error)
                            return "config." + error;
                    }
                }
                if (message.payload != null && message.hasOwnProperty("payload")) {
                    if (properties.data === 1)
                        return "data: multiple values";
                    properties.data = 1;
                    {
                        var error = $root.phrase_spotting.Payload.verify(message.payload);
                        if (error)
                            return "payload." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Request)
                    return object;
                var message = new $root.phrase_spotting.Request();
                if (object.config != null) {
                    if (typeof object.config !== "object")
                        throw TypeError(".phrase_spotting.Request.config: object expected");
                    message.config = $root.phrase_spotting.Configuration.fromObject(object.config);
                }
                if (object.payload != null) {
                    if (typeof object.payload !== "object")
                        throw TypeError(".phrase_spotting.Request.payload: object expected");
                    message.payload = $root.phrase_spotting.Payload.fromObject(object.payload);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Request
             * @static
             * @param {phrase_spotting.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.config != null && message.hasOwnProperty("config")) {
                    object.config = $root.phrase_spotting.Configuration.toObject(message.config, options);
                    if (options.oneofs)
                        object.data = "config";
                }
                if (message.payload != null && message.hasOwnProperty("payload")) {
                    object.payload = $root.phrase_spotting.Payload.toObject(message.payload, options);
                    if (options.oneofs)
                        object.data = "payload";
                }
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof phrase_spotting.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Request";
            };
    
            return Request;
        })();
    
        phrase_spotting.Payload = (function() {
    
            /**
             * Properties of a Payload.
             * @memberof phrase_spotting
             * @interface IPayload
             * @property {Array.<phrase_spotting.Payload.IRequestItem>|null} [items] Payload items
             * @property {boolean|null} [verbose] Payload verbose
             */
    
            /**
             * Constructs a new Payload.
             * @memberof phrase_spotting
             * @classdesc Represents a Payload.
             * @implements IPayload
             * @constructor
             * @param {phrase_spotting.IPayload=} [properties] Properties to set
             */
            function Payload(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Payload items.
             * @member {Array.<phrase_spotting.Payload.IRequestItem>} items
             * @memberof phrase_spotting.Payload
             * @instance
             */
            Payload.prototype.items = $util.emptyArray;
    
            /**
             * Payload verbose.
             * @member {boolean} verbose
             * @memberof phrase_spotting.Payload
             * @instance
             */
            Payload.prototype.verbose = false;
    
            /**
             * Creates a new Payload instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Payload
             * @static
             * @param {phrase_spotting.IPayload=} [properties] Properties to set
             * @returns {phrase_spotting.Payload} Payload instance
             */
            Payload.create = function create(properties) {
                return new Payload(properties);
            };
    
            /**
             * Encodes the specified Payload message. Does not implicitly {@link phrase_spotting.Payload.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Payload
             * @static
             * @param {phrase_spotting.IPayload} message Payload message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Payload.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.phrase_spotting.Payload.RequestItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.verbose != null && Object.hasOwnProperty.call(message, "verbose"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.verbose);
                return writer;
            };
    
            /**
             * Encodes the specified Payload message, length delimited. Does not implicitly {@link phrase_spotting.Payload.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Payload
             * @static
             * @param {phrase_spotting.IPayload} message Payload message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Payload.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Payload message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Payload
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Payload} Payload
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Payload.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Payload();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.phrase_spotting.Payload.RequestItem.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.verbose = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Payload message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Payload
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Payload} Payload
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Payload.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Payload message.
             * @function verify
             * @memberof phrase_spotting.Payload
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Payload.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.phrase_spotting.Payload.RequestItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.verbose != null && message.hasOwnProperty("verbose"))
                    if (typeof message.verbose !== "boolean")
                        return "verbose: boolean expected";
                return null;
            };
    
            /**
             * Creates a Payload message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Payload
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Payload} Payload
             */
            Payload.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Payload)
                    return object;
                var message = new $root.phrase_spotting.Payload();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".phrase_spotting.Payload.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".phrase_spotting.Payload.items: object expected");
                        message.items[i] = $root.phrase_spotting.Payload.RequestItem.fromObject(object.items[i]);
                    }
                }
                if (object.verbose != null)
                    message.verbose = Boolean(object.verbose);
                return message;
            };
    
            /**
             * Creates a plain object from a Payload message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Payload
             * @static
             * @param {phrase_spotting.Payload} message Payload
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Payload.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (options.defaults)
                    object.verbose = false;
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.phrase_spotting.Payload.RequestItem.toObject(message.items[j], options);
                }
                if (message.verbose != null && message.hasOwnProperty("verbose"))
                    object.verbose = message.verbose;
                return object;
            };
    
            /**
             * Converts this Payload to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Payload
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Payload.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Payload
             * @function getTypeUrl
             * @memberof phrase_spotting.Payload
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Payload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Payload";
            };
    
            Payload.RequestItem = (function() {
    
                /**
                 * Properties of a RequestItem.
                 * @memberof phrase_spotting.Payload
                 * @interface IRequestItem
                 * @property {string|null} [text] RequestItem text
                 * @property {phrase_spotting.IMetadata|null} [meta] RequestItem meta
                 */
    
                /**
                 * Constructs a new RequestItem.
                 * @memberof phrase_spotting.Payload
                 * @classdesc Represents a RequestItem.
                 * @implements IRequestItem
                 * @constructor
                 * @param {phrase_spotting.Payload.IRequestItem=} [properties] Properties to set
                 */
                function RequestItem(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RequestItem text.
                 * @member {string} text
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @instance
                 */
                RequestItem.prototype.text = "";
    
                /**
                 * RequestItem meta.
                 * @member {phrase_spotting.IMetadata|null|undefined} meta
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @instance
                 */
                RequestItem.prototype.meta = null;
    
                /**
                 * Creates a new RequestItem instance using the specified properties.
                 * @function create
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {phrase_spotting.Payload.IRequestItem=} [properties] Properties to set
                 * @returns {phrase_spotting.Payload.RequestItem} RequestItem instance
                 */
                RequestItem.create = function create(properties) {
                    return new RequestItem(properties);
                };
    
                /**
                 * Encodes the specified RequestItem message. Does not implicitly {@link phrase_spotting.Payload.RequestItem.verify|verify} messages.
                 * @function encode
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {phrase_spotting.Payload.IRequestItem} message RequestItem message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestItem.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                    if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
                        $root.phrase_spotting.Metadata.encode(message.meta, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified RequestItem message, length delimited. Does not implicitly {@link phrase_spotting.Payload.RequestItem.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {phrase_spotting.Payload.IRequestItem} message RequestItem message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestItem.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RequestItem message from the specified reader or buffer.
                 * @function decode
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {phrase_spotting.Payload.RequestItem} RequestItem
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestItem.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Payload.RequestItem();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.text = reader.string();
                                break;
                            }
                        case 2: {
                                message.meta = $root.phrase_spotting.Metadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RequestItem message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {phrase_spotting.Payload.RequestItem} RequestItem
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestItem.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RequestItem message.
                 * @function verify
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.text != null && message.hasOwnProperty("text"))
                        if (!$util.isString(message.text))
                            return "text: string expected";
                    if (message.meta != null && message.hasOwnProperty("meta")) {
                        var error = $root.phrase_spotting.Metadata.verify(message.meta);
                        if (error)
                            return "meta." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a RequestItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {phrase_spotting.Payload.RequestItem} RequestItem
                 */
                RequestItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.phrase_spotting.Payload.RequestItem)
                        return object;
                    var message = new $root.phrase_spotting.Payload.RequestItem();
                    if (object.text != null)
                        message.text = String(object.text);
                    if (object.meta != null) {
                        if (typeof object.meta !== "object")
                            throw TypeError(".phrase_spotting.Payload.RequestItem.meta: object expected");
                        message.meta = $root.phrase_spotting.Metadata.fromObject(object.meta);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RequestItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {phrase_spotting.Payload.RequestItem} message RequestItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.text = "";
                        object.meta = null;
                    }
                    if (message.text != null && message.hasOwnProperty("text"))
                        object.text = message.text;
                    if (message.meta != null && message.hasOwnProperty("meta"))
                        object.meta = $root.phrase_spotting.Metadata.toObject(message.meta, options);
                    return object;
                };
    
                /**
                 * Converts this RequestItem to JSON.
                 * @function toJSON
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RequestItem
                 * @function getTypeUrl
                 * @memberof phrase_spotting.Payload.RequestItem
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RequestItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/phrase_spotting.Payload.RequestItem";
                };
    
                return RequestItem;
            })();
    
            return Payload;
        })();
    
        phrase_spotting.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof phrase_spotting
             * @interface IResponse
             * @property {Array.<phrase_spotting.Response.IResponseResult>|null} [results] Response results
             */
    
            /**
             * Constructs a new Response.
             * @memberof phrase_spotting
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {phrase_spotting.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                this.results = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response results.
             * @member {Array.<phrase_spotting.Response.IResponseResult>} results
             * @memberof phrase_spotting.Response
             * @instance
             */
            Response.prototype.results = $util.emptyArray;
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Response
             * @static
             * @param {phrase_spotting.IResponse=} [properties] Properties to set
             * @returns {phrase_spotting.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link phrase_spotting.Response.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Response
             * @static
             * @param {phrase_spotting.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.results != null && message.results.length)
                    for (var i = 0; i < message.results.length; ++i)
                        $root.phrase_spotting.Response.ResponseResult.encode(message.results[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link phrase_spotting.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Response
             * @static
             * @param {phrase_spotting.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.results && message.results.length))
                                message.results = [];
                            message.results.push($root.phrase_spotting.Response.ResponseResult.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof phrase_spotting.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.results != null && message.hasOwnProperty("results")) {
                    if (!Array.isArray(message.results))
                        return "results: array expected";
                    for (var i = 0; i < message.results.length; ++i) {
                        var error = $root.phrase_spotting.Response.ResponseResult.verify(message.results[i]);
                        if (error)
                            return "results." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Response)
                    return object;
                var message = new $root.phrase_spotting.Response();
                if (object.results) {
                    if (!Array.isArray(object.results))
                        throw TypeError(".phrase_spotting.Response.results: array expected");
                    message.results = [];
                    for (var i = 0; i < object.results.length; ++i) {
                        if (typeof object.results[i] !== "object")
                            throw TypeError(".phrase_spotting.Response.results: object expected");
                        message.results[i] = $root.phrase_spotting.Response.ResponseResult.fromObject(object.results[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Response
             * @static
             * @param {phrase_spotting.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.results = [];
                if (message.results && message.results.length) {
                    object.results = [];
                    for (var j = 0; j < message.results.length; ++j)
                        object.results[j] = $root.phrase_spotting.Response.ResponseResult.toObject(message.results[j], options);
                }
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof phrase_spotting.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Response";
            };
    
            Response.ResponseResult = (function() {
    
                /**
                 * Properties of a ResponseResult.
                 * @memberof phrase_spotting.Response
                 * @interface IResponseResult
                 * @property {string|null} [text] ResponseResult text
                 * @property {number|null} [score] ResponseResult score
                 * @property {Array.<phrase_spotting.ISpan>|null} [spans] ResponseResult spans
                 * @property {string|null} [configurationItemId] ResponseResult configurationItemId
                 * @property {phrase_spotting.IConfigurationItem|null} [configurationItem] ResponseResult configurationItem
                 * @property {phrase_spotting.IMetadata|null} [meta] ResponseResult meta
                 * @property {phrase_spotting.IResponseInfoStrict|null} [strict] ResponseResult strict
                 * @property {phrase_spotting.IResponseInfoSemantic|null} [semantic] ResponseResult semantic
                 * @property {string|null} [configurationItemTag] ResponseResult configurationItemTag
                 */
    
                /**
                 * Constructs a new ResponseResult.
                 * @memberof phrase_spotting.Response
                 * @classdesc Represents a ResponseResult.
                 * @implements IResponseResult
                 * @constructor
                 * @param {phrase_spotting.Response.IResponseResult=} [properties] Properties to set
                 */
                function ResponseResult(properties) {
                    this.spans = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ResponseResult text.
                 * @member {string|null|undefined} text
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.text = null;
    
                /**
                 * ResponseResult score.
                 * @member {number|null|undefined} score
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.score = null;
    
                /**
                 * ResponseResult spans.
                 * @member {Array.<phrase_spotting.ISpan>} spans
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.spans = $util.emptyArray;
    
                /**
                 * ResponseResult configurationItemId.
                 * @member {string} configurationItemId
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.configurationItemId = "";
    
                /**
                 * ResponseResult configurationItem.
                 * @member {phrase_spotting.IConfigurationItem|null|undefined} configurationItem
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.configurationItem = null;
    
                /**
                 * ResponseResult meta.
                 * @member {phrase_spotting.IMetadata|null|undefined} meta
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.meta = null;
    
                /**
                 * ResponseResult strict.
                 * @member {phrase_spotting.IResponseInfoStrict|null|undefined} strict
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.strict = null;
    
                /**
                 * ResponseResult semantic.
                 * @member {phrase_spotting.IResponseInfoSemantic|null|undefined} semantic
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.semantic = null;
    
                /**
                 * ResponseResult configurationItemTag.
                 * @member {string|null|undefined} configurationItemTag
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                ResponseResult.prototype.configurationItemTag = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * ResponseResult _text.
                 * @member {"text"|undefined} _text
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                Object.defineProperty(ResponseResult.prototype, "_text", {
                    get: $util.oneOfGetter($oneOfFields = ["text"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * ResponseResult _score.
                 * @member {"score"|undefined} _score
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                Object.defineProperty(ResponseResult.prototype, "_score", {
                    get: $util.oneOfGetter($oneOfFields = ["score"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * ResponseResult _configurationItem.
                 * @member {"configurationItem"|undefined} _configurationItem
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                Object.defineProperty(ResponseResult.prototype, "_configurationItem", {
                    get: $util.oneOfGetter($oneOfFields = ["configurationItem"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * ResponseResult spotterResponse.
                 * @member {"strict"|"semantic"|undefined} spotterResponse
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                Object.defineProperty(ResponseResult.prototype, "spotterResponse", {
                    get: $util.oneOfGetter($oneOfFields = ["strict", "semantic"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * ResponseResult _configurationItemTag.
                 * @member {"configurationItemTag"|undefined} _configurationItemTag
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 */
                Object.defineProperty(ResponseResult.prototype, "_configurationItemTag", {
                    get: $util.oneOfGetter($oneOfFields = ["configurationItemTag"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new ResponseResult instance using the specified properties.
                 * @function create
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {phrase_spotting.Response.IResponseResult=} [properties] Properties to set
                 * @returns {phrase_spotting.Response.ResponseResult} ResponseResult instance
                 */
                ResponseResult.create = function create(properties) {
                    return new ResponseResult(properties);
                };
    
                /**
                 * Encodes the specified ResponseResult message. Does not implicitly {@link phrase_spotting.Response.ResponseResult.verify|verify} messages.
                 * @function encode
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {phrase_spotting.Response.IResponseResult} message ResponseResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseResult.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                    if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.score);
                    if (message.spans != null && message.spans.length)
                        for (var i = 0; i < message.spans.length; ++i)
                            $root.phrase_spotting.Span.encode(message.spans[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.configurationItemId != null && Object.hasOwnProperty.call(message, "configurationItemId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.configurationItemId);
                    if (message.configurationItem != null && Object.hasOwnProperty.call(message, "configurationItem"))
                        $root.phrase_spotting.ConfigurationItem.encode(message.configurationItem, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
                        $root.phrase_spotting.Metadata.encode(message.meta, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.strict != null && Object.hasOwnProperty.call(message, "strict"))
                        $root.phrase_spotting.ResponseInfoStrict.encode(message.strict, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.semantic != null && Object.hasOwnProperty.call(message, "semantic"))
                        $root.phrase_spotting.ResponseInfoSemantic.encode(message.semantic, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.configurationItemTag != null && Object.hasOwnProperty.call(message, "configurationItemTag"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.configurationItemTag);
                    return writer;
                };
    
                /**
                 * Encodes the specified ResponseResult message, length delimited. Does not implicitly {@link phrase_spotting.Response.ResponseResult.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {phrase_spotting.Response.IResponseResult} message ResponseResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResponseResult.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ResponseResult message from the specified reader or buffer.
                 * @function decode
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {phrase_spotting.Response.ResponseResult} ResponseResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseResult.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Response.ResponseResult();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.text = reader.string();
                                break;
                            }
                        case 2: {
                                message.score = reader.float();
                                break;
                            }
                        case 3: {
                                if (!(message.spans && message.spans.length))
                                    message.spans = [];
                                message.spans.push($root.phrase_spotting.Span.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.configurationItemId = reader.string();
                                break;
                            }
                        case 5: {
                                message.configurationItem = $root.phrase_spotting.ConfigurationItem.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.meta = $root.phrase_spotting.Metadata.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.strict = $root.phrase_spotting.ResponseInfoStrict.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.semantic = $root.phrase_spotting.ResponseInfoSemantic.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.configurationItemTag = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ResponseResult message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {phrase_spotting.Response.ResponseResult} ResponseResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResponseResult.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ResponseResult message.
                 * @function verify
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResponseResult.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.text != null && message.hasOwnProperty("text")) {
                        properties._text = 1;
                        if (!$util.isString(message.text))
                            return "text: string expected";
                    }
                    if (message.score != null && message.hasOwnProperty("score")) {
                        properties._score = 1;
                        if (typeof message.score !== "number")
                            return "score: number expected";
                    }
                    if (message.spans != null && message.hasOwnProperty("spans")) {
                        if (!Array.isArray(message.spans))
                            return "spans: array expected";
                        for (var i = 0; i < message.spans.length; ++i) {
                            var error = $root.phrase_spotting.Span.verify(message.spans[i]);
                            if (error)
                                return "spans." + error;
                        }
                    }
                    if (message.configurationItemId != null && message.hasOwnProperty("configurationItemId"))
                        if (!$util.isString(message.configurationItemId))
                            return "configurationItemId: string expected";
                    if (message.configurationItem != null && message.hasOwnProperty("configurationItem")) {
                        properties._configurationItem = 1;
                        {
                            var error = $root.phrase_spotting.ConfigurationItem.verify(message.configurationItem);
                            if (error)
                                return "configurationItem." + error;
                        }
                    }
                    if (message.meta != null && message.hasOwnProperty("meta")) {
                        var error = $root.phrase_spotting.Metadata.verify(message.meta);
                        if (error)
                            return "meta." + error;
                    }
                    if (message.strict != null && message.hasOwnProperty("strict")) {
                        properties.spotterResponse = 1;
                        {
                            var error = $root.phrase_spotting.ResponseInfoStrict.verify(message.strict);
                            if (error)
                                return "strict." + error;
                        }
                    }
                    if (message.semantic != null && message.hasOwnProperty("semantic")) {
                        if (properties.spotterResponse === 1)
                            return "spotterResponse: multiple values";
                        properties.spotterResponse = 1;
                        {
                            var error = $root.phrase_spotting.ResponseInfoSemantic.verify(message.semantic);
                            if (error)
                                return "semantic." + error;
                        }
                    }
                    if (message.configurationItemTag != null && message.hasOwnProperty("configurationItemTag")) {
                        properties._configurationItemTag = 1;
                        if (!$util.isString(message.configurationItemTag))
                            return "configurationItemTag: string expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a ResponseResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {phrase_spotting.Response.ResponseResult} ResponseResult
                 */
                ResponseResult.fromObject = function fromObject(object) {
                    if (object instanceof $root.phrase_spotting.Response.ResponseResult)
                        return object;
                    var message = new $root.phrase_spotting.Response.ResponseResult();
                    if (object.text != null)
                        message.text = String(object.text);
                    if (object.score != null)
                        message.score = Number(object.score);
                    if (object.spans) {
                        if (!Array.isArray(object.spans))
                            throw TypeError(".phrase_spotting.Response.ResponseResult.spans: array expected");
                        message.spans = [];
                        for (var i = 0; i < object.spans.length; ++i) {
                            if (typeof object.spans[i] !== "object")
                                throw TypeError(".phrase_spotting.Response.ResponseResult.spans: object expected");
                            message.spans[i] = $root.phrase_spotting.Span.fromObject(object.spans[i]);
                        }
                    }
                    if (object.configurationItemId != null)
                        message.configurationItemId = String(object.configurationItemId);
                    if (object.configurationItem != null) {
                        if (typeof object.configurationItem !== "object")
                            throw TypeError(".phrase_spotting.Response.ResponseResult.configurationItem: object expected");
                        message.configurationItem = $root.phrase_spotting.ConfigurationItem.fromObject(object.configurationItem);
                    }
                    if (object.meta != null) {
                        if (typeof object.meta !== "object")
                            throw TypeError(".phrase_spotting.Response.ResponseResult.meta: object expected");
                        message.meta = $root.phrase_spotting.Metadata.fromObject(object.meta);
                    }
                    if (object.strict != null) {
                        if (typeof object.strict !== "object")
                            throw TypeError(".phrase_spotting.Response.ResponseResult.strict: object expected");
                        message.strict = $root.phrase_spotting.ResponseInfoStrict.fromObject(object.strict);
                    }
                    if (object.semantic != null) {
                        if (typeof object.semantic !== "object")
                            throw TypeError(".phrase_spotting.Response.ResponseResult.semantic: object expected");
                        message.semantic = $root.phrase_spotting.ResponseInfoSemantic.fromObject(object.semantic);
                    }
                    if (object.configurationItemTag != null)
                        message.configurationItemTag = String(object.configurationItemTag);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ResponseResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {phrase_spotting.Response.ResponseResult} message ResponseResult
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResponseResult.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.spans = [];
                    if (options.defaults) {
                        object.configurationItemId = "";
                        object.meta = null;
                    }
                    if (message.text != null && message.hasOwnProperty("text")) {
                        object.text = message.text;
                        if (options.oneofs)
                            object._text = "text";
                    }
                    if (message.score != null && message.hasOwnProperty("score")) {
                        object.score = options.json && !isFinite(message.score) ? String(message.score) : message.score;
                        if (options.oneofs)
                            object._score = "score";
                    }
                    if (message.spans && message.spans.length) {
                        object.spans = [];
                        for (var j = 0; j < message.spans.length; ++j)
                            object.spans[j] = $root.phrase_spotting.Span.toObject(message.spans[j], options);
                    }
                    if (message.configurationItemId != null && message.hasOwnProperty("configurationItemId"))
                        object.configurationItemId = message.configurationItemId;
                    if (message.configurationItem != null && message.hasOwnProperty("configurationItem")) {
                        object.configurationItem = $root.phrase_spotting.ConfigurationItem.toObject(message.configurationItem, options);
                        if (options.oneofs)
                            object._configurationItem = "configurationItem";
                    }
                    if (message.meta != null && message.hasOwnProperty("meta"))
                        object.meta = $root.phrase_spotting.Metadata.toObject(message.meta, options);
                    if (message.strict != null && message.hasOwnProperty("strict")) {
                        object.strict = $root.phrase_spotting.ResponseInfoStrict.toObject(message.strict, options);
                        if (options.oneofs)
                            object.spotterResponse = "strict";
                    }
                    if (message.semantic != null && message.hasOwnProperty("semantic")) {
                        object.semantic = $root.phrase_spotting.ResponseInfoSemantic.toObject(message.semantic, options);
                        if (options.oneofs)
                            object.spotterResponse = "semantic";
                    }
                    if (message.configurationItemTag != null && message.hasOwnProperty("configurationItemTag")) {
                        object.configurationItemTag = message.configurationItemTag;
                        if (options.oneofs)
                            object._configurationItemTag = "configurationItemTag";
                    }
                    return object;
                };
    
                /**
                 * Converts this ResponseResult to JSON.
                 * @function toJSON
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResponseResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ResponseResult
                 * @function getTypeUrl
                 * @memberof phrase_spotting.Response.ResponseResult
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ResponseResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/phrase_spotting.Response.ResponseResult";
                };
    
                return ResponseResult;
            })();
    
            return Response;
        })();
    
        phrase_spotting.SpotterConfigurationStrict = (function() {
    
            /**
             * Properties of a SpotterConfigurationStrict.
             * @memberof phrase_spotting
             * @interface ISpotterConfigurationStrict
             * @property {number|null} [collapseLen] SpotterConfigurationStrict collapseLen
             */
    
            /**
             * Constructs a new SpotterConfigurationStrict.
             * @memberof phrase_spotting
             * @classdesc Represents a SpotterConfigurationStrict.
             * @implements ISpotterConfigurationStrict
             * @constructor
             * @param {phrase_spotting.ISpotterConfigurationStrict=} [properties] Properties to set
             */
            function SpotterConfigurationStrict(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SpotterConfigurationStrict collapseLen.
             * @member {number} collapseLen
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @instance
             */
            SpotterConfigurationStrict.prototype.collapseLen = 0;
    
            /**
             * Creates a new SpotterConfigurationStrict instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {phrase_spotting.ISpotterConfigurationStrict=} [properties] Properties to set
             * @returns {phrase_spotting.SpotterConfigurationStrict} SpotterConfigurationStrict instance
             */
            SpotterConfigurationStrict.create = function create(properties) {
                return new SpotterConfigurationStrict(properties);
            };
    
            /**
             * Encodes the specified SpotterConfigurationStrict message. Does not implicitly {@link phrase_spotting.SpotterConfigurationStrict.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {phrase_spotting.ISpotterConfigurationStrict} message SpotterConfigurationStrict message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpotterConfigurationStrict.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.collapseLen != null && Object.hasOwnProperty.call(message, "collapseLen"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.collapseLen);
                return writer;
            };
    
            /**
             * Encodes the specified SpotterConfigurationStrict message, length delimited. Does not implicitly {@link phrase_spotting.SpotterConfigurationStrict.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {phrase_spotting.ISpotterConfigurationStrict} message SpotterConfigurationStrict message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpotterConfigurationStrict.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SpotterConfigurationStrict message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.SpotterConfigurationStrict} SpotterConfigurationStrict
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpotterConfigurationStrict.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.SpotterConfigurationStrict();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.collapseLen = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SpotterConfigurationStrict message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.SpotterConfigurationStrict} SpotterConfigurationStrict
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpotterConfigurationStrict.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SpotterConfigurationStrict message.
             * @function verify
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SpotterConfigurationStrict.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.collapseLen != null && message.hasOwnProperty("collapseLen"))
                    if (!$util.isInteger(message.collapseLen))
                        return "collapseLen: integer expected";
                return null;
            };
    
            /**
             * Creates a SpotterConfigurationStrict message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.SpotterConfigurationStrict} SpotterConfigurationStrict
             */
            SpotterConfigurationStrict.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.SpotterConfigurationStrict)
                    return object;
                var message = new $root.phrase_spotting.SpotterConfigurationStrict();
                if (object.collapseLen != null)
                    message.collapseLen = object.collapseLen | 0;
                return message;
            };
    
            /**
             * Creates a plain object from a SpotterConfigurationStrict message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {phrase_spotting.SpotterConfigurationStrict} message SpotterConfigurationStrict
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SpotterConfigurationStrict.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.collapseLen = 0;
                if (message.collapseLen != null && message.hasOwnProperty("collapseLen"))
                    object.collapseLen = message.collapseLen;
                return object;
            };
    
            /**
             * Converts this SpotterConfigurationStrict to JSON.
             * @function toJSON
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SpotterConfigurationStrict.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SpotterConfigurationStrict
             * @function getTypeUrl
             * @memberof phrase_spotting.SpotterConfigurationStrict
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SpotterConfigurationStrict.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.SpotterConfigurationStrict";
            };
    
            return SpotterConfigurationStrict;
        })();
    
        phrase_spotting.SpotterConfigurationSematic = (function() {
    
            /**
             * Properties of a SpotterConfigurationSematic.
             * @memberof phrase_spotting
             * @interface ISpotterConfigurationSematic
             * @property {number|null} [threshold] SpotterConfigurationSematic threshold
             */
    
            /**
             * Constructs a new SpotterConfigurationSematic.
             * @memberof phrase_spotting
             * @classdesc Represents a SpotterConfigurationSematic.
             * @implements ISpotterConfigurationSematic
             * @constructor
             * @param {phrase_spotting.ISpotterConfigurationSematic=} [properties] Properties to set
             */
            function SpotterConfigurationSematic(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SpotterConfigurationSematic threshold.
             * @member {number} threshold
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @instance
             */
            SpotterConfigurationSematic.prototype.threshold = 0;
    
            /**
             * Creates a new SpotterConfigurationSematic instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {phrase_spotting.ISpotterConfigurationSematic=} [properties] Properties to set
             * @returns {phrase_spotting.SpotterConfigurationSematic} SpotterConfigurationSematic instance
             */
            SpotterConfigurationSematic.create = function create(properties) {
                return new SpotterConfigurationSematic(properties);
            };
    
            /**
             * Encodes the specified SpotterConfigurationSematic message. Does not implicitly {@link phrase_spotting.SpotterConfigurationSematic.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {phrase_spotting.ISpotterConfigurationSematic} message SpotterConfigurationSematic message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpotterConfigurationSematic.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.threshold != null && Object.hasOwnProperty.call(message, "threshold"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.threshold);
                return writer;
            };
    
            /**
             * Encodes the specified SpotterConfigurationSematic message, length delimited. Does not implicitly {@link phrase_spotting.SpotterConfigurationSematic.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {phrase_spotting.ISpotterConfigurationSematic} message SpotterConfigurationSematic message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SpotterConfigurationSematic.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SpotterConfigurationSematic message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.SpotterConfigurationSematic} SpotterConfigurationSematic
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpotterConfigurationSematic.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.SpotterConfigurationSematic();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.threshold = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SpotterConfigurationSematic message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.SpotterConfigurationSematic} SpotterConfigurationSematic
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SpotterConfigurationSematic.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SpotterConfigurationSematic message.
             * @function verify
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SpotterConfigurationSematic.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.threshold != null && message.hasOwnProperty("threshold"))
                    if (typeof message.threshold !== "number")
                        return "threshold: number expected";
                return null;
            };
    
            /**
             * Creates a SpotterConfigurationSematic message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.SpotterConfigurationSematic} SpotterConfigurationSematic
             */
            SpotterConfigurationSematic.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.SpotterConfigurationSematic)
                    return object;
                var message = new $root.phrase_spotting.SpotterConfigurationSematic();
                if (object.threshold != null)
                    message.threshold = Number(object.threshold);
                return message;
            };
    
            /**
             * Creates a plain object from a SpotterConfigurationSematic message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {phrase_spotting.SpotterConfigurationSematic} message SpotterConfigurationSematic
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SpotterConfigurationSematic.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.threshold = 0;
                if (message.threshold != null && message.hasOwnProperty("threshold"))
                    object.threshold = options.json && !isFinite(message.threshold) ? String(message.threshold) : message.threshold;
                return object;
            };
    
            /**
             * Converts this SpotterConfigurationSematic to JSON.
             * @function toJSON
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SpotterConfigurationSematic.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for SpotterConfigurationSematic
             * @function getTypeUrl
             * @memberof phrase_spotting.SpotterConfigurationSematic
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SpotterConfigurationSematic.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.SpotterConfigurationSematic";
            };
    
            return SpotterConfigurationSematic;
        })();
    
        /**
         * SpotterKind enum.
         * @name phrase_spotting.SpotterKind
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} STRICT=1 STRICT value
         * @property {number} SEMANTIC=2 SEMANTIC value
         */
        phrase_spotting.SpotterKind = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "STRICT"] = 1;
            values[valuesById[2] = "SEMANTIC"] = 2;
            return values;
        })();
    
        phrase_spotting.ConfigurationItem = (function() {
    
            /**
             * Properties of a ConfigurationItem.
             * @memberof phrase_spotting
             * @interface IConfigurationItem
             * @property {string|null} [id] ConfigurationItem id
             * @property {phrase_spotting.SpotterKind|null} [kind] ConfigurationItem kind
             * @property {string|null} [groupName] ConfigurationItem groupName
             * @property {Array.<string>|null} [head] ConfigurationItem head
             * @property {Array.<string>|null} [complement] ConfigurationItem complement
             * @property {string|null} [tag] ConfigurationItem tag
             */
    
            /**
             * Constructs a new ConfigurationItem.
             * @memberof phrase_spotting
             * @classdesc Represents a ConfigurationItem.
             * @implements IConfigurationItem
             * @constructor
             * @param {phrase_spotting.IConfigurationItem=} [properties] Properties to set
             */
            function ConfigurationItem(properties) {
                this.head = [];
                this.complement = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ConfigurationItem id.
             * @member {string} id
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.id = "";
    
            /**
             * ConfigurationItem kind.
             * @member {phrase_spotting.SpotterKind} kind
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.kind = 0;
    
            /**
             * ConfigurationItem groupName.
             * @member {string} groupName
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.groupName = "";
    
            /**
             * ConfigurationItem head.
             * @member {Array.<string>} head
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.head = $util.emptyArray;
    
            /**
             * ConfigurationItem complement.
             * @member {Array.<string>} complement
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.complement = $util.emptyArray;
    
            /**
             * ConfigurationItem tag.
             * @member {string|null|undefined} tag
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            ConfigurationItem.prototype.tag = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * ConfigurationItem _tag.
             * @member {"tag"|undefined} _tag
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             */
            Object.defineProperty(ConfigurationItem.prototype, "_tag", {
                get: $util.oneOfGetter($oneOfFields = ["tag"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new ConfigurationItem instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {phrase_spotting.IConfigurationItem=} [properties] Properties to set
             * @returns {phrase_spotting.ConfigurationItem} ConfigurationItem instance
             */
            ConfigurationItem.create = function create(properties) {
                return new ConfigurationItem(properties);
            };
    
            /**
             * Encodes the specified ConfigurationItem message. Does not implicitly {@link phrase_spotting.ConfigurationItem.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {phrase_spotting.IConfigurationItem} message ConfigurationItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfigurationItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.kind);
                if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.groupName);
                if (message.head != null && message.head.length)
                    for (var i = 0; i < message.head.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.head[i]);
                if (message.complement != null && message.complement.length)
                    for (var i = 0; i < message.complement.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.complement[i]);
                if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.tag);
                return writer;
            };
    
            /**
             * Encodes the specified ConfigurationItem message, length delimited. Does not implicitly {@link phrase_spotting.ConfigurationItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {phrase_spotting.IConfigurationItem} message ConfigurationItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfigurationItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ConfigurationItem message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.ConfigurationItem} ConfigurationItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfigurationItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.ConfigurationItem();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.kind = reader.int32();
                            break;
                        }
                    case 3: {
                            message.groupName = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.head && message.head.length))
                                message.head = [];
                            message.head.push(reader.string());
                            break;
                        }
                    case 5: {
                            if (!(message.complement && message.complement.length))
                                message.complement = [];
                            message.complement.push(reader.string());
                            break;
                        }
                    case 6: {
                            message.tag = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ConfigurationItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.ConfigurationItem} ConfigurationItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfigurationItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ConfigurationItem message.
             * @function verify
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConfigurationItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.kind != null && message.hasOwnProperty("kind"))
                    switch (message.kind) {
                    default:
                        return "kind: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    if (!$util.isString(message.groupName))
                        return "groupName: string expected";
                if (message.head != null && message.hasOwnProperty("head")) {
                    if (!Array.isArray(message.head))
                        return "head: array expected";
                    for (var i = 0; i < message.head.length; ++i)
                        if (!$util.isString(message.head[i]))
                            return "head: string[] expected";
                }
                if (message.complement != null && message.hasOwnProperty("complement")) {
                    if (!Array.isArray(message.complement))
                        return "complement: array expected";
                    for (var i = 0; i < message.complement.length; ++i)
                        if (!$util.isString(message.complement[i]))
                            return "complement: string[] expected";
                }
                if (message.tag != null && message.hasOwnProperty("tag")) {
                    properties._tag = 1;
                    if (!$util.isString(message.tag))
                        return "tag: string expected";
                }
                return null;
            };
    
            /**
             * Creates a ConfigurationItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.ConfigurationItem} ConfigurationItem
             */
            ConfigurationItem.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.ConfigurationItem)
                    return object;
                var message = new $root.phrase_spotting.ConfigurationItem();
                if (object.id != null)
                    message.id = String(object.id);
                switch (object.kind) {
                default:
                    if (typeof object.kind === "number") {
                        message.kind = object.kind;
                        break;
                    }
                    break;
                case "UNKNOWN":
                case 0:
                    message.kind = 0;
                    break;
                case "STRICT":
                case 1:
                    message.kind = 1;
                    break;
                case "SEMANTIC":
                case 2:
                    message.kind = 2;
                    break;
                }
                if (object.groupName != null)
                    message.groupName = String(object.groupName);
                if (object.head) {
                    if (!Array.isArray(object.head))
                        throw TypeError(".phrase_spotting.ConfigurationItem.head: array expected");
                    message.head = [];
                    for (var i = 0; i < object.head.length; ++i)
                        message.head[i] = String(object.head[i]);
                }
                if (object.complement) {
                    if (!Array.isArray(object.complement))
                        throw TypeError(".phrase_spotting.ConfigurationItem.complement: array expected");
                    message.complement = [];
                    for (var i = 0; i < object.complement.length; ++i)
                        message.complement[i] = String(object.complement[i]);
                }
                if (object.tag != null)
                    message.tag = String(object.tag);
                return message;
            };
    
            /**
             * Creates a plain object from a ConfigurationItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {phrase_spotting.ConfigurationItem} message ConfigurationItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConfigurationItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.head = [];
                    object.complement = [];
                }
                if (options.defaults) {
                    object.id = "";
                    object.kind = options.enums === String ? "UNKNOWN" : 0;
                    object.groupName = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.kind != null && message.hasOwnProperty("kind"))
                    object.kind = options.enums === String ? $root.phrase_spotting.SpotterKind[message.kind] === undefined ? message.kind : $root.phrase_spotting.SpotterKind[message.kind] : message.kind;
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    object.groupName = message.groupName;
                if (message.head && message.head.length) {
                    object.head = [];
                    for (var j = 0; j < message.head.length; ++j)
                        object.head[j] = message.head[j];
                }
                if (message.complement && message.complement.length) {
                    object.complement = [];
                    for (var j = 0; j < message.complement.length; ++j)
                        object.complement[j] = message.complement[j];
                }
                if (message.tag != null && message.hasOwnProperty("tag")) {
                    object.tag = message.tag;
                    if (options.oneofs)
                        object._tag = "tag";
                }
                return object;
            };
    
            /**
             * Converts this ConfigurationItem to JSON.
             * @function toJSON
             * @memberof phrase_spotting.ConfigurationItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConfigurationItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ConfigurationItem
             * @function getTypeUrl
             * @memberof phrase_spotting.ConfigurationItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ConfigurationItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.ConfigurationItem";
            };
    
            return ConfigurationItem;
        })();
    
        phrase_spotting.Configuration = (function() {
    
            /**
             * Properties of a Configuration.
             * @memberof phrase_spotting
             * @interface IConfiguration
             * @property {Array.<phrase_spotting.IConfigurationItem>|null} [items] Configuration items
             * @property {phrase_spotting.ISpotterConfigurationStrict|null} [strict] Configuration strict
             * @property {phrase_spotting.ISpotterConfigurationSematic|null} [semantic] Configuration semantic
             */
    
            /**
             * Constructs a new Configuration.
             * @memberof phrase_spotting
             * @classdesc Represents a Configuration.
             * @implements IConfiguration
             * @constructor
             * @param {phrase_spotting.IConfiguration=} [properties] Properties to set
             */
            function Configuration(properties) {
                this.items = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Configuration items.
             * @member {Array.<phrase_spotting.IConfigurationItem>} items
             * @memberof phrase_spotting.Configuration
             * @instance
             */
            Configuration.prototype.items = $util.emptyArray;
    
            /**
             * Configuration strict.
             * @member {phrase_spotting.ISpotterConfigurationStrict|null|undefined} strict
             * @memberof phrase_spotting.Configuration
             * @instance
             */
            Configuration.prototype.strict = null;
    
            /**
             * Configuration semantic.
             * @member {phrase_spotting.ISpotterConfigurationSematic|null|undefined} semantic
             * @memberof phrase_spotting.Configuration
             * @instance
             */
            Configuration.prototype.semantic = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Configuration spotterConfiguration.
             * @member {"strict"|"semantic"|undefined} spotterConfiguration
             * @memberof phrase_spotting.Configuration
             * @instance
             */
            Object.defineProperty(Configuration.prototype, "spotterConfiguration", {
                get: $util.oneOfGetter($oneOfFields = ["strict", "semantic"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Configuration instance using the specified properties.
             * @function create
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {phrase_spotting.IConfiguration=} [properties] Properties to set
             * @returns {phrase_spotting.Configuration} Configuration instance
             */
            Configuration.create = function create(properties) {
                return new Configuration(properties);
            };
    
            /**
             * Encodes the specified Configuration message. Does not implicitly {@link phrase_spotting.Configuration.verify|verify} messages.
             * @function encode
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {phrase_spotting.IConfiguration} message Configuration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Configuration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.phrase_spotting.ConfigurationItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.strict != null && Object.hasOwnProperty.call(message, "strict"))
                    $root.phrase_spotting.SpotterConfigurationStrict.encode(message.strict, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.semantic != null && Object.hasOwnProperty.call(message, "semantic"))
                    $root.phrase_spotting.SpotterConfigurationSematic.encode(message.semantic, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Configuration message, length delimited. Does not implicitly {@link phrase_spotting.Configuration.verify|verify} messages.
             * @function encodeDelimited
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {phrase_spotting.IConfiguration} message Configuration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Configuration.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Configuration message from the specified reader or buffer.
             * @function decode
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {phrase_spotting.Configuration} Configuration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Configuration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.phrase_spotting.Configuration();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.phrase_spotting.ConfigurationItem.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.strict = $root.phrase_spotting.SpotterConfigurationStrict.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.semantic = $root.phrase_spotting.SpotterConfigurationSematic.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Configuration message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {phrase_spotting.Configuration} Configuration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Configuration.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Configuration message.
             * @function verify
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Configuration.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.phrase_spotting.ConfigurationItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.strict != null && message.hasOwnProperty("strict")) {
                    properties.spotterConfiguration = 1;
                    {
                        var error = $root.phrase_spotting.SpotterConfigurationStrict.verify(message.strict);
                        if (error)
                            return "strict." + error;
                    }
                }
                if (message.semantic != null && message.hasOwnProperty("semantic")) {
                    if (properties.spotterConfiguration === 1)
                        return "spotterConfiguration: multiple values";
                    properties.spotterConfiguration = 1;
                    {
                        var error = $root.phrase_spotting.SpotterConfigurationSematic.verify(message.semantic);
                        if (error)
                            return "semantic." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Configuration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {phrase_spotting.Configuration} Configuration
             */
            Configuration.fromObject = function fromObject(object) {
                if (object instanceof $root.phrase_spotting.Configuration)
                    return object;
                var message = new $root.phrase_spotting.Configuration();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".phrase_spotting.Configuration.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".phrase_spotting.Configuration.items: object expected");
                        message.items[i] = $root.phrase_spotting.ConfigurationItem.fromObject(object.items[i]);
                    }
                }
                if (object.strict != null) {
                    if (typeof object.strict !== "object")
                        throw TypeError(".phrase_spotting.Configuration.strict: object expected");
                    message.strict = $root.phrase_spotting.SpotterConfigurationStrict.fromObject(object.strict);
                }
                if (object.semantic != null) {
                    if (typeof object.semantic !== "object")
                        throw TypeError(".phrase_spotting.Configuration.semantic: object expected");
                    message.semantic = $root.phrase_spotting.SpotterConfigurationSematic.fromObject(object.semantic);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Configuration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {phrase_spotting.Configuration} message Configuration
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Configuration.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.phrase_spotting.ConfigurationItem.toObject(message.items[j], options);
                }
                if (message.strict != null && message.hasOwnProperty("strict")) {
                    object.strict = $root.phrase_spotting.SpotterConfigurationStrict.toObject(message.strict, options);
                    if (options.oneofs)
                        object.spotterConfiguration = "strict";
                }
                if (message.semantic != null && message.hasOwnProperty("semantic")) {
                    object.semantic = $root.phrase_spotting.SpotterConfigurationSematic.toObject(message.semantic, options);
                    if (options.oneofs)
                        object.spotterConfiguration = "semantic";
                }
                return object;
            };
    
            /**
             * Converts this Configuration to JSON.
             * @function toJSON
             * @memberof phrase_spotting.Configuration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Configuration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Configuration
             * @function getTypeUrl
             * @memberof phrase_spotting.Configuration
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Configuration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/phrase_spotting.Configuration";
            };
    
            return Configuration;
        })();
    
        return phrase_spotting;
    })();

    return $root;
});
